import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, {BAFetchData} from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Tags from "./Tags";
import SegmentedLists from "./SegmentedLists";
import EmailBroadcastDetail from "./EmailBroadcastDetail";
import dayjs from 'dayjs';
import { setGlobalState, useGlobalState } from '../globalstates/GState';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CardSettings from './CardSettings';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function Reservations({ handlerReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate, isLookup, }) {

    const [currentUser] = useGlobalState("userInfo");
    const [myWorkCriteria, setMyWorkCriteria] = useState(false);
    const [reserveRange, setReserveRange] = useState("Upcoming");
    const [reservationStatus, setReservationStatus] = useState("Active");
    const navigate = useNavigate();
    let internalTimeout = null;
    let criteria = null;
    const [searchResultData, setSearchResultData] = useState([]);
    const lookup = isLookup == true ? true : false;
    let params = useParams();
    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const [toggleRefetch, setToggleRefetch] = useState(false)

    const [rowIds, setRowIds] = useState([]);



    const pageTitle = getTranslation('contact_search_page', 'header_section', 'Reservations/Meetings');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [gridId, setGridId] = useState(1);
    const headerOptions = {
        elements: [
            {
                type: "dropdown", text: getTranslation('contact_search_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [
                    { type: "dropdown", text: getTranslation('contact_search_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } }
                ]
            },
            { type: "button", text: getTranslation('contact_search_page', 'header_section', 'New'), caption: "New", icon: "bi bi-plus-lg", url: "/securityrole/0", target: "", color: "btn-success", options: null, function: null }
        ], breadcrumbs: [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }]
    }

    if (isLookup == true) {
        headerOptions.elements = [];
    }

    function onMyWorkChanged(checked) {
        setMyWorkCriteria(checked);
    }

    const columns = [
        {
            field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
            renderCell: ({ row }) =>
                <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('contact_search_page', 'search_grid', 'Open')}</Button>,
        },
        {
            field: 'startDateTime',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Date/Time'),
            width: 300,
            renderCell: function (params) {
                return (
                    <>
                        <div><span style={{fontWeight:"bold"}}>{dayjs(params.row.startDateTime).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('dddd, MMMM DD, YYYY -- h:mma')}</span><br /><span>Duration: </span><span>{params.row.estDur}</span></div>
                    </>
                );
            }
        },
        {
            field: 'title',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Title'),
            width: 300
        },
    ];



    function onGridOpenBtnClick(row) {
        //navigate('/securityrole/' + row.securityRoleId);

        var msg = '';
        setGenericDialogSchema({
            title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                <CardSettings dialogType={dialogType} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} cardId={row.cardId} ></CardSettings>,
            body: { innerHtml: { __html: msg } }, dialogType: "popup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
            cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('CardEdit', returnValues); }
        })
        setOpenDialog(true);

    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "CardEdit":
                beginSearch();
                break;
        }
    }


    useEffect(() => {
        beginSearch();
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        beginSearch();
    }, [myWorkCriteria, reserveRange, reservationStatus]);

    function buildReturnValues(data) {
        if (handlerReturnValue) {
            setAcceptAfterUpdate(true);
            handlerReturnValue(data);
        }
    }

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />
            <div style={{ backgroundColor: "#ffffff", padding: "5px 10px 5px 10px", display: "grid", gridTemplateColumns: "1fr 70px", gridGap: "10px" }}>
                <div style={{ alignItems:"start",display: "grid", gridTemplateColumns: "1fr 205px 170px 160px", gridGap: "10px" }}>
                    <div>
                        <TextField fullWidth placeholder="Enter search keywords" variant="standard" style={{ border: "1px solid whitesmoke" }} onChange={(e) => { handleOnCriteriaChange(e.target.value); }} />
                    </div>
                    <div>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={reserveRange}
                                onChange={(e) => { setReserveRange(e.target.value); }}
                            >
                                    <FormControlLabel value="Upcoming" control={<Radio />} label="Upcoming" />
                                    <FormControlLabel value="Past" control={<Radio />} label="Past" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div>
                        <FormControlLabel style={{ marginBottom: "10px" }}
                            control={
                                <Switch checked={myWorkCriteria} onChange={(e) => onMyWorkChanged(e.target.checked)} name="gilad" />
                            }
                            label="My Meetings"
                        />
                    </div>
                    <div>
                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={reservationStatus || 'Active'}
                                onChange={(e) => { setReservationStatus(e.target.value); }}
                            >
                                <MenuItem value="Active">Status: Active</MenuItem>
                                <MenuItem value="No Show">Status: No Show</MenuItem>
                                <MenuItem value="Cancelled">Status: Cancelled</MenuItem>
                                <MenuItem value="All">Status: All</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div>
                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { beginSearch(); }} style={{ width: "100%" }}>Search</button>
                </div>
            </div>
            <div className="fg-app-page-box">
                {/*<Button variant="outlined" onClick={showDialog}>*/}
                {/*    Open alert dialog*/}
                {/*</Button>*/}
                {/*<Button variant="contained" color="success" onClick={importContacts}>*/}
                {/*    Import Contacts*/}
                {/*</Button>*/}
                <div>
                    <Box sx={{ height: 630, width: '100%' }}>
                        <DataGrid      
                            sx={{
                                border: 0,
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within": {
                                    outline: "none !important",
                                },
                            }}
                            rows={searchResultData}
                            getRowId={(row) => row.cardId}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns status and traderName, the other columns will remain visible
                                        open: lookup == true ? false : true,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            checkboxSelection={lookup == true ? false : true}
                            disableRowSelectionOnClick={lookup == true ? false : true}
                            onRowSelectionModelChange={(ids) => {
                                setRowIds(ids);
                                if (lookup != true) {
                                    if (handlerReturnValue) {
                                        handlerReturnValue(ids)
                                    }
                                } else {
                                    if (ids.length > 0) {
                                        const selectedIDs = new Set(ids);
                                        const selectedRowData = searchResultData.filter((row) =>
                                            selectedIDs.has(row.cardId)
                                        );
                                        buildReturnValues(selectedRowData[0]);
                                    } else {
                                        buildReturnValues(null);
                                    }
                                }
                            }}
                        />
                    </Box>
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function importContacts() {
        //setLoaderSchema({msg:"Process will take a few seconds..."})
        setShowLoader(true);            
        setTimeout(() => {
            setTimeout(() => {
                setShowLoader(false);
            }, "1000");
        }, "50");
    }

    function showDialog() {
        var msg = 'You are about to delete this contact record. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Contacts Created', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); okAction() } })
        setOpenDialog(true);
    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog()} })
        setOpenDialog(true);
    }

    function handleBulkDeleteConfirm(gridId) {
        if (rowIds.length > 0) {
            var plural = helpers.getPlural(rowIds.length);
            var msg = 'You are about to delete ' + rowIds.length + ' reservation' + plural + '. <span style="font-weight:bold;">Are you sure</span>?';
            setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecords() } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }


    function deleteRecords() {
        var test = '';
        test = '1234';
        BAFetchData({
            callName: "BulkDelete",
            method: "POST",
            url: "pm/card/bulkdelete",
            token: "fg1234",
            body: rowIds, onSuccess: onAPISuccess, onError: onAPIError
        });
    }


    function resetResults() {
        setRowIds([]);
        beginSearch();
    }

    function handleOnCriteriaChange(value) {
        criteria = value.toLowerCase();
        if (internalTimeout != undefined) {
            clearTimeout(internalTimeout);
        }
        internalTimeout = setTimeout(() => {
            beginSearch();
        }, 250);
    }

    function beginSearch() {


        var queryStr = "";
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [
                {
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "bookableitemid", "ConditionOperator": "NOT_EMPTY", "Value": null, "DataType": null }
                    ]
                }
            ]
        };

        if (reserveRange == "Upcoming") {
            filtering.ConditionGroups[0].Conditions.push({ "Name": "startdatetime", "ConditionOperator": "GREATER_THAN_OR_EQUAL", "Value": dayjs().format("YYYY-MM-DD HH:mm:ss"), "DataType": "datetime" })
        } else if (reserveRange == "Past") {
            filtering.ConditionGroups[0].Conditions.push({ "Name": "startdatetime", "ConditionOperator": "LESS_THAN", "Value": dayjs().format("YYYY-MM-DD HH:mm:ss"), "DataType": "datetime" })
        }

        if (reservationStatus == "No Show" || reservationStatus == "Cancelled") {
            filtering.ConditionGroups[0].Conditions.push({ "Name": "reservationstatus", "ConditionOperator": "EQUAL", "Value": reservationStatus, "DataType": "string" })
        } else if (reservationStatus == "Active") {
            filtering.ConditionGroups[0].Conditions.push({ "Name": "reservationstatus", "ConditionOperator": "IS_EMPTY", "Value": null, "DataType": "string" })
        }
                

        if ((criteria != null && criteria != '')) {
            filtering.ConditionGroups.push(
                {
                    "LogicalOperator": "or", "Conditions": []
                }
            )
        }

        if (criteria != null && criteria != '') {
            filtering.ConditionGroups[1].Conditions.push({ "Name": "title", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" })
        }

        queryStr += "filtering=" + JSON.stringify(filtering)

        if (myWorkCriteria == true) {
            queryStr += "&bamid=" + currentUser.currentBusinessAccountDTO.bamId;
            //filtering.ConditionGroups[1].Conditions.push({ "Name": "bamId", "ConditionOperator": "EQUAL", "Value": currentUser.currentBusinessAccountDTO.bamId, "DataType": "Number" })
        }

        BAFetchData({
            callName: "GetData",
            method: "GET",
            url: "pm/card/getlistwithmemberinfo?" + queryStr,
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });

    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetData":
                setSearchResultData(data);
                break;
            case "BulkDelete":
                resetResults(); //Reset search results and clear records selected.               
                var msg = "Reservation record(s) has been deleted.";
                setGenericDialogSchema({ title: 'Reservation(s) Deleted', component: null, body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);                
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

}

export default Reservations;