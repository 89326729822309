import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { BAFetchData } from '../customhooks/useBAFetch';
import Avatar from '@mui/material/Avatar';
import { useGlobalState } from '../globalstates/GState';
import ValueLadderCtrl from '../components/automationbuilderctrl/ValueLadderCtrl';
import ChatResponse from '../components/ai/ChatResponse';
import FunnelObjectDefinitions from "../components/ai/funnelobjectdefinitions.json";
import { ChatOpenAi } from "@langchain/openai";
import {ChatPromptTemplate} from "@langchain/core/prompts";

function MA() {
    const aiBirthDate = '8/15/2023';
    let todayDate = new Date().toISOString().slice(0, 10)
    const [currentUser] = useGlobalState("userInfo");
    const userInitials = currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)
    const chatContainerRef = useRef(null);
    const [maxTokens, setMaxTokens] = useState(1000);
    const token = "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";
    const [currResponse, setCurrResponse] = useState('');
    const [errList, setErrList] = useState([]);

    //const model = new ChatOpenAI({
    //    modelName: "gpt-4o", //chatgpt-4o-latest
    //    temperature: 0.7,
    //    //    maxTokens: 1000,
    //    //    verbose: true,
    //});

    let training =[
        { "role": "system", "content": "You are a helpful marketing assistant. Your name throughout this session will be MA, which stands for Marketing Assistant. You are an Ai model created by Funnel Goal to assist users with marketing and sales efforts. You were born (created) on " + aiBirthDate + ", but you become smarter each day. Your religion is Christianity. You are female. Your nationality is African American. When someone asks your age I want you to do the math and answer them based upon your birthdate/creation date and today\'s date which is " + todayDate + "." },
        { "role": "system", "content": "Funnel Goal is a IT company where Samuel Genus is the founder. The working hours are Monday through Friday 10am to 2pm. The company was started in 2019. Their business email is support@funnelgoal.io. Their website is funnelgoal.io. Our phone number is 704-498-2901. The found of Funnel Goal is Samuel Genus. Our pricing is $249 per month and you get everything." },
        { "role": "system", "content": "My name is " + currentUser.firstName + ' ' + currentUser.lastName },
        //{ "role": "system", "content": "When thinking about a funnel blueprint/mapping I want you to determine your answers from the list of funnel blueprint/mapping object types for you to select from when providing a funnel blueprint/mapping." },
        //{ "role": "system", "content": "There are four object types categories that are used within Funnel Goal's funnel blueprint/mapping. 1. Traffic Source. 2. Page. 3. Event. 4. Auto Action."},
        //{ "role": "system", "content": "The first funnel blueprint/mapping object type is \"Traffic Source.\" A Traffic Source object type can be any of the following: 1. Faceboo ad. 2. Facebook post. 3. Facebook reel. 4. Facebook page post. 5. YouTube ad. 6. YouTube postcast. 7. Instagram ad. 8. Instagram page post. 9. TikTok ad. 10. TikTok post. 11. Twitter post. 12 Twitter ad. 13. Podcast. 14. Email campaign/broadcast. 15. Google Adwords Ad." },
        //{ "role": "system", "content": "The second funnel blueprint/mapping object type is \"Page.\" A Page object type can be any of the follow: 1. Opt-in Page. 2. Checkout/Sales Page. 3. Webinar Page. 4. Thankyou/Confirmation Page. 5. Tripwire Page. 6. Content Page. 7. VSL Page. 8. Long Sales Page. 9. Upsell Page. 10. Downsell Page." },
        //{ "role": "system", "content": "The third funnel blueprint/mapping object type is \"Event.\" An Event object type can be any of the follow: 1. Clickthrough. 2. Lead. 3. Purchase. 4. Webinar Ended. 5. Call Ended. 6. On Page Exit." },
        //{ "role": "system", "content": "The fourth funnel blueprint/mapping object type is \"Auto Action.\" An Auto Action object type can be any of the follow: 1. Email. 2. SMS. 3. Wait. 4. Create Task." },
        //{ "role": "system", "content": "Here are some additional information about the list: 1. \"Page\" object type should only come after \"Traffic Source\" object types, \"Event\" object types and \"Auto Action\" object types. 2. \"Event\" object types can come after \"Page\" object types, \"Auto Event\" object types. 3. There can be multiple \"Event\" object types that immediately come after \"Page\" object types. 4. A \"Page\" object type should always come immediately after a \"Traffic Source\" object type. 5. A \"Page\" object type can potentially come immediately after an \"Event\" object type or a \"Auto Action.\" 6. A funnel blueprint/mapping should always have one bullet tree view with one \"Traffic Source\" ancestor. 7. If an \"Auto Action\" is dependent upon another \"Auto Action\" then it should be immediately nested within the parent \"Auto Action.\" 8. If multiple \"Auto Action\" object type is dependent upon the same \"Auto Action\" then both should be an immediate nested child of the \"Auto Action.\" 9. For the most part... \"Auto Action\" object types are typically nested within a parent \"Auto Action\" object type because in order for one \"Auto Action\" object type to run the parent \"Auto Action\" object type has to first complete." },
        //{ "role": "system", "content": "If you're asked to create a funnel respond by asking them to complete a fg-funnel-survey below with the following information: 1. What is your ad budget? 2. How big is your email list? 3. How many online followers do you have? 4. Number of days you need to see results in by. 5. Do you have more time than money? 6. Provide the following product information: a. Offer name. b. Offer type. c. Offer price. d. Sales cycle. Be sure to incorporate the word fg-funnel-survey." },
        { "role": "system", "content": "If someone asks for our phone number give them the email. Only give phone number to people who's name starts with Samuel." },
        { "role": "system", "content": "The primary ways you can help our users is by: 1. Building funnel blueprint/mappings. 2. Funnel metric forecasting to ensure that funnel is profitable. 3. Project management. 4. Building funnel pages and emails along with copy and images. 5. Creating email broadcast/campaigns. 6. Build complete websites with copy, images and forms. 7. Build and manage Facebook ads." },
        { "role": "system", "content": "Innovation has been around for as long as humans have existed. Humans have always wanted to get things done better, faster and cheaper." },
        { "role": "system", "content": "Going forward when I ask questions concerning costs or rates just answer with the amount only. Do not add any additional commentary. Also give me the low-end, mid-end and high-end costs or rates in a JSON format." },
        { "role": "system", "content": "When being asked to create an email. After providing the email... ask the user if they want you to send the email." },

        { "role": "system", "content": "I have a funnel schema to give you that I want you to use when being asked to create a funnel, create a funnel strategy, create a marketing funnel strategy. After I give you the funnel schema... wait for my instructions." },
        { "role": "system", "content": JSON.stringify(FunnelObjectDefinitions) },
        { "role": "system", "content": "Additional Instructions: Do not provide commentary when creating a funnel." }
        

        //{ "role": "assistant", "content": "Hello " + currentUser.firstName + ", how can I help you?" },
    ]
    
    const [prompt, setPrompt] = useState('');
    const [messages, setMessages] = useState([]);


    function createErrMsgItem(label, errMsg) {
        return { label: label, errMsg: errMsg }
    }

    function setupTraining() {

    }



    function newMessageItem(role, content) {
        return { "role": role, "content": content};
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "ChatGPT":
                var tmpMessages = [...messages];
                console.log("Assistant", data.choices[0].message.content);
                tmpMessages.push(newMessageItem('assistant', data.choices[0].message.content));
                //tmpMessages.push(newMessageItem('assistant', data.choices[0].message.content.replaceAll('\n', '<br/>')));
                setMessages(tmpMessages);
                break;
            case "GetEffortReport":
                addEffortReportData(data);
                break;
            case "GetContactList":
                addContactListData(data, packageObj.initialTraining);
                break;
            case "RunSql":
                var tmpMessages = [...messages];
                tmpMessages.push(newMessageItem('assistant', "I executed the sql."));
                setMessages(tmpMessages);   
                getContactList(false);
                break;
        }
    }

    function onAPIError(error, callName) {
        alert(error);
        //var msg = error;
        //setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        //setOpenDialog(true);
    }


    function sendPrompt(e) {
        var tmpPrompt = prompt;
        var tmpMessages = [...messages];
        tmpMessages.push(newMessageItem('user', tmpPrompt.replaceAll('\n', '<br/>')));
        setMessages(tmpMessages);
    }

    function processPrompt_Master(prompt) {
        const params = {
            messages: [...messages],
            model: "chatgpt-4o-latest",
            max_tokens: maxTokens,
            temperature: 0,
            //stream: true, //Enable streaming
        };

        BAFetchData({
            callName: "ChatGPT",
            method: "POST",
            url: "https://api.openai.com/v1/chat/completions",
            // old one but still using
            //token: "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",
            // new one "Dev - FG"
            token: "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", 
            body: JSON.stringify(params),
            onSuccess: onAPISuccess,
            onError: onAPIError
        });
    }

    async function processPrompt(prompt) {
        const params = {
            messages: [...messages],
            model: "chatgpt-4o-latest",
            max_tokens: maxTokens,
            temperature: 0,
            stream: true, //Enable streaming
        };

        //const responseStream = await BAFetchData({
        //    callName: "ChatGPT",
        //    method: "POST",
        //    url: "https://api.openai.com/v1/chat/completions",
        //    token: "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",
        //    body: JSON.stringify(params),
        //    onSuccess: null,
        //    onError: null
        //});

        const responseStream = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Replace with your OpenAI API key
            },
            body: JSON.stringify({
                model: 'chatgpt-4o-latest', // Replace with the desired model (e.g., gpt-4)
                messages: [...messages], 
                max_tokens: maxTokens,
                temperature: 0,
                stream: true, // Enable streaming
            }),
        });


        if (!responseStream.ok) {
            let tmpErrList = [];
            const errorData = await responseStream.json();
            tmpErrList.push(createErrMsgItem('openAi API', `${errorData.error.code} -- ${errorData.error.message}`));
            setErrList(tmpErrList);
            //setError(errorData.error?.message || 'An error occurred.');
            //setLoading(false);
            return;
        }

        const reader = responseStream.body.getReader();
        const decoder = new TextDecoder();
        let done = false;

        let response = '';
        while (!done) {
            const { value, done: streamDone } = await reader.read();
            done = streamDone;
            const chunk = decoder.decode(value, { stream: true });
            // Parse and extract the content from the streamed response
            const lines = chunk.split('\n').filter((line) => line.trim() !== '');
            for (const line of lines) {
                if (line.startsWith('data:')) {
                    const data = line.replace(/^data: /, '');
                    if (data === '[DONE]') break;
                    try {
                        const json = JSON.parse(data);
                        const text = json.choices[0]?.delta?.content || ''; // Incremental content
                        response += text;
                        setCurrResponse((prev) => prev + text);
                    } catch (e) {
                        console.error('Error parsing streamed data:', e);
                    }
                }
            }
        }
        console.log("Assistant", response);
        setMessages((prev) => [...prev, { role: 'assistant', content: response }]);
        setCurrResponse('');
    }



    useEffect(() => {
        if (messages.length > 0) {
            if (prompt != '') {
                setPrompt('');
                processPrompt(prompt);
            }
            // Scroll to the bottom when messages change
            const chatContainer = chatContainerRef.current;
            if (chatContainer) {
                chatContainer.scrollTop = chatContainer.scrollHeight;
            }
        }
    }, [messages]);

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }, [currResponse]);

    function renderPostType(speaker) {
        var postTypeClass = 'fg-machat-user-post';
        if (speaker == 'assistant') {
            postTypeClass = 'fg-machat-ma-post';
        }
        return postTypeClass;
    }

    //This is where all of the additional Ai training goes

    function getEffortReport() {
        var filtering = {};
        BAFetchData({
            callName: "GetEffortReport",
            method: "GET",
            url: "rpt/marketing/geteffortdata?startdate=null&enddate=null&filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addEffortReportData(data) {
        let dataMetrics = [];
        for (let i = 0; i < data.length; i++) {
            dataMetrics.push({ label: data[i].label, value: data[i].value });
        }
        dataMetrics.push({ label: "Monthly Sales Goal", value: 8000.00 })
        dataMetrics.push({ label: "Monthly Leads Goal", value: 20 })
        dataMetrics.push({ label: "Monthly Customer Goal", value: 20 })

        dataMetrics.push({ label: "Sales Conversion Rate", value: 0 })
        dataMetrics.push({ label: "Customer Acquizition Cost", value: 0 })
        dataMetrics.push({ label: "Customer Lifetime Value", value: 0 })

        dataMetrics.push({ label: "Revenue Growth Rate", value: 0 })

        dataMetrics.push({ label: "Gross Margin", value: 0 })
        dataMetrics.push({ label: "Net Profit Margin", value: 0 })
        dataMetrics.push({ label: "ROAS", value: 0 })
        dataMetrics.push({ label: "Average Order Value", value: 0 })
        dataMetrics.push({ label: "Churn Rate", value: 0 })
        dataMetrics.push({ label: "Rentention Rate", value: 0 })

        training.push({ "role": "system", "content": "Here is a list of data metrics... " + JSON.stringify(dataMetrics) });       
       
        getContactList(true);               
    }

    function getContactList(initialTraining) {
        var filtering = {};
        BAFetchData({
            callName: "GetContactList",
            method: "GET",
            url: "crm/contact/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError,
            packageObj: {
                initialTraining: initialTraining
            }
        });
    }

    function addContactListData(data, initialTraining) {
        //let contactList = [];
        for (let i = 0; i < data.length; i++) {
            //contactList.push({ contactId: data[i].contactId, firstName: data[i].firstName, lastName: data[i].lastName, email: data[i].email, phone: data[i].phone });
            delete data[i].internalState;
            delete data[i].contactName;
            delete data[i].tagMappings;
            delete data[i].segmentedListMappings;
        }
        if (initialTraining == false) {
            var tmpMessages = [...messages];
            tmpMessages.push(newMessageItem('system', "Here is the latest list of contacts... " + JSON.stringify(data) + ". There are " + data.length + " contact record(s)."));
            setMessages(tmpMessages);
        } else {
            training.push({ "role": "system", "content": "Here is the latest list of contacts... " + JSON.stringify(data) + ". There are " + data.length + " contact record(s)." });
            addFinalPrompt();
        }

        //
        //training.push({ "role": "system", "content": "Here is a list of contacts... " + JSON.stringify(contactList) });
        //addFinalPrompt();
    }

    function addFinalPrompt() {

        training.push({ "role": "system", "content": `The baId is... ${currentUser.currentBusinessAccountDTO.baid}.` });
        training.push({ "role": "system", "content": `When being asked to insert, add, create a contact provide a PostgreSql sql script snippet as the response. The PostgreSql table is crm_contacts. Ignore middle initials. Instead of the word sql as the language can you use the word fgsql. Always use baid in insert, update and delete sql. Also when updating or deleting use the primary key.` });
        training.push({ "role": "system", "content": "When providing JSON for funnel the language should be JSON_funnel." });
        training.push({ "role": "system", "content": `The name of the person you will be speaking to is... first name is... ${currentUser.firstName} and last name is.. ${currentUser.lastName}.` });
        training.push({ "role": "assistant", "content": "Hello " + currentUser.firstName + ", how can I help you?" });
        setMessages(training);
        
    }

    function runSql(sql) {
        console.log('sql', sql);

        let formData = new FormData();
        formData.append("sql", sql);

        BAFetchData({
            callName: "RunSql",
            method: "POST",
            url: "rpt/marketing/contactinsertsql",
            token: "fg1234",
            body: formData, onSuccess: onAPISuccess, onError: onAPIError
        });
    }
  
    useEffect(() => {
        getEffortReport();
    },[])

    return (
        <>
            <div style={{height:"100%",display:"grid",gridTemplateColumns:"1fr 250px",gridGap:"40px"} }>
                <div style={{padding:"20px", display:"flex",flexDirection:"column",height:"90vh"}}>
                    <div ref={chatContainerRef} style={{ flex: 1,height:"100%",width: "900px", marginLeft: "auto", marginRight: "auto",overflow:"auto" }}>
                        {messages.map((message, index) => (
                            (message.role != 'system' ?
                                <div key={"message_index_" + index} className={"fg-machat-post " + renderPostType(message.role)} style={{ display: "grid", gridTemplateColumns: "40px 1fr", gridGap: "20px", width: "100%" }}>
                                    <div>{message.role == 'assistant' ? <img alt='' src="images/fg_ai_avatar_ma_profile_60_x_60.png" style={{ width: "48px", height: "48px", borderRadius: "50%" }} /> : <Avatar sx={{ width: 48, height: 48, marginBottom: "5px", fontSize: "19px" }}>{userInitials}</Avatar>}</div>
                                    <div style={{ paddingTop: "10px", wordBreak:"break-word" }}>
                                        {/*<span dangerouslySetInnerHTML={{ __html: message.content }}></span>*/}
                                        <ChatResponse content={message.content} onRunSql={(sql) => { runSql(sql); }} />

                                        {message.content.indexOf('fg-funnel-survey') > -1 ?
                                            <div className="fg-machat-post" style={{ padding: "20px 20px 20px 20px", backgroundColor: "#5E95DE", width: "390px", marginTop: "40px",borderRadius:"6px" }}>
                                                <div style={{ color: "white" }}>Complete the form below and then press the submit button.</div>
                                                <div style={{ width: "350px", backgroundColor: "white", padding: "10px", borderRadius: "6px", marginTop: "20px" }}>                                                   
                                                    <ValueLadderCtrl valueLadder={{
                                                        "industry": "",
                                                        "budget": 0,
                                                        "maxResultDays": 90,
                                                        "moreMoneyThanTime": false,
                                                        "audienceSize": 0,
                                                        "valueLadder": []
                                                    }}
                                                        createAutomation={true}
                                                        
                                                        onRecordCreated={() => {
                                                        alert('automation created');
                                                    }}/>
                                                </div>
                                            </div>
                                            : null}


                                    </div>
                                </div>
                                : null)
                        ))}
                        {currResponse != '' ?
                            <div className={"fg-machat-post " + renderPostType("assistant")} style={{ display: "grid", gridTemplateColumns: "40px 1fr", gridGap: "20px", width: "100%" }}>
                                <div><img alt='' src="images/fg_ai_avatar_ma_profile_60_x_60.png" style={{ width: "48px", height: "48px", borderRadius: "50%" }} /></div>
                                <div style={{ paddingTop: "10px", wordBreak: "break-word" }}>
                                    {/*<span dangerouslySetInnerHTML={{ __html: message.content }}></span>*/}
                                    <ChatResponse content={currResponse} onRunSql={(sql) => { runSql(sql); }} />
                                </div>
                            </div>
                            : null}
                    </div>
                    <div style={{ height:"100px",width:"900px",marginLeft:"auto",marginRight:"auto",display: "grid", gridTemplateColumns: "1fr 100px",gridGap:"10px",backgroundColor:"white",padding:"10px" }}>
                        <div>
                            <TextField id="title" autoFocus fullWidth multiline maxRows={8} label="Prompt" variant="standard" value={prompt || ''} onChange={(e) => { setPrompt(e.target.value); }} />
                            <div style={{ textAlign: "center", fontSize: "13px", paddingTop: "5px" }}>MA may produce inaccurate information about people, places, or facts. Please verify all responses.</div>
                            {errList.map((errItem) => (
                                <div style={{ color: "red" }}>{errItem.errMsg}</div>
                            ))}
                            
                        </div>
                        <div style={{ paddingTop: "13px" }}>
                            <Button variant="outlined" color="primary" size="medium" fullWidth style={{ backgroundColor: "#5E95DE", color: "#ffffff", margin: "0px", border: "none", padding: "4.5px" }} onClick={(e) => { sendPrompt(e); }}>Submit</Button>
                        </div>
                    </div>
                </div>
                <div style={{backgroundColor:"white",padding:"0px"}}>
                    <div style={{borderBottom:"5px solid #c0c0c0"}}>
                        <img alt="" src="images/fg_ai_avatar_ma.png" style={{ width: "100%" }} />
                    </div>
                    <div style={{padding:"20px"}}>
                        <p style={{fontSize:"20px",fontWeight:"500"}}>Hi, my name is MA.</p>
                        <p>I'm an Ai model integrated with Funnel Goal 2.0 to assist you with accomplishing your marketing and sales efforts.</p>
                        <p>Ask me questions using the text box on the bottom of this page and I will answer them as best as I can.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MA;