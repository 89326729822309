import React, { useContext, useState, useEffect, useRef } from 'react';
import { DataBrokerContext } from '../PageBuilderCtrl';
import '../css/pagebuilderctrl.css';

function CssDataSourceInfoCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    //const oldIdRef = useRef(null);
    function handleOnValueChange(propertyName,value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        
    }

    function handleOnBlur() {
        propertyChangeCallback(widgetObj);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])

    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Data Source Name</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div className="fg-pb-subsection-instruction">Enter data source name.</div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Name</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.dataSourceName != undefined ? widgetObj.dataSourceName : ""} onChange={(e) => { handleOnValueChange('dataSourceName',e.target.value); }} onBlur={(e) => { handleOnBlur(); }} />
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Type</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.dataSourceType} onChange={(e) => { handleOnValueChange('dataSourceType', e.target.value); }} onBlur={(e) => { handleOnBlur(); }}>
                                <option value=""></option>
                                <option value="collection">Collection</option>
                                <option value="nested-collection">Nested Collection</option>
                                <option value="nested-csv">Nested CSV</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CssDataSourceInfoCtrl;

