import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import { BAFetchData } from '../../../customhooks/useBAFetch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import SegmentedLists from '../../../pages/SegmentedLists';
import DeleteIcon from '@mui/icons-material/Delete';
import { Editor } from '@tinymce/tinymce-react';
import Tags from '../../../pages/Tags';
import DataPackageAliasListCtrl from '../DataPackageAliasListCtrl';
import FileManager from '../../../pages/FileManager';
import { useGlobalState } from '../../../globalstates/GState';


function AOEmailCtrl({ objectSettings, onChange, dataPackageList }) {

    const [currentUser] = useGlobalState("userInfo");
    const [objectSettingsObj, setObjectSettingsObj] = useState(objectSettings);
    const [segmentedListInfo, setSegmentedListInfo] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});
    const editorRef = useRef(null);



    function onValueChange(propertyName, value) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings[propertyName] = value;
        if (propertyName == 'senderEmail') {
            tmpObjectSettingsObj.settings['replyToEmail'] = value;
        } else if (propertyName == 'senderName') {
            tmpObjectSettingsObj.settings['replyToName'] = value;
        }
        updateSummary(tmpObjectSettingsObj);
        setObjectSettingsObj(tmpObjectSettingsObj);
        onChange(tmpObjectSettingsObj);
    }

    function updateSummary(tmpObjectSettingsObj) {
        var summary = "Send Email";
        tmpObjectSettingsObj.settings.summary = summary;
    }

    function newSetting() {
        //return { "action": "Add", "contactId": null, list: [], "summary": "" };
        return { "senderEmail": null, "senderName": null, "replyToEmail": null, "replyToName": null, "sendToEmail": null, "sendToName": null, "ccList": null, "bccList": null, "attachmentList": null, "subject": null, "body": null, summary: "" };
    }

    function openTagSearch(actionType) {
        var msg = '';
        setGenericDialogSchema({ title: 'Tag Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <Tags handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></Tags>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('TagSearch', returnValues); } })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "TagSearch":
                var tmpObjectSettingsObj = { ...objectSettingsObj };
                tmpObjectSettingsObj.settings['tagId'] = returnValues.tagId;
                setObjectSettingsObj(tmpObjectSettingsObj);
                onChange(tmpObjectSettingsObj);
                getTagInfo(returnValues.tagId);
                break;
        }
    }

    function getTagInfo(tagId) {
        BAFetchData({
            callName: "GetTagInfo",
            method: "GET",
            url: "crm/tag/getrecord?tagid=" + tagId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addItem(data) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings.list.push({ "tagId": data.tagId, "tagName": data.tagName });
        updateSummary(tmpObjectSettingsObj);
        setObjectSettingsObj(tmpObjectSettingsObj);
        onChange(tmpObjectSettingsObj);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetTagInfo":
                addItem(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleRemoveItem(e, index) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings.list.splice(index, 1);
        updateSummary(tmpObjectSettingsObj);
        setObjectSettingsObj(tmpObjectSettingsObj);
        onChange(tmpObjectSettingsObj);
    }

    function handleChangeEditor(editor) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings.body = editor;
        setObjectSettingsObj(tmpObjectSettingsObj);
    }

    function openFileManager(editor) {
        var actionType = "FullPath";
        var msg = '';
        setGenericDialogSchema({ title: 'File Manager', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={setReturnValue} returnFullPath={true} recType={null} recId={null} iconOnly={false}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleUpdateImage(returnValues, actionType, editor); } })
        setOpenDialog(true);
    }

    function handleUpdateImage(imgUrl, actionType, editor) {
        let altMsg = 'Click show image link';
        let imgElementStr = '<img src="' + imgUrl + '" alt="' + altMsg + '" width="400px"/>';
        editor.insertContent(imgElementStr);

        //alert(imgUrl);
        //handleOnOtherChange(imgUrl);
    }

    useEffect(() => {
        setShowLoader(false);
    }, []);

    useEffect(() => {
        if (JSON.stringify(objectSettings.settings) == "{}") {
            objectSettings.settings = newSetting();
        }
        setObjectSettingsObj(objectSettings);
    }, [objectSettings]);

    return (
        <>
            {JSON.stringify(objectSettings.settings) != "{}" &&
                <div style={{ display: "grid", gridTemplateColumns: "250px 1fr", gridGap: "40px" }}>
                    <div style={{ border: "1px solid #c0c0c0", padding: "10px", borderRadius: "12px" }}>
                        <DataPackageAliasListCtrl dataPackageList={dataPackageList} />
                    </div>
                    <div>
                        <div style={{display:"grid",gridTemplateColumns:"1fr 1fr",gridGap:"10px"}}>
                            <div>
                                <TextField fullWidth autoComplete='off' label="Sender Email" variant="outlined" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: objectSettingsObj.settings.senderEmail }} value={objectSettingsObj.settings.senderEmail || ''} onChange={(e) => { onValueChange('senderEmail', e.target.value); }} />
                            </div>
                            <div>
                                <TextField fullWidth autoComplete='off' label="Sender Name" variant="outlined" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: objectSettingsObj.settings.senderName }} value={objectSettingsObj.settings.senderName || ''} onChange={(e) => { onValueChange('senderName', e.target.value); }} />
                            </div>
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px" }}>
                            <div>
                                <TextField fullWidth autoComplete='off' label="Reply Email" variant="outlined" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: objectSettingsObj.settings.replyToEmail }} value={objectSettingsObj.settings.replyToEmail || ''} onChange={(e) => { onValueChange('replyToEmail', e.target.value); }} />
                            </div>
                            <div>
                                <TextField fullWidth autoComplete='off' label="Reply Name" variant="outlined" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: objectSettingsObj.settings.replyToName }} value={objectSettingsObj.settings.replyToName || ''} onChange={(e) => { onValueChange('replyToName', e.target.value); }} />
                            </div>
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px" }}>
                            <div>
                                <TextField fullWidth autoComplete='off' label="Send to Email" variant="outlined" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: objectSettingsObj.settings.sendToEmail }} value={objectSettingsObj.settings.sendToEmail || ''} onChange={(e) => { onValueChange('sendToEmail', e.target.value); }} />
                            </div>
                            <div>
                                <TextField fullWidth autoComplete='off' label="Send to Name" variant="outlined" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: objectSettingsObj.settings.sendToName }} value={objectSettingsObj.settings.sendToName || ''} onChange={(e) => { onValueChange('sendToName', e.target.value); }} />
                            </div>
                        </div>
                        <div>
                            <TextField fullWidth autoComplete='off' label="Subject" variant="outlined" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: objectSettingsObj.settings.subject }} value={objectSettingsObj.settings.subject || ''} onChange={(e) => { onValueChange('subject', e.target.value); }} />
                        </div>
                        <div>
                            <Editor
                                tinymceScriptSrc={process.env.REACT_APP_BASE_URL + '/tinymce/tinymce.min.js'}
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={objectSettingsObj.settings.body}
                                onEditorChange={handleChangeEditor}
                                init={{
                                    height: 400,
                                    menubar: false,
                                    toolbar_mode: 'wrap',
                                    autoresize_bottom_margin: 50,
                                    plugins: [
                                        'advlist', 'autolink', 'blockquote', 'emoticons', 'lists', 'link', 'image', 'charmap',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'codesample', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'preview', 'searchreplace', 'strikethrough', 'help', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | blocks | ' +
                                        'bold italic strikethrough forecolor blockquote | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | link fileManager table emoticons | code codesample | searchreplace | preview fullscreen | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    setup: function (editor) {
                                        editor.ui.registry.addIcon('fileManagerIcon', '<svg fill="#000000" height="18px" width="18px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_11_"> <path id="XMLID_12_" d="M195,150c27.57,0,50-22.43,50-50s-22.43-50-50-50s-50,22.43-50,50S167.43,150,195,150z"></path> <path id="XMLID_13_" d="M315,0H15C6.716,0,0,6.716,0,15v239.804c0,0.01,0,0.02,0,0.03V315c0,8.284,6.716,15,15,15h300 c8.284,0,15-6.716,15-15V15C330,6.716,323.284,0,315,0z M300,209.636l-32.957-44.388c-2.829-3.811-7.296-6.058-12.043-6.058 s-9.214,2.247-12.043,6.058l-47.531,64.016l-78.093-112.802C114.531,112.415,109.922,110,105,110s-9.531,2.415-12.333,6.462 L30,206.981V30h270V209.636z"></path> </g> </g></svg>');

                                        editor.ui.registry.addButton('fileManager', {
                                            icon: 'fileManagerIcon',
                                            onAction: function (_) {
                                                openFileManager(editor)
                                            }
                                        });
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            }

            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default AOEmailCtrl;
