import AppControls from "./pages/AppControls";
import Login from "./pages/Login";
import Overview from "./pages/Overview";
import ContactSearch from "./pages/ContactSearch";
import SegmentedLists from "./pages/SegmentedLists";
import SegmentedListDetail from "./pages/SegmentedListDetail";
import Tags from "./pages/Tags";
import EmailBroadcastDetail from "./pages/EmailBroadcastDetail";
import SMSBroadcastDetail from "./pages/SMSBroadcastDetail";
import BusinessAccounts from "./pages/BusinessAccounts";
import AccountMembers from "./pages/AccountMembers";
import TagDetail from "./pages/TagDetail";

import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import BlankAppLayout from "./components/BlankAppLayout";
import DefaultAppLayout from "./components/DefaultAppLayout";
import NoLayout from "./components/NoLayout";
import AuthLayout from "./components/AuthLayout";
import ForgotPassword from "./pages/ForgotPassword";
import CreateAccount from "./pages/CreateAccount";
import AcceptInvite from "./pages/AcceptInvite";
import ChangePassword from "./pages/ChangePassword";
import MemberInvite from "./pages/MemberInvite";
import MemberInvites from "./pages/MemberInvites";
import AccountMember from "./pages/AccountMember";
import SecurityRoles from "./pages/SecurityRoles";
import SecurityRole from "./pages/SecurityRole";
import PageBuilder from "./pages/PageBuilder";
import FileManager from "./pages/FileManager";
import Sites from "./pages/Sites";
import SiteSettings from "./pages/SiteSettings";
import Forms from "./pages/Forms";
import FormDetail from "./pages/FormDetail";
import Blogs from "./pages/Blogs";
import BlogDetail from "./pages/BlogDetail";
import AutomationPage from "./pages/Automation";
import Automations from "./pages/Automations";
import AutomationSettings from "./pages/AutomationSettings";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import Boards from "./pages/Boards";
import BoardSettings from "./pages/BoardSettings";
import ProjectSettings from "./pages/ProjectSettings";
import CardSettings from "./pages/CardSettings";
import MA from "./pages/MA";
import BusinessAccountInfo from "./pages/BusinessAccountInfo";
import SiteFeatures from "./pages/SiteFeatures";
import Products from "./pages/Products";
import Product from "./pages/Product";
import ProductTypes from "./pages/ProductTypes";
import ProductType from "./pages/ProductType";
import Transactions from "./pages/Transactions";
import Order from "./pages/Order";
import Quote from "./pages/Quote";
import Invoice from "./pages/Invoice";
import Payment from "./pages/Payment";
import OpenInvoices from "./pages/OpenInvoices";
import Contact from "./pages/Contact";
import NewContact from "./pages/NewContact";
import CustomForm from "./pages/CustomForm";
import TrackingPixels from "./pages/TrackingPixels";
import TrackingPixel from "./pages/TrackingPixel";
import SegmentedListView from "./pages/SegmentedListView";
import UserProfile from "./pages/UserProfile";
import Welcome from "./pages/Welcome";
import Inactive from "./pages/Inactive";
import CancelAccount from "./pages/CancelAccount";
import TransactionViewer from "./pages/TransactionViewer";
import WhiteLabelSettings from "./pages/WhiteLabelSettings";
import EmailBroadcasts from "./pages/EmailBroadcasts";
import EmailBroadcast from "./pages/EmailBroadcast";
import SMSBroadcasts from "./pages/SMSBroadcasts";
import SMSBroadcast from "./pages/SMSBroadcast";
import DirectTraffic from "./pages/DirectTraffic";
import Unsubscribe from "./pages/Unsubscribe";
import EmailBroadcastAnalytics from "./pages/EmailBroadcastAnalytics";
import Courses from "./pages/Courses";
import CourseDetail from "./pages/CourseDetail";
import CourseSettings from "./pages/CourseSettings";
import IntegrationItems from "./pages/IntegrationItems";
import IntegrationItem from "./pages/IntegrationItem";
import AuthSetup from "./pages/AuthSetup";
import AuthReturn from "./pages/AuthReturn";
import IntegrationScheduledSMPosts from "./pages/IntegrationScheduledSMPosts";
import IntegrationScheduledSMPost from "./pages/IntegrationScheduledSMPost";
import Integrations from "./pages/Integrations";
import BookableItems from "./pages/BookableItems";
import BookableItem from "./pages/BookableItem";
import Reservation from "./pages/portal/Reservation";
import PageNotFound from "./pages/PageNotFound";
import Reservations from "./pages/Reservations";


const AppRoutes = [
    { index: true, path: '/', exact: true, layout: NoLayout, element: <Login /> },
    { path: '/login', exact: true, layout: NoLayout, element: <Login /> },
    { path: '*', exact: false, layout: AuthLayout, element: <PageNotFound /> },
    { path: '/forgotpassword', exact: false, layout: NoLayout, element: <ForgotPassword /> },
    { path: '/changepassword/:token', exact: false, layout: NoLayout, element: <ChangePassword/>},
    { path: '/createaccount', exact: false, layout: NoLayout, element: <CreateAccount /> },
    { path: '/memberinvite/:bamInviteId', exact: false, layout: AuthLayout, element: <MemberInvite /> },
    { path: '/memberinvites', exact: false, layout: AuthLayout, element: <MemberInvites/> },
    { path: '/acceptinvite/:iid', exact: false, layout: NoLayout, element: <AcceptInvite/>},
    { path: '/overview', exact: false, layout: AuthLayout, element: <Overview /> },
    { path: '/inactive', exact: false, layout: AuthLayout, element: <Inactive /> },
    { path: '/contactsearch', exact: false, layout: AuthLayout, element: <ContactSearch /> },
    { path: '/newcontact/:contactId', exact: false, layout: AuthLayout, element: <NewContact /> },
    { path: '/contact/:contactId', exact: false, layout: AuthLayout, element: <Contact /> },
    { path: '/segmentedlists', exact: false, layout: AuthLayout, element: <SegmentedLists /> },
    { path: '/segmentedlistdetail/:listId', exact: false, layout: AuthLayout, element: <SegmentedListDetail /> },
    { path: '/segmentedlistview/:listId', exact: false, layout: AuthLayout, element: <SegmentedListView /> },
    { path: '/tags', exact: false, layout: AuthLayout, element: <Tags /> },
    { path: '/tagdetail/:tagId', exact: false, layout: AuthLayout, element: <TagDetail /> },
    { path: '/emailbroadcastdetail', exact: false, layout: AuthLayout, element: <EmailBroadcastDetail /> },
    { path: '/businessaccounts', exact: false, layout: AuthLayout, element: <BusinessAccounts /> },
    { path: '/accountmembers', exact: false, layout: AuthLayout, element: <AccountMembers /> },
    { path: '/accountmember/:bamId', exact: false, layout: AuthLayout, element: <AccountMember /> },
    { path: '/securityroles', exact: false, layout: AuthLayout, element: <SecurityRoles/> },
    { path: '/securityrole/:securityRoleId', exact: false, layout: AuthLayout, element: <SecurityRole/> },
    { path: '/appcontrols', exact: false, layout: AuthLayout, element: <AppControls /> },
    { path: '/pagebuilder/:siteId', exact: false, layout: AuthLayout, element: <PageBuilder /> },
    { path: '/filemanager', exact: false, layout: AuthLayout, element: <FileManager /> },
    { path: '/sites', exact: false, layout: AuthLayout, element: <Sites /> },
    { path: '/sitesettings/:siteId', exact: false, layout: AuthLayout, element: <SiteSettings /> },
    { path: '/sitefeatures/:siteId', exact: false, layout: AuthLayout, element: <SiteFeatures /> },
    { path: '/forms', exact: false, layout: AuthLayout, element: <Forms /> },
    { path: '/customform/:formId', exact: false, layout: AuthLayout, element: <CustomForm /> },
    { path: '/formdetail/:formId', exact: false, layout: AuthLayout, element: <FormDetail /> },
    { path: '/blogs', exact: false, layout: AuthLayout, element: <Blogs /> },
    { path: '/blogdetail/:blogId', exact: false, layout: AuthLayout, element: <BlogDetail /> },
    { path: '/automation/:automationId', exact: false, layout: AuthLayout, element: <AutomationPage /> },
    { path: '/automations', exact: false, layout: AuthLayout, element: <Automations /> },
    { path: '/automationsettings/:automationId', exact: false, layout: AuthLayout, element: <AutomationSettings /> },
    { path: '/projects', exact: false, layout: AuthLayout, element: <Projects /> },
    { path: '/projectsettings/:projectId', exact: false, layout: AuthLayout, element: <ProjectSettings /> },
    { path: '/project/:projectId', exact: false, layout: AuthLayout, element: <Project /> },
    { path: '/boards', exact: false, layout: AuthLayout, element: <Boards /> },
    { path: '/boardsettings/:boardId', exact: false, layout: AuthLayout, element: <BoardSettings /> },
    { path: '/cardsettings/:cardId', exact: false, layout: AuthLayout, element: <CardSettings /> },
    { path: '/ma', exact: false, layout: AuthLayout, element: <MA /> },
    { path: '/businessaccountinfo/:baid', exact: false, layout: AuthLayout, element: <BusinessAccountInfo /> },
    { path: '/products', exact: false, layout: AuthLayout, element: <Products /> },
    { path: '/product/:productId', exact: false, layout: AuthLayout, element: <Product /> },
    { path: '/producttypes', exact: false, layout: AuthLayout, element: <ProductTypes /> },
    { path: '/producttype/:productTypeId', exact: false, layout: AuthLayout, element: <ProductType /> },
    { path: '/transactions/:transactionType', exact: false, layout: AuthLayout, element: <Transactions /> },
    { path: '/order/:headerId', exact: false, layout: AuthLayout, element: <Order /> },
    { path: '/quote/:headerId', exact: false, layout: AuthLayout, element: <Quote /> },
    { path: '/invoice/:headerId', exact: false, layout: AuthLayout, element: <Invoice /> },
    { path: '/payment/:headerId', exact: false, layout: AuthLayout, element: <Payment /> },
    { path: '/openinvoices', exact: false, layout: AuthLayout, element: <OpenInvoices /> },

    { path: '/trackingpixels', exact: false, layout: AuthLayout, element: <TrackingPixels /> },
    { path: '/trackingpixel/:pixelId', exact: false, layout: AuthLayout, element: <TrackingPixel /> },
    { path: '/userprofile', exact: false, layout: AuthLayout, element: <UserProfile /> },
    { path: '/welcome', exact: false, layout: AuthLayout, element: <Welcome /> },
    { path: '/cancelaccount', exact: false, layout: AuthLayout, element: <CancelAccount /> },

    { path: '/transactionviewer/:transactionType/:baToken/:transactionToken', exact: true, layout: NoLayout, element: <TransactionViewer /> },
    { path: '/whitelabelsettings/:baid', exact: false, layout: AuthLayout, element: <WhiteLabelSettings /> },
    { path: '/emailbroadcasts', exact: false, layout: AuthLayout, element: <EmailBroadcasts /> },
    { path: '/emailbroadcast/:broadcastId', exact: false, layout: AuthLayout, element: <EmailBroadcast /> },
    { path: '/smsbroadcasts', exact: false, layout: AuthLayout, element: <SMSBroadcasts /> },
    { path: '/smsbroadcast/:broadcastId', exact: false, layout: AuthLayout, element: <SMSBroadcast /> },
    { path: '/smsbroadcastdetail', exact: false, layout: AuthLayout, element: <SMSBroadcastDetail /> },
    { path: '/directtraffic', exact: true, layout: NoLayout, element: <DirectTraffic /> },
    { path: '/unsubscribe/:baToken/:emailId', exact: true, layout: NoLayout, element: <Unsubscribe /> },
    { path: '/emailbroadcastanalytics/:broadcastId', exact: false, layout: AuthLayout, element: <EmailBroadcastAnalytics /> },
    { path: '/courses', exact: false, layout: AuthLayout, element: <Courses /> },
    { path: '/coursedetail/:courseId', exact: false, layout: AuthLayout, element: <CourseDetail /> },
    { path: '/coursesettings/:courseId', exact: false, layout: AuthLayout, element: <CourseSettings /> },
    { path: '/integrations', exact: false, layout: AuthLayout, element: <Integrations /> },
    { path: '/integrationitems', exact: false, layout: AuthLayout, element: <IntegrationItems /> },
    { path: '/integrationitem/:integrationId/:iSchemaId', exact: false, layout: AuthLayout, element: <IntegrationItem /> },
    { path: '/authsetup', exact: false, layout: AuthLayout, element: <AuthSetup /> },
    { path: '/authreturn', exact: false, layout: AuthLayout, element: <AuthReturn /> },
    { path: '/integrationscheduledsmposts', exact: false, layout: AuthLayout, element: <IntegrationScheduledSMPosts /> },
    { path: '/integrationscheduledsmpost/:postId', exact: false, layout: AuthLayout, element: <IntegrationScheduledSMPost /> },

    { path: '/bookableitems', exact: false, layout: AuthLayout, element: <BookableItems /> },
    { path: '/bookableitem/:bookableItemId', exact: false, layout: AuthLayout, element: <BookableItem /> },
    { path: '/:portalPathName/:bookableSlug', exact: false, layout: NoLayout, element: <Reservation /> },
    { path: '/:portalPathName/:bookableSlug/:locationId', exact: false, layout: NoLayout, element: <Reservation /> },
    { path: '/:portalPathName/:bookableSlug/:locationId/:resourceId', exact: false, layout: NoLayout, element: <Reservation /> },
    { path: '/reservations', exact: false, layout: AuthLayout, element: <Reservations /> },
];

export default AppRoutes;


