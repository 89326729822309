import React, { useContext, useState, useEffect } from 'react';
import { BsGearFill } from "react-icons/bs";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import helpers from '../../helpers/Helpers';
function TwoValueReportCtrl({ props }) {

    function getFormatValue(value, dataType) {
        if (dataType == undefined) {
            dataType = 'number';
        }
        switch (dataType){
            case 'money':
                value = '$' + helpers.formatNumber(value, 2)
                break;
            case 'number':
                value = helpers.formatNumber(value, 0)
                break;
            case 'percent':
                value = helpers.formatNumber(value, 2) + '%'
                break;
        }
        return value;
    }

    function formatGoalOperator(operator) {
        var results = "";
        if (operator == '>=') {
            results = "or more";
        } else if (operator == '<=') {
            results = "or less";
        }
        return results;
    }

    function getCompareDifference() {
        var difference = 0;
        if (props.value1.value > props.compare.value) {
            if (props.compare.type == "total") {
                difference = props.value1.value - props.compare.value;
            } else {
                difference = props.value1.value - props.compare.value;
            }
            
            return <><span><TrendingUpIcon style={{ color: "green" }} /></span> <span style={{ color: "green" }}>{getFormatValue(difference,props.compare.dataType)}</span> | <span style={{ color: "grey" }}>vs. {props.compare.label}</span></>;
        } else {
            if (props.compare.type == "total") {
                difference = props.compare.value - props.value1.value;
            } else {
                difference = props.compare.value - props.value1.value;
            }            
            return <><span><TrendingDownIcon style={{ color: "red" }} /></span> <span style={{ color: "red" }}>{getFormatValue(difference, props.compare.dataType)}</span> | <span style={{ color: "grey" }}>vs. {props.compare.label}</span></>;
        }       
    }

    useEffect(() => {


    }, [])

    return (
        <>
            <div style={{border:"1px solid whitesmoke",padding:"5px"}}>
                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "5px" }}>
                    <div></div>
                    <div style={{ textAlign: "center", color: "#808080", fontSize: "14px", fontWeight: "500", paddingTop: "3px" }}>{props.reportName}</div>
                    <div>
                        {props.showSettingsIcon == true ?
                            <BsGearFill className="nav-link-icon noclick" style={{ color: "#c0c0c0" }} />
                            : null}
                    </div>
                </div>
                <div style={{ fontWeight: "bold", textAlign: "center", fontSize: "26px" }}>{getFormatValue(props.value1.value, props.value1.dataType)}</div>
                {props.showValue2 == true ?
                    <div style={{ fontWeight: "500", textAlign: "center", fontSize: "12px" }}><span>{props.value2.label}</span> {getFormatValue(props.value2.value, props.value2.dataType)}</div>
                    : null}
                {props.showCompare == true ?
                    <div style={{ textAlign: "center", fontSize: "13px" }}>{getCompareDifference()}</div>
                    : null}
                {props.showGoal == true ?
                    <div style={{ textAlign: "center", fontSize: "13px" }}>Goal: {getFormatValue(props.goal.value, props.goal.dataType)} {formatGoalOperator(props.goal.operator)}</div>
                    : null}
            </div>
        </>
    );
}

export default TwoValueReportCtrl;