import React, { useContext, useState, useEffect } from 'react';
import './css/pagebuilderctrl.css';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { DataBrokerContext } from "./PageBuilderCtrl";
import DialogCtrl from '../dialogctrl/dialogctrl';
import { BAFetchData } from '../../customhooks/useBAFetch';
import { useGlobalState } from '../../globalstates/GState';
import helpers from '../../helpers/Helpers';


function SitePagesListCtrl({ siteId, handleOnPageClick, handleOnPageDelete, renderNum, reloadNum, modifedPageName }) {

    const [currentUser] = useGlobalState("userInfo");
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseOver, onWidgetMouseLeave, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, handleUpdateChanges, handleMergeVariables } = useContext(DataBrokerContext);
    const [pageListData, setPageListData] = useState({ data: null, loading: true, error: null });
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});        
    const [currRenderNum, setCurrRenderNum] = useState(null);
    const [currReloadNum, setCurrReloadNum] = useState(null);
    const [pageIndex, setPageIndex] = useState(null);
    const [sitePageId, setSitePageId] = useState(null);

    function beginSearch() {
        var filtering = {};

        filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "or", "Conditions": [
                    { "Name": "siteid", "ConditionOperator": "EQUAL", "Value": siteId, "DataType": "String" },
                ]
            }]
        };
        BAFetchData({
            callName: "BeginSearch", method: "GET", url: "cms/sitepages/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function handleDeleteConfirm(e, sitePageId) {
        e.preventDefault();
        e.stopPropagation(); 
        var msg = 'You are about to delete page or master page. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deletePage(sitePageId) } })
        setOpenDialog(true);
    }

    function deletePage(sitePageId) {
        //e.preventDefault();
        //e.stopPropagation(); 
        //var tmpPageInfo = { ...pageInfo };
        //tmpPageInfo.sitePageId = sitePageId;
        //tmpPageInfo.name = pageName;
        //setPageInfo((...pageInfo) => { return tmpPageInfo });
        var test = "test";
        test = "water";


        BAFetchData({
            callName: "DeletePageRecord", method: "DELETE", url: "cms/sitepages/delete?sitepageid=" + sitePageId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "BeginSearch":
                //var headerXPagination = JSON.parse(header['x-pagination']);
                //setPaginationInfo((...paginationInfo) => { return { itemsPerPage: headerXPagination.itemsPerPage, totalPages: headerXPagination.totalPages, totalRecords: headerXPagination.totalCount, hasNext: headerXPagination.hasNext, hasPrevious: headerXPagination.hasPrevious } });
                setPageListData((...pageListData) => { return { data: data, loading: false, error: null } });
                break;
            case "DeletePageRecord":
                //resetResults(); //Reset search results and clear records selected.               
                var msg = "Page has been deleted.";
                setGenericDialogSchema({ title: 'Page Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); beginSearch(); handleOnPageDelete(); }});
                setOpenDialog(true);
                break;
            case "GetPageInfo":
                handleOnPageClick(data.sitePageId, data);
                break;
        }
    }

    function onAPIError(error, callName) {

        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }


    function onOnPageClick(e, index, sitePageId, pageName) {
        setPageIndex(index);
        setSitePageId(sitePageId);
        BAFetchData({
            callName: "GetPageInfo", method: "GET", url: "cms/sitepages/getrecord?sitepageid=" + sitePageId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });        
    }


    useEffect(() => {
        if (pageListData.data != null) {
            if (pageIndex != null) {
                var tmpPageListData = { ...pageListData };
                if (tmpPageListData.data[pageIndex].name != modifedPageName) {
                    tmpPageListData.data[pageIndex].name = modifedPageName;
                }
            }
        }
    }, [modifedPageName]);


    useEffect(() => {
        if (currRenderNum != renderNum) {
            beginSearch();
        }
    }, [renderNum]);

    useEffect(() => {
        if (currReloadNum != reloadNum) {
            if (pageIndex != null) {
                onOnPageClick(null, pageIndex, sitePageId, null);
            }
        }
    }, [reloadNum]);

    


    useEffect(() => {
        beginSearch();
    }, []);


    return (
        <>
             <div>
                <div className="fg-pb-widget-list">
                    <div className="fg-pb-page-item" style={{ display: "grid", gridTemplateColumns: "30px 1fr", gridGap: "10px" }}>
                        <div><FolderOpenIcon></FolderOpenIcon></div>
                        <div>master pages</div>
                    </div>
                    {pageListData.loading == false ?
                        pageListData.data.map((row, index) => (
                            ((row.pageType == 'm' || row.pageType == 's') ?
                                <div key={"sitepage_" + index} className="fg-pb-page-item" style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "10px",paddingLeft:"36px" }} onClick={(e) => { onOnPageClick(e, index, row.sitePageId, row.name); }}>
                                    <div><DescriptionIcon></DescriptionIcon></div>
                                    <div>{row.name}</div>
                                    <div onClick={(e) => { handleDeleteConfirm(e, row.sitePageId); }}>
                                        {helpers.permissionAllowed(currentUser.permissions, 'Marketing-Websites', ['allowEdit']) == true ?
                                            <DeleteForeverIcon></DeleteForeverIcon>
                                            : null}
                                    </div>
                                </div>
                                : null)
                        ))
                        : null}
                    {pageListData.loading == false ?
                        pageListData.data.map((row, index) => (
                            ((row.pageType == 'p') ?
                            <div key={"sitepage_" + index} className="fg-pb-page-item" style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "10px" }} onClick={(e) => { onOnPageClick(e, index, row.sitePageId, row.name); } }>
                                <div><DescriptionIcon></DescriptionIcon></div>
                                <div>{row.name}</div>
                                    <div style={{ cursor: "pointer" }} onClick={(e) => { handleDeleteConfirm(e, row.sitePageId); }}>
                                        {helpers.permissionAllowed(currentUser.permissions, 'Marketing-Websites', ['allowEdit']) == true ?
                                            <DeleteForeverIcon></DeleteForeverIcon>
                                        : null}
                                    </div>
                            </div>
                                : null)
                        ))
                        : null}
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default SitePagesListCtrl;