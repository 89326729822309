import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';


function CssBoxShadowCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [imageList, setImageList] = useState([{ name: "" }, { name: "" }]);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [boxShadow, setBoxShadow] = useState({"hOffset": "0px", "vOffset": "0px", "blur": "0px", "spread": "0px", "color": "rgba(0,0,0,1)" });


    //const [widgetCss, setWidgetCss] = useState(elements[widgetId]);

    function parseValue(value) {
        var result = null;
        if (value != '') {
            var shadowInfo = value.split(' ');
            result = {"hOffset": shadowInfo[0], "vOffset": shadowInfo[1], "blur": shadowInfo[2], "spread": shadowInfo[3], "color": shadowInfo[4] };
        } else {
            result = { "hOffset": "0px", "vOffset": "3px", "blur": "6px", "spread": "0px", "color": "rgba(0,0,0,.23)" };
        }
        return result;
    }

    function handleOpenColorSelector() {
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Color Selector', component: (dialogType, setReturnValue) => <ColorSelectorCtrl defaultColor="red" hideControls={false} handlerReturnValue={dialogType, setReturnValue}></ColorSelectorCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: false, maxWidth: "200", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleReturnColor(returnValues); } })
        setOpenDialog(true);
    }



    function handleReturnColor(returnValues) {
        alert(JSON.stringify(returnValues));
    }

    //function parseValue(value) {
    //    var mtList = ['px'];
    //    for (var i = 0; i < mtList.length; i++) {
    //        value = value.toString().replace(mtList[i], '');
    //    }
    //    return value;
    //}

    //function updateCssProperty(propertyName, value) {
    //    var tmpValue = parseValue(value);

    //    var tmpWidgetObj = { ...widgetObj };
    //    if (tmpValue != '' && tmpValue != null) {
    //        if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
    //            tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
    //        }
    //        tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
    //    } else {
    //        if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
    //            if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
    //                delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
    //            }
    //        }
    //    }
    //    setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //    propertyChangeCallback(tmpWidgetObj);
    //}

    function updateCssProperty(propertyName, value) {
        var tmpValue = parseValue(value);

        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function onPresetChange(value) {
        setBoxShadow(value);
        var boxShadowValue = value;
        updateCssProperty("boxShadow", boxShadowValue);
    }

    function onValueChange(propertyName, value) {
        var tmpBoxShadow = { ...boxShadow };
        if (propertyName == 'color') {
            value = value.replaceAll(' ', '');
        }
        tmpBoxShadow[propertyName] = value;

        setBoxShadow(tmpBoxShadow);
        var boxShadowValue = tmpBoxShadow.hOffset + ' ' + tmpBoxShadow.vOffset + ' ' + tmpBoxShadow.blur + ' ' + tmpBoxShadow.spread + ' ' + tmpBoxShadow.color;
        updateCssProperty("boxShadow", boxShadowValue);
    }


    

    useEffect(() => {
        setWidgetObj(widgetData);        
        setBoxShadow(parseValue(getMergedStyleToBP(widgetData, 'boxShadow')));
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Box Shadow</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Presets</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" onChange={(e) => { onPresetChange(e.target.value); }}>
                                <option value="">Select...</option>
                                <option value="0px 1px 3px 0px rgba(0,0,0,0.12)">Material Design 01</option>
                                <option value="0px 3px 6px 0px rgba(0,0,0,0.16)">Material Design 02</option>
                                <option value="0px 10px 20px 0px rgba(0,0,0,0.19)">Material Design 03</option>
                                <option value="0px 14px 28px 0px rgba(0,0,0,0.25)">Material Design 04</option>
                                <option value="0px 19px 38px 0px rgba(0,0,0,0.3)">Material Design 05</option>
                                <option value="0px 29px 52px 0px rgba(0,0,0,0.4)">Material Design 06</option>
                                <option value="0px 45px 65px 0px rgba(0,0,0,0.5)">Material Design 07</option>
                                <option value="0px 60px 80px 0px rgba(0,0,0,0.6)">Material Design 08</option>
                            </select>
                        </div>
                    </div> 
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >H-Offset</div>
                        <div><SliderCtrl value={boxShadow.hOffset} min={0} max={200} onDataChange={(value) => { onValueChange('hOffset', value); }}></SliderCtrl></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >V-Offset</div>
                        <div><SliderCtrl value={boxShadow.vOffset} min={0} max={200} onDataChange={(value) => { onValueChange('vOffset', value); }}></SliderCtrl></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >Blur</div>
                        <div><SliderCtrl value={boxShadow.blur} min={0} max={200} onDataChange={(value) => { onValueChange('blur', value); }}></SliderCtrl></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >Spread</div>
                        <div><SliderCtrl value={boxShadow.spread} min={0} max={200} onDataChange={(value) => { onValueChange('spread', value); }}></SliderCtrl></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >Color</div>
                        <div><ColorFieldCtrl defaultColor={boxShadow.color} onColorChange={(color) => { onValueChange('color', color); }} hideColorLabel={false} hideControls={true}></ColorFieldCtrl></div>
                    </div>

                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

    //function getMergedStyleToBPOld(widgetData, propertyName) {
    //    var value = "";
    //    var bp = null;
    //    for (var i = 0; i < orderedBreakpoints.length; i++) {
    //        bp = orderedBreakpoints[i];
    //        if (widgetData.breakpointStyles.hasOwnProperty(bp)) {
    //            if (widgetData.breakpointStyles[bp].hasOwnProperty(propertyName)) {
    //                if (widgetData.breakpointStyles[bp][propertyName] != '') {
    //                    value = widgetData.breakpointStyles[bp][propertyName];
    //                }                    
    //            }
    //        }
    //        if (bp == Number(currentBreakpoint)) {
    //            break;   
    //        }
    //    }
    //    return value;
    //}
}

export default CssBoxShadowCtrl;

