import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function OpenInvoices({ handlerReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate, isLookup, customerId, paymentId, invoiceList }) {
    const navigate = useNavigate();
    let params = useParams();
    const lookup = isLookup == true ? true : false;
    let transType = 'invoice';
    //const [transactionType, setTransactionType] = useState(params.transactionType);
    const [columns, setColumns] = useState([]);
    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "automations_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const [searchResultData, setSearchResultData] = useState([]);

    const [rowIds, setRowIds] = useState([]);
    const pageTitle = getTranslation('automations_search_page', 'header_section', getPageCaption());
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [gridId, setGridId] = useState(1);

    var newOptions = [];
    switch (transType) {
        case "orderqs":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Quick Sales Order'), name: "OrderQS", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/order/0") } })
            break;
        case "quote":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Quote'), name: "Quote", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/quote/0") } });
            break;
        case "invoice":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Invoice'), name: "Invoice", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/invoice/0") } });
            break;
        case "payment":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Payment'), name: "Payment", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/payment/0") } });
            break;
    }

    const headerOptions = {
        elements: [
            {
                type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'Actions'), name:"Actions",icon: "", url: null, color: "btn-outline-secondary", elements: [
                    { type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } }
                    
                ]
            },
            {
                type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'New'), name: "New", icon: "bi bi-plus-lg", url: null, dropdownClassNames: "fg-save-dropdown", dropdownBtnClassNames: "fg-save-dropdown-btn", color: "btn-success", elements: newOptions
            }
        ], breadcrumbs: [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }]
    }

    if (isLookup == true) {
        headerOptions.elements = [];
    }

    function resetGridColumns() {
        var tmpColumns = [];

        tmpColumns.push({
            field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
            renderCell: ({ row }) =>
                <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('automations_search_page', 'search_grid', 'Open')}</Button>,
        });

        tmpColumns.push({
            field: 'headerId',
            headerName: getTranslation('automations_search_page', 'search_grid', 'ID'),
            width: 75,
            renderCell: function (params) {
                return <span style={{fontSize:"22px",fontWeight:"bold"}}>{params.row.headerId}</span>;
            }
        });
        if (transType == 'orderqs') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Date'),
                width: 110,
                renderCell: function (params) {
                    return dayjs(params.row.transactionDate).format('MM/DD/YYYY');
                }
            });
        } else if (transType == 'quote') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Dates'),
                width: 200,
                renderCell: function (params) {
                    return <div>
                        <span style={{ display: "inline-block", width: "90px" }}>Date: </span><span>{dayjs(params.row.transactionDate).format('MM/DD/YYYY')}</span>
                        <br /><span style={{ display: "inline-block", width: "90px" }}>Expire Date:</span><span>{dayjs(params.row.transactionDate).format('MM/DD/YYYY')}</span>
                    </div>;
                }
            });
        } else if (transType == 'invoice') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Dates'),
                width: 200,
                renderCell: function (params) {
                    return <div>
                        <span style={{ display: "inline-block", width: "90px" }}>Date: </span><span>{dayjs(params.row.transactionDate).format('MM/DD/YYYY')}</span>
                        <br /><span style={{ display: "inline-block", width: "90px" }}>Due Date:</span><span>{dayjs(params.row.dueDate).format('MM/DD/YYYY')}</span>
                    </div>;
                }
            });
        } else if (transType == 'payment') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Date'),
                width: 110,
                renderCell: function (params) {
                    return dayjs(params.row.transactionDate).format('MM/DD/YYYY');
                }
            });
        }
        tmpColumns.push({
            field: 'customerEmail',
            headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
            width: 275,
            renderCell: function (params) {
                return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
            }
        });

        if (transType == 'quote') {
            tmpColumns.push({
                field: 'totalAmtDue',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Quote Amount'),
                width: 125,
                renderCell: function (params) {
                    return <span style={{fontWeight:"bold"}}>{params.row.totalAmtDue}</span>;
                }
            });
        } else if (transType == 'orderqs') {
            tmpColumns.push({
                field: 'totalAmtDue',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Due'),
                width: 175,
                headerAlign: 'right',
                align: 'right',
                renderCell: function (params) {
                    return <div style={{ textAlign: "right" }}><span style={{ fontWeight: "bold" }}>{"$" + helpers.formatNumber(params.row.totalAmtDue, 2)}</span>
                        <br /><span style={{ fontSize: "12px" }}>Amt. Paid:</span><span style={{ display: "inline-block", fontSize: "12px", width: "60px" }}>{"$" + params.row.headerAmt}</span>
                    </div>
                }
            });
        } else if (transType == 'invoice') {
            tmpColumns.push({
                field: 'headerAmt',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Due'),
                width: 175,
                headerAlign: 'right',
                align: 'right',
                renderCell: function (params) {
                    return <div style={{ textAlign: "right" }}><span style={{ fontWeight: "bold" }}>{"$" + helpers.formatNumber(params.row.headerAmt, 2)}</span>
                        <br /><span style={{ fontSize: "12px" }}>Amt. Paid:</span><span style={{ display: "inline-block", fontSize: "12px", width: "60px" }}>{"$" + helpers.formatNumber(params.row.totalPaid, 2)}</span>
                        <br /><span style={{ fontSize: "12px" }}>Balance:</span><span style={{ display: "inline-block", fontSize: "12px", width: "60px" }}>{"$" + helpers.formatNumber(params.row.openBalance, 2)}</span>
                    </div>
                }
            });
        } else if (transType == 'payment') {
            tmpColumns.push({
                field: 'headerAmt',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Paid'),
                width: 125,
                renderCell: function (params) {
                    return <span style={{ fontWeight: "bold" }}>{params.row.headerAmt}</span>;
                }
            });
        }
        tmpColumns.push({
            field: 'posted',
            headerName: getTranslation('products_search_page', 'search_grid', 'Posted'),
            width: 120,
            renderCell: function (params) {
                return <div>
                    {params.row.posted == true ?
                        <>
                            <Chip label="Posted" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        : 
                        <>
                            <Chip label="Not-Posted" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>
                    }
                    {params.row.emailSent == true ?
                        <>
                            <Chip label="Email Sent" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                            :
                        <>
                            <Chip label="Email Not Sent" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>
                    }
                    {params.row.fulfilled == true ?
                        <>
                            <Chip label="Fulfilled" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        :
                        <>
                            <Chip label="Not Fulfilled" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>
                    }
                </div>
            }
        });

        if (transType == "invoice") {
            tmpColumns.push({
                field: 'lateInfo',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Late Info'),
                width: 200,
                headerAlign: 'left',
                align: 'left',
                renderCell: function (params) {
                    return <div>
                        <span style={{ display: "inline-block", fontSize: "12px", width: "85px", marginRight: "10px" }}>Days Late: </span><span style={{ display: "inline-block", fontSize: "12px", width: "80px", color: "red" }}>{params.row.daysLate}</span>
                        <br /><span style={{ display: "inline-block", fontSize: "12px", width: "85px", marginRight: "10px" }}>Attempt Date:</span><span style={{ display: "inline-block", fontSize: "12px", width: "80px" }}>{(params.row.lastPaymentAttemptDate != null ? dayjs(params.row.lastPaymentAttemptDate).format('MM/DD/YYYY') : "")}</span>
                        <br /><Chip label={"Late Notice Attempts: " + (params.row.paymentAttempts != null ? params.row.paymentAttempts : 0)} sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FFB22B", color: "white" }} />
                    </div>
                }
            });
            tmpColumns.push({
                field: 'status',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Status'),
                width: 200,
                renderCell: function (params) {
                    if (params.row.status == 'open') {
                        return <Chip label="Open" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} />;
                    } else if (params.row.status == 'paid') {
                        return <Chip label="Paid" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} />;
                    } else if (params.row.status == 'past due') {
                        return <Chip label="Past due" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FC4B6C", color: "white", marginBottom: "3px" }} />;
                    }

                }
            });
        }

        setColumns(tmpColumns);
    }




    function getPageCaption() {
        var title = "";
        switch (transType) {
            case 'orderqs':
                title = "Orders";
                break;
            case 'quote':
                title = "Quotes";
                break;
            case 'invoice':
                title = "Invoices";
                break;
        }
        return title;
    }



    function onGridOpenBtnClick(row) {
        switch (transType) {
            case "orderqs":
                navigate('/order/' + row.headerId);
                break;
            case "quote":
                navigate('/quote/' + row.headerId);
                break;
            case "invoice":
                navigate('/invoice/' + row.headerId);
                break;
        }        
    }

    function beginSearch() {
        var transactionFiltering = {
            "LogicalOperator": "and", "ConditionGroups": [
                {
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "transactionType", "ConditionOperator": "EQUAL", "Value": transType, "DataType": "String" },
                    ]
                },
                {
                    "LogicalOperator": "or", "Conditions": [
                        { "Name": "status", "ConditionOperator": "EQUAL", "Value": "Open", "DataType": "String" },
                        { "Name": "status", "ConditionOperator": "EQUAL", "Value": "Past Due", "DataType": "String" }
                    ]
                }
            ]
        };

        BAFetchData({
            callName: "GetData",
            method: "GET",
            url: "accounting/transactionheader/getopeninvoicesbycustomer?customerId=" + customerId + "&paymentId=" + paymentId + "&invoicelist=" + invoiceList,
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });


    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleBulkDeleteConfirm(gridId) {
        if (rowIds.length > 0) {
            var plural = helpers.getPlural(rowIds.length);
            var msg = 'You are about to delete ' + rowIds.length + ' transaction' + plural + '. <span style="font-weight:bold;">Are you sure</span>?';
            setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecords() } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }

    function deleteRecords() {
        BAFetchData({
            callName: "BulkDelete",
            method: "POST",
            url: "accounting/transactionheader/BulkDelete",
            token: "fg1234",
            body: rowIds, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function resetResults() {
        setRowIds([]);
        setSearchRefetch(true);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetData":
                setSearchResultData(data);
                break;
            case "BulkDelete":
                resetResults(); //Reset search results and clear records selected.               
                var msg = "Transaction record(s) has been deleted.";
                setGenericDialogSchema({ title: 'Transaction(s) Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function buildReturnValues(invoiceRow) {
        if (handlerReturnValue) {
            setAcceptAfterUpdate(true);
            handlerReturnValue(invoiceRow);
        }
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        resetGridColumns();
        //setTransactionType(params.transactionType);
        beginSearch();
    }, [params.transactionType]);

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />
            
            <div className="fg-app-page-box">
                <div>
                    <Box sx={{ height: 630, width: '100%' }}>
                        <DataGrid      
                            sx={{
                                border: 0,
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within": {
                                    outline: "none !important",
                                }, '& .MuiDataGrid-cell': {
                                    alignItems: 'start',paddingTop:'10px',paddingBottom:'10px'
                                },
                            }}
                            rowHeight={80}
                            rows={searchResultData}
                            getRowId={(row) => row.headerId}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns status and traderName, the other columns will remain visible
                                        open: lookup == true ? false : true,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            checkboxSelection={lookup == true ? false : true}
                            disableRowSelectionOnClick={lookup == true ? false : true}
                            onRowSelectionModelChange={(ids) => {
                                setRowIds(ids);
                                const selectedIDs = new Set(ids);
                                const selectedRowData = searchResultData.filter((row) =>
                                    selectedIDs.has(row.headerId)
                                );
                                if (selectedRowData.length > 0) {
                                    buildReturnValues(selectedRowData[0]);
                                } else {
                                    buildReturnValues(null);
                                }
                            }}
                        />
                    </Box>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );


}

export default OpenInvoices;
