import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


function ChoiceSelector({ dialogType, handlerReturnValue }) {

    function onValueChange(value) {
        if (handlerReturnValue) {
            handlerReturnValue(value);
        }
    }

    return (
        <>
            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => {onValueChange(e.target.value);}}
                    label="Options"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value="Event">Event</MenuItem>
                    <MenuItem value="File">File</MenuItem>
                    <MenuItem value="WebsitePage">Website Page</MenuItem>
                    <MenuItem value="Product">Product</MenuItem>
                    <MenuItem value="Blog">Blog</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                </Select>
            </FormControl>
        </>

    );
}

export default ChoiceSelector;