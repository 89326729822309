import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';
import ButtonFieldCtrl from '../../formcontrols/ButtonFieldCtrl';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import Products from '../../../pages/Products';

function CssMacroPresetCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    function handleOnPropertyChange(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        switch (propertyName) {
            case "name":
                tmpWidgetObj.macroPresetInfo[propertyName] = value;
                if (value != null && value != '') {
                    runPresetMacro(tmpWidgetObj, value);
                }
                break;
            case "productName":
                tmpWidgetObj.macroPresetInfo.settings[propertyName] = value;
                break;
        }


        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        //propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnBlur(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function getCustomEventIndexByName(name) {
        var index = null;
        for (var cei = 0; cei < widgetObj.customEvents.length; cei++) {
            if (widgetObj.customEvents[cei].eventName == name) {
                index = cei;
                break;
            }
        }
        return index;
    }

    function runPresetMacro(tmpWidgetObj, presetName,dataPackage) {
        switch (presetName) {
            case 'buy-now':
                var index = getCustomEventIndexByName('onClick');
                var eventName = 'onClick';
                var value = 'helpers.eComm_BuyNow(e, %%fg_product_detail_view.selectedType~, %%fg_product_detail_view.paymentOption~, %%fg_product_detail_view.selectedPrice~, %%fg_product_detail_view.productId~, %%fg_product_detail_view.selectedVariantId~, %%fg_product_detail_view.selectedCogs~, %%fg_product_detail_view.selectedSkuNum~)';
                if (dataPackage != undefined) {
                    value = 'helpers.eComm_BuyNow(e, \'' + dataPackage.recurType + '\', \'' + dataPackage.paymentOption + '\', ' + dataPackage.totalDueToday + ', \'' + dataPackage.productId + '\', \'' + dataPackage.variantId + '\', 0, \'' + dataPackage.skuNum + '\')';
                }
                if (index != null) {
                    tmpWidgetObj.customEvents[index].eventName = eventName;
                    tmpWidgetObj.customEvents[index].value = value;
                } else {
                    tmpWidgetObj.customEvents.push({ eventName: eventName, value: value });
                }
                tmpWidgetObj.linkType = 'buy_now';
                tmpWidgetObj.linkTarget = '_self';
                //tmpWidgetObj.customClassName = '+ toggleUnavailable(%%productTypeVarAttrList.row.attrName~, %%options.row.csvvalue~) + toggleSelected(%%productTypeVarAttrList.row.attrName~, %%options.row.csvvalue~)';
                break;
            case 'detail-update-qty':
                var index = getCustomEventIndexByName('onChange');
                var eventName = 'onChange';
                var value = 'changeQty(e.target.value)';
                if (index != null) {
                    tmpWidgetObj.customEvents[index].eventName = eventName;
                    tmpWidgetObj.customEvents[index].value = value;
                } else {
                    tmpWidgetObj.customEvents.push({ eventName: eventName, value: value });
                }
                break;
            case 'detail-add-to-cart':
                var index = getCustomEventIndexByName('onClick');
                var eventName = 'onClick';
                var value = 'addToCart(e, %%fg_product_detail_view.selectedType~, %%fg_product_detail_view.paymentOption~, %%fg_product_detail_view.selectedPrice~, %%fg_product_detail_view.productId~, %%fg_product_detail_view.selectedVariantId~, %%fg_product_detail_view.selectedCogs~, %%fg_product_detail_view.selectedSkuNum~)';
                if (index != null) {
                    tmpWidgetObj.customEvents[index].eventName = eventName;
                    tmpWidgetObj.customEvents[index].value = value;
                } else {
                    tmpWidgetObj.customEvents.push({ eventName: eventName, value: value });
                }
                tmpWidgetObj.linkType = 'detail_add_to_cart';
                tmpWidgetObj.linkTarget = '_self';
                //tmpWidgetObj.customClassName = '+ toggleUnavailable(%%productTypeVarAttrList.row.attrName~, %%options.row.csvvalue~) + toggleSelected(%%productTypeVarAttrList.row.attrName~, %%options.row.csvvalue~)';
                break;
            case 'remove-from-cart':
                var index = getCustomEventIndexByName('onClick');
                var eventName = 'onClick';
                var value = 'removeFromCart(e, %%details.row.detailId~)';
                if (index != null) {
                    tmpWidgetObj.customEvents[index].eventName = eventName;
                    tmpWidgetObj.customEvents[index].value = value;
                } else {
                    tmpWidgetObj.customEvents.push({ eventName: eventName, value: value });
                }
                tmpWidgetObj.linkType = 'remove_from_cart';
                break;
            case 'select-variant-option':
                var index = getCustomEventIndexByName('onClick');
                var eventName = 'onClick';
                var value = 'selectVariant(e, %%productTypeVarAttrList.row.attrName~, %%options.row.csvvalue~)';
                if (index != null) {
                    tmpWidgetObj.customEvents[index].eventName = eventName;
                    tmpWidgetObj.customEvents[index].value = value;
                } else {
                    tmpWidgetObj.customEvents.push({ eventName: eventName, value: value });
                }
                tmpWidgetObj.customClassName = '+ toggleUnavailable(%%productTypeVarAttrList.row.attrName~, %%options.row.csvvalue~) + toggleSelected(%%productTypeVarAttrList.row.attrName~, %%options.row.csvvalue~)';
                tmpWidgetObj.customId = 'fg-variant';
                break;
            case 'update-cart-item-qty':
                var index = getCustomEventIndexByName('onBlur');
                var eventName = 'onBlur';
                var value = 'updateCartItemQty(e,%%details.row.detailId~)';
                if (index != null) {
                    tmpWidgetObj.customEvents[index].eventName = eventName;
                    tmpWidgetObj.customEvents[index].value = value;
                } else {
                    tmpWidgetObj.customEvents.push({ eventName: eventName, value: value });
                }
                break;
            case 'initiate-checkout':
                var index = getCustomEventIndexByName('onClick');
                var eventName = 'onClick';
                var value = 'initiateCheckout(e)';
                if (index != null) {
                    tmpWidgetObj.customEvents[index].eventName = eventName;
                    tmpWidgetObj.customEvents[index].value = value;
                } else {
                    tmpWidgetObj.customEvents.push({ eventName: eventName, value: value });
                }
                tmpWidgetObj.linkType = 'initiate-checkout';
                tmpWidgetObj.linkTarget = '_self';
                break;
            case 'process-payment':
                var index = getCustomEventIndexByName('onClick');
                var eventName = 'onClick';
                var value = 'processPayment(e,fg_shopping_cart)';
                if (index != null) {
                    tmpWidgetObj.customEvents[index].eventName = eventName;
                    tmpWidgetObj.customEvents[index].value = value;
                } else {
                    tmpWidgetObj.customEvents.push({ eventName: eventName, value: value });
                }
                tmpWidgetObj.linkType = 'process_payment';
                tmpWidgetObj.linkTarget = '_self';
                break;
        }
    } 

    function openProductLookup() {
        var msg = '';
        setGenericDialogSchema({ title: 'Product Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <Products handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></Products>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('GetProductInfo', returnValues); } });
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case 'GetProductInfo':
                var tmpWidgetObj = { ...widgetObj };
                //tmpWidgetObj[propertyName] = value;
                //if (propertyName == 'macroPreset') {
                if (returnValues.productId != null && returnValues.productId != '') {
                    runPresetMacro(tmpWidgetObj, 'buy-now',returnValues);
                }
                tmpWidgetObj.macroPresetInfo.settings['productName'] = `${returnValues.productName} ${returnValues.variantSummary}`;
                //}
                setWidgetObj(tmpWidgetObj);
                //setWidgetObj((...widgetObj) => { return tmpWidgetObj });
                break;
        }
    }


    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])

    

    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Macro Preset</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Name</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={(widgetObj.macroPresetInfo != null) ? widgetObj.macroPresetInfo.name : ''} onChange={(e) => { handleOnPropertyChange('name', e.target.value); }} onBlur={(e) => { handleOnBlur("name", e.target.value); }}>
                                <option value=""></option>                      
                                <option value="add-to-cart">Ecomm - Add to Cart</option>
                                <option value="buy-now">Ecomm - Buy Now</option>
                                <option value="initiate-checkout">Ecomm - Initiate checkout</option>
                                <option value="detail-add-to-cart">Ecomm - Detail add to cart</option>
                                <option value="detail-update-qty">Ecomm - Detail update qty</option>
                                <option value="process-payment">Ecomm - Process payment</option>
                                <option value="remove-from-cart">Ecomm - Remove from cart</option>
                                <option value="select-variant-option">Ecomm - Select variant option</option>
                                <option value="update-cart-item-qty">Ecomm - Update cart item qty</option>                                                                
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "90px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Select Product</div>
                        <div>
                            {widgetObj.hasOwnProperty('macroPresetInfo') == true ?
                                <ButtonFieldCtrl displayReadOnly={true} value={widgetObj.macroPresetInfo.settings.productName || ''} onClick={() => { openProductLookup(); }} onRemove={() => { handleOnPropertyChange('linkUrl', null); }} onChange={(value) => { handleOnPropertyChange('linkUrl', value); }} style={{ border: "1px solid #ced4da", padding: "0px 0px 0px 3px" }}></ButtonFieldCtrl>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );

}

export default CssMacroPresetCtrl;

