import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import ImageFieldCtrl from '../components/formcontrols/ImageFieldCtrl';
import { useGlobalState } from '../globalstates/GState';
import Checkbox from '@mui/material/Checkbox';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function NewContact({dialogType,handlerReturnValue}) {

    const [currentUser] = useGlobalState("userInfo");

    const navigate = useNavigate();
    let params = useParams();
    const [tempFileRecId, setFileRecId] = useState(helpers.getUUID());
    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [listDataSource, setListDataSource] = useState([]);
    const [listSelected, setListSelected] = useState([]);

    const [tagsDataSource, setTagsDataSource] = useState([]);
    const [tagsSelected, setTagsSelected] = useState([]);

    const [formData, setFormData] = useState({});

    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.contactId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_detail_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('contact_detail_page', 'header_section', 'Contact Detail');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.contactId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [
                    { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Send Email/SMS/Voicemail'), name: "Send Email/SMS/Voicemail", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Create Appointment'), name: "Create Appointment", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Send Zoom Invite'), name: "Send Zoom Invite", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Add / Remove Automation'), name: "Add/Remove Automation", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Create Work Order'), name: "Create Work Order", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Create Invoice'), name: "Create Invoice", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Receive Payment'), name: "Recieve Payment", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Send CC Authorization Form'), name: "Send CC Authorization Form", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Send Account Invite'), name: "Send Account Invite", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: null },

                    { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Open Business Account'), name: "Open Business Account", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } },
                ]
            }
        )
    }
    headerOptions.elements.push({ type: "button", text: getTranslation('contact_detail_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    const list_onTagDBInsert = (criteria, handleOnDBInsertCallbackComplete) => {
        var segmentedList = {
            "listId": 0,
            "baid": 0,
            "listName": criteria,
            "active": true,
            "dynamic": false,
            "rules": null,
            "dateCreated": new Date().toISOString(),
            "total": 0,
            "internalState": "added"
        }

        BAFetchData({
            callName: "AddList", method: "POST", url: "crm/segmentedlist/add",
            token: "fg1234", body:segmentedList,onSuccess: handleOnDBInsertCallbackComplete, onError: onAPIError
        });
    }

    const list_onTagAdded = (id, text) => {
        var found = false;
        var temp = JSON.parse(JSON.stringify(formData));
        for (var i = 0; i < temp.segmentedListMappings.length; i++) {
            if (temp.segmentedListMappings[i].listId == id) {
                found = true;
                if (temp.segmentedListMappings[i].listMapId > 0) {
                    temp.segmentedListMappings[i].internalState = "unchanged";
                }                
                break;
            }
        }
        if (found == false) {
            var segmentedListMappingDTO = {
                "listMapId": 0,
                "baid": 0,
                "listId": id,
                "contactId": primaryKeyValue,
                "dateCreated": new Date().toISOString(),
                "listName": text,
                "internalState": "added"
            }
            temp.segmentedListMappings.push(segmentedListMappingDTO);
        }
        setFormData(temp);
    }


    const list_onTagRemoved = (id) => {
        var found = false;
        var remove = false;
        var temp = JSON.parse(JSON.stringify(formData));
        for (var i = 0; i < temp.segmentedListMappings.length; i++) {
            if (temp.segmentedListMappings[i].listId == id) {
                found = true;
                if (temp.segmentedListMappings[i].listMapId == 0) {
                    temp.segmentedListMappings.splice(i, 1);
                } else {
                    temp.segmentedListMappings[i].internalState = "deleted";
                }
                break;
            }
        }
        setFormData(temp);
    }

    function list_onTagSearch(criteria, handleOnSearchCallbackComplete) {
        if (criteria != '') {
            var filtering = {
                "LogicalOperator": "and", "ConditionGroups": [{
                    "LogicalOperator": "or", "Conditions": [
                        { "Name": "ListName", "ConditionOperator": "STARTSWITH", "Value": criteria, "DataType": "String" }
                    ]
                }]
            };
            BAFetchData({
                callName: "GetList", method: "GET", url: "crm/segmentedlist/getlist?filtering=" + JSON.stringify(filtering),
                token: "fg1234", onSuccess: handleOnSearchCallbackComplete, onError: onAPIError
            });

        }
    }


    const tags_onTagDBInsert = (criteria, handleOnDBInsertCallbackComplete) => {
        const test = 'test';
        var tag = {
            "tagId": 0,
            "baid": 0,
            "tagName": criteria,
            "tagType": "Contact",
            "active": true,
            "dateCreated": new Date().toISOString(),
            "total": 0,
            "internalState": "added"
        }

        BAFetchData({
            callName: "AddTag", method: "POST", url: "crm/tag/add",
            token: "fg1234", body: tag, onSuccess: handleOnDBInsertCallbackComplete, onError: onAPIError
        });
    }

    const tags_onTagAdded = (id, text) => {
        var found = false;
        var temp = JSON.parse(JSON.stringify(formData));
        for (var i = 0; i < temp.tagMappings.length; i++) {
            if (temp.tagMappings[i].tagId == id) {
                found = true;
                if (temp.tagMappings[i].tagMapId > 0) {
                    temp.tagMappings[i].internalState = "unchanged";
                }                
                break;
            }
        }
        if (found == false) {
            var tagMappingDTO = {
                "tagMapId": 0,
                "baid": 0,
                "tagId": id,
                "contactId": primaryKeyValue,
                "refType": "contact",
                "dateCreated": new Date().toISOString(),
                "tagName": text,
                "internalState": "added"
            }
            temp.tagMappings.push(tagMappingDTO);
        }
        setFormData(temp);            
    }

    const tags_onTagRemoved = (id) => {
        var found = false;
        var remove = false;
        var temp = JSON.parse(JSON.stringify(formData));
        for (var i = 0; i < temp.tagMappings.length; i++) {
            if (temp.tagMappings[i].tagId == id) {
                found = true;
                if (temp.tagMappings[i].tagMapId == 0) {
                    temp.tagMappings.splice(i, 1);
                } else {
                    temp.tagMappings[i].internalState = "deleted";
                }
                break;
            }
        }
        setFormData(temp);
    }

    function tags_onTagSearch(criteria, handleOnSearchCallbackComplete) {
        if (criteria != '') {
            var filtering = {
                "LogicalOperator": "and", "ConditionGroups": [{
                    "LogicalOperator": "or", "Conditions": [
                        { "Name": "TagName", "ConditionOperator": "STARTSWITH", "Value": criteria, "DataType": "String" }
                    ]
                }]
            };
            BAFetchData({
                callName: "GetTags", method: "GET", url: "crm/tag/getlist?filtering=" + JSON.stringify(filtering),
                token: "fg1234", onSuccess: handleOnSearchCallbackComplete, onError: onAPIError
            });

        }
    }

    function updateTempRecId(contactId) {

        BAFetchData({
            callName: "UpdateTempRecId",
            method: "POST",
            url: "files/updatetempid?category=main&rectype=contact&tempfilerecid=" + tempFileRecId + "&recid=" + contactId,
            token: "fg1234",
            toggleRefetch: searchRefetch,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addDefaultData(data) {
        if (primaryKeyValue == 0) {
            //set default data
        }
    }

    function onFieldBlur(e, propertyName, value) {
        var tmpFormData = { ...formData };

        switch (propertyName) {
            case 'physicalAddress1':
            case 'physicalAddress2':
            case 'physicalCity':
            case 'physicalState':
            case 'physicalZip':
            case 'physicalCountryCode':
                if (tmpFormData.copyPhysicalAddress == true) {
                    copyPhysicalAddress(tmpFormData);
                }
                break;
        }
        setFormData(tmpFormData);
    }

    function onCopyPhysicalAddressChange(e, value) {
        var tmpFormData = { ...formData };
        tmpFormData.copyPhysicalAddress = value;
        if (value == true) {
            copyPhysicalAddress(tmpFormData);
        }
        setFormData(tmpFormData);

    }

    function copyPhysicalAddress(tmpFormData) {
        tmpFormData.mailingAddress1 = tmpFormData.physicalAddress1;
        tmpFormData.mailingAddress2 = tmpFormData.physicalAddress2;
        tmpFormData.mailingCity = tmpFormData.physicalCity;
        tmpFormData.mailingState = tmpFormData.physicalState;
        tmpFormData.mailingZip = tmpFormData.physicalZip;
        tmpFormData.mailingCountryCode = tmpFormData.physicalCountryCode;
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetList":
                setListDataSource(data);
                break;
            case "GetTags":
                setTagsDataSource(data);
                break;
            case "GetMainRecord":
                addDefaultData(data);
                setFormData(data);
                setListSelected(data.segmentedListMappings);
                setTagsSelected(data.tagMappings);

                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));

                break;
            case "SaveForm":                
                setPrimaryKeyValue(data.contactId);
                setFormData(data);
                setListSelected(data.segmentedListMappings);
                setTagsSelected(data.tagMappings);
                updateTempRecId(data.contactId);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/contact/' + data.contactId); } })
                setOpenDialog(true);
                break;
            case "UpdateTempRecId":
                var test = "test";
                    test = "hello";
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/contactsearch'); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "crm/contact/getrecordfull?contactid=" + primaryKeyValue + "&includelist=true&includetags=true",
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "crm_contacts", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);



    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "900px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Contact Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <FormControlLabel style={{ marginBottom: "0px" }}
                                                control={
                                                    <Switch checked={formData.isCompany} onChange={(e) => setFormData(formData => ({ ...formData, isCompany: e.target.checked }))} name="gilad" />
                                                }
                                                label="Is Company"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4">
                                                    <TextField id="firstName" autoFocus fullWidth label="First Name" variant="standard" helperText={errorList['firstName'] ? <span style={{ color: "red" }}>{errorList['firstName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.firstName || ''} InputLabelProps={{ shrink: formData.firstName }} onChange={(e) => setFormData(formData => ({...formData, firstName: e.target.value}))} />
                                                </div>
                                                <div className="col-sm-12 col-md-4">
                                                    <TextField id="lastName" fullWidth label="Last Name" variant="standard" helperText={errorList['lastName'] ? <span style={{ color: "red" }}>{errorList['lastName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.lastName || ''} InputLabelProps={{ shrink: formData.lastName }} onChange={(e) => setFormData(formData => ({ ...formData, lastName: e.target.value }))} />
                                                </div>
                                                <div className="col-sm-12 col-md-4">
                                                    <ImageFieldCtrl imgSrc={formData.profileImg || ''} label="Profile Image" imgType="inline" returnFullPath={true} recType="contact" recId={primaryKeyValue != "0" ? primaryKeyValue : tempFileRecId} onChange={(imgValue) => setFormData(formData => ({ ...formData, profileImg: imgValue }))}></ImageFieldCtrl>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="businessName" fullWidth label="Business Name" variant="standard" helperText={errorList['businessName'] ? <span style={{ color: "red" }}>{errorList['businessName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.businessName || ''} InputLabelProps={{ shrink: formData.businessName }} onChange={(e) => setFormData(formData => ({ ...formData, businessName: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <TextField id="email" fullWidth label="Email" variant="standard" helperText={errorList['email'] ? <span style={{ color: "red" }}>{errorList['email']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.email || ''} InputLabelProps={{ shrink: formData.email }} onChange={(e) => setFormData(formData => ({ ...formData, email: e.target.value }))} />
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    
                                                    <TextField id="phone" fullWidth label="Phone" variant="standard" helperText={errorList['phone'] ? <span style={{color:"red"}}>{errorList['phone']}</span>  : ''} style={{ marginBottom: "16px" }} value={formData.phone || ''} InputLabelProps={{ shrink: formData.phone }} onChange={(e) => setFormData(formData => ({ ...formData, phone: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Segmentation</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div style={{ marginBottom: "20px" }}>
                                                        <MultiSelectCtrl id="segmentedListMappings" label="Segmented Lists" placeholder="Enter list name" defaultKeyValuePair={listSelected} dataSourceList={listDataSource} remoteSource={true} dataSourceIdField="listId" dataSourceTextField="listName" onSearchCallback={(criteria, handleonSearchCallbackComplete) => list_onTagSearch(criteria, handleonSearchCallbackComplete)} onDatabaseInsert={(criteria, handleOnDBInsertCallbackComplete) => list_onTagDBInsert(criteria, handleOnDBInsertCallbackComplete)} onTagAddedCallback={(id, text) => list_onTagAdded(id, text)} onTagRemovedCallback={(id, text) => list_onTagRemoved(id, text)} />
                                                        <div>{errorList['segmentedListMappings'] ? <span style={{ color: "red" }}>{errorList['segmentedListMappings']}</span> : ''}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div style={{marginBottom:"20px"}}>
                                                        <MultiSelectCtrl id="tagMappings" label="Segmented Tags" placeholder="Enter tag name" defaultKeyValuePair={tagsSelected} dataSourceList={tagsDataSource} remoteSource={true} dataSourceIdField="tagId" dataSourceTextField="tagName" onSearchCallback={(criteria, handleonSearchCallbackComplete) => tags_onTagSearch(criteria, handleonSearchCallbackComplete)} onDatabaseInsert={(criteria, handleOnDBInsertCallbackComplete) => tags_onTagDBInsert(criteria, handleOnDBInsertCallbackComplete)} onTagAddedCallback={(id, text) => tags_onTagAdded(id, text)} onTagRemovedCallback={(id, text) => tags_onTagRemoved(id, text)} />
                                                        <div>{errorList['tagMappings'] ? <span style={{ color: "red" }}>{errorList['tagMappings']}</span> : ''}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="segment">
                                <div className="segment-title">Physical Address</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <TextField id="physicalAddress1" fullWidth label="Street Address" variant="standard" helperText={errorList['physicalAddress1'] ? <span style={{ color: "red" }}>{errorList['physicalAddress1']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.physicalAddress1 || ''} InputLabelProps={{ shrink: formData.physicalAddress1 }} onChange={(e) => setFormData(formData => ({ ...formData, physicalAddress1: e.target.value }))} onBlur={(e) => onFieldBlur(e, 'physicalAddress1', e.target.value)} />
                                                </div>
                                                <div className="col-sm-5">
                                                    <TextField id="physicalAddress2" fullWidth label="Apt or Suite #" variant="standard" helperText={errorList['physicalAddress2'] ? <span style={{ color: "red" }}>{errorList['physicalAddress2']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.physicalAddress2 || ''} InputLabelProps={{ shrink: formData.physicalAddress2 }} onChange={(e) => setFormData(formData => ({ ...formData, physicalAddress2: e.target.value }))} onBlur={(e) => onFieldBlur(e, 'physicalAddress2', e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <TextField id="physicalCity" fullWidth label="City" variant="standard" helperText={errorList['physicalCity'] ? <span style={{ color: "red" }}>{errorList['physicalCity']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.physicalCity || ''} InputLabelProps={{ shrink: formData.physicalCity }} onChange={(e) => setFormData(formData => ({ ...formData, physicalCity: e.target.value }))} onBlur={(e) => onFieldBlur(e, 'physicalCity', e.target.value)} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <TextField id="physicalState" fullWidth label="State/Province" variant="standard" helperText={errorList['physicalState'] ? <span style={{ color: "red" }}>{errorList['physicalState']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.physicalState || ''} InputLabelProps={{ shrink: formData.physicalState }} onChange={(e) => setFormData(formData => ({ ...formData, physicalState: e.target.value }))} onBlur={(e) => onFieldBlur(e, 'physicalState', e.target.value)} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <TextField id="physicalZip" fullWidth label="Zip/Postal" variant="standard" helperText={errorList['physicalZip'] ? <span style={{ color: "red" }}>{errorList['physicalZip']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.physicalZip || ''} InputLabelProps={{ shrink: formData.physicalZip }} onChange={(e) => setFormData(formData => ({ ...formData, physicalZip: e.target.value }))} onBlur={(e) => onFieldBlur(e, 'physicalZip', e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="physicalCountryCode" fullWidth label="Country Code" variant="standard" helperText={errorList['physicalCountryCode'] ? <span style={{ color: "red" }}>{errorList['physicalCountryCode']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.physicalCountryCode || ''} InputLabelProps={{ shrink: formData.physicalCountryCode }} onChange={(e) => setFormData(formData => ({ ...formData, physicalCountryCode: e.target.value }))} onBlur={(e) => onFieldBlur(e, 'physicalCountryCode', e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="segment">
                                <div className="segment-title"><span>Shipping Address</span>
                                    <div style={{ display: "inline-block", marginLeft: "20px" }}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox style={{ padding: "2px" }} checked={formData.copyPhysicalAddress} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} onChange={(e) => onCopyPhysicalAddressChange(e, e.target.checked)} />}
                                            label="Same as physical address"
                                            labelPlacement="end"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <TextField id="mailingAddress1" fullWidth label="Street Address" variant="standard" helperText={errorList['mailingAddress1'] ? <span style={{ color: "red" }}>{errorList['mailingAddress1']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingAddress1 || ''} InputLabelProps={{ shrink: formData.mailingAddress1 }} onChange={(e) => setFormData(formData => ({ ...formData, mailingAddress1: e.target.value }))} />
                                                </div>
                                                <div className="col-sm-5">
                                                    <TextField id="mailingAddress2" fullWidth label="Apt or Suite #" variant="standard" helperText={errorList['mailingAddress2'] ? <span style={{ color: "red" }}>{errorList['mailingAddress2']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingAddress2 || ''} InputLabelProps={{ shrink: formData.mailingAddress2 }} onChange={(e) => setFormData(formData => ({ ...formData, mailingAddress2: e.target.value }))} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <TextField id="mailingCity" fullWidth label="City" variant="standard" helperText={errorList['mailingCity'] ? <span style={{ color: "red" }}>{errorList['mailingCity']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingCity || ''} InputLabelProps={{ shrink: formData.mailingCity }} onChange={(e) => setFormData(formData => ({ ...formData, mailingCity: e.target.value }))} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <TextField id="mailingState" fullWidth label="State/Province" variant="standard" helperText={errorList['mailingState'] ? <span style={{ color: "red" }}>{errorList['mailingState']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingState || ''} InputLabelProps={{ shrink: formData.mailingState }} onChange={(e) => setFormData(formData => ({ ...formData, mailingState: e.target.value }))} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <TextField id="mailingZip" fullWidth label="Zip/Postal" variant="standard" helperText={errorList['mailingZip'] ? <span style={{ color: "red" }}>{errorList['mailingZip']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingZip || ''} InputLabelProps={{ shrink: formData.mailingZip }} onChange={(e) => setFormData(formData => ({ ...formData, mailingZip: e.target.value }))} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="mailingCountryCode" fullWidth label="Country Code" variant="standard" helperText={errorList['mailingCountryCode'] ? <span style={{ color: "red" }}>{errorList['mailingCountryCode']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingCountryCode || ''} InputLabelProps={{ shrink: formData.mailingCountryCode }} onChange={(e) => setFormData(formData => ({ ...formData, mailingCountryCode: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function showDialog() {
        var msg = 'You are about to delete this contact record. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Contacts Created', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); okAction() } })
        setOpenDialog(true);
    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog()} })
        setOpenDialog(true);
    }

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete contact. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "crm/contact/delete?contactId=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    async function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (await isCustomFormValidationValid() == true) {
                saveForm();
            }
        } else {
            var msg = 'There are form validation errors.';
            setGenericDialogSchema({ title: 'Validation Errors', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }                
    }

    function saveForm() {
        if (primaryKeyValue == 0) {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "crm/contact/add",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "crm/contact/update",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    async function contactEmailExists() {
        const data = await BAFetchData({
            callName: "ContactEmailExists",
            method: "GET",
            url: "crm/contact/contactemailexists?email=" + formData.email + "&contactid=" + formData.contactId,
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        return data;
    }

    async function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        if (formData.isCompany == false) {
            if (formData.firstName == null && formData.lastName == null && formData.businessName == null && formData.email == null) {
                isValid = false;
                tempErrorList['firstName'] = "First Name, Last Name, Business Name or Email is required.";
                tempErrorList['lastName'] = "First Name, Last Name, Business Name or Email is required.";
                tempErrorList['businessName'] = "First Name, Last Name, Business Name or Email is required.";
                tempErrorList['email'] = "First Name, Last Name, Business Name or Email is required.";
            }
        } else {
            if ((formData.isCompany == true && formData.businessName == null)) {
                isValid = false;
                tempErrorList['businessName'] = "Business Name is required when Is Company switch is true.";
            }
        }
        if (formData.email != null && formData.email != '') {
            var total = await contactEmailExists();
            if (total.data > 0) {
                isValid = false;
                tempErrorList['email'] = "Email already exist. Enter a different email address.";
            }
        }


        setErrorList(tempErrorList);
        return isValid;
    }
}

export default NewContact;