import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import helpers from '../../helpers/Helpers';
import './css/kanbanctrl.css';
import Button from '@mui/material/Button';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import KanbanCardCtrl from './KanbanCardCtrl';
import { KanbanBrokerContext } from './KanbanCtrl';
import DropdownMenuCtrl from '../dropdownmenuctrl/dropdownmenuctrl';
import TextField from '@mui/material/TextField';
import { useGlobalState } from '../../globalstates/GState';




function KanbanColumnCtrl({ column, pipeline }) {
    let columnTotalsInfo = null;
    const [currentUser] = useGlobalState("userInfo");
    const { cards, setCards, boards, setBoards, projectId, handleOnSaveCard } = useContext(KanbanBrokerContext);

    const [columnObj, setColumnObj] = useState(column);
    const [newInfo, setNewInfo] = useState({show:false,cardType:null});

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});    
    const [newTitle, setNewTitle] = useState('');

    const moreMenuItems = [];
    moreMenuItems.push({ menuType: "MenuItem", name: "Rename", caption: getTranslation('application_shell', 'profile_menu', 'Rename'), icon: null, link: null, callback: () => shortAddAction('Rename') });

    const addMenuItems = [];
    addMenuItems.push({ menuType: "MenuItem", name: "Appointment", caption: getTranslation('application_shell', 'profile_menu', 'Appointment'), icon: null, link: null, callback: () => shortAddAction('Appointment') });
    addMenuItems.push({ menuType: "MenuItem", name: "Bug", caption: getTranslation('application_shell', 'profile_menu', 'Bug'), icon: null, link: null, callback: () => shortAddAction('Bug') });
    addMenuItems.push({ menuType: "MenuItem", name: "Incident", caption: getTranslation('application_shell', 'profile_menu', 'Incident'), icon: null, link: null, callback: () => shortAddAction('Incident') });
    addMenuItems.push({ menuType: "MenuItem", name: "Opportunity", caption: getTranslation('application_shell', 'profile_menu', 'Opportunity'), icon: null, link: null, callback: () => shortAddAction('Opportunity') });
    addMenuItems.push({ menuType: "MenuItem", name: "Task", caption: getTranslation('application_shell', 'profile_menu', 'Task'), icon: null, link: null, callback: () => shortAddAction('Task') });
    addMenuItems.push({ menuType: "MenuItem", name: "User Story", caption: getTranslation('application_shell', 'profile_menu', 'User Story'), icon: null, link: null, callback: () => shortAddAction('User Story') });      

    function getTranslation(a,b,value) {
        return value;
    }

    function shortAddAction(cardType) {
        setNewInfo({ show: true, cardType: cardType });
    }

    function closeQuickSave() {
        setNewTitle('');
        setNewInfo({ show: false, cardType: null });
    }

    function quickSave(cardType) {
        var newId = "card_" + helpers.getUUID();
        var tmpCards = { ...cards };
        var tmpColumnObj = { ...columnObj };
        var newCard = { cardId: newId, cardType: cardType, title: newTitle, baid: currentUser.currentBusinessAccountDTO.baid, createdBy: currentUser.currentBusinessAccountDTO.bamId,endDate: null, estDur: null, completed: false, cardMembers: [], projectId: projectId, boardId: columnObj.boardId, columnId: columnObj.columnId };

        handleOnSaveCard(newCard, 'add');

        tmpCards[newId] = newCard;
        tmpColumnObj.cardIds.push(newId);
        setColumnObj(tmpColumnObj);
        setCards(tmpCards);
        setNewTitle('');
    }

    function allowDrop(e) {
        e.preventDefault();
    }
    function handleOnDrop(e: React.DragEvent, columnId) {

        e.preventDefault();
        e.stopPropagation();

        const cardInfoStr = e.dataTransfer.getData("card");
        const cardInfo = JSON.parse(cardInfoStr);

        var tmpBoards = [...boards];
        var tmpCards = { ...cards };
        var tmpColumnObj = { ...columnObj };

        var cardObj = tmpCards[cardInfo.cardId];
        var sourceColumnId = cardObj.columnId;
        var destColumnId = columnId;
        cardObj.columnId = columnId;

        handleOnSaveCard(cardObj,'update');

        //add card id to destination column
        //tmpColumnObj.cardIds.push(cardObj.cardId);
        moveCard(tmpBoards, cardObj.cardId, cardObj.boardId, cardObj.boardId, sourceColumnId, destColumnId);
        setCards(tmpCards);
        setBoards(tmpBoards);

        
        //setColumnObj(tmpColumnObj);
        
        

    }



    function moveCard(tmpBoards, cardId, sourceBoardId, destBoardId, sourceColumnId, destColumnId) {
        var board = null;
        var column = null;
        //disassociate card from source board
        for (var bi = 0; bi < tmpBoards.length; bi++) {
            board = tmpBoards[bi];
            if (board.boardId == sourceBoardId) {
                for (var ci = 0; ci < board.columns.length; ci++) {
                    column = board.columns[ci];
                    if (column.columnId == sourceColumnId) {
                        for (var cci = 0; cci < column.cardIds.length; cci++) {
                            if (column.cardIds[cci] == cardId) {
                                column.cardIds.splice(cci, 1);
                                break;
                            }
                        }
                        break;
                    }
                }
                break;
            }
        }

        //associate card with dest board
        for (var bi = 0; bi < tmpBoards.length; bi++) {
            board = tmpBoards[bi];
            if (board.boardId == destBoardId) {
                for (var ci = 0; ci < board.columns.length; ci++) {
                    column = board.columns[ci];
                    if (column.columnId == destColumnId) {
                        column.cardIds.push(cardId);
                        break;
                    }
                }
                break;
            }
        }
    }

    useEffect(() => {
        setColumnObj(column);
    }, [column]);
     

    function getColumnTotals() {
        var colTotals = { duration: '0h', cost: 0 };
        var minutes = parseInt(0);
        var cost = parseFloat(0);
        var dealValue = parseFloat(0);
        for (var ci = 0; ci < columnObj.cardIds.length; ci++) {
            var cardObj = cards[columnObj.cardIds[ci]];
            if (cardObj != undefined) {
                if (cardObj.completed == false) {
                    minutes += parseInt(helpers.convertShorthandToMin(cardObj.estDur || ''));
                    if (cardObj.cardType != 'Opportunity') {
                        cost += parseFloat(cardObj.estCost || 0);
                    } else {
                        dealValue += parseFloat(cardObj.dealValue || 0);
                    }
                }
            }
        }
        colTotals = { duration: helpers.convertMinToShorthand(minutes), cost: cost, dealValue: dealValue };
        return colTotals;
    }

    return (
        <>
            <div className="fgkb-board-column" style={{ width: "260px", display: "inline-block", marginRight: "20px" }} onDragOver={(e) => { allowDrop(e); }} onDrop={(e) => { handleOnDrop(e, columnObj.columnId); }}>
                <div className="fgkb-board-column-header" style={{ backgroundColor: "#ffffff", padding: "5px", marginBottom: "20px", borderTop: "5px solid #808080", borderRight: "1px solid #808080", borderBottom: "1px solid #808080", borderLeft: "1px solid #808080", borderRadius: "4px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 25px", width: "100%" }}>
                        <div><span style={{ fontWeight: "500" }}>{columnObj.columnName}</span> <span style={{ marginLeft: "5px", display: "inline-block", border: "1px solid #c0c0c0", textAlign: "center", fontSize: "10px", height: "16px", minWidth: "20px", padding: "0px 3px 0px 3px", borderRadius: "4px" }}>{columnObj.cardIds.length}</span></div>
                        <div style={{ textAlign: "right", display:"none" }}><DropdownMenuCtrl buttonType="More" imageSrc="" avatarText="" toolTip={getTranslation('application_shell', 'profile_menu', 'Profile Menu')} menuItems={moreMenuItems} iconStyle={{ width: 28, height: 28, marginBottom: "0px", fontSize: "14px" }}></DropdownMenuCtrl></div>
                        <div style={{ textAlign: "right" }}>
                            {helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectCards', ['allowAdd']) == true ?
                                <DropdownMenuCtrl buttonType="Add" imageSrc="" avatarText="" toolTip={getTranslation('application_shell', 'profile_menu', 'Profile Menu')} menuItems={addMenuItems} iconStyle={{ width: 28, height: 28, marginBottom: "0px", fontSize: "14px" }}></DropdownMenuCtrl>
                                : null}
                        </div>
                        
                    </div>
                    {pipeline != true ?
                        <div style={{ display: "grid", gridTemplateColumns: "75px 1fr", fontSize: "12px" }}>
                            <div>Dur: {getColumnTotals().duration}</div>
                            <div style={{ textAlign: "right" }}>Cost: ${getColumnTotals().cost}</div>
                        </div>
                        : 
                        <div style={{ display: "grid", gridTemplateColumns: "75px 1fr 1fr", fontSize: "12px" }}>
                            <div>Dur: {getColumnTotals().duration}</div>
                            <div style={{ textAlign: "right" }}>Value: ${getColumnTotals().dealValue}</div>
                            <div style={{ textAlign: "right" }}>Cost: ${getColumnTotals().cost}</div>
                        </div>
                        }
                </div>
                <div className="fgkb-board-column-list">
                    {newInfo.show ?
                        <div style={{ marginBottom: "10px", backgroundColor: "white", padding: "5px" }}>
                            <div>
                                <TextField autoFocus fullWidth label={"New " + newInfo.cardType} autoComplete='off' variant="outlined" size="small" style={{ marginBottom: "16px" }} value={newTitle || ''} InputLabelProps={{ shrink: newInfo.cardType }} onChange={(e) => { setNewTitle(e.target.value); }} />
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                <div><Button variant="outlined" color="primary" size="small" style={{ color: "#ffffff", backgroundColor: "#26C6DA", minWidth: "unset", width: "100%", border: "1px solid #26C6DA", padding: "4px", fontSize: "10px" }} onClick={(e) => { quickSave(newInfo.cardType); }}>Save</Button></div>
                                <div><Button variant="outlined" color="primary" size="small" style={{ color: "#000000", backgroundColor: "white", minWidth: "unset", width: "100%", border: "1px solid rgba(0,0,0,.12)", padding: "4px", fontSize: "10px" }} onClick={(e) => { closeQuickSave(); }}>Cancel</Button></div>                                
                            </div>
                        </div>
                        : null}
                    {columnObj.cardIds.map((cardId, ci) => (
                            <KanbanCardCtrl key={"card_" + ci} cardId={cardId} />
                    ))}
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default KanbanColumnCtrl;



