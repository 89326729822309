import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import ImageFieldCtrl from '../components/formcontrols/ImageFieldCtrl';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import MasterVariantsCtrl from '../components/formcontrols/MasterVariantsCtrl';
import VariantBuilderCtrl from '../components/formcontrols/VariantBuilderCtrl';
import ProductVariantsCtrl from '../components/formcontrols/ProductVariantsCtrl';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useGlobalState } from '../globalstates/GState';
import MultiImageFieldCtrl from '../components/formcontrols/MultiImageFieldCtrl';

function Product({ dialogType, handlerReturnValue }) {

    const navigate = useNavigate();
    let params = useParams();
    const [currentUser] = useGlobalState("userInfo");
    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [refreshId, setRefreshId] = useState(0);
    const [formData, setFormData] = useState({});
    const [defaultImgSizes, setDefaultImageSizes] = useState({ thumbnail: '', small: '', medium: '', large: '' });


    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.productId);
    const [data, setData] = useState({ isCompany: false });
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "product_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('product_page', 'header_section', 'Product');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs: [] };
    if (params.productId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('product_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
        if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Products', ['allowDelete']) == true) {
            headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('product_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
        }
    }


    if ((helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Products', ['allowEdit']) == true) || (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Products', ['allowAdd']) == true && primaryKeyValue == '0')) {
        headerOptions.elements.push({ type: "button", text: getTranslation('product_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    }

    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Products", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == "0") {
            //set default data
            data.active = false;
            data.recurType = "";
            data.billPeriodOptions = [];
            data.syncDay = 'Init';
            data.prorate = false;
            data.downpayment = '0';
            data.subscriptionDiscount = '0';
        }
    }

    function refreshPage() {
        setFormData({});
        setRefreshId(helpers.getUUID());
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                data.billPeriodOptions = JSON.parse(data.billPeriodOptions);
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                getDefaultProductImages();
                break;
            case "GetAccountData":
                setDefaultImageSizes({ thumbnail: data.productImgSizeThumbnail, small: data.productImgSizeSmall, medium: data.productImgSizeMedium, large: data.productImgSizeLarge });
                break;
            case "SaveForm":
                setPrimaryKeyValue(data.productId);
                data.billPeriodOptions = JSON.parse(data.billPeriodOptions);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/product/' + data.productId); refreshPage(); } })
                setOpenDialog(true);
                setShowLoader(false);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/products'); } })
                setOpenDialog(true);
                break;
        }
    }


    function onRecurDetailsChanged(e, propertyName, value) {
        var tmpFormData = { ...formData };


        switch (propertyName) {
            case 'planPrice':
            case 'downpayment':
            case 'billInstallment':
                value = value.replaceAll('$', '');
                if (value == '') {
                    value = '0';
                }
                break;
            case 'subscriptionDiscount':
                value = value.replaceAll('%', '');
                if (value == '') {
                    value = '0';
                } else {
                    if (parseInt(value) > 100) {
                        value = '100';
                    }
                }
                break;
        }


        tmpFormData[propertyName] = value;
        switch (tmpFormData.recurType) {
            case '':
                break;
            case 'Payment Plan':
                var billInstallment = parseFloat(0);
                if (parseInt(tmpFormData.billLength) > 0) {
                    billInstallment = ((parseFloat(tmpFormData.planPrice) - parseFloat(tmpFormData.downpayment)) / parseInt(tmpFormData.billLength)).toFixed(2);
                }                
                tmpFormData.billInstallment = billInstallment.toString();
                break;
            case 'SubscriptionOnly-Static':
                break;
            case 'Subscription-Static':
            case 'Subscription':
                var billInstallment = 0;
                var discountAmt = 0;
                if (parseInt(tmpFormData.subscriptionDiscount) > 0) {
                    discountAmt = parseFloat(tmpFormData.price) * (parseFloat(tmpFormData.subscriptionDiscount) / 100);
                }
                tmpFormData.billInstallment = (parseFloat(tmpFormData.price) - discountAmt).toFixed(2).toString();
                break;
            case 'SubscriptionOnly':
                break;
        }
        setFormData(tmpFormData);
    }

    function onRecurTypeChanged(e, value) {
        var tmpFormData = { ...formData };
        tmpFormData.recurType = value;
        switch (value) {
            case '':
                break;
            case 'Payment Plan':
                tmpFormData.planPrice = tmpFormData.price;
                tmpFormData.downpayment = '0';
                tmpFormData.billLength = 2;
                tmpFormData.billInterval = 1;
                tmpFormData.billPeriod = 'month';
                tmpFormData.billInstallment = (tmpFormData.price / tmpFormData.billLength).toFixed(2);
                tmpFormData.syncDay = 'Init';
                tmpFormData.prorate = false;
                break;
            case 'SubscriptionOnly-Static':
                tmpFormData.downpayment = '0';
                tmpFormData.freeTrialInterval = '0';
                tmpFormData.freeTrialPeriod = null;
                tmpFormData.billLength = '0';
                tmpFormData.billInterval = '1';
                tmpFormData.billPeriod = 'month';
                tmpFormData.billInstallment = tmpFormData.price;
                tmpFormData.syncDay = 'Init';
                tmpFormData.prorate = false;
                break;
            case 'Subscription-Static':
                tmpFormData.downpayment = '0';
                tmpFormData.subscriptionDiscount = '0';
                tmpFormData.freeTrialInterval = '0';
                tmpFormData.freeTrialPeriod = null;
                tmpFormData.billLength = '0';
                tmpFormData.billInterval = 1;
                tmpFormData.billPeriod = 'month';
                tmpFormData.billInstallment = tmpFormData.price;
                tmpFormData.syncDay = 'Init';
                tmpFormData.prorate = false;
                break;
            case 'Subscription':
                tmpFormData.downpayment = '0';
                tmpFormData.subscriptionDiscount = '0';
                tmpFormData.freeTrialInterval = '0';
                tmpFormData.freeTrialPeriod = null;
                tmpFormData.billLength = '0';
                tmpFormData.billInterval = 1;
                tmpFormData.billPeriod = 'month';
                tmpFormData.billIntervalOptions = '4';
                tmpFormData.billPeriodOptions = [];
                tmpFormData.billInstallment = tmpFormData.price;
                tmpFormData.syncDay = 'Init';
                tmpFormData.prorate = false;
                break;
            case 'SubscriptionOnly':
                tmpFormData.downpayment = '0';
                tmpFormData.subscriptionDiscount = '0';
                tmpFormData.freeTrialInterval = '0';
                tmpFormData.freeTrialPeriod = null;
                tmpFormData.billLength = '0';
                tmpFormData.billInterval = 1;
                tmpFormData.billPeriod = 'month';
                tmpFormData.billIntervalOptions = '4';
                tmpFormData.billPeriodOptions = [];
                tmpFormData.billInstallment = tmpFormData.price;
                tmpFormData.syncDay = 'Init';
                tmpFormData.prorate = false;
                break;
        }

        setFormData(tmpFormData);
        //setFormData(formData => ({ ...formData, recurType: value }));
    }

    function onAPIError(error, callName) {
        setShowLoader(false);
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function getDefaultProductImages() {
        BAFetchData({
            callName: "GetAccountData",
            method: "GET",
            url: "security/businessaccount/getrecord?baid=" + currentUser.currentBusinessAccountDTO.baid,
            token: "fg1234",
            toggleRefetch: searchRefetch,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function handleOnProductNameChanged(productNameVal) {
        var tmpFormData = { ...formData };
        tmpFormData.productName = productNameVal;
        tmpFormData.slug = helpers.slugify(productNameVal);
        setFormData((...formData) => { return tmpFormData });
    }

    useEffect(() => {
        setShowLoader(false);
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "ecommerce/product/getformrecord?productid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas, refreshId]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "ecommerce_products", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);



    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {JSON.stringify(formData) == "{}" && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {JSON.stringify(formData) != "{}" &&

                <div className="fg-app-page-box" style={{ maxWidth: "1075px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="segment">
                                <div className="segment-title">Product/Service Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <TextField id="productName" autoFocus required fullWidth label="Product Name" variant="standard" helperText={errorList['productName'] ? <span style={{ color: "red" }}>{errorList['productName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.productName || ''} InputLabelProps={{ shrink: formData.productName }} onChange={(e) => { handleOnProductNameChanged(e.target.value); }} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <TextField id="skuNum" required fullWidth label="Sku #" variant="standard" inputProps={{ maxLength: 10 }} helperText={errorList['skuNum'] ? <span style={{ color: "red" }}>{errorList['skuNum']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.skuNum || ''} InputLabelProps={{ shrink: formData.skuNum }} onChange={(e) => setFormData(formData => ({ ...formData, skuNum: e.target.value }))} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="slug" required fullWidth label="Slug" variant="standard" helperText={errorList['slug'] ? <span style={{ color: "red" }}>{errorList['slug']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.slug || ''} InputLabelProps={{ shrink: formData.slug }} onChange={(e) => setFormData(formData => ({ ...formData, slug: e.target.value }))} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <TextField id="price" required fullWidth label="Price" type="number" variant="standard" helperText={errorList['price'] ? <span style={{ color: "red" }}>{errorList['price']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.price >= 0 ? formData.price : ''} InputLabelProps={{ shrink: formData.price !== null && formData.price !== undefined && formData.price !== '' }} inputProps={{ className: "fg-number-no-arrows" }}  onChange={(e) => setFormData(formData => ({ ...formData, price: e.target.value }))} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <TextField id="compareAtPrice" fullWidth label="Discounted Price" variant="standard" helperText={errorList['compareAtPrice'] ? <span style={{ color: "red" }}>{errorList['compareAtPrice']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.compareAtPrice >= 0 ? formData.compareAtPrice : ''} InputLabelProps={{ shrink: formData.compareAtPrice !== null && formData.compareAtPrice !== undefined && formData.compareAtPrice !== '' }} onChange={(e) => setFormData(formData => ({ ...formData, compareAtPrice: e.target.value }))} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <TextField id="setupFee" fullWidth label="Setup Fee" variant="standard" helperText={errorList['setupFee'] ? <span style={{ color: "red" }}>{errorList['setupFee']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.setupFee >= 0 ? formData.setupFee : ''} InputLabelProps={{ shrink: formData.setupFee !== null && formData.setupFee !== undefined && formData.setupFee !== '' }} onChange={(e) => setFormData(formData => ({ ...formData, setupFee: e.target.value }))} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <FormControlLabel style={{ marginBottom: "10px" }}
                                                        control={
                                                            <Switch checked={formData.setupFeeOnce} onChange={(e) => setFormData(formData => ({ ...formData, setupFeeOnce: e.target.checked }))} name="gilad" />
                                                        }
                                                        label="One Setup Fee"
                                                    />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <FormControlLabel style={{ marginBottom: "10px" }}
                                                        control={
                                                            <Switch checked={formData.hasUsageFees} onChange={(e) => setFormData(formData => ({ ...formData, hasUsageFees: e.target.checked }))} name="gilad" />
                                                        }
                                                        label="Has Additional Usage Fees"
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <FormControlLabel style={{ marginBottom: "10px" }}
                                                        control={
                                                            <Switch checked={formData.cardRequiredFree} onChange={(e) => setFormData(formData => ({ ...formData, cardRequiredFree: e.target.checked }))} name="gilad" />
                                                        }
                                                        label="Card Required When Initial Cost is $0"
                                                    />
                                                </div>
                                            </div>
                                            {(currentUser.currentBusinessAccountDTO.baid == 1 && currentUser.firstName == 'System') ?
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                            <InputLabel id="demo-simple-select-standard-label">Vendor</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={formData.vendorId || ''}
                                                                onChange={(e) => setFormData(formData => ({ ...formData, vendorId: e.target.value }))}
                                                                label="Vendor"
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                            </Select>
                                                            <FormHelperText>{errorList['vendorId'] ? <span style={{ color: "red" }}>{errorList['vendorId']}</span> : ''} </FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                : null}
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <FormControlLabel style={{ marginBottom: "10px" }}
                                                        control={
                                                            <Switch checked={formData.isVirtual} onChange={(e) => setFormData(formData => ({ ...formData, isVirtual: e.target.checked }))} name="gilad" />
                                                        }
                                                        label="Virtual"
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <FormControlLabel style={{ marginBottom: "10px" }}
                                                        control={
                                                            <Switch checked={formData.active} onChange={(e) => setFormData(formData => ({ ...formData, active: e.target.checked }))} name="gilad" />
                                                        }
                                                        label="Active"
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <FormControlLabel style={{ marginBottom: "10px" }}
                                                        control={
                                                            <Switch checked={formData.showOnSite} onChange={(e) => setFormData(formData => ({ ...formData, showOnSite: e.target.checked }))} name="gilad" />
                                                        }
                                                        label="On Site"
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <FormControlLabel style={{ marginBottom: "10px" }}
                                                        control={
                                                            <Switch checked={formData.taxable} onChange={(e) => setFormData(formData => ({ ...formData, taxable: e.target.checked }))} name="gilad" />
                                                        }
                                                        label="Taxable"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DesktopDatePicker label="Publish Date" variant="Standard" slotProps={{ textField: { fullWidth: true } }} value={formData.publishDate != null ? dayjs(formData.publishDate) : null} onChange={(newValue) => setFormData(formData => ({ ...formData, publishDate: newValue }))} />
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div className="segment" style={{ marginTop: "40px" }} >
                                    <div className="segment-title">Subscription/Payment Plan Settings</div>
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <FormControl>
                                                            <FormLabel id="demo-radio-buttons-group-label">Select desired option</FormLabel>
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                value={formData.recurType || ''}
                                                                onChange={(e) => { onRecurTypeChanged(e, e.target.value); }}
                                                                name="radio-buttons-group"
                                                            >
                                                                <FormControlLabel value="" control={<Radio />} label="None" style={{ marginBottom: "10px" }} />
                                                                <FormControlLabel value="Payment Plan" control={<Radio />} label="Payment plan option is available with this product." style={{ marginBottom: "10px" }} />
                                                                <FormControlLabel value="SubscriptionOnly-Static" control={<Radio />} label="This is a subscription ONLY product (not a one-time purchase). I will determine the recurring billing/delivery terms." style={{ marginBottom: "10px" }} />
                                                                <FormControlLabel value="Subscription-Static" control={<Radio />} label="Subscription is available. I will determine the recurring billing/delivery terms." style={{ marginBottom: "10px" }} />
                                                                <FormControlLabel value="Subscription" control={<Radio />} label="Subscription is available. Customer will determine the recurring billing/delivery terms." style={{ marginBottom: "10px" }} />
                                                                <FormControlLabel value="SubscriptionOnly" control={<Radio />} label="This is a subscription ONLY product (not a one-time purchase). Customer will determine the recurring billing/delivery terms." style={{ marginBottom: "10px" }} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                {formData.recurType != '' ?
                                                    <>
                                                        {(formData.recurType == 'Subscription-Static' || formData.recurType == 'Subscription') ?
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <TextField id="subscriptionDiscount" fullWidth type="number" label="Subscription Percent Discount" variant="standard" helperText={<>{errorList['subscriptionDiscount'] ? <span style={{ color: "red" }}>{errorList['subscriptionDiscount']}</span> : ''}<span style={{ display: "inline-block" }}>Entice customer to purchase subscription by giving a discount.</span></>} style={{ marginBottom: "16px" }} value={formData.subscriptionDiscount || ''} InputLabelProps={{ shrink: formData.subscriptionDiscount }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => { onRecurDetailsChanged(e, 'subscriptionDiscount', e.target.value); }} />
                                                                </div>
                                                            </div>
                                                            : null}
                                                        {(formData.recurType == 'SubscriptionOnly-Static' || formData.recurType == 'Subscription-Static' || formData.recurType == 'Subscription' || formData.recurType == 'SubscriptionOnly') ?
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <TextField id="freeTrialInterval" fullWidth type="number" label="Free Trial Interval" variant="standard" helperText={errorList['freeTrialInterval'] ? <span style={{ color: "red" }}>{errorList['freeTrialInterval']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.freeTrialInterval || ''} InputLabelProps={{ shrink: formData.freeTrialInterval }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => setFormData(formData => ({ ...formData, freeTrialInterval: e.target.value }))} />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                        <InputLabel id="demo-simple-select-standard-label">Free Trial Period</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-standard-label"
                                                                            id="demo-simple-select-standard"
                                                                            value={formData.freeTrialPeriod || ''}
                                                                            onChange={(e) => setFormData(formData => ({ ...formData, freeTrialPeriod: e.target.value }))}
                                                                            label="Free Trial Period"
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em></em>
                                                                            </MenuItem>
                                                                            <MenuItem value="day">day(s)</MenuItem>
                                                                            <MenuItem value="week">week(s)</MenuItem>
                                                                            <MenuItem value="month">month(s)</MenuItem>
                                                                            <MenuItem value="year">year(s)</MenuItem>
                                                                        </Select>
                                                                        <FormHelperText>{errorList['freeTrialPeriod'] ? <span style={{ color: "red" }}>{errorList['freeTrialPeriod']}</span> : ''} </FormHelperText>
                                                                    </FormControl>
                                                                </div>
                                                            </div>
                                                            : null}
                                                        {(formData.recurType == 'Payment Plan') ?
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <TextField id="planPrice" fullWidth type="number" label="Total Payment Plan Price" variant="standard" helperText={<>{errorList['planPrice'] ? <span style={{ color: "red" }}>{errorList['planPrice']}</span> : ''}<span style={{ display: "inline-block" }}>You may want this price to be higher than original product price being that customer is not paying in full immediately.</span></>} style={{ marginBottom: "16px" }} value={formData.planPrice || ''} InputLabelProps={{ shrink: formData.planPrice }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => { onRecurDetailsChanged(e, 'planPrice', e.target.value); }} />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <TextField id="downpayment" fullWidth type="number" label="Downpayment" variant="standard" helperText={<>{errorList['downpayment'] ? <span style={{ color: "red" }}>{errorList['downpayment']}</span> : ''}<span style={{ display: "inline-block" }}>The amount you want the customer to put down.</span></>} style={{ marginBottom: "16px" }} value={formData.downpayment || ''} InputLabelProps={{ shrink: formData.downpayment }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => { onRecurDetailsChanged(e, 'downpayment', e.target.value); }} />
                                                                </div>
                                                            </div>
                                                            : null}
                                                        {(formData.recurType == 'Payment Plan' || formData.recurType == 'SubscriptionOnly-Static' || formData.recurType == 'Subscription-Static' || formData.recurType == 'Subscription' || formData.recurType == 'SubscriptionOnly') ?
                                                            <div className="row">
                                                                <div className="col-sm-3">
                                                                    {(formData.recurType == 'Subscription' || formData.recurType == 'SubscriptionOnly') ?
                                                                        <TextField id="billIntervalOptions" fullWidth type="number" label="Max Intervals" variant="standard" helperText={errorList['billIntervalOptions'] ? <span style={{ color: "red" }}>{errorList['billIntervalOptions']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.billIntervalOptions || ''} InputLabelProps={{ shrink: formData.billIntervalOptions }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => setFormData(formData => ({ ...formData, billIntervalOptions: e.target.value }))} />
                                                                        : null}
                                                                    {(formData.recurType == 'Payment Plan' || formData.recurType == 'SubscriptionOnly-Static' || formData.recurType == 'Subscription-Static') ?
                                                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                            <InputLabel id="demo-simple-select-standard-label">Expires After</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-standard-label"
                                                                                id="demo-simple-select-standard"
                                                                                value={formData.billLength >= 0 ? formData.billLength : ''}
                                                                                onChange={(e) => { onRecurDetailsChanged(e, 'billLength', e.target.value); }}
                                                                                label="Bill Length"
                                                                            >
                                                                            <MenuItem value={0} >Never Expires</MenuItem>
                                                                                {Array.apply(null, { length: 24 }).map((e, i) => (
                                                                                    <MenuItem value={i + 1} >{i + 1} Payment{(i + 1) > 1 ? 's' : ''}</MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                            <FormHelperText>{errorList['billLength'] ? <span style={{ color: "red" }}>{errorList['billLength']}</span> : ''} </FormHelperText>
                                                                        </FormControl>
                                                                        : null}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    {(formData.recurType == 'Subscription' || formData.recurType == 'SubscriptionOnly') ?
                                                                        <div>
                                                                            <div style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)", fontFamily: "Roboto", margin: "0px", padding: "0px" }} >Available Periods</div>
                                                                            <ToggleButtonGroup value={formData.billPeriodOptions || []} aria-label="align" fullWidth onChange={(e, list) => setFormData(formData => ({ ...formData, billPeriodOptions: list }))}>
                                                                                <ToggleButton value="Day" title="center" aria-label="tv" size="small" style={{ padding: "0px 12px 0px 12px" }}>
                                                                                    <span style={{ fontSize: "12px", marginTop: "3px" }}>Day</span>
                                                                                </ToggleButton>
                                                                                <ToggleButton value="Week" title="center" aria-label="tv" size="small" style={{ padding: "0px 12px 0px 12px" }}>
                                                                                    <span style={{ fontSize: "12px", marginTop: "3px" }}>Week</span>
                                                                                </ToggleButton>
                                                                                <ToggleButton value="Month" title="center" aria-label="tv" size="small" style={{ padding: "0px 12px 0px 12px" }}>
                                                                                    <span style={{ fontSize: "12px", marginTop: "3px" }}>Month</span>
                                                                                </ToggleButton>
                                                                                <ToggleButton value="Year" title="center" aria-label="tv" size="small" style={{ padding: "0px 12px 0px 12px" }}>
                                                                                    <span style={{ fontSize: "12px", marginTop: "3px" }}>Year</span>
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                            <formHelperText className="fg-helper-text">{errorList['billPeriodOptions'] ? <span style={{ color: "red" }}>{errorList['billPeriodOptions']}</span> : ''} </formHelperText>
                                                                            <div style={{ fontSize: ".75rem", color: "rgba(0, 0, 0, 0.6)", lineHeight: "1.22", fontWeight: "400", letterSpacing: "0.03333em" }}>Select the options you want to make available to customer</div>
                                                                        </div>
                                                                        : null}
                                                                    {(formData.recurType == 'Payment Plan' || formData.recurType == 'SubscriptionOnly-Static' || formData.recurType == 'Subscription-Static') ?
                                                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%" }}>
                                                                            <div>
                                                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                                    <InputLabel id="demo-simple-select-standard-label">Bill Interval</InputLabel>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-standard-label"
                                                                                        id="demo-simple-select-standard"
                                                                                        label="Bill Interval"
                                                                                        value={formData.billInterval || "0"}
                                                                                        onChange={(e) => setFormData(formData => ({ ...formData, billInterval: e.target.value }))}
                                                                                    >
                                                                                        <MenuItem value="0"></MenuItem>
                                                                                        <MenuItem value="1">every</MenuItem>
                                                                                        <MenuItem value="2">every 2</MenuItem>
                                                                                        <MenuItem value="3">every 3</MenuItem>
                                                                                    </Select>
                                                                                    <FormHelperText>{errorList['billInterval'] ? <span style={{ color: "red" }}>{errorList['billInterval']}</span> : ''} </FormHelperText>
                                                                                </FormControl>
                                                                            </div>
                                                                            <div>
                                                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                                    <InputLabel id="demo-simple-select-standard-label">Bill Period</InputLabel>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-standard-label"
                                                                                        id="demo-simple-select-standard"
                                                                                        label="Bill Length"
                                                                                        value={formData.billPeriod || ""}
                                                                                        onChange={(e) => setFormData(formData => ({ ...formData, billPeriod: e.target.value }))}
                                                                                    >
                                                                                        <MenuItem value=""></MenuItem>
                                                                                        <MenuItem value="day">day(s)</MenuItem>
                                                                                        <MenuItem value="week">week(s)</MenuItem>
                                                                                        <MenuItem value="month">month(s)</MenuItem>
                                                                                        <MenuItem value="year">year(s)</MenuItem>
                                                                                    </Select>
                                                                                    <FormHelperText>{errorList['billPeriod'] ? <span style={{ color: "red" }}>{errorList['billPeriod']}</span> : ''} </FormHelperText>
                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                                <div className="col-sm-3">
                                                                <TextField id="billInstallment" fullWidth readonly label="Installment Amt." InputProps={{ readOnly: true, }} variant="standard" helperText={errorList['billInstallment'] ? <span style={{ color: "red" }}>{errorList['billInstallment']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.billInstallment >= 0 ? formData.billInstallment : ''} InputLabelProps={{ shrink: formData.billInstallment !== null && formData.billInstallment !== undefined && formData.billInstallment !== '' }} onChange={(e) => setFormData(formData => ({ ...formData, billInstallment: e.target.value }))} />
                                                                </div>
                                                            </div>
                                                            : null}
                                                        {(formData.recurType == 'Payment Plan' || formData.recurType == 'SubscriptionOnly-Static' || formData.recurType == 'Subscription-Static') ?
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                        <InputLabel id="demo-simple-select-standard-label">Billing Sync Day</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-standard-label"
                                                                            id="demo-simple-select-standard"
                                                                            value={formData.syncDay || ''}
                                                                            onChange={(e) => setFormData(formData => ({ ...formData, syncDay: e.target.value }))}
                                                                            label="Billing Sync Day"
                                                                        >
                                                                            <MenuItem value="Init">Day of Initial Payment</MenuItem>
                                                                        </Select>
                                                                        <FormHelperText>{<>{errorList['syncDay'] ? <span style={{ color: "red" }}>{errorList['syncDay']}</span> : ''}<span style={{ display: "inline-block" }}>What day do you want to sync billing/delivery to.This helps to process similar transactions at the same time.</span></>}</FormHelperText>
                                                                    </FormControl>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                        <InputLabel id="demo-simple-select-standard-label">Prorate First Payment</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-standard-label"
                                                                            id="demo-simple-select-standard"
                                                                            value={formData.prorate || false}
                                                                            onChange={(e) => setFormData(formData => ({ ...formData, prorate: e.target.value }))}
                                                                            label="Prorate First Payment"
                                                                        >
                                                                            <MenuItem value={false} >False</MenuItem>
                                                                            <MenuItem value={true} >True</MenuItem>
                                                                        </Select>
                                                                        <FormHelperText>{<>{errorList['prorate'] ? <span style={{ color: "red" }}>{errorList['prorate']}</span> : ''}<span style={{ display: "inline-block" }}>First payment will only be for the remaining days left in billing cycle. Then afterwards full payment is charged.</span></>}</FormHelperText>
                                                                    </FormControl>
                                                                </div>
                                                            </div>
                                                            : null}
                                                        {(formData.recurType == 'Subscription' || formData.recurType == 'SubscriptionOnly') ?
                                                            <div className="row" style={{ marginTop: "20px" }}>
                                                                <div className="col-sm-12">
                                                                    <div style={{ backgroundColor: "whitesmoke", padding: "10px" }}>
                                                                        <p style={{ fontWeight: "500" }}>Your customer will see this option on website product detail/checkout page.</p>
                                                                        <p>Subscribe to this product
                                                                            {parseFloat(formData.subscriptionDiscount) > 0 ?
                                                                                <>
                                                                                    <span> and save  {formData.subscriptionDiscount}%</span> (<span style={{ color: "black", fontWeight: "bold" }}>${(parseFloat(formData.price) * (parseFloat(formData.subscriptionDiscount) / 100)).toFixed(2)}</span>)
                                                                                </>
                                                                                : null}
                                                                            .</p>
                                                                        <div style={{ display: "grid", gridTemplateColumns: "260px 1fr" }}>
                                                                            <div>Deliver product and bill me <span style={{ color: "black", fontWeight: "bold" }}>${formData.billInstallment}</span></div>
                                                                            <div>
                                                                                <FormControl variant="standard" sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-standard-label"
                                                                                        id="demo-simple-select-standard"
                                                                                        value='1'
                                                                                    >
                                                                                        {Array.apply(null, { length: formData.billIntervalOptions }).map((e, i) => (
                                                                                            <MenuItem value={i + 1}>every{i > 0 ? ' ' + (i + 1) : ''}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>&nbsp;
                                                                                <FormControl variant="standard" sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-standard-label"
                                                                                        id="demo-simple-select-standard"
                                                                                        value={formData.billPeriodOptions[0]}
                                                                                    >
                                                                                        {formData.billPeriodOptions.map((period, index) => (
                                                                                            <MenuItem value={period} >{period}(s)</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null}
                                                    </>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="segment">
                                <div className="segment-title">Inventory</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <FormControlLabel style={{ marginBottom: "10px" }}
                                                        control={
                                                            <Switch checked={formData.variantsExists} onChange={(e) => setFormData(formData => ({ ...formData, variantsExists: e.target.checked }))} name="gilad" />
                                                        }
                                                        label="Variants Exists"
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <FormControlLabel style={{ marginBottom: "10px" }}
                                                        control={
                                                            <Switch checked={formData.ignoreInventory} onChange={(e) => setFormData(formData => ({ ...formData, ignoreInventory: e.target.checked }))} name="gilad" />
                                                        }
                                                        label="Ignore Inventory"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="qtyOnHand" fullWidth type="number" label="Qty on Hand" variant="standard" helperText={errorList['qtyOnHand'] ? <span style={{ color: "red" }}>{errorList['qtyOnHand']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.qtyOnHand || ''} InputLabelProps={{ shrink: formData.qtyOnHand }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => setFormData(formData => ({ ...formData, qtyOnHand: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="segment">
                                <div className="segment-title">Product Images</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <ImageFieldCtrl imgSrc={formData.featureImage || ''} label="Feature Image" recType="product" recId={formData.productId} imgType="large" imgWidth="270px" imgHeight="270px" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, featureImage: imgValue }))}></ImageFieldCtrl>
                                                    <ImageFieldCtrl imgSrc={formData.hoverImage || ''} label="Hover Image" recType="product" recId={formData.productId} imgType="large" imgWidth="270px" imgHeight="270px" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, hoverImage: imgValue }))}></ImageFieldCtrl>
                                                    <MultiImageFieldCtrl imgList={formData.moreImages || ''} label="Additional Media" recType="product" recId={formData.productId} imgType="large" imgWidth="270px" imgHeight="270px" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, moreImages: imgValue }))}></MultiImageFieldCtrl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Invoice Summary</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="invoiceSummary" required fullWidth label="" variant="standard" helperText={errorList['invoiceSummary'] ? <span style={{ color: "red" }}>{errorList['invoiceSummary']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.invoiceSummary || ''} InputLabelProps={{ shrink: formData.invoiceSummary }} onChange={(e) => setFormData(formData => ({ ...formData, invoiceSummary: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="segment-title">Long Description</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="longDescription" multiline rows={4} fullWidth label="" variant="standard" helperText={errorList['longDescription'] ? <span style={{ color: "red" }}>{errorList['longDescription']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.longDescription || ''} InputLabelProps={{ shrink: formData.longDescription }} onChange={(e) => setFormData(formData => ({ ...formData, longDescription: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="segment">
                                <div className="segment-title">SEO / Marketing</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="seoTitle" fullWidth label="SEO Title" variant="standard" helperText={errorList['seoTitle'] ? <span style={{ color: "red" }}>{errorList['seoTitle']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.seoTitle || ''} InputLabelProps={{ shrink: formData.seoTitle }} onChange={(e) => setFormData(formData => ({ ...formData, seoTitle: e.target.value }))} />
                                                    <TextField id="seoDescription" multiline rows={2} fullWidth label="SEO Description" variant="standard" helperText={errorList['seoDescription'] ? <span style={{ color: "red" }}>{errorList['seoDescription']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.seoDescription || ''} InputLabelProps={{ shrink: formData.seoDescription }} onChange={(e) => setFormData(formData => ({ ...formData, seoDescription: e.target.value }))} />
                                                    <TextField id="seoKeywords" multiline rows={2} fullWidth label="SEO Keywords" variant="standard" helperText={errorList['seoKeywords'] ? <span style={{ color: "red" }}>{errorList['seoKeywords']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.seoKeywords || ''} InputLabelProps={{ shrink: formData.seoKeywords }} onChange={(e) => setFormData(formData => ({ ...formData, seoKeywords: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="segment">
                                <div className="segment-title">SEO Image</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <ImageFieldCtrl imgSrc={formData.seoImage || ''} label="SEO Image" recType="product" recId={formData.productId} imgType="large" imgHeight="270px" imgWidth="270px" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, seoImage: imgValue }))}></ImageFieldCtrl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Delivery Method</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Delivery Method</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.deliveryMethod || ''}
                                                            onChange={(e) => setFormData(formData => ({ ...formData, deliveryMethod: e.target.value }))}
                                                            label="Delivery Method"
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            <MenuItem value="Download">Download</MenuItem>
                                                            <MenuItem value="DropShipment">Drop Shipment (Another company will package and ship this product)</MenuItem>
                                                            <MenuItem value="Email">Email</MenuItem>
                                                            <MenuItem value="ShipManualPrice">I will ship this product and provide shipping rate</MenuItem>
                                                            <MenuItem value="OnlineAccess">Online Access</MenuItem>
                                                            <MenuItem value="Pickup">Pickup</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{errorList['deliveryMethod'] ? <span style={{ color: "red" }}>{errorList['deliveryMethod']}</span> : ''} </FormHelperText>
                                                    </FormControl>
                                                    {formData.deliveryMethod == 'ShipManualPrice' ?
                                                        <>
                                                            <TextField id="shippingCost" fullWidth type="number" label="Shipping Cost" variant="standard" helperText={errorList['shippingCost'] ? <span style={{ color: "red" }}>{errorList['shippingCost']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.shippingCost || ''} InputLabelProps={{ shrink: formData.shippingCost }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => setFormData(formData => ({ ...formData, shippingCost: e.target.value }))} />
                                                            <TextField id="handlingCost" fullWidth type="number" label="Handling Cost" variant="standard" helperText={errorList['handlingCost'] ? <span style={{ color: "red" }}>{errorList['handlingCost']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.handlingCost || ''} InputLabelProps={{ shrink: formData.handlingCost }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => setFormData(formData => ({ ...formData, handlingCost: e.target.value }))} />
                                                        </>
                                                        : null}
                                                    {formData.deliveryMethod == 'Download' ?
                                                        <TextField id="deliveryUrl" fullWidth label="Delivery Url" variant="standard" helperText={errorList['deliveryUrl'] ? <span style={{ color: "red" }}>{errorList['deliveryUrl']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.deliveryUrl || ''} InputLabelProps={{ shrink: formData.deliveryUrl }} onChange={(e) => setFormData(formData => ({ ...formData, deliveryUrl: e.target.value }))} />
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Product Dimensions / Weight</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <TextField id="productLength" fullWidth type="number" label="Length (inches)" variant="standard" helperText={errorList['productLength'] ? <span style={{ color: "red" }}>{errorList['productLength']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.productLength || ''} InputLabelProps={{ shrink: formData.productLength }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => setFormData(formData => ({ ...formData, productLength: e.target.value }))} />
                                                    <TextField id="productWidth" fullWidth type="number" label="Width (inches)" variant="standard" helperText={errorList['productWidth'] ? <span style={{ color: "red" }}>{errorList['productWidth']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.productWidth || ''} InputLabelProps={{ shrink: formData.productWidth }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => setFormData(formData => ({ ...formData, productWidth: e.target.value }))} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <TextField id="productHeight" fullWidth type="number" label="Height (inches)" variant="standard" helperText={errorList['productHeight'] ? <span style={{ color: "red" }}>{errorList['productHeight']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.productHeight || ''} InputLabelProps={{ shrink: formData.productHeight }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => setFormData(formData => ({ ...formData, productHeight: e.target.value }))} />
                                                    <TextField id="productGirth" fullWidth type="number" label="Girth (inches)" variant="standard" helperText={errorList['productGirth'] ? <span style={{ color: "red" }}>{errorList['productGirth']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.productGirth || ''} InputLabelProps={{ shrink: formData.productGirth }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => setFormData(formData => ({ ...formData, productGirth: e.target.value }))} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <TextField id="productPounds" fullWidth type="number" label="Pounds" variant="standard" helperText={errorList['productPounds'] ? <span style={{ color: "red" }}>{errorList['productPounds']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.productPounds || ''} InputLabelProps={{ shrink: formData.productPounds }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => setFormData(formData => ({ ...formData, productPounds: e.target.value }))} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <TextField id="productOunces" fullWidth type="number" label="Ounces" variant="standard" helperText={errorList['productOunces'] ? <span style={{ color: "red" }}>{errorList['productOunces']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.productOunces || ''} InputLabelProps={{ shrink: formData.productOunces }} inputProps={{ className: "fg-number-no-arrows" }} onChange={(e) => setFormData(formData => ({ ...formData, productOunces: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="segment">
                        <div style={{ display: "inline-block" }} className="segment-title">Product Master Variants<br /><small style={{ fontWeight: "normal", fontSize: "16px", fontStyle: "italic" }}>Enter variants such as size, color, etc...</small></div>
                        <formHelperText className="fg-helper-text">{errorList['productTypeVarAttrList'] ? <span style={{ color: "red",display:"block" }}>{errorList['productTypeVarAttrList']}</span> : ''} </formHelperText>
                        <div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <MasterVariantsCtrl productTypeVarAttrList={formData.productTypeVarAttrList} onChange={(e, list) => setFormData(formData => ({ ...formData, productTypeVarAttrList: list }))} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="segment" style={{ marginTop: "20px" }}>
                        <div style={{ display: "inline-block" }} className="segment-title">Variant Details</div><div style={{ display: "inline-block", marginLeft: "20px" }}><Button variant="outlined" onClick={(e) => { openVariantBuilder(e); }}>Create Variants Entries</Button></div><div style={{ display: "inline-block", marginLeft: "20px" }}><Button variant="outlined" onClick={(e) => { handleClearAllBtnClick(e); }}>Clear All</Button></div>
                        <formHelperText className="fg-helper-text">{errorList['productVariants'] ? <span style={{ color: "red", display: "block" }}>{errorList['productVariants']}</span> : ''} </formHelperText>
                            <div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-12">
                                            <ProductVariantsCtrl productId={formData.productId} productVariantList={formData.productVariants} attrList={formData.productTypeVarAttrList} subscriptionDiscount={formData.subscriptionDiscount} onChange={(list) => setFormData(formData => ({ ...formData, productVariants: list }))} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            }
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>

    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete site. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "ecommerce/product/delete?productid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
        var maxPrice = formData.price;
        for (var i = 0; i < formData.productVariants.length; i++) {
            if (formData.productVariants[i].unitPrice > maxPrice) {
                maxPrice = formData.productVariants[i].unitPrice;
            }
        }
        if (maxPrice > formData.price) {
            formData.maxPrice = maxPrice;
        } else {
            formData.maxPrice = 0;
        }        
        if (formData.featureImage == null || formData.featureImage == '') {
            formData.featureImage = defaultImgSizes.medium;
        }
    }

    async function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (await isCustomFormValidationValid() == true) {
                setShowLoader(true);
                saveForm();
            }
        }
    }

    function saveForm() {
        var tmpFormData = { ...formData };
        tmpFormData.billPeriodOptions = JSON.stringify(tmpFormData.billPeriodOptions);
        if (primaryKeyValue == '0') {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "ecommerce/product/add",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "ecommerce/product/update",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function openVariantBuilder() {
        var msg = '';
        //setGenericDialogSchema({title: 'Product Variant Builder', component: (dialogType, setReturnValue) => <VariantBuilderCtrl skuNum={formData.skuNum} price={formData.price} masterVariants={formData.productTypeVarAttrList} handlerReturnValue={dialogType, setReturnValue}></VariantBuilderCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('VariantBuilder', returnValues); }})

        setGenericDialogSchema({ title: 'Product Variant Buidler', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <VariantBuilderCtrl productId={formData.productId} skuNum={formData.skuNum} price={formData.price} billInstallment={formData.billInstallment} masterVariants={formData.productTypeVarAttrList} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick}  setAcceptAfterUpdate={setAcceptAfterUpdate}></VariantBuilderCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: false, showOKBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('VariantBuilder', returnValues); } })

        setOpenDialog(true);
    }

    function updateVariants(returnValue) {
        alert(JSON.stringify(returnValue));
    }

    function handleClearAllBtnClick(e) {
        var msg = 'You are about to delete all variant details. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); clearAllVariants() } })
        setOpenDialog(true);
    }

    function clearAllVariants() {
        var tmpFormData = { ...formData };

        for (var i = tmpFormData.productVariants.length - 1; i >= 0; i--) {
            if (tmpFormData.productVariants[i].internalState != "added") {
                tmpFormData.productVariants[i].internalState = "deleted";
            } else {
                tmpFormData.productVariants.splice(i, 1);
            }
        }
        setFormData(tmpFormData);
        return tmpFormData.productVariants;
    }


    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case 'VariantBuilder':
                var oldList = [...formData.productVariants];
                //var oldList = clearAllVariants();
                setFormData(formData => ({ ...formData, productVariants: oldList.concat(returnValues)}));
                break;
        }
    }

    async function productNameExists() {
        const data = await BAFetchData({
            callName: "ProductNameExists",
            method: "GET",
            url: "ecommerce/product/productnameexists?productname=" + formData.productName + "&productid=" + formData.productId,
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        return data;
    }

    async function productSkuNumExists() {
        const data = await BAFetchData({
            callName: "ProductSkuNumExists",
            method: "GET",
            url: "ecommerce/product/productskunumexists?skunum=" + formData.skuNum + "&productid=" + formData.productId,
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        return data;
    }

    async function productSlugExists() {
        const data = await BAFetchData({
            callName: "ProductSlugExists",
            method: "GET",
            url: "ecommerce/product/productslugexists?slug=" + formData.slug + "&productid=" + formData.productId,
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        return data;
    }

    async function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation

        var total = await productNameExists();
        if (total.data > 0) {
            isValid = false;
            tempErrorList['productName'] = "Product Name already exist. Enter a different product name.";
        }

        var total = await productSkuNumExists();
        if (total.data > 0) {
            isValid = false;
            tempErrorList['skuNum'] = "Sku Num already exist. Enter a different sku num.";
        }

        var total = await productSlugExists();
        if (total.data > 0) {
            isValid = false;
            tempErrorList['slug'] = "Slug already exist. Enter a different slug.";
        }

        if (formData.recurType == 'Payment Plan') {
            if ((formData.planPrice > 0) == false) {

                isValid = false;
                tempErrorList['planPrice'] = "Should be greater than zero.";
            }
        }
        if (formData.recurType == 'Payment Plan' || formData.recurType == 'Subscription-Static' || formData.recurType == 'SubscriptionOnly-Static') {
            if ((formData.billLength > 1) == false && formData.recurType == 'Payment Plan') {
                isValid = false;
                tempErrorList['billLength'] = "Should be more than 1 payment.";
            }
            if (formData.billInterval == null || formData.billInterval == '' || formData.billInterval == '0') {
                isValid = false;
                tempErrorList['billInterval'] = "Is required.";
            }
            if (formData.billPeriod == null || formData.billPeriod == '') {
                isValid = false;
                tempErrorList['billPeriod'] = "Is required.";
            }
            if (formData.recurType == 'Payment Plan') {
                if ((formData.billInstallment > 0) == false) {
                    isValid = false;
                    tempErrorList['billInstallment'] = "Should be greater than zero.";
                }
            } else {
                let freeTierExists = false;
                for (let i = 0; i < formData.productVariants.length; i++) {
                    if (formData.productVariants[i].unitPrice == 0 || formData.productVariants[i].unitBillInstallment == 0) {
                        freeTierExists = true;
                        break;
                    }
                }
                if (freeTierExists == false) {
                    if ((formData.billInstallment > 0) == false) {
                        isValid = false;
                        tempErrorList['billInstallment'] = "Should be greater than zero.";
                    }
                }
            }
            
        }

        if (formData.recurType == 'Subscription-Static' || formData.recurType == 'SubscriptionOnly-Static' || formData.recurType == 'Subscription'  || formData.recurType == 'SubscriptionOnly') {
            if (formData.freeTrialInterval > 0) {
                if (formData.freeTrialPeriod == null || formData.freeTrialPeriod == '') {
                    isValid = false;
                    tempErrorList['freeTrialPeriod'] = "Is required when Free Trial Interval is greater zero.";
                }
            }
        }

        if (formData.recurType == 'Subscription' || formData.recurType == 'SubscriptionOnly') {
            if ((formData.billIntervalOptions > 0) == false) {
                isValid = false;
                tempErrorList['billIntervalOptions'] = "Is required.";
            }
            if (formData.billPeriodOptions == null || formData.billPeriodOptions == '') {
                isValid = false;
                tempErrorList['billPeriodOptions'] = "Is required.";
            }
        }

        if (formData.deliveryMethod == null || formData.deliveryMethod == '') {
            isValid = false;
            tempErrorList['deliveryMethod'] = "Is required.";
        } else {
            if (formData.deliveryMethod == 'Download') {
                if (formData.deliveryUrl == null || formData.deliveryUrl == '') {
                    isValid = false;
                    tempErrorList['deliveryUrl'] = "Is required.";
                }
            }
        }
        if (formData.variantsExists == true) {
            if (helpers.isListEmpty(formData.productTypeVarAttrList) == true) {
                isValid = false;
                tempErrorList['productTypeVarAttrList'] = "At least master variant is required when Variants Exists is set to true.";
            }
            if (helpers.isListEmpty(formData.productVariants) == true) {
                isValid = false;
                tempErrorList['productVariants'] = "At least two or more Variant Details is required when Variants Exists is set to true.";
            }
        }
        

        setErrorList(tempErrorList);
        return isValid;
    }
}

export default Product;