import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import DomainNameChangeCtrl from '../components/formcontrols/DomainNameChangeCtrl';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';
import ImageFieldCtrl from '../components/formcontrols/ImageFieldCtrl';
import { useGlobalState } from '../globalstates/GState';
import SimpleListCtrl from '../components/formcontrols/SimpleListCtrl';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function SiteSettings({dialogType,handlerReturnValue}) {

    const [currentUser] = useGlobalState("userInfo");
    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});
    const [pixelList, setPixelList] = useState([]);

    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.siteId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "site_settings_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('site_settings_page', 'header_section', 'Site Settings');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.siteId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('site_settings_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
        if (helpers.permissionAllowed(currentUser.permissions, 'Marketing-Websites', ['allowDelete']) == true) {
            headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('site_settings_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
        }

    }
    if ((helpers.permissionAllowed(currentUser.permissions, 'Marketing-Websites', ['allowEdit']) == true) || (helpers.permissionAllowed(currentUser.permissions, 'Marketing-Websites', ['allowAdd']) == true && primaryKeyValue == 0)) {
        headerOptions.elements.push({ type: "button", text: getTranslation('site_settings_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    }
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == 0) {
            //set default data
            data.fontList = JSON.stringify(["Open Sans", "Oswald"]);
            //data.styleList = JSON.stringify({ "#h1": { "id": "#h1", "referenceType": "class", "breakpointStyles": { "768": { "fontSize": "32px" }, "2500": { "fontSize": "44px", "fontFamily": "Oswald" } } }, "#button": { "id": "#button", "referenceType": "class", "breakpointStyles": { "2500": { "backgroundColor": "black", "color": "white", "paddingTop": "10px", "paddingBottom": "10px", "paddingLeft": "20px", "paddingRight": "20px", "border": "none" } } }, "#p": { "id": "#p", "referenceType": "class", "breakpointStyles": { "2500": { "lineHeight": "140%", "fontSize": "16px", "fontFamily": "Open Sans" } } }, "#input": { "id": "#input", "referenceType": "class", "breakpointStyles": { "2500": { "width": "100%", "marginBottom": "10px", "backgroundColor": "#ffffff" } } }, "#label": { "id": "#label", "referenceType": "class", "breakpointStyles": { "2500": { "color": "grey", "fontFamily": "Open Sans", "marginBottom": "5px" } } } });
            data.styleList = JSON.stringify({ "#h1": { "id": "#h1", "referenceType": "class", "breakpointStyles": { "768": { "fontSize": "32px" }, "2500": { "fontSize": "44px", "fontFamily": "Oswald" } } }, "#button": { "id": "#button", "referenceType": "class", "breakpointStyles": { "2500": { "backgroundColor": "black", "color": "white", "paddingTop": "10px", "paddingBottom": "10px", "paddingLeft": "20px", "paddingRight": "20px", "border": "none" } } }, "#p": { "id": "#p", "referenceType": "class", "breakpointStyles": { "2500": { "lineHeight": "140%", "fontSize": "16px", "fontFamily": "Open Sans" } } }, "#input": { "id": "#input", "referenceType": "class", "breakpointStyles": { "2500": { "width": "100%", "marginBottom": "16px", "backgroundColor": "#ffffff", "border": "1px solid rgba(213, 212, 212, 1)", "borderRadius": "4px", "fontSize": "16px", "paddingTop": "12px", "paddingBottom": "12px", "paddingLeft": "16px", "paddingRight": "16px" } }, "customEvents": [], "customAttributes": [] }, "#label": { "id": "#label", "referenceType": "class", "breakpointStyles": { "2500": { "color": "grey", "fontFamily": "Open Sans", "marginBottom": "5px", "display": "block" } }, "customEvents": [], "customAttributes": [] }, "": { "id": "", "referenceType": "class", "breakpointStyles": {} }, "#select": { "id": "#select", "referenceType": "class", "breakpointStyles": { "2500": { "width": "100%", "borderRadius": "4px", "border": "1px solid rgba(210, 210, 210, 1)", "backgroundColor": "#ffffff", "fontSize": "16px", "paddingLeft": "16px", "paddingRight": "16px", "paddingTop": "12px", "paddingBottom": "12px" } }, "customEvents": [], "customAttributes": [] } });
            data.active = true;
        }        
    }

    function openDomainPrompt() {
        var msg = '';
        setGenericDialogSchema({title: 'Enter Custom Domain Name', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <DomainNameChangeCtrl handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} ></DomainNameChangeCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => {setOpenDialog(); onPopupReturn("DomainName", returnValues);}})
        setOpenDialog(true);    
    }

    function onPopupReturn(returnType, returnValue) {
        switch (returnType) {
            case 'DomainName':
                if (returnValue != null && returnValue != "") {
                    var tmpFormData = { ...formData };
                    tmpFormData.changeDomain = true;
                    tmpFormData.customDomain = returnValue;
                    setFormData(tmpFormData);
                    changeDomain(tmpFormData);
                }
                break;
        }
    }

    function getPixelList() {
        BAFetchData({
            callName: "GetPixelList",
            method: "GET",
            url: "trk/pixel/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: searchRefetch,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function changeDomain(tmpFormData) {
        BAFetchData({
            callName: "ChangeDomain",
            method: "GET",
            url: "cms/sites/changedomain?siteid=" + primaryKeyValue + '&customDomain=' + tmpFormData.customDomain,
            token: "fg1234",
            toggleRefetch: searchRefetch,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data);
                if (data.globalJsScripts != null && data.globalJsScripts != null) {
                    data.globalJsScripts = JSON.parse(data.globalJsScripts);
                }
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                getPixelList();
                break;
            case "SaveForm":                
                setPrimaryKeyValue(data.siteId);
                if (data.globalJsScripts != null && data.globalJsScripts != null) {
                    data.globalJsScripts = JSON.parse(data.globalJsScripts);
                }
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/sitesettings/' + data.siteId); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case "ChangeDomain":
                var msg = 'Domain name has been changed.';
                setGenericDialogSchema({ title: 'Domain Name Changed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/sitesettings/' + primaryKeyValue); } })
                setOpenDialog(true);
                break;
            case "GetPixelList":
                setPixelList(data);
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/sites'); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "cms/sites/getrecord?siteid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "cms_sites", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);



    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "900px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Site Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <FormControlLabel style={{ marginBottom: "10px" }}
                                                control={
                                                    <Switch checked={formData.active} onChange={(e) => setFormData(formData => ({ ...formData, active: e.target.checked }))} name="gilad" />
                                                }
                                                label="Active"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="name" autoFocus required fullWidth label="Site Name" variant="standard" helperText={errorList['name'] ? <span style={{ color: "red" }}>{errorList['name']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.name || ''} InputLabelProps={{ shrink: formData.name }} onChange={(e) => setFormData(formData => ({ ...formData, name: e.target.value }))} />
                                                    <TextField id="description" multiline rows={4} fullWidth label="Description" variant="standard" helperText={errorList['description'] ? <span style={{ color: "red" }}>{errorList['description']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.description || ''} InputLabelProps={{ shrink: formData.description }} onChange={(e) => setFormData(formData => ({ ...formData, description: e.target.value }))} />
                                                    <TextField id="siteCode" fullWidth label="Site Code (Read Only)" variant="standard" helperText={errorList['siteCode'] ? <span style={{ color: "red" }}>{errorList['siteCode']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.siteCode || ''} InputLabelProps={{ shrink: formData.siteCode }} inputProps={{ readOnly: true }} onChange={(e) => setFormData(formData => ({ ...formData, siteCode: e.target.value }))} />                                                    
                                                    <ImageFieldCtrl imgSrc={formData.favIcon || ''} label="Fav Icon" imgType="inline" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, favIcon: imgValue }))}></ImageFieldCtrl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">URL Paths</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="devUrl" required fullWidth label="Dev URL (Read Only)" variant="standard" helperText={errorList['devUrl'] ? <span style={{ color: "red" }}>{errorList['devUrl']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.devUrl || ''} InputLabelProps={{ shrink: formData.devUrl }} inputProps={{ readOnly: true }} onChange={(e) => setFormData(formData => ({ ...formData, devUrl: e.target.value }))} />
                                                    <div style={{display:"grid",gridTemplateColumns:"1fr 175px",gridGap:"10px"}}>
                                                        <div>
                                                            <TextField id="prodUrl" required fullWidth label="Prod URL (Read Only)" variant="standard" helperText={errorList['prodUrl'] ? <span style={{ color: "red" }}>{errorList['prodUrl']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.prodUrl || ''} InputLabelProps={{ shrink: formData.prodUrl }} inputProps={{ readOnly: true }} onChange={(e) => setFormData(formData => ({ ...formData, prodUrl: e.target.value }))} />
                                                        </div>
                                                        <div >
                                                            <Button variant="outlined" fullWidth onClick={() => openDomainPrompt()}>{getTranslation('automations_search_page', 'search_grid', 'Change Domain')}</Button>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">SEO Information</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div>
                                                        <TextField id="siteTitle" autoFocus required fullWidth label="SEO Title" variant="standard" helperText={errorList['seoTitle'] ? <span style={{ color: "red" }}>{errorList['seoTitle']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.seoTitle || ''} InputLabelProps={{ shrink: formData.seoTitle }} onChange={(e) => setFormData(formData => ({ ...formData, seoTitle: e.target.value }))} />                                                    
                                                    </div>
                                                    <div>
                                                        <TextField id="seoDescription" multiline rows={4} fullWidth label="SEO Description" variant="standard" helperText={errorList['seoDescription'] ? <span style={{ color: "red" }}>{errorList['seoDescription']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.seoDescription || ''} InputLabelProps={{ shrink: formData.seoDescription }} onChange={(e) => setFormData(formData => ({ ...formData, seoDescription: e.target.value }))} />
                                                    </div>
                                                    <div>
                                                        <ImageFieldCtrl imgSrc={formData.seoImage || ''} label="SEO Image" imgType="inline" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, seoImage: imgValue }))}></ImageFieldCtrl>
                                                    </div>
                                                    <div style={{ marginTop:"20px"}}>
                                                        <TextField id="seoKeywords" multiline rows={4} fullWidth label="SEO Keywords" variant="standard" helperText={errorList['seoKeywords'] ? <span style={{ color: "red" }}>{errorList['seoKeywords']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.seoKeywords || ''} InputLabelProps={{ shrink: formData.seoKeywords }} onChange={(e) => setFormData(formData => ({ ...formData, seoKeywords: e.target.value }))} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Tracking Section</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div>
                                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                            <InputLabel id="demo-simple-select-standard-label">Tracking Pixel</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={formData.pixelId || ''}
                                                                onChange={(e) => setFormData(formData => ({ ...formData, pixelId: e.target.value }))}
                                                                label="Tracking Pixel"
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {pixelList.map(trackingPixel => {
                                                                    return <MenuItem key={trackingPixel.pixelId} value={trackingPixel.pixelId} >{trackingPixel.pixelName + ' - ' + trackingPixel.pixelId}</MenuItem>
                                                                })}
                                                            </Select>
                                                            <FormHelperText>{errorList['pixelId'] ? <span style={{ color: "red" }}>{errorList['pixelId']}</span> : ''} </FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Site Scripts</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div>
                                                        <SimpleListCtrl list={formData.globalJsScripts || []} label="Global JS Scripts" buttonCaption="Add Script" onChange={(listValues) => setFormData(formData => ({ ...formData, globalJsScripts: listValues }))} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Global Page Load Scripts</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div>
                                                        <TextField id="globalPageLoadJsScripts" multiline rows={8} fullWidth label="Global Page Load JS Scripts" variant="standard" helperText={errorList['globalPageLoadJsScripts'] ? <span style={{ color: "red" }}>{errorList['globalPageLoadJsScripts']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.globalPageLoadJsScripts || ''} InputLabelProps={{ shrink: formData.globalPageLoadJsScripts }} onChange={(e) => setFormData(formData => ({ ...formData, globalPageLoadJsScripts: e.target.value }))} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete site. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "cms/sites/delete?siteid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm();
            }
        }                
    }

    function saveForm() {

        var tmpFormData = { ...formData };
        tmpFormData.globalJsScripts = JSON.stringify(tmpFormData.globalJsScripts);
        if (primaryKeyValue == '0') {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "cms/sites/add",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "cms/sites/update",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        //if (formData.isCompany == false) {
        //    if (formData.firstName == null && formData.lastName == null && formData.businessName == null) {
        //        isValid = false;
        //        tempErrorList['firstName'] = "First Name, Last Name or Business Name is required.";
        //    }
        //}
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default SiteSettings;