import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';
import WidgetRouter, { GetCanvasComponent } from '../WidgetRouter';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InputNameValueListCtrl from './InputNameValueListCtrl';
import InputStateListCtrl from './InputStateListCtrl';
import InputCodeEditorCtrl from './InputCodeEditorCtrl';


function InputPropertiesCtrl({ widgetData, propertyChangeCallback}) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [propSchema, setPropSchema] = useState({});

    function init() {
        let tmpPropSchema = {}
        const component = GetCanvasComponent(widgetData.elementName);
        if (typeof component.getPropSchema === "function") {
            tmpPropSchema = component.getPropSchema();
        }
        setPropSchema(tmpPropSchema)
    }

    function onValueChange(sectionName, propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.customInputs[sectionName].property[propertyName] = value;
        setWidgetObj(tmpWidgetObj);
        //setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    }

    function onBlur(sectionName, propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.customInputs[sectionName].property[propertyName] = value;
        setWidgetObj(tmpWidgetObj);
        //setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }


    function updateCssProperty(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        if (value != '' && value != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    //function handleOnPropertyChange(propertyName, value) {
    //    var tmpWidgetObj = { ...widgetObj };
    //    tmpWidgetObj[propertyName] = value;
    //    setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //    //propertyChangeCallback(tmpWidgetObj);
    //}

    //function handleOnBlur(propertyName, value) {
    //    var tmpWidgetObj = { ...widgetObj };
    //    tmpWidgetObj[propertyName] = value;
    //    setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //    propertyChangeCallback(tmpWidgetObj);
    //}

    useEffect(() => {
        setWidgetObj(widgetData);
        init();
    }, [widgetData])

    useEffect(() => {
        init();
    }, [])



    return (
        <>
            <div>
                {Object.keys(propSchema).map((schemaSectionKey) => {                    
                    const schemaSectionItem = propSchema[schemaSectionKey];
                    return (
                        <div>
                            <div className="fg-pg-panel-sub-section">{schemaSectionItem.caption}</div>
                            <div style={{ backgroundColor: "white", padding: "5px" }}>
                            {Object.keys(schemaSectionItem.property).map((schemaPropertyKey) => {
                                const schemaPropertyItem = schemaSectionItem.property[schemaPropertyKey];
                                const valuePropertyItem = widgetObj.customInputs[schemaSectionKey].property;
                                let propertyValue = valuePropertyItem[schemaPropertyKey];
                                //if (schemaPropertyItem.dataType == "jsonArray") {
                                //    propertyValue = JSON.parse(propertyValue || []);
                                //}
                                switch (schemaPropertyItem.control.type) {
                                    case "text":
                                        return (
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                                                <div className="fg-pb-property-sub-label">{schemaPropertyItem.label}</div>
                                                <div>
                                                    <input className="form-control form-control-sm fg-pb-property-field" value={propertyValue || ''} defaultValue={propertyValue || ''} onChange={(e) => { onValueChange(schemaSectionKey, schemaPropertyKey, e.target.value); }}  onBlur={(e) => { onBlur(schemaSectionKey, schemaPropertyKey, e.target.value); }} />
                                                </div>
                                            </div>
                                        )
                                        break;
                                    case "switch":
                                        return (
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                                                <div className="fg-pb-property-sub-label">{schemaPropertyItem.label}</div>
                                                <div>
                                                    <Switch checked={propertyValue || false} onChange={(e) => { onBlur(schemaSectionKey, schemaPropertyKey, e.target.checked); }} name="gilad" />
                                                </div>
                                            </div>
                                        )
                                        break;
                                    case "name-value-list":
                                        return (
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                                                <div className="fg-pb-property-sub-label">{schemaPropertyItem.label}</div>
                                                <div>
                                                    <InputNameValueListCtrl propertyValue={propertyValue || []} propertyChangeCallback={(value) => { onBlur(schemaSectionKey, schemaPropertyKey, value); }} propertyLabel={schemaPropertyItem.label} />
                                                </div>
                                            </div>
                                        )
                                        break;
                                    case "state-var-list-ctrl":
                                        return (
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                                                <div className="fg-pb-property-sub-label">{schemaPropertyItem.label}</div>
                                                <div>
                                                    <InputStateListCtrl propertyValue={propertyValue || []} propertyChangeCallback={(value) => { onBlur(schemaSectionKey, schemaPropertyKey, value); }} propertyLabel={schemaPropertyItem.label} />
                                                </div>
                                            </div>
                                        )
                                        break;
                                    case "code-editor":
                                        return (
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                                                <div className="fg-pb-property-sub-label">{schemaPropertyItem.label}</div>
                                                <div>
                                                    <InputCodeEditorCtrl propertyValue={propertyValue || ""} defaultLanguage="javascript" propertyChangeCallback={(value) => { onBlur(schemaSectionKey, schemaPropertyKey, value); }} />
                                                </div>
                                            </div>
                                        )
                                        break;
                                    case "css-editor":
                                        return (
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                                                <div className="fg-pb-property-sub-label">{schemaPropertyItem.label}</div>
                                                <div>
                                                    <InputCodeEditorCtrl propertyValue={propertyValue || ""} defaultLanguage="css" propertyChangeCallback={(value) => { onBlur(schemaSectionKey, schemaPropertyKey, value); }} />
                                                </div>
                                            </div>
                                        )
                                        break;
                                }                                
                            })}
                            </div>
                        </div>
                    )
                })}


                {/*<div className="fg-pg-panel-sub-section">Mouse Cursor</div>*/}
                {/*<div style={{ backgroundColor: "white", padding: "5px" }}>*/}
                {/*    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>*/}
                {/*        <div className="fg-pb-property-sub-label">Name</div>*/}
                {/*        <div>*/}
                {/*            <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'cursor')} onChange={(e) => { updateCssProperty('cursor', e.target.value); }}>*/}
                {/*                <option value=""></option>                               */}
                {/*                <option value="default">Default</option>*/}
                {/*                <option value="pointer">Pointer</option>*/}
                {/*            </select>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*</div>*/}
            </div>
        </>
    );

}

export default InputPropertiesCtrl;

