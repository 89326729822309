import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DialogCtrl from "../../components/dialogctrl/dialogctrl";
import BookingCtrl from "../../components/formcontrols/booking/BookingCtrl";
import LoaderSpinnerCtrl from "../../components/loaderspinnerctrl/loaderspinnerctrl";
import { BAChangeDefaultHeaders, BAFetchData } from "../../customhooks/useBAFetch";
import dayjs from 'dayjs';
import helpers from "../../helpers/Helpers";
import { fetchData } from "../../customhooks/useFetch";

function Reservation() {

    const navigate = useNavigate();
    let params = useParams();

    const { portalPathName, bookableSlug, locationId, resourceId } = useParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [resetId, setResetId] = useState(null);
    const [bookableItemList, setBookableItemList] = useState([]);
    const [availSlots, setAvailSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [resources, setResources] = useState([]);
    const [businessAccountDTO, setBusinessAccountDTO] = useState({});
    

    function onComplete(timezoneName, bookableItemId, selectedLocationId, selectedResourceId, confirmedDateTime, answers, payInfo) {
        let formData = new FormData();
        formData.append("eventId", bookableItemId);
        formData.append("locationId", selectedLocationId);
        formData.append("timezoneName", timezoneName);
        //let resourceIdList = []
        //resourceIdList.push(selectedResourceId);
        formData.append('resourceIdList', selectedResourceId);
        const startDateTime = new Date(confirmedDateTime);
        formData.append("startDateTime", dayjs(startDateTime).format('YYYY-MM-DD HH:mm'));
        formData.append("formAnswers", JSON.stringify(answers));

        BAFetchData({
            callName: "AddCardFromEvent",
            method: "POST",
            url: "pm/card/addfromevent",
            token: "fg1234",
            body: formData,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getResources(bookableItemId, locationId) {
        BAFetchData({
            callName: "GetResources",
            method: "GET",
            url: "bookableitems/getresources?bookableitemid=" + bookableItemId + "&locationid=" + locationId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onDateChanged(selDate, bookableItemId, locationId, resourceId) {
        if (selDate == null) {
            setAvailSlots([]);
            setSelectedDate(null);
        } else {
            const keyDate = dayjs(selDate).format('YYYY-MM-DD');
            getAvailableSlots(bookableItemId, locationId, resourceId, keyDate);
            setSelectedDate(selDate);
        }
    }

    function getAvailableDates(bookableItemId, locationId, resourceId) {
        let test = '';
        test = '';
        BAFetchData({
            callName: "GetAvailableDates",
            method: "GET",
            url: "bookableitems/getavailabledates?bookableitemid=" + bookableItemId + "&locationid=" + locationId + "&resourceId=" + resourceId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getAvailableSlots(bookableItemId, locationId, resourceId, date) {
        if (date != 'Invalid Date') {
            let test = "";
            test = "";
            BAFetchData({
                callName: "GetAvailableSlots",
                method: "GET",
                url: "bookableitems/getavailableslots?bookableitemid=" + bookableItemId + "&locationid=" + locationId + "&resourceid=" + resourceId + "&date=" + date,
                token: "fg1234",
                toggleRefetch: null,
                onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            setAvailSlots([]);
        }
    }

    function getBusinessAccountByPortalPathName() {
        fetchData({
            callName: "GetRecordByPortalPathName",
            method: "GET",
            url: "security/businessaccount/getrecordbyportalpathname?portalpathname=" + portalPathName,
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getBookingItemList() {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "slug", "ConditionOperator": "EQUAL", "Value": bookableSlug, "DataType": "String" }
                ]
            }]
        };

        BAFetchData({
            callName: "GetBookableItemList",
            method: "GET",
            url: "bookableitems/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }



    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetRecordByPortalPathName":
                BAChangeDefaultHeaders(data.token);
                setBusinessAccountDTO(data);
                getBookingItemList();
                break;
            case "GetBookableItemList":
                for (let i = 0; i < data.length; i++) {
                    data[i].questions = JSON.parse(data[i].questions);
                    data[i].locationConfigurations = JSON.parse(data[i].locationConfigurations);
                }
                console.log('list', data.length);
                setBookableItemList(data);
                break;
            case "GetAvailableDates":
                setSelectedDates(data);
                break;
            case "GetAvailableSlots":
                setAvailSlots(data);
                break;
            case "GetResources":
                setResources(data);
                break;
            case "AddCardFromEvent":
                //var msg = 'Reservation has been created.';
                //setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/bookableitem/' + data.bookableItemId); } })
                //setOpenDialog(true);
                //setResetId(helpers.getUUID());
                break;

        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {       
        setShowLoader(false);
        getBusinessAccountByPortalPathName();        
    },[])

    return (
        <>
            <div className="fg-app-page-box" style={{ width: "100%", borderTop: "none", paddingTop: "40px",margin:"0px", backgroundColor:"#FBFCFD"}}>
                <div style={{ maxWidth:"516px",display:"block",marginLeft: "auto", marginRight: "auto",backgroundColor:"white",padding:"20px" }}>
                    <BookingCtrl businessLogoUrl={businessAccountDTO.businessLogoImg || ''} resetId={resetId} highlightCurrentDate={true} daysInFuture={14} selectedDates={new Set(selectedDates)} resources={resources} onSelectedDateChange={(selDate, bookableItemId, locationId, resourceId) => { onDateChanged(selDate, bookableItemId, locationId, resourceId); }} bookableItemList={bookableItemList} onLocationChanged={(bookableItemId, locationId) => { getResources(bookableItemId, locationId); }} onResourceChanged={(bookableItemId, locationId, resourceId) => { getAvailableDates(bookableItemId, locationId, resourceId); }} selectedDate={selectedDate} availSlots={availSlots} onComplete={(timezoneName, bookableItemId, selectedLocationId, selectedResourceId, confirmedDateTime, answers, payInfo) => { onComplete(timezoneName, bookableItemId, selectedLocationId, selectedResourceId, confirmedDateTime, answers, payInfo); }} defaultLocationId={locationId} defaultResourceId={resourceId} />
                </div>
                {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
                {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            </div>
        </>

    )
}

export default Reservation;