import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';

function CourseStudentsCtrl({courseId }) {
    return (
        <>
            <div>Course Students</div>
        </>
    );
}

export default CourseStudentsCtrl;
