function getBackgroundImageArray(backgroundImage) {
    // get backgroundImageStyle
    let bgimg_style = backgroundImage,
        // count for parenthesis
        parenthesis = -1;

    // split background by characters...
    return bgimg_style.split('').reduce((str_to_split, character) => {
        // if opening parenthesis
        if (character === '(') {
            // if first opening parenthesis set parenthesis count to zero
            if (parenthesis === -1) {
                parenthesis = 0;
            }
            // add 1 to parenthesis count
            parenthesis++;
        }
        // for closing parenthesis reduce parenthesis count by 1
        else if (character === ')') {
            parenthesis--;
        }
        else {
            // if current character is a comma and it is not inside a parenthesis, at a "split" character
            if (character === ',') {
                if (parenthesis === 0) {
                    str_to_split += '||';
                    return str_to_split;
                }
            }
        }

        // else keep the character
        str_to_split += character;

        return str_to_split;
    }, '')
        // split the resulting string by the split characters including whitespaces before and after to generate an array
        .split(/\s*\|\|\s*/);
}

export function mergeStylesToCurrBP(elParams, currBP, orderedBreakpoints, custom) {
    var tempOrderedBP = [];
    var css = {};
    //if (mobileFirst == true) {
    if (custom == undefined) {
        for (var i = 0; i < orderedBreakpoints.length; i++) {
            //Check if breakpoint exists for element
            if (elParams.breakpointStyles != undefined) {
                if (elParams.breakpointStyles[orderedBreakpoints[i]] != undefined) {
                    var tmpBPCss = elParams.breakpointStyles[orderedBreakpoints[i]];
                    if (tmpBPCss.hasOwnProperty('backgroundImage') == true) {
                        var backgroundImageList = getBackgroundImageArray(tmpBPCss.backgroundImage);
                        if (elParams.hasOwnProperty('aiBGImages') == true) {
                            if (elParams.aiBGImages != null) {
                                for (var aibgi = 0; aibgi < elParams.aiBGImages.length; aibgi++) {
                                    if (elParams.aiBGImages[aibgi].variable != '' && elParams.aiBGImages[aibgi].prompt != '' && elParams.aiBGImages[aibgi].answer != '' && elParams.aiBGImages[aibgi].accept == 'true') {
                                        backgroundImageList[aibgi] = 'url(' + elParams.aiBGImages[aibgi].answer + ')';
                                    }
                                }

                                tmpBPCss.backgroundImage = ""
                                var delimeter = "";
                                for (var bili = 0; bili < backgroundImageList.length; bili++) {
                                    tmpBPCss.backgroundImage += delimeter + backgroundImageList[bili];
                                    delimeter = ", ";
                                }
                            }
                        }
                    }
                    //if (elParams.breakpointStyles[orderedBreakpoints[i]] = orderedBreakpoints[i]) {
                    //css += elParams.breakpointStyles[orderedBreakpoints[i]];
                    css = Object.assign(css, tmpBPCss);
                    //}
                }
                if (orderedBreakpoints[i] == Number(currBP)) {
                    break;
                }
            } else {
                break;
            }
        }
        css = Object.assign(css, elParams.inlineStyle);
    } else {
        for (var i = 0; i < orderedBreakpoints.length; i++) {
            //Check if breakpoint exists for element
            if (elParams.backgroundVideo.breakpointStyles != undefined) {
                if (elParams.backgroundVideo.breakpointStyles[orderedBreakpoints[i]] != undefined) {
                    var tmpBPCss = elParams.backgroundVideo.breakpointStyles[orderedBreakpoints[i]];
                    css = Object.assign(css, tmpBPCss);
                    //}
                }
                if (orderedBreakpoints[i] == Number(currBP)) {
                    break;
                }
            } else {
                break;
            }
        }
        css = css;
        //css = Object.assign(css, elParams.inlineStyle);
    }
    //}
    return css;
}