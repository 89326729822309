import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate} from 'react-router-dom';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import ImageFieldCtrl from '../formcontrols/ImageFieldCtrl';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { buildAutomationWithAi } from './utils';
import { BAFetchData } from '../../customhooks/useBAFetch';

function ValueLadderCtrl({ valueLadder, onChange, createAutomation, onRecordCreated }) {
    const navigate = useNavigate();
    const [valueLadderData, setValueLadderData] = useState(valueLadder);
    const primaryKeyValue = '0';
    const [automationId, setAutomationId] = useState('');

    function updateBlueprint(e) {
        var tmpValueLadderData = { ...valueLadderData };
        if (createAutomation == false) {
            onChange(tmpValueLadderData);
        } else if (createAutomation == true) {
            //const result = buildAutomationWithAi(tmpValueLadderData);
            //console.log('result', result)
            //setElements(result);

            BAFetchData({
                callName: "GetAutomationRecord",
                method: "GET",
                url: "automation/getrecord?automationid=0",
                token: "fg1234",
                toggleRefetch: null,
                onSuccess: onAPISuccess, onError: onAPIError
            });


        }        
    }

    function saveForm(data) {
        var tmpData = { ...data };
        tmpData.forecastSummary = JSON.stringify(data.forecastSummary);
        tmpData.elements = JSON.stringify(data.elements);
        tmpData.autoSurvey = JSON.stringify(data.autoSurvey);
        if (primaryKeyValue == '0') {
            BAFetchData({
                callName: "SaveNewForm", method: "POST", url: "automation/add",
                token: "fg1234", body: JSON.stringify(tmpData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "automation/update",
                token: "fg1234", body: JSON.stringify(tmpData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function addDefaultData(data, adBudget) {
        if (primaryKeyValue == '0') {
            data.elements = "{}";
            data.forecastSummary = { "goal": { "adSpend": parseFloat(adBudget), "visitors": 0, "visitCost": 0, "leads": 0, "cpl": 0, "customers": 0, "cpc": 0, "orders": 0, "aov": 0, "revenue": 0, "roas": 0, "cogs": 0, "totalReturns": 0, "returnValue": 0, "totalChargebacks": 0, "chargebackValue": 0, "otherExpensesValus": 0, "totalExpenses": 0, "profit": 0, "poas": 0 }, "live": { "adSpend": 0, "visitors": 0, "visitCost": 0, "leads": 0, "cpl": 0, "customers": 0, "cpc": 0, "orders": 0, "aov": 0, "revenue": 0, "roas": 0, "cogs": 0, "totalReturns": 0, "returnValue": 0, "totalChargebacks": 0, "chargebackValue": 0, "otherExpensesValus": 0, "totalExpenses": 0, "profit": 0, "poas": 0 } };
            data.active = true;
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetAutomationRecord":                 
                var tmpValueLadderData = { ...valueLadderData };
                const result = buildAutomationWithAi(tmpValueLadderData);
                addDefaultData(data, tmpValueLadderData.budget);
                data.elements = result;
                data.autoSurvey = tmpValueLadderData;
                data.name = "New Automation - " + data.automationId;
                //setElements(result);
                saveForm(data);
                break;
            case "SaveForm":
                setAutomationId(data.automationId);
                //setPrimaryKeyValue(data.automationId);
                //alert('form saved');
                //setFormData(data);
                //var msg = 'Form has been saved.';
                //setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/automationsettings/' + data.automationId); } })
                //setOpenDialog(true);
                break;
            case 'SaveNewForm':
                setAutomationId(data.automationId);
                //setPrimaryKeyValue(data.automationId);
                //alert('new form saved');
                //setFormData(data);                                
                //var msg = 'Form has been saved.';
                //setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/automation/' + data.automationId); } })
                //setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        alert(error);
        //var msg = error;
        //setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        //setOpenDialog(true);
    }

    function onOfferValueChange(e, index, propertyName, value) {
        var tmpValueLadderData = { ...valueLadderData };
        tmpValueLadderData.valueLadder[index][propertyName] = value;

        //onChange(tmpProductList);

        setValueLadderData(tmpValueLadderData);
    }

    function handleOnProductAddBtnClick(e) {
        var tmpValueLadderData = { ...valueLadderData };
        tmpValueLadderData.valueLadder.push(getNewProductJson());
        //onChange(tmpValueLadderData);
        setValueLadderData(tmpValueLadderData);
    }

    function getNewProductJson() {
        return {
            "offerName": null, "offerType": null, "offerTypeCaption": null, "price":0, "displayOrder":valueLadderData.valueLadder.length + 1,"salesCycle":7
            };
    }

    function handleOnProductRemoveBtnClick(e, index) {
        var tmpValueLadderData = { ...valueLadderData };
        tmpValueLadderData.valueLadder.splice(index, 1);
        setValueLadderData(tmpValueLadderData);
    }

    useEffect(() => {
        setValueLadderData(valueLadder);
    }, [valueLadder]);

    return (
        <>
            {automationId == '' ?
                <div style={{ padding: "5px 5px 20px 5px" }}>
                    <TextField id="industry" required fullWidth label="Industry" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={valueLadderData.industry || ''} InputLabelProps={{ shrink: valueLadderData.industry }} onChange={(e) => setValueLadderData(valueLadderData => ({ ...valueLadderData, industry: e.target.value }))} />
                    <TextField id="budget" required fullWidth label="Budget" autoComplete='off' type="number" variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={valueLadderData.budget || ''} InputLabelProps={{ shrink: valueLadderData.budget }} onChange={(e) => setValueLadderData(valueLadderData => ({ ...valueLadderData, budget: e.target.value }))} />
                    <TextField id="maxResultDays" required fullWidth label="Need Results in How Many Days" autoComplete='off' type="number" variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={valueLadderData.maxResultDays || ''} InputLabelProps={{ shrink: valueLadderData.maxResultDays }} onChange={(e) => setValueLadderData(valueLadderData => ({ ...valueLadderData, maxResultDays: e.target.value }))} />
                    <TextField id="audienceSize" required fullWidth label="Audience Size" autoComplete='off' type="number" variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={valueLadderData.audienceSize || ''} InputLabelProps={{ shrink: valueLadderData.audienceSize }} onChange={(e) => setValueLadderData(valueLadderData => ({ ...valueLadderData, audienceSize: e.target.value }))} />
                    <FormControlLabel style={{ marginBottom: "10px" }}
                        control={
                            <Switch checked={valueLadderData.moreMoneyThanTime} onChange={(e) => setValueLadderData(valueLadderData => ({ ...valueLadderData, moreMoneyThanTime: e.target.checked }))} name="gilad" />
                        }
                        label="More Money Than Time"
                    />

                    <div>
                        <div style={{ marginBottom: "20px" }} >
                            <h2 style={{ fontSize: "20px", fontWeight: "bold" }}>Value Ladder</h2>
                            <p style={{ lineHeight: "100%" }}>Add product offers in the order you want visitors to purchase them.</p>
                        </div>
                        <div>
                            {valueLadderData.valueLadder.map((valueLadderItem, index) => (
                                <div style={{ backgroundColor: "white", padding: "15px 5px 0px 5px", marginBottom: "20px", borderRadius: "4px" }}>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 30px", gridGap: "10px" }}>
                                        <div><TextField id="offerName" required fullWidth label="Offer Name" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={valueLadderItem.offerName || ''} InputLabelProps={{ shrink: valueLadderItem.offerName }} onChange={(e) => { onOfferValueChange(e, index, "offerName", e.target.value); }} /></div>
                                        <div onClick={(e) => { handleOnProductRemoveBtnClick(e, index); }}><DeleteIcon /></div>
                                    </div>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                        <div><TextField id="offerType" required fullWidth label="Offer Type" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={valueLadderItem.offerType || ''} InputLabelProps={{ shrink: valueLadderItem.offerType }} onChange={(e) => { onOfferValueChange(e, index, "offerType", e.target.value); }} /></div>
                                        <div><TextField id="price" required fullWidth type="number" label="Price" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={valueLadderItem.price || ''} InputLabelProps={{ shrink: valueLadderItem.price }} onChange={(e) => { onOfferValueChange(e, index, "price", e.target.value); }} /></div>
                                    </div>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px", width: "100%" }}>
                                        <div><TextField id="salesCycle" required fullWidth label="Sales Cycle" type="number" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={valueLadderItem.salesCycle || ''} InputLabelProps={{ shrink: valueLadderItem.salesCycle }} onChange={(e) => { onOfferValueChange(e, index, "salesCycle", e.target.value); }} /></div>
                                        <div><TextField id="displayOrder" required fullWidth label="Display Order" type="number" autoComplete='off' variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={valueLadderItem.displayOrder || ''} InputLabelProps={{ shrink: valueLadderItem.displayOrder }} onChange={(e) => { onOfferValueChange(e, index, "displayOrder", e.target.value); }} /></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: "10px" }}>
                            <div><Button variant="outlined" fullWidth color="primary" size="medium" style={{ border: "1px solid rgba(0,0,0,.12)", padding: "4.5px 12px 4.5px 12px" }} onClick={(e) => { handleOnProductAddBtnClick(e); }}>Add Offer</Button></div>
                            <div><Button variant="outlined" fullWidth color="primary" size="medium" style={{ border: "1px solid rgba(0,0,0,.12)", padding: "4.5px 12px 4.5px 12px" }} onClick={(e) => { updateBlueprint(e); }}>Submit Form</Button></div>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <h2 style={{ fontSize: "20px", fontWeight: "500", marginBottom: "20px" }}>Funnel/Automation blueprint has been created.</h2>
                    <p>Next Steps:
                        <ul>
                            <li>You will want to review this funnel blueprint/mapping and make additional edits if needed.</li>
                            <li>Forecast the funnel metrics to ensure that it is profitable. (I can help you with that)</li>
                            <li>Create a new project based upon this funnel blueprint/mappping. (I can do that for you)</li>
                            <li>If you want i will complete the entire buildout of the funnel. I can build the landing pages and emails along with the copy and images.</li>
                        </ul>
                    </p>
                    <p>Click button below to open the funnel/automation.</p>
                    <Button variant="outlined" fullWidth color="primary" size="medium" style={{ backgroundColor:"#5E95DE",color:"white",border: "1px solid rgba(0,0,0,.12)", padding: "4.5px 12px 4.5px 12px" }} onClick={(e) => { navigate('/automation/' + automationId); }}>Click Here To Open</Button>
                </div>

                            }
        </>
    );
}

export default ValueLadderCtrl;
