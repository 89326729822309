export const updateElementsToLatestVersion = (components, createNewWidget) => {
    let masterObject = null;
    for (const id in components) {
        masterObject = createNewWidget(components[id].elementName)
        components[id] = syncObject(components[id], masterObject);
    }
    return components;
}

export const syncObject = (objectA, masterObject) => {
    for (const key in masterObject) {
        // Check if the key exists in objectA
        if (!objectA.hasOwnProperty(key)) {
            // If missing, add the key and its value to objectA
            objectA[key] = masterObject[key];
        } else if (
            typeof masterObject[key] === 'object' &&
            masterObject[key] !== null &&
            !Array.isArray(masterObject[key])
        ) {
            // If the value is a nested object, call syncObject recursively
            syncObject(objectA[key], masterObject[key]);
        }
    }
    return objectA; // Return the updated objectA
}

