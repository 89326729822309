import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useGlobalState } from '../globalstates/GState';
import ImageFieldCtrl from '../components/formcontrols/ImageFieldCtrl';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function BusinessAccountInfo({dialogType,handlerReturnValue}) {
    const [currentUser] = useGlobalState("userInfo");

    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});
    const [timezoneList, setTimezoneList] = useState([]);
    const [countryList, setCountryList] = useState([]);

    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.baid);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "site_settings_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('site_settings_page', 'header_section', 'Business Account');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.baid != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('site_settings_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
    }
    if (helpers.permissionAllowed(currentUser.permissions, 'Security-AccountCancellation', ['allow']) == true) {
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('site_settings_page', 'header_section', 'Cancel Account'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
    }
    if (helpers.permissionAllowed(currentUser.permissions, 'Security-BusinessAccountInfo', ['allowEdit']) == true) {
        headerOptions.elements.push({ type: "button", text: getTranslation('site_settings_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    }
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == 0) {
            //set default data
            data.active = true;
        }        
    }

    function getCountryList() {
        BAFetchData({
            callName: "GetCountryList",
            method: "GET",
            url: "location/country/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getTimezoneList(countryCode) {
        var filtering = {};
        if (countryCode != null) {
            filtering = {
                "LogicalOperator": "and", "ConditionGroups": [{
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "countrycode", "ConditionOperator": "EQUAL", "Value": countryCode, "DataType": "String" }
                    ]
                }]
            };
        }

        BAFetchData({
            callName: "GetTimezoneList",
            method: "GET",
            url: "time/timezone/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                getCountryList();
                getTimezoneList((data.mailingCountry != null ? data.mailingCountry : null));
                break;
            case "SaveForm":                
                setPrimaryKeyValue(data.baid);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/businessaccountinfo/' + data.baid); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/businessaccounts'); } })
                setOpenDialog(true);
                break;
            case 'GetTimezoneList':
                setTimezoneList(data);
                break;
            case 'GetCountryList':
                setCountryList(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "security/businessaccount/getrecord?baid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "security_business_accounts", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);



    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "900px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Business Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="businessName" autoFocus required fullWidth label="Business Name" variant="standard" helperText={errorList['businessName'] ? <span style={{ color: "red" }}>{errorList['businessName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.businessName || ''} InputLabelProps={{ shrink: formData.businessName }} onChange={(e) => setFormData(formData => ({ ...formData, businessName: e.target.value }))} />
                                                    <ImageFieldCtrl imgSrc={formData.businessLogoImg || ''} label="Business Logo" imgType="inline" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, businessLogoImg: imgValue }))}></ImageFieldCtrl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Business Contact</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="email" required fullWidth label="Email" variant="standard" helperText={errorList['email'] ? <span style={{ color: "red" }}>{errorList['email']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.email || ''} InputLabelProps={{ shrink: formData.email }} onChange={(e) => setFormData(formData => ({ ...formData, email: e.target.value }))} />
                                                    <TextField id="phone" required fullWidth label="Phone" variant="standard" helperText={errorList['phone'] ? <span style={{ color: "red" }}>{errorList['phone']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.phone || ''} InputLabelProps={{ shrink: formData.phone }} onChange={(e) => setFormData(formData => ({ ...formData, phone: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Business Location</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.mailingCountry || ''}
                                                            onChange={(e) => { setFormData(formData => ({ ...formData, mailingCountry: e.target.value })); getTimezoneList(e.target.value); } }
                                                            label="Age"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {countryList.map((country, ci) => (
                                                                <MenuItem key={"country_" + ci} value={country.countryCode}>{country.countryName}</MenuItem>
                                                            ))}

                                                        </Select>
                                                        <FormHelperText>{errorList['mailingCountry'] ? <span style={{ color: "red" }}>{errorList['mailingCountry']}</span> : ''} </FormHelperText>
                                                    </FormControl>
                                                    <TextField id="mailingAddress1" required fullWidth label="Address 1" variant="standard" helperText={errorList['mailingAddress1'] ? <span style={{ color: "red" }}>{errorList['mailingAddress1']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingAddress1 || ''} InputLabelProps={{ shrink: formData.mailingAddress1 }} onChange={(e) => setFormData(formData => ({ ...formData, mailingAddress1: e.target.value }))} />
                                                    <TextField id="mailingAddress2" fullWidth label="Address 2" variant="standard" helperText={errorList['mailingAddress2'] ? <span style={{ color: "red" }}>{errorList['mailingAddress2']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingAddress2 || ''} InputLabelProps={{ shrink: formData.mailingAddress2 }} onChange={(e) => setFormData(formData => ({ ...formData, mailingAddress2: e.target.value }))} />
                                                    <TextField id="mailingCity" required fullWidth label="City" variant="standard" helperText={errorList['mailingCity'] ? <span style={{ color: "red" }}>{errorList['mailingCity']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingCity || ''} InputLabelProps={{ shrink: formData.mailingCity }} onChange={(e) => setFormData(formData => ({ ...formData, mailingCity: e.target.value }))} />
                                                    <TextField id="mailingState" required fullWidth label="State" variant="standard" helperText={errorList['mailingState'] ? <span style={{ color: "red" }}>{errorList['mailingState']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingState || ''} InputLabelProps={{ shrink: formData.mailingState }} onChange={(e) => setFormData(formData => ({ ...formData, mailingState: e.target.value }))} />
                                                    <TextField id="mailingZip" required fullWidth label="Zip/Postal" variant="standard" helperText={errorList['mailingZip'] ? <span style={{ color: "red" }}>{errorList['mailingZip']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingZip || ''} InputLabelProps={{ shrink: formData.mailingZip }} onChange={(e) => setFormData(formData => ({ ...formData, mailingZip: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Timezone</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Timezone</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.timezoneName || ''}
                                                            onChange={(e) => setFormData(formData => ({ ...formData, timezoneName: e.target.value }))}
                                                            label="Age"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {timezoneList.map((timezone, tzi) => (
                                                                <MenuItem key={"tz_" + tzi} value={timezone.timezoneName}>{timezone.timezoneName}</MenuItem>
                                                            ))}
                                                            
                                                        </Select>
                                                        <FormHelperText>{errorList['timezoneName'] ? <span style={{ color: "red" }}>{errorList['timezoneName']}</span> : ''} </FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Ai Training Data</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="aiTraining" fullWidth multiline maxRows={8} label="Training Data" variant="standard" helperText={errorList['aiTraining'] ? <span style={{ color: "red" }}>{errorList['aiTraining']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.aiTraining || ''} InputLabelProps={{ shrink: formData.aiTraining }} onChange={(e) => setFormData(formData => ({ ...formData, aiTraining: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Default Product Images</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="productImgSizeThumbnail" required fullWidth label="Thumbnail" variant="standard" helperText={errorList['productImgSizeThumbnail'] ? <span style={{ color: "red" }}>{errorList['productImgSizeThumbnail']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.productImgSizeThumbnail || ''} InputLabelProps={{ shrink: formData.productImgSizeThumbnail }} onChange={(e) => setFormData(formData => ({ ...formData, productImgSizeThumbnail: e.target.value }))} />
                                                    <TextField id="productImgSizeSmall" required fullWidth label="Small" variant="standard" helperText={errorList['productImgSizeSmall'] ? <span style={{ color: "red" }}>{errorList['productImgSizeSmall']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.productImgSizeSmall || ''} InputLabelProps={{ shrink: formData.productImgSizeSmall }} onChange={(e) => setFormData(formData => ({ ...formData, productImgSizeSmall: e.target.value }))} />
                                                    <TextField id="productImgSizeMedium" required fullWidth label="Medium" variant="standard" helperText={errorList['productImgSizeMedium'] ? <span style={{ color: "red" }}>{errorList['productImgSizeMedium']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.productImgSizeMedium || ''} InputLabelProps={{ shrink: formData.productImgSizeMedium }} onChange={(e) => setFormData(formData => ({ ...formData, productImgSizeMedium: e.target.value }))} />
                                                    <TextField id="productImgSizeLarge" required fullWidth label="Large" variant="standard" helperText={errorList['productImgSizeLarge'] ? <span style={{ color: "red" }}>{errorList['productImgSizeLarge']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.productImgSizeLarge || ''} InputLabelProps={{ shrink: formData.productImgSizeLarge }} onChange={(e) => setFormData(formData => ({ ...formData, productImgSizeLarge: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Default Blog Images</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="blogImgSizeThumbnail" required fullWidth label="Thumbnail" variant="standard" helperText={errorList['blogImgSizeThumbnail'] ? <span style={{ color: "red" }}>{errorList['blogImgSizeThumbnail']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.blogImgSizeThumbnail || ''} InputLabelProps={{ shrink: formData.blogImgSizeThumbnail }} onChange={(e) => setFormData(formData => ({ ...formData, blogImgSizeThumbnail: e.target.value }))} />
                                                    <TextField id="blogImgSizeSmall" required fullWidth label="Small" variant="standard" helperText={errorList['blogImgSizeSmall'] ? <span style={{ color: "red" }}>{errorList['blogImgSizeSmall']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.blogImgSizeSmall || ''} InputLabelProps={{ shrink: formData.blogImgSizeSmall }} onChange={(e) => setFormData(formData => ({ ...formData, blogImgSizeSmall: e.target.value }))} />
                                                    <TextField id="blogImgSizeMedium" required fullWidth label="Medium" variant="standard" helperText={errorList['blogImgSizeMedium'] ? <span style={{ color: "red" }}>{errorList['blogImgSizeMedium']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.blogImgSizeMedium || ''} InputLabelProps={{ shrink: formData.blogImgSizeMedium }} onChange={(e) => setFormData(formData => ({ ...formData, blogImgSizeMedium: e.target.value }))} />
                                                    <TextField id="blogImgSizeLarge" required fullWidth label="Large" variant="standard" helperText={errorList['blogImgSizeLarge'] ? <span style={{ color: "red" }}>{errorList['blogImgSizeLarge']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.blogImgSizeLarge || ''} InputLabelProps={{ shrink: formData.blogImgSizeLarge }} onChange={(e) => setFormData(formData => ({ ...formData, blogImgSizeLarge: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Default Blog Style</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="blogHeadingFont" required fullWidth label="Default H-Tag Font" variant="standard" helperText={errorList['blogHeadingFont'] ? <span style={{ color: "red" }}>{errorList['blogHeadingFont']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.blogHeadingFont || ''} InputLabelProps={{ shrink: formData.blogHeadingFont }} onChange={(e) => setFormData(formData => ({ ...formData, blogHeadingFont: e.target.value }))} />
                                                    <TextField id="blogParagraphFont" required fullWidth label="Default Body Font" variant="standard" helperText={errorList['blogParagraphFont'] ? <span style={{ color: "red" }}>{errorList['blogParagraphFont']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.blogParagraphFont || ''} InputLabelProps={{ shrink: formData.blogParagraphFont }} onChange={(e) => setFormData(formData => ({ ...formData, blogParagraphFont: e.target.value }))} />
                                                    <TextField id="blogParagraphFontSize" required fullWidth label="Default Body Font Size" variant="standard" helperText={errorList['blogParagraphFontSize'] ? <span style={{ color: "red" }}>{errorList['blogParagraphFontSize']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.blogParagraphFontSize || ''} InputLabelProps={{ shrink: formData.blogParagraphFontSize }} onChange={(e) => setFormData(formData => ({ ...formData, blogParagraphFontSize: e.target.value }))} />
                                                    <TextField id="blogParagraphLineHeight" required fullWidth label="Default Body Line Height" variant="standard" helperText={errorList['blogParagraphLineHeight'] ? <span style={{ color: "red" }}>{errorList['blogParagraphLineHeight']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.blogParagraphLineHeight || ''} InputLabelProps={{ shrink: formData.blogParagraphLineHeight }} onChange={(e) => setFormData(formData => ({ ...formData, blogParagraphLineHeight: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {formData.whiteLabel == true ?
                                <>
                            <div className="segment">
                                <div className="segment-title">White Label Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="wlName" fullWidth label="White Label Brand Name" variant="standard" helperText={errorList['wlName'] ? <span style={{ color: "red" }}>{errorList['wlName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.wlName || ''} InputLabelProps={{ shrink: formData.wlName }} onChange={(e) => setFormData(formData => ({ ...formData, wlName: e.target.value }))} />
                                                    <TextField id="wlAppDomain" fullWidth label="App Domain" variant="standard" helperText={errorList['wlAppDomain'] ? <span style={{ color: "red" }}>{errorList['wlAppDomain']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.wlAppDomain || ''} InputLabelProps={{ shrink: formData.wlAppDomain }} onChange={(e) => setFormData(formData => ({ ...formData, wlAppDomain: e.target.value }))} />
                                                    <TextField id="wlBusinessDomain" fullWidth label="Business Domain" variant="standard" helperText={errorList['wlBusinessDomain'] ? <span style={{ color: "red" }}>{errorList['wlBusinessDomain']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.wlBusinessDomain || ''} InputLabelProps={{ shrink: formData.wlBusinessDomain }} onChange={(e) => setFormData(formData => ({ ...formData, wlBusinessDomain: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">White Label Images</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <ImageFieldCtrl imgSrc={formData.wlLoginLogo || ''} label="Login Logo (width: 200px height: 30px)" imgType="large" imgWidth="200px" imgHeight="30px" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, wlLoginLogo: imgValue }))}></ImageFieldCtrl>
                                                    <ImageFieldCtrl imgSrc={formData.wlEmailLogo || ''} label="Email Logo (width: 200px height: 30px)" imgType="large" imgWidth="200px" imgHeight="30px" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, wlEmailLogo: imgValue }))}></ImageFieldCtrl>
                                                    <ImageFieldCtrl imgSrc={formData.wlIconLogo || ''} label="Icon Logo (width: 36px height: 36px)" imgType="inline" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, wlIconLogo: imgValue }))}></ImageFieldCtrl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                </>
                                : null}
                            <div className="segment" style={{marginTop:"20px"} }>
                                <div className="segment-title">Portal Settings</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="portalPathName" required fullWidth label="Portal Path Name" variant="standard" helperText={errorList['portalPathName'] ? <span style={{ color: "red" }}>{errorList['portalPathName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.portalPathName || ''} InputLabelProps={{ shrink: formData.portalPathName }} onChange={(e) => setFormData(formData => ({ ...formData, portalPathName: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to cancel Business Account. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "security/businessaccount/delete?baid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm();
            }
        }                
    }

    function saveForm() {
        if (primaryKeyValue == '0') {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "security/businessaccount/add",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "security/businessaccount/update",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        if (formData.timezoneName == null || formData.timezoneName == '') {
            isValid = false;
            tempErrorList['timezoneName'] = "Is required.";
        }
        if (formData.email == null || formData.email == '') {
            isValid = false;
            tempErrorList['email'] = "Is required.";
        }
        if (formData.phone == null || formData.phone == '') {
            isValid = false;
            tempErrorList['phone'] = "Is required.";
        }
        if (formData.mailingCountry == null || formData.mailingCountry == '') {
            isValid = false;
            tempErrorList['mailingCountry'] = "Is required.";
        }
        if (formData.mailingAddress1 == null || formData.mailingAddress1 == '') {
            isValid = false;
            tempErrorList['mailingAddress1'] = "Is required.";
        }
        if (formData.mailingCity == null || formData.mailingCity == '') {
            isValid = false;
            tempErrorList['mailingCity'] = "Is required.";
        }
        if (formData.mailingState == null || formData.mailingState == '') {
            isValid = false;
            tempErrorList['mailingState'] = "Is required.";
        }
        if (formData.mailingZip == null || formData.mailingZip == '') {
            isValid = false;
            tempErrorList['mailingZip'] = "Is required.";
        }
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default BusinessAccountInfo;