import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import './css/kanbanctrl.css';
import Button from '@mui/material/Button';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { KanbanBrokerContext } from './KanbanCtrl';
import DropdownMenuCtrl from '../dropdownmenuctrl/dropdownmenuctrl';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContactSearch from '../../pages/ContactSearch';
import { BAFetchData } from '../../customhooks/useBAFetch';
import helpers from '../../helpers/Helpers';
import CardSettings from '../../pages/CardSettings';
import dayjs from 'dayjs';
import Avatar from '@mui/material/Avatar';

import ScheduleIcon from '@mui/icons-material/Schedule';
import BugReportIcon from '@mui/icons-material/BugReport';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { useGlobalState } from '../../globalstates/GState';


function KanbanCardCtrl({ cardId }) {
    const [currentUser] = useGlobalState("userInfo");

    const { cards, setCards, boards, setBoards, projectId, handleOnSaveCard } = useContext(KanbanBrokerContext);

    const [cardObj, setCardObj] = useState(cards[cardId]);
    

    const moreMenuItems = [];
    if (helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectCards', ['allowEdit']) == true) {
        moreMenuItems.push({ menuType: "MenuItem", name: "MarkComplete", caption: getTranslation('application_shell', 'profile_menu', 'Mark complete'), icon: null, link: null, callback: () => quickAction('MarkComplete') });
        moreMenuItems.push({ menuType: "Divider", name: "Divider", caption: null, icon: null, link: null, callback: null });
    }
    if (helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectCards', ['allowDelete']) == true) {
        moreMenuItems.push({ menuType: "MenuItem", name: "Delete", caption: getTranslation('application_shell', 'profile_menu', 'Delete'), icon: null, link: null, callback: () => quickAction('Delete') });
    }

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    //const [elements, setElements] = useState({
    //    autoitem_1111: {id: "autoitem_1111", autoType: "TrafficSource", autoName: "Facebook", caption: "Traffic Source", typography: {}, icon: "iconfacebook_off.png", width: "100",position: { top: 233, left: 50 }, inputs: [], outputs: { yes: { "autoitem_2222": { connectPosition: 'l-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_2222: { id: "autoitem_2222", autoType: "Page", autoName: "Optin", caption: "Sales Page", typography: {}, icon: "iconoptinpage_off.png", width: "100", position: { top: 200, left: 225 }, inputs: ['autoitem_1111'], outputs: { yes: { "autoitem_3333": { connectPosition: 'l-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_3333: { id: "autoitem_3333", autoType: "Event", autoName: "Purchase", caption: "Purchase", typography: {}, icon: "iconclick_off.png", width: "100", position: { top: 236, left: 400 }, inputs: ['autoitem_2222'], outputs: { yes: { "autoitem_4444": { connectPosition: 'l-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_4444: { id: "autoitem_4444", autoType: "Page", autoName: "Sales", caption: "Upsell", typography: {}, icon: "iconoptinpage_off.png", width: "100", position: { top: 200, left: 575 }, inputs: ['autoitem_3333'], outputs: { yes: { "autoitem_5555": { connectPosition: 'l-2', lineType: 'solid' } }, no: { "autoitem_6666": { connectPosition: 'l-1', lineType: 'solid' } } } },
    //    autoitem_5555: { id: "autoitem_5555", autoType: "Event", autoName: "Purchase", caption: "Purchase Upsell", typography: {}, icon: "iconclick_off.png", width: "100", position: { top: 231, left: 750 }, inputs: ['autoitem_4444'], outputs: { yes: { "autoitem_6666": { connectPosition: 't-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_6666: { id: "autoitem_6666", autoType: "Page", autoName: "Optin", caption: "Thankyou ", typography: {}, icon: "iconoptinpage_off.png", width: "100", position: { top: 400, left: 750 }, inputs: ['autoitem_5555', 'autoitem_4444'], outputs: { yes: {}, no: {} } },
    //});

    function quickAction(action) {
        switch (action) {
            case 'MarkComplete':
                var tmpCardObj = { ...cardObj };
                var tmpCards = { ...cards };

                tmpCardObj.completed = true;                
                tmpCards[tmpCardObj.cardId] = tmpCardObj;

                handleOnSaveCard(tmpCardObj, 'update');

                setCards(tmpCards);
                setCardObj(tmpCardObj);
                break;
            case 'Delete':
                deleteCard(true);
                //var tmpCards = { ...cards };
                //var tmpBoards = [...boards];
                //removeCardFromColumn(tmpBoards, cardObj.boardId, cardObj.columnId, cardObj.cardId);

                //handleOnSaveCard(cardObj, 'delete');
                //delete tmpCards[cardObj.cardId];
                //setCards(tmpCards);
                //setBoards(tmpBoards);

                break;
        }
    }

    function deleteCard(useAPI) {
        var tmpCards = { ...cards };
        var tmpBoards = [...boards];
        removeCardFromColumn(tmpBoards, cardObj.boardId, cardObj.columnId, cardObj.cardId);
        if (useAPI == true) {
            handleOnSaveCard(cardObj, 'delete');
        }
        delete tmpCards[cardObj.cardId];
        setCards(tmpCards);
        setBoards(tmpBoards);

    }

    function removeCardFromColumn(tmpBoards, boardId, columnId, cardId) {        
        var board = null;
        var column = null;
        for (var bi = 0; bi < tmpBoards.length; bi++) {
            board = tmpBoards[bi];
            if (board.boardId == boardId) {
                for (var ci = 0; ci < board.columns.length; ci++) {
                    column = board.columns[ci];
                    if (column.columnId == columnId) {
                        for (var cci = 0; cci < column.cardIds.length; cci++) {
                            if (column.cardIds[cci] == cardId) {
                                column.cardIds.splice(cci, 1);
                                break;
                            }
                        }
                        break;
                    }
                }
                break;
            }
        }
    }

    function getTranslation(a, b, value) {
        return value;
    }

    function handleOnDragFromCanvas(e: React.DragEvent, id) {
        if (helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectCards', ['allowEdit']) == true) {
            e.dataTransfer.setData("card", JSON.stringify({ cardId: id }));
        }
    }

    function handleOnCardClick(e, cardId) {
        openCard(cardId);
    }

    function openCard(cardId) {
        var msg = '';
        //setGenericDialogSchema({
        //    title: '', component: (dialogType, setReturnValue, handleOKClick, setAcceptAfterUpdate) =>
        //        <CardSettings handlerReturnValue={setReturnValue} cardId={cardId} setAcceptAfterUpdate={setAcceptAfterUpdate}></CardSettings>,
        //    body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "1200px", showCancelBtn: true, showOKBtn: false,
        //    cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('CardEdit', returnValues); }
        //});
        setGenericDialogSchema({
            title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                <CardSettings handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} cardId={cardId} ></CardSettings>,
            body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
            cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('CardEdit', returnValues); }
        })
        setOpenDialog(true);
    }

    function openContactSearch(e) {
        e.preventDefault();
        e.stopPropagation();
        if (helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectCards', ['allowEdit']) == true) {
            var msg = '';
            setGenericDialogSchema({
                title: 'ContactSearch', component: (dialogType, setReturnValue) => <ContactSearch handlerReturnValue={setReturnValue} isLookup={true}></ContactSearch>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('ContactSearch', returnValues); }
            })
            setOpenDialog(true);
        } else {
            helpers.permissionErrMsg(setGenericDialogSchema, setOpenDialog);
        }
    }


    function onPopupOK(returnCategory, returnValue) {
        switch (returnCategory) {
            case 'ContactSearch':
                BAFetchData({
                    callName: "GetContactRecord",
                    method: "GET",
                    url: "crm/contact/getrecord?contactid=" + returnValue,
                    token: "fg1234",
                    toggleRefetch: null,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
            case 'CardEdit':
                if (returnValue != '0') {
                    BAFetchData({
                        callName: "GetCardSummary",
                        method: "GET",
                        url: "pm/card/getrecordsummary?cardid=" + returnValue,
                        token: "fg1234",
                        toggleRefetch: null,
                        onSuccess: onAPISuccess, onError: onAPIError
                    });
                } else {
                    deleteCard(false);
                }
                break;
        }
    }  

    

    function addCardToColumn(tmpBoards, boardId, columnId, cardId) {
        var board = null;
        var column = null;
        //disassociate card from source board
        for (var bi = 0; bi < tmpBoards.length; bi++) {
            board = tmpBoards[bi];
            if (board.boardId == boardId) {
                for (var ci = 0; ci < board.columns.length; ci++) {
                    column = board.columns[ci];
                    if (column.columnId == columnId) {
                        column.cardIds.push(cardId);
                        break;
                    }
                }
                break;
            }
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetContactRecord":
                var tmpCardObj = { ...cardObj };
                var cardMemberObj = { cardMemberId: helpers.getUUID(), baid: 0, cardId: tmpCardObj.cardId, contactId: data.contactId, contactName: data.contactName, firstName: data.firstName, lastName: data.lastName, profileImg: data.profileImg };
                tmpCardObj.cardMembers.push(cardMemberObj);
                setCardObj(tmpCardObj);

                BAFetchData({
                    callName: "SaveCardMember", method: "POST", url: "pm/cardmember/addsummary",
                    token: "fg1234", body: JSON.stringify(cardMemberObj), onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
            case "GetCardSummary":
                var tmpCardObj = { ...cardObj };
                var tmpCards = { ...cards };
                var tmpBoards = [...boards];

                var oldBoardId = tmpCardObj.boardId;
                var newBoardId = data.boardId;

                var oldColumnId = tmpCardObj.columnId;
                var newColumnId = data.columnId;

                tmpCardObj = data;
                //tmpCardObj.completed = true;
                tmpCards[tmpCardObj.cardId] = tmpCardObj;

                //handleOnSaveCard(tmpCardObj, 'update');

                if (oldColumnId != newColumnId) {
                    removeCardFromColumn(tmpBoards, oldBoardId, oldColumnId, data.cardId);
                    addCardToColumn(tmpBoards, newBoardId, newColumnId, data.cardId);
                }

                setCards(tmpCards);
                setCardObj(tmpCardObj);
                setBoards(tmpBoards);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setCardObj(cards[cardId]);
    }, [cardId]);

    function renderColor() {
        var color = '#EAEAEA';
        if (cardObj != undefined) {
            if (cardObj.completed == true) {
                color = "#26C6DA";
            } else if (cardObj.roadblock == true) {
                color = "#BC2130";
            }
        }
        return color;
    }

    function isDraggable() {
        var isDraggableStr = false;
        if (helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectCards', ['allowEdit']) == true) {
            isDraggableStr = true;
        }
        return isDraggableStr;
    }

    return (
        <>
           
            <div className="fgkb-board-card" draggable={isDraggable()} onDragStart={(e) => { handleOnDragFromCanvas(e, cardObj.cardId); }} style={{ borderLeft: "5px solid " + renderColor() }} onClick={(e) => { handleOnCardClick(e, cardObj.cardId);} }>
                <div className="fgkb-board-card-header">
                    <div style={{ lineHeight: "120%", fontWeight: "500", color: "#5E5E5E" }}>
                        {cardObj.cardType == "Appointment" ? <ScheduleIcon style={{marginRight:"5px"}} /> : null}
                        {cardObj.cardType == "Bug" ? <BugReportIcon style={{ color: "red", marginRight: "5px" }} /> : null}
                        {cardObj.cardType == "Incident" ? <WarningAmberIcon style={{ marginRight: "5px" }} /> : null}
                        {cardObj.cardType == "Opportunity" ? <MonetizationOnIcon style={{ color: "#26C6DA", marginRight: "5px" }} /> : null}
                        {cardObj.cardType == "Task" ? <AssignmentLateIcon style={{ marginRight: "5px" }} /> : null}
                        {cardObj.cardType == "User Story" ? <StickyNote2Icon style={{ marginRight: "5px" }} /> : null}
                        {cardObj.title}
                    </div>
                    <div style={{ textAlign: "right" }}><div style={{ textAlign: "right" }}><DropdownMenuCtrl buttonType="More" imageSrc="" avatarText="" toolTip={getTranslation('application_shell', 'profile_menu', 'Profile Menu')} menuItems={moreMenuItems} iconStyle={{ width: 28, height: 28, marginBottom: "0px", fontSize: "14px" }}></DropdownMenuCtrl></div></div>
                </div>
                <div className="fgkb-board-card-line-two">
                    {cardObj.cardType != "Appointment" ?
                        <div style={{ fontSize: "12px", color: "#C0C0C0" }}>{cardObj.dueDateTime != null ? dayjs(cardObj.dueDateTime).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MMM D') : ""}</div>
                        :
                        <div style={{ fontSize: "12px", color: "#C0C0C0" }}>{cardObj.startDateTime != null ? dayjs(cardObj.startDateTime).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MMM D') : ""}</div>
                    }
                    <div style={{ textAlign: "right" }}>
                        {cardObj.cardMembers.map((contact, index) => (
                            contact.profileImg != null ?
                            <img key={"card_" + cardObj.cardId + "_member_" + index} src={contact.profileImg} style={{ width: "24px", height: "24px", display: "inline-block", borderRadius: "50%", border: "1px solid #c0c0c0", marginRight: "3px" }} title={contact.contactName} />
                            :
                                <Avatar style={{ fontSize: "15px", width: "24px", height: "24px", display: "inline-flex", marginRight: "3px" }} title={contact.contactName}>{contact.firstName.charAt(0) + contact.lastName.charAt(0)}</Avatar>
                        ))}
                    </div>
                    <div onClick={(e) => { openContactSearch(e) }} style={{ cursor: "pointer" }}><PersonAddAlt1Icon style={{ color: "#C0C0C0", border: "1px solid #C0C0C0", borderRadius: "50px", padding: "3px" }} /></div>
                </div>
                {cardObj.estDur ?
                    <div className="fgkb-board-card-line-three">
                        Est. Dur: {cardObj.estDur}
                    </div>
                    : null}
                <div className="fgkb-board-card-line-three" style={{marginTop:"5px",fontSize:"11px",color:"grey",fontWeight:"bold"} }>
                    #-{cardObj.cardNumber}
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default KanbanCardCtrl;



