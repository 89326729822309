import React, { useContext, useState, useRef, useEffect } from 'react';
import WidgetRouter, { BuildPropDefault, MergeStylesToCurrBP} from '../WidgetRouter';
import { DataBrokerContext } from "../PageBuilderCtrl";
import HoverCtrl, { OnWidgetClick, OnWidgetMouseEnter, OnWidgetMouseLeave, OnWidgetMouseOut, OnWidgetMouseOver, RenderWidgetLabel, RenderWidgetContextMenu } from '../HoverCtrl';
import { CollectionItemContext } from "./CollectionListItemWidget";


function HeadingWidget({ params, tmpElements, currBP, }) {   
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseOver, onWidgetMouseLeave, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, handleUpdateChanges, handleMergeVariables } = useContext(DataBrokerContext);
    const collectionItemContext = useContext(CollectionItemContext);

    //const { collectionDSName, collectionDSRowIndex } = useContext(CollectionItemContext);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [hoverSettings, setHoverSettings] = useState({ style: { pointerEvents: "none", position: "absolute", top: 0, left: 0, width: 0, height: 0, border: "1px solid #5E95DE" }, labelPosition: "top", labels: [], show: false });
    const [selectedSettings, setSelectedSettings] = useState({ style: { pointerEvents: "none", position: "absolute", top: 0, left: 0, width: 0, height: 0, border: "1px solid #5E95DE" }, labelPosition: "top", labels: [], show: false });
    const [widgetData, setWidgetData] = useState(params);
    const [editable, setEditable] = useState(false);
    const textArea = useRef();

    var timer = 0;
    var delay = 100;
    var prevent = false;

    function handleOnClick(e, id, widgetData) {
        timer = setTimeout(() => {
            if (!prevent) {
                onWidgetClick(e, id, widgetData, (updatedWidgetData) => handleOnPropertyChange(updatedWidgetData), () => handleOnHideHighlight(), () => handleOnSave());
            }
        }, delay);        
        if (e != undefined) {
            e.stopPropagation();
        }
    }

    function handleOnSave() {
        //setElements({
        //    ...elements, // copy the old fields
        //    [widgetData.id]: widgetData //override with updated target widget
        //});
        //console.log('save widget data')
    }

    function handleOnHideHighlight() {
        setSelectedSettings({
            ...selectedSettings, // copy the old fields
            show: false
        });
        setHoverSettings({
            ...hoverSettings, // copy the old fields
            show: false
        });
        setShowContextMenu(false);
    }

    function handleOnPropertyChange(updatedWidgetData) {
        setWidgetData((...widgetData) => { return updatedWidgetData });
        handleUpdateChanges(updatedWidgetData);
    }

    function handleOnSettingsClick() {
        alert('show settings');
    }

    function handleOnDoubleClick(e) {

        clearTimeout(timer);
        prevent = true;
        textArea.current.contentEditable = true;
        textArea.current.focus();

        setTimeout(() => {
            prevent = false;
        }, delay);
      
    }

    function handleOnKeyDown(e) {
        if (['27', 'Escape'].includes(String(e.key))) {
            //var tmpWidgetData = { ...widgetData };
            //console.log(tmpWidgetData.id + '... ' + e.target.innerHTML);
            //tmpWidgetData.text = e.target.innerHTML;
            //handleOnPropertyChange(tmpWidgetData);

            params.text = e.target.innerHTML;
            handleOnPropertyChange(params);

            //console.log(e.target.value);
            //setWidgetData((...widgetData) => { return tmpWidgetData });
            //setEditable(false);
            textArea.current.contentEditable = false;
            
        }
    }

    function aiTextMerge(text) {        
        if (params.aiText.accept == 'true') {
            if (params.aiText.answer != '') {
                text = params.aiText.answer;
            }
        }
        return text;
    }


    return (      
        <>
            <h1 id={params.id} ref={textArea} onKeyDown={(e) => { handleOnKeyDown(e); }} onDoubleClick={(e) => { handleOnDoubleClick(e); }} elementname={params.elementName} style={MergeStylesToCurrBP(params, currBP)} className={"pb-widget  pb-no-click-children " + params.className} onMouseEnter={(e) => { onWidgetMouseEnter(e, params.id); }} onMouseOver={(e) => { onWidgetMouseOver(e, params.id); }} onMouseLeave={(e) => { onWidgetMouseLeave(e, params.id); }} onClick={(e) => { handleOnClick(e, params.id, params); }} onDragStart={(e) => handleOnDragFromCanvas(e, params.id, "heading", "widget")} onDragOver={(e) => { handleOnDragOver(e, params.id); }} onDragLeave={(e) => { handleOnDragLeave(e, params.id); }} onDrop={(e) => { handleOnDrop(e, params.id); }}
                dangerouslySetInnerHTML={{ __html: handleMergeVariables(aiTextMerge(params.designText || params.text), false, collectionItemContext,(collectionItemContext != undefined ? collectionItemContext.collectionDSRowIndex : null), (collectionItemContext != undefined ? collectionItemContext.parentCollectionListRowIndex : null)) }}></h1>
        </>
    )
}

HeadingWidget.getPropSchema = function () {
    return {
        "reactUseStates": BuildPropDefault("reactUseStates")
    };
};

export default HeadingWidget;