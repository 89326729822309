import React, { useContext, useState, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import Button from '@mui/material/Button';
import { setGlobalState, useGlobalState } from '../globalstates/GState';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SingleValueReportCtrl from '../components/dashboardcontrols/SingleValueReportWCtrl';
import BarChartReportCtrl from '../components/dashboardcontrols/BarChartReportCtrl';
import GridReportCtrl from '../components/dashboardcontrols/GridReportCtrl';
import ResizeObserver from 'rc-resize-observer';
import helpers from '../helpers/Helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import TransactionViewerCtrl from '../components/transactionviewer/TransactionViewerCtrl';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';

function AuthReturn() {

    const navigate = useNavigate();

    const [currentUser] = useGlobalState("userInfo");
    const [searchParams] = useSearchParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    function getToken() {            
        if (searchParams.get('state') != null) {
            var code = searchParams.get('code');
            var stateJson = JSON.parse(searchParams.get('state'));
            BAFetchData({
                callName: "GetToken",
                method: "GET",
                url: "integration/item/getauthtoken?code=" + code + "&state=" + JSON.stringify(stateJson),
                token: "fg1234",
                onSuccess: onAPISuccess, onError: onAPIError
            });
        }       
    }

    function saveToken() {
        if (searchParams.get('state') != null) {
            var code = searchParams.get('code');
            var stateJson = JSON.parse(searchParams.get('state'));
            BAFetchData({
                callName: "GetToken",
                method: "GET",
                url: "integration/item/savetoken?code=" + code + "&state=" + JSON.stringify(stateJson),
                token: "fg1234",
                onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function additionalSetup() {
        var stateJson = JSON.parse(searchParams.get('state'));
        switch (stateJson.platform) {
            case 'Facebook':
            case 'Google':
                BAFetchData({
                    callName: "AdditionalSetup",
                    method: "GET",
                    url: "apiintegration/additionalsetup?integrationid=" + stateJson.integrationId + "&ischemaid=" + stateJson.iSchemaId,
                    token: "fg1234",
                    onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
        }

    }

    function integrationCompleted() {
        var stateJson = JSON.parse(searchParams.get('state'));
        var msg = stateJson.platform + " integration connection established";
        setGenericDialogSchema({ title: 'Integration Completed', component: null, body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/integrationitem/' + stateJson.integrationId); } })
        setOpenDialog(true);

    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetToken":
                var stateJson = JSON.parse(searchParams.get('state'));
                switch (stateJson.platform) {
                    case 'Google':
                    case 'Facebook':
                        additionalSetup();              
                        break;
                    default:
                        integrationCompleted();
                        break;
                }                
                break;
            case "AdditionalSetup":
                integrationCompleted();
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }
    useEffect(() => {
        setShowLoader(false);            
        getToken();
    }, []);

    return (
        <>
                <div className="fg-app-page-box">
                    <div style={{display:"grid",gridTemplateColumns:"1fr",gridWidth:"100%",gridGap:"10px"}}>
                        <div>
                        <h1>Auth Return</h1>

                        </div>
                    </div>         
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default AuthReturn;

