import React, { useState, useEffect, useContext, createContext } from 'react';
import AppHeader from './applayout/appheader/AppHeader';
import AppMainNav from './applayout/appmainnav/AppMainNav';
import { Route, Routes, Outlet, useNavigate } from 'react-router-dom';
import { setGlobalState, useGlobalState } from '../globalstates/GState';
import useBAFetch, { BAFetchData, BAChangeDefaultHeaders } from '../customhooks/useBAFetch';
import { fetchData } from '../customhooks/useFetch';

function AuthLayout() {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const AuthContext = createContext();

    function validateLogin(foundUser) {
        fetchData({
            callName: "ValidateLogin",
            method: "GET",
            url: "security/user/loginwithtoken?userToken=" + foundUser.token,
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName) {
        switch (callName) {
            case "ValidateLogin":
                var isValid = true;
                var tempErrorList = {};
                if (data != null && data != '') {
                    const loggedInUser = localStorage.getItem("fg-user");
                    const foundUser = JSON.parse(loggedInUser);
                    //userInfo = foundUser;
                    if (foundUser.currentBusinessAccountDTO != null) {
                        BAChangeDefaultHeaders(foundUser.currentBusinessAccountDTO.token);
                    }
                    if (data.permissions != '') {
                        foundUser.permissions = JSON.parse(data.permissions);
                    }
                    setCurrentUser(foundUser);
                    setGlobalState("userInfo", foundUser);
                } else {
                    navigate('/login');
                }
                break;
        }
    }

    function onAPIError(error, callName) {
        navigate('/login');
    }

    useEffect(() => {
        const loggedInUser = localStorage.getItem("fg-user");
        if (loggedInUser) {
            const foundUser = JSON.parse(loggedInUser);
            validateLogin(foundUser);
        } else {
            navigate('/login');
        }
    }, []);


    return (
        <div>
        { currentUser != null &&
                <div id="app-container" className="fgd-app-container" >
                    <div id="app-container" className="fgd-app-container" >
                        <AppHeader></AppHeader>
                        <div id="app-sidebar" className="fgd-app-mainnav">
                            <AppMainNav></AppMainNav>
                        </div>
                        <div id="app-page-area" className="fgd-app-page-area">
                            <Outlet />
                        </div>
                    </div>
                </div>
        }
        </div>
    )
}

export default AuthLayout

