import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ImageFieldCtrl from '../components/formcontrols/ImageFieldCtrl';
import LookupFieldCtrl from '../components/formcontrols/LookupFieldCtrl';
import ContactSearch from './ContactSearch';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

import './css/apphtmleditor.css';

import { Editor } from '@tinymce/tinymce-react';
import { useGlobalState } from '../globalstates/GState';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';


function BlogDetail({dialogType,handlerReturnValue}) {
    const [currentUser] = useGlobalState("userInfo");
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [refreshId, setRefreshId] = useState(0);
    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});
    const [defaultImgSizes, setDefaultImageSizes] = useState({ thumbnail: '', small: '', medium: '', large: '' });
    const [defaultEditorStyle, setDefaultEditorStyle] = useState({ hTagFont: "arial", bodyFont: "arial", bodyFontSize: "18px", bodyLineHeight: "140%" });
    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.blogId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "blog_detail_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('blog_detail_page', 'header_section', 'Blog Detail');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.blogId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('blog_detail_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
        if (helpers.permissionAllowed(currentUser.permissions, 'Marketing-Blogs', ['allowDelete']) == true) {
            headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('blog_detail_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
        }
    }



    if ((helpers.permissionAllowed(currentUser.permissions, 'Marketing-Blogs', ['allowEdit']) == true) || (helpers.permissionAllowed(currentUser.permissions, 'Marketing-Blogs', ['allowAdd']) == true && primaryKeyValue == 0)) {
        headerOptions.elements.push({ type: "button", text: getTranslation('blog_detail_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    }
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == 0) {
            //set default data
            data.active = false;
        }        
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                getDefaultProductImages();
                break;
            case "GetAccountData":
                setDefaultImageSizes({ thumbnail: data.blogImgSizeThumbnail, small: data.blogImgSizeSmall, medium: data.blogImgSizeMedium, large: data.blogImgSizeLarge });
                break;
            case "SaveForm":                
                setPrimaryKeyValue(data.blogId);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/blogdetail/' + data.blogId); refreshPage(); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/blogs'); } })
                setOpenDialog(true);
                break;
            case 'GetContactLookup':
                var tmpFormData = { ...formData };
                tmpFormData.authorId = data.contactId;
                tmpFormData.author = data;
                setFormData((...formData) => { return tmpFormData });
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function getDefaultProductImages() {
        BAFetchData({
            callName: "GetAccountData",
            method: "GET",
            url: "security/businessaccount/getrecord?baid=" + currentUser.currentBusinessAccountDTO.baid,
            token: "fg1234",
            toggleRefetch: searchRefetch,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "marketing/blog/getrecord?blogid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas, refreshId]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "marketing_blogs", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);

    function handleChangeEditor(editor) {
        var tmpFormData = { ...formData };
        tmpFormData.content = editor;
        setFormData((...formData) => { return tmpFormData });
    }

    function handleOnTitleChanged(titleVal) {
        var tmpFormData = { ...formData };
        tmpFormData.title = titleVal;
        tmpFormData.slug = helpers.slugify(titleVal);
        setFormData((...formData) => { return tmpFormData });
    }

    function refreshPage() {
        setFormData({});
        setRefreshId(helpers.getUUID());
    }

    function openContactSearch(actionType) {
        var msg = '';
        //setGenericDialogSchema({ title: 'Contact Search', component: (dialogType, setReturnValue) => <ContactSearch handlerReturnValue={dialogType, setReturnValue}></ContactSearch>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleContactSelected(returnValues, actionType); } })
        setGenericDialogSchema({ title: 'Contact Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <ContactSearch handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></ContactSearch>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('GetContactInfo', returnValues); } })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "GetContactInfo":
                BAFetchData({
                    callName: "GetContactLookup",
                    method: "GET",
                    url: "crm/contact/getrecord?contactid=" + returnValues,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
        }
    }


    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "1200px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="segment">
                                <div className="segment-title">Blog Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="title" autoFocus required fullWidth label="Title" variant="standard" helperText={errorList['title'] ? <span style={{ color: "red" }}>{errorList['title']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.title || ''} InputLabelProps={{ shrink: formData.title }} onChange={(e) => { handleOnTitleChanged(e.target.value); } } />
                                                    <TextField id="subTitle" fullWidth label="Sub Title" variant="standard" helperText={errorList['subTitle'] ? <span style={{ color: "red" }}>{errorList['subTitle']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.subTitle || ''} InputLabelProps={{ shrink: formData.subTitle }} onChange={(e) => setFormData(formData => ({ ...formData, subTitle: e.target.value }))} />
                                                    <TextField id="slug" fullWidth label="Slug" variant="standard" helperText={errorList['slug'] ? <span style={{ color: "red" }}>{errorList['slug']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.slug || ''} InputLabelProps={{ shrink: formData.slug }} onChange={(e) => setFormData(formData => ({ ...formData, slug: e.target.value }))} />                                                    
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Blog Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.blogType || ''}
                                                            onChange={(e) => setFormData(formData => ({ ...formData, blogType: e.target.value }))}
                                                            label="Age"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value="Blog">Blog</MenuItem>
                                                            <MenuItem value="Help">Help</MenuItem>
                                                            <MenuItem value="Internal">Internal</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{errorList['blogType'] ? <span style={{ color: "red" }}>{errorList['blogType']}</span> : ''} </FormHelperText>
                                                    </FormControl>
                                                    <ImageFieldCtrl imgSrc={formData.mainThumbnail || ''} label="Main Thumbnail" imgType="inline" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, mainThumbnail: imgValue }))}></ImageFieldCtrl>
                                                    <TextField id="description" multiline rows={4} fullWidth label="Description" variant="standard" helperText={errorList['description'] ? <span style={{ color: "red" }}>{errorList['description']}</span> : ''} style={{ marginBottom: "16px", marginTop:"16px" }} value={formData.description || ''} InputLabelProps={{ shrink: formData.description }} onChange={(e) => setFormData(formData => ({ ...formData, description: e.target.value }))}  />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Publish Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <FormControlLabel style={{ marginBottom: "10px" }}
                                                control={
                                                    <Switch checked={formData.active} onChange={(e) => setFormData(formData => ({ ...formData, active: e.target.checked }))} name="gilad" />
                                                }
                                                label="Active"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <LookupFieldCtrl dataPackage={formData.author || ''} textFieldName="contactName" valueFieldName="contactId" label="Author" helperText={errorList['authorId'] ? <span style={{ color: "red" }}>{errorList['authorId']}</span> : ''} onClick={() => { openContactSearch('lookup'); }} onRemove={() => { setFormData(formData => ({ ...formData, authorId: null })); setFormData(formData => ({ ...formData, author: null })); } }></LookupFieldCtrl>
                                                    <TextField id="contributors" fullWidth label="Contributors" variant="standard" helperText={errorList['contributors'] ? <span style={{ color: "red" }}>{errorList['contributors']}</span> : ''} style={{ marginBottom: "16px", marginTop:"16px" }} value={formData.contributors || ''} InputLabelProps={{ shrink: formData.contributors }} onChange={(e) => setFormData(formData => ({ ...formData, contributors: e.target.value }))} />
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                        <DemoContainer components={['DatePicker']} style={{ marginBottom: "10px" }}>
                                                            <DesktopDatePicker
                                                                label="Date Published"
                                                                variant="Standard"
                                                                slotProps={{ textField: { fullWidth: true, helperText: errorList['datePublished'] ? <span style={{ color: "red" }} > {errorList['datePublished']}</span> : '' } }}
                                                                value={formData.datePublished != null ? dayjs(formData.datePublished) : null}
                                                                onChange={(newValue) => setFormData(formData => ({ ...formData, datePublished: newValue }))} />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment" style={{marginTop:"28px"}}>
                                <div className="segment-title">SEO Information</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div>
                                                <TextField id="siteTitle" required fullWidth label="SEO Title" variant="standard" helperText={errorList['seoTitle'] ? <span style={{ color: "red" }}>{errorList['seoTitle']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.seoTitle || ''} InputLabelProps={{ shrink: formData.seoTitle }} onChange={(e) => setFormData(formData => ({ ...formData, seoTitle: e.target.value }))} />
                                            </div>
                                            <div>
                                                <TextField id="seoDescription" multiline rows={4} fullWidth label="SEO Description" variant="standard" helperText={errorList['seoDescription'] ? <span style={{ color: "red" }}>{errorList['seoDescription']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.seoDescription || ''} InputLabelProps={{ shrink: formData.seoDescription }} onChange={(e) => setFormData(formData => ({ ...formData, seoDescription: e.target.value }))} />
                                            </div>
                                            <div>
                                                <ImageFieldCtrl imgSrc={formData.seoImage || ''} label="SEO Image" imgType="inline" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, seoImage: imgValue }))}></ImageFieldCtrl>
                                            </div>
                                            <div style={{ marginTop: "20px" }}>
                                                <TextField id="seoKeywords" multiline rows={4} fullWidth label="SEO Keywords" variant="standard" helperText={errorList['seoKeywords'] ? <span style={{ color: "red" }}>{errorList['seoKeywords']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.seoKeywords || ''} InputLabelProps={{ shrink: formData.seoKeywords }} onChange={(e) => setFormData(formData => ({ ...formData, seoKeywords: e.target.value }))} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="segment">
                                <div className="segment-title">Content</div>
                                <formHelperText className="fg-helper-text">{errorList['content'] ? <span style={{ color: "red" }}>{errorList['content']}</span> : ''} </formHelperText>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <Editor
                                                        tinymceScriptSrc={process.env.REACT_APP_BASE_URL + '/tinymce/tinymce.min.js'}
                                                        onInit={(evt, editor) => editorRef.current = editor}
                                                        value={formData.content}
                                                        onEditorChange={handleChangeEditor}
                                                        init={{
                                                            height: 500,
                                                            menubar: false,
                                                            toolbar_mode: 'wrap',
                                                            autoresize_bottom_margin: 50,
                                                            plugins: [
                                                                'advlist', 'autolink', 'autoresize', 'blockquote','emoticons', 'lists', 'link', 'image', 'charmap',
                                                                'anchor', 'searchreplace', 'visualblocks', 'code', 'codesample', 'fullscreen',
                                                                'insertdatetime', 'media', 'table', 'preview', 'searchreplace', 'strikethrough', 'help', 'wordcount'
                                                            ],
                                                            toolbar: 'undo redo | blocks | ' +
                                                                'bold italic strikethrough forecolor blockquote | alignleft aligncenter ' +
                                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                'removeformat | link image  media table emoticons | code codesample | searchreplace | preview fullscreen | help',
                                                            content_style: 'body { font-family:' + defaultEditorStyle.bodyFont + '; font-size:' + defaultEditorStyle.bodyFontSize + '; line-height:' + defaultEditorStyle.bodyLineHeight + ';} h1, h2, h3, h4, h5, h6 {font-family:' + defaultEditorStyle.hTagFont + ';}'
                                                        }}
                                                    />
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete tag. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "marketing/blog/delete?blogid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
        if (formData.mainThumbnail == null || formData.mainThumbnail == '') {
            formData.mainThumbnail = defaultImgSizes.large;
        }
    }

    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm();
            }
        }                
    }

    function saveForm() {
        var tmpFormData = { ...formData };
        tmpFormData.polishedContent = "<div class='editor'>" + tmpFormData.content + "</div>";
        if (primaryKeyValue == 0) {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "marketing/blog/add",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "marketing/blog/update",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        if (formData.active == true) {
            if (formData.content == null || formData.content == '' ) {
                isValid = false;
                tempErrorList['content'] = "Is requird.";
            }
            if (formData.authorId == null || formData.authorId == '') {
                isValid = false;
                tempErrorList['authorId'] = "Is requird.";
            }
            if (formData.datePublished == null || formData.datePublished == '') {
                isValid = false;
                tempErrorList['datePublished'] = "Is requird.";
            }
        }
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default BlogDetail;