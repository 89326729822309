import React, { useContext, useState, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import Button from '@mui/material/Button';
import { setGlobalState, useGlobalState } from '../globalstates/GState';
import SingleValueReportCtrl from '../components/dashboardcontrols/SingleValueReportWCtrl';
import BarChartReportCtrl from '../components/dashboardcontrols/BarChartReportCtrl';
import GridReportCtrl from '../components/dashboardcontrols/GridReportCtrl';
import ResizeObserver from 'rc-resize-observer';
import helpers from '../helpers/Helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';

function CancelAccount() {
    const navigate = useNavigate();
    const [currentUser] = useGlobalState("userInfo");
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };

    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }


    function onCancelBtnClick() {
        BAFetchData({
            callName: "CancelBusinessAccount",
            method: "POST",
            url: "security/businessaccount/cancel?baid=" + currentUser.currentBusinessAccountDTO.baid,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError,
            packageObj: null
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "CancelBusinessAccount":
                var msg = 'Business Account has been scheduled for cancellation.';
                setGenericDialogSchema({ title: 'Business Cancellation Scheduled', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/businessaccounts'); } })
                setOpenDialog(true);
                break;

        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    return (
        <>
                <div className="fg-app-page-box">
                    <div style={{display:"grid",gridTemplateColumns:"1fr 300px",gridWidth:"100%",gridGap:"10px"}}>
                        <div>
                        <h1>Cancel Business Account</h1>
                        <p>Cancelling your account means that your account will not renew on the next bill date.</p>
                        <p>We will hold your data for 30 days after the cancellation date. Just in case you change your mind and want to re-activate.</p>
                        <p>Are you sure you want to cancel?</p>
                        <Button variant="outlined" style={{ backgroundColor: "#EB5454", color: "white", border:"1px solid #EB5454"}} onClick={() => onCancelBtnClick()}>{getTranslation('contact_search_page', 'search_grid', 'Yes, Cancel Business Account')}</Button>
                        </div>
                    </div>                
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default CancelAccount;

