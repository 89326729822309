import React, { useContext, useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';

function SliderCtrl({ value, onDataChange, ignoreMeasurementType, min, max, measureTypeList}) {

    const [data, setData] = useState(getMeasurementType(value));
    const [mtList, setMTList] = useState(measureTypeList);

    function getMeasurementType(value) {       
        var dataType = "px";
        if (value != undefined && value != '') {
            if (value.toString().indexOf("px") > -1) {
                dataType = "px";
            } else if (value.toString().indexOf("%") > -1) {
                dataType = "%";
            } else if (value.toString().indexOf("em") > -1) {
                dataType = "em";
            } else if (value.toString().indexOf("rem") > -1) {
                dataType = "rem";
            } else if (value.toString().indexOf("vw") > -1) {
                dataType = "vw";
            } else if (value.toString().indexOf("vh") > -1) {
                dataType = "vh";
            } else if (value.toString().indexOf("svw") > -1) {
                dataType = "svw";
            } else if (value.toString().indexOf("svh") > -1) {
                dataType = "svh";
            } else if (value.toString().indexOf("dvw") > -1) {
                dataType = "dvw";
            } else if (value.toString().indexOf("dvh") > -1) {
                dataType = "dvh";
            } else if (value == "unset") {
                dataType = "";
            }
        } else {
            value = '';
        }
        
        return {value:value.toString().replace(dataType,""), measurementType: dataType};
    }

    function handleOnValueChange(value) {
        var tmpData = { ...data };
        tmpData.value = value;
        if (value == 'unset') {
            tmpData.measurementType = "";
        }
        setData((...data) => { return tmpData });
        if (ignoreMeasurementType == true) {
            onDataChange(tmpData.value);
        } else {
            onDataChange(tmpData.value + tmpData.measurementType);
        }
    }

    function handleOnMeasurementTypeChange(measurementType) {
        var tmpData = { ...data };
        tmpData.measurementType = measurementType;
        setData((...data) => { return tmpData });
        if (ignoreMeasurementType == true) {
            onDataChange(tmpData.value);
        } else {
            onDataChange(tmpData.value + tmpData.measurementType);
        }
        
    }

    useEffect(() => {
        setData(getMeasurementType(value));
    }, [value])

    useEffect(() => {
        if (measureTypeList == null) {
            setMTList(['px', '%', 'rem', 'vw', 'vh', 'svw', 'svh']);
        } else {

            setData({ value: value.toString().replace(measureTypeList[0], ""), measurementType: measureTypeList[0] })
            setMTList(measureTypeList);

        }
    }, [measureTypeList])

    return (
        <>
            {ignoreMeasurementType ?
                <div style={{ display: "grid", gridTemplateColumns: "1fr 35px", gridGap: "5px", width: "100%" }}>
                    <div><Slider size="small" value={(!isNaN(+data.value) ? data.value : '')} defaultValue={(!isNaN(+data.Value) ? data.value : '')} min={min} max={max} aria-label="Small" valueLabelDisplay="auto" style={{ padding: "0px 0px", top: "-3px" }} onChange={(e) => { handleOnValueChange(e.target.value); }} /></div>
                    <div><input className="form-control form-control-sm fg-pb-property-field" value={data.value || ''} onChange={(e) => { handleOnValueChange(e.target.value); }} /></div>
                </div>
                : 
                <div style={{ display: "grid", gridTemplateColumns: "1fr 35px 35px", gridGap: "5px", width: "100%" }}>
                    <div><Slider size="small" value={(!isNaN(+data.value) ? data.value : '')} defaultValue={(!isNaN(+data.value) ? data.value : '')} min={min} max={max} aria-label="Small" valueLabelDisplay="auto" style={{ padding: "0px 0px", top: "-3px" }} onChange={(e) => { handleOnValueChange(e.target.value); }} /></div>
                    <div><input className="form-control form-control-sm fg-pb-property-field" value={data.value || ''} onChange={(e) => { handleOnValueChange(e.target.value); }} /></div>
                    <div>
                        <select className="form-control form-control-sm fg-pb-property-field" style={{ textAlign: "center" }} value={data.measurementType} onChange={(e) => { handleOnMeasurementTypeChange(e.target.value); }}>
                            {mtList != undefined ?                                
                                    mtList.map((mt, index) => (
                                        <option value={mt}>{mt}</option>
                                    ))                                
                            : null}
                        </select>
                    </div>
                </div>
            }
        </>
    );
}

export default SliderCtrl;