import React, { useState, useEffect } from 'react';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button  from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import DropdownMenuCtrl from '../components/dropdownmenuctrl/dropdownmenuctrl';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider }  from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs }  from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import dayjs from 'dayjs';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';

function AppControls() {
    const pageTitle = "App Controls";
    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [gridId, setGridId] = useState(1);

    const [startDate, setStartDate] = useState(null);
    const [age, setAge] = useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [isCompany, setIsCompany] = useState(true);

    const headerOptions = {
        elements: [
            {
                type: "dropdown", name: "Actions", text: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [
                    { type: "dropdown", name: "Add to List", text: "Add to List", icon: "", url: "https://www.google.com", target: "_blank", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", name: "Remove from List", text: "Remove from List", icon: null, url: "", target: "", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", name: "Add Tag", text: "Add Tag", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", name: "Remove Tag", text: "Remove Tag", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", name: "Delete", text: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } },
                    { type: "dropdown", name: "Create Fake Contacts", text: "Create Fake Contacts", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { createDemoContacts(gridId) } }
                ]
            },
            { type: "button", name: "New", text: "New", icon: "bi bi-plus-lg", url: "/newcontact", target:"", color: "btn-success", options: null, function: null },
            { type: "button", name: "Import", text: "Import", icon: "bi bi-cloud-upload-fill", target:"", url: null, color: "btn-outline-secondary", options: null, function: null }
        ], breadcrumbs: [{ name: "Home", url: "/" }, { name: "AppControls", url: null, function: null }]
    }

    const menuItems = [];
    menuItems.push({ menuType: "MenuItem", name: "Profile", caption: "Profile", icon: null, link: null, callback: null });
    menuItems.push({ menuType: "MenuItem", name: "CreateSupportTicket", caption: "Create Support Ticket", icon: null, link: null, callback: null });
    menuItems.push({ menuType: "MenuItem", name: "OpenSecondWindow", caption: "Open in Second Window", icon: null, link: null, callback: null });
    menuItems.push({ menuType: "Divider", name: "Divider", caption: null, icon: null, link: null, callback: null });
    menuItems.push({ menuType: "MenuItem", name: "Logout", caption: "Logout", icon: "Logout", link: null, callback: null });

    const topItems = [
        { label: 'Chair', value: 'Chair' },
        { label: 'Desk', value: 'Desk' },
        { label: 'Bed', value: 'Bed' },
        { label: 'Space Heater', value: 'Space Heater' },
        { label: 'Shelf', value: 'Shelf' },
        { label: 'Television', value: 'Television' },
        { label: 'Laptop', value: 'Laptop' },
        { label: 'Curtain', value: 'Curtain' },
        { label: 'Pen', value: 'Pen' },
    ]

    const tagList = [
        { id: 0, text: 'Chair' },
        { id: 1, text: 'Desk' },
        { id: 2, text: 'Bed' },
        { id: 3, text: 'Space Heater' },
        { id: 4, text: 'Shelf' },
        { id: 5, text: 'Television' },
        { id: 6, text: 'Laptop' },
        { id: 7, text: 'Curtain' },
        { id: 8, text: 'Pen' },
        { id: 9, text: 'Mouse' },
        { id: 10, text: 'Napkin' },
        { id: 11, text: 'Towel' },
        { id: 12, text: 'Sneaker' },
        { id: 13, text: 'Pants' },
        { id: 14, text: 'Cup' },
        { id: 15, text: 'Window' },
    ]

    const selectedList = [
        { id: 1, text: 'Desk' },
        { id: 2, text: 'Bed' },
        { id: 9, text: 'Mouse' },
        { id: 12, text: 'Sneaker' },
        { id: 15, text: 'Window' },
    ]

    const onTagDBInsert = (criteria) => {
        alert('Add to database: ' + criteria);
        return { id: 55, text: criteria };
    }

    const onTagAdded = (id, text) => {
        alert('added: ' + id + ' ' + text);
    }

    const onTagRemoved = (id) => {
        alert('removed: ' + id);
    }

    useEffect(() => {
        setShowLoader(false);            
    },[]);



    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />
            
            <div className="fg-app-page-box">      
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">LoaderCtrl</div>
                            <div className="card-body">
                                <p>The LoaderCtrl is a great way to inform users that a process is running. There is also an option to add a message to the loader.</p>
                                <p>When to use:
                                    <ul>
                                        <li>While page is loading.</li>
                                        <li>While process is running.</li>
                                    </ul>
                                </p>                                
                                <Button variant="outlined" onClick={importContacts}>
                                Show Loader
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">DialogCtrl</div>
                            <div className="card-body">
                                <p>The DialogCtrl is used to show popup dialog.</p>
                                <p>When to use:
                                    <ul>
                                        <li>To show a warning confirmation before user attemps to perform a important process. Such as deleting a record.</li>
                                        <li>To inform a user that process has been completed.</li>
                                    </ul>
                                </p>
                                <Button variant="outlined" onClick={showDialog}>
                                    Open alert dialog
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">DropdownMenuCtrl</div>
                            <div className="card-body">
                                <p>The DropdownMenuCtrl is a great way to show a dropdown menu.</p>
                                <p>When to use:
                                    <ul>
                                        <li>When you want to provide multiple options to a button to save space.</li>
                                    </ul>
                                </p>
                                <DropdownMenuCtrl buttonType="Button" buttonText="Actions" imageSrc="images/sam01.jpg" toolTip="Profile Menu" menuItems={menuItems}></DropdownMenuCtrl>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">TextField</div>
                            <div className="card-body">
                                <p>The TextField wrapper component is a complete form control including a label, input, and help text. It comes with three variants: outlined (default), filled, and standard.</p>
                                <p>When to use:
                                    <ul>
                                        <li>To allow imput from user.</li>
                                        <li>To show data to user in form format.</li>
                                    </ul>
                                </p>
                                <TextField id="standard-basic" fullWidth label="Standard" variant="standard" helperText="Optional helper/error info" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">DateField</div>
                            <div className="card-body">
                                <p>The DateField allows users to enter a date. It contains masking to prevent invalid dates from being entered.</p>
                                <p>When to use:
                                    <ul>
                                        <li>When you want the user to enter a date</li>
                                        <li>When the date is known by the user and they want to enter that date using the keyboard. Typically a date picker can slow down the data entry process so using this field is more appropriate.</li>
                                    </ul>
                                </p>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateField']}>
                                        <DateField label="Basic date field" />
                                    </DemoContainer>
                                </LocalizationProvider>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">DatePicker</div>
                            <div className="card-body">
                                <p>The DatePicker control allows users to pick a date using the mouse instead of entering date using keyboard.</p>
                                <p>When to use:
                                    <ul>
                                        <li>When user need to look at calendar in order to select the appropriate date.</li>
                                    </ul>
                                </p>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DesktopDatePicker label="Start date" variant="Standard" defaultValue={dayjs('2022-04-17')} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">TimeField</div>
                            <div className="card-body">
                                <p>The TimeField allows users to enter a time using the keyboard. It contains masking to prevent invalid times from being entered.</p>
                                <p>When to use:
                                    <ul>
                                        <li>When you want the user to enter a time</li>
                                        <li>When the time is known by the user and they want to enter that time using the keyboard. Typically a time picker can slow down the data entry process so using the TimeField control is more appropriate.</li>
                                    </ul>
                                </p>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>                                    
                                    <TimeField label="Start time" defaultValue={dayjs('2022-04-17T15:30')} />                                    
                                </LocalizationProvider>


                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">DateTimeField</div>
                            <div className="card-body">
                                <p>The DateTimeField control allows users to enter a date and time using the keyboard.</p>
                                <p>When to use:
                                    <ul>
                                        <li>When user knows the date and time and just want to enter it using the keyboard.</li>
                                    </ul>
                                </p>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DateTimeField
                                            label="Start date/time"
                                            defaultValue={dayjs('2022-04-17T15:30')}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">DateTimePicker</div>
                            <div className="card-body">
                                <p>The TimeField allows users to enter a time using the keyboard. It contains masking to prevent invalid times from being entered.</p>
                                <p>When to use:
                                    <ul>
                                        <li>When you want the user to enter a time</li>
                                        <li>When the time is known by the user and they want to enter that time using the keyboard. Typically a time picker can slow down the data entry process so using the TimeField control is more appropriate.</li>
                                    </ul>
                                </p>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDateTimePicker label="Start date/time" variant="Standard" defaultValue={dayjs('2022-04-17T15:30')} />
                                </LocalizationProvider>


                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">Select</div>
                            <div className="card-body">
                                <p>The Select control allows users to select single value from a dropdown list.</p>
                                <p>When to use:
                                    <ul>
                                        <li>When there are multiple options the user need to choose from.</li>
                                    </ul>
                                </p>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label">Age</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={age}
                                    onChange={handleChange}
                                    label="Age"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">Checkbox</div>
                            <div className="card-body">
                                <p>The Checkbox field allows users to enter true/false value.</p>
                                <p>When to use:
                                    <ul>
                                        <li>When you want to capture true/false value.</li>                                        
                                    </ul>
                                </p>
                                <FormControlLabel control={<Checkbox defaultChecked size="large"/>} label="Add onions to order?" />

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">Switch</div>
                            <div className="card-body">
                                <p>The Select control allows users to select single value from a dropdown list.</p>
                                <p>When to use:
                                    <ul>
                                        <li>When there are multiple options the user need to choose from.</li>
                                    </ul>
                                </p>
                                <FormControlLabel
                                    control={
                                        <Switch checked={isCompany} onChange={(e) => setIsCompany(e.target.checked)} name="gilad" />
                                    }
                                    label="Gilad Gray"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">Autocomplete</div>
                            <div className="card-body">
                                <p>The Autocomplete field allows users to quickly locate value from dropdown by entering in particle spelling.</p>
                                <p>When to use:
                                    <ul>
                                        <li>When you have a lot of options to choose from but need a quick way for users to locate it.</li>
                                    </ul>
                                </p>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={topItems}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Items for home" />}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header">Switch</div>
                            <div className="card-body">
                                <p>The Select control allows users to select single value from a dropdown list.</p>
                                <p>When to use:
                                    <ul>
                                        <li>When there are multiple options the user need to choose from.</li>
                                    </ul>
                                </p>
                                <MultiSelectCtrl id="list01" label="Segmented Tags" defaultKeyValuePair={selectedList} dataSourceList={tagList} dataSourceIdField="id" dataSourceTextField="text" onSearchCallback={(criteria) => onTagSearch(criteria)} onDatabaseInsert={(criteria) => onTagDBInsert(criteria)} onTagAddedCallback={(id, text) => onTagAdded(id, text)} onTagRemovedCallback={(id, text) => onTagRemoved(id,text)}  />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function onTagSearch(criteria) {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "or", "Conditions": [
                    { "Name": "FieldName", "ConditionOperator": "STARTSWITH", "Value": criteria, "DataType": "String" }
                ]
            }]
        };
        alert(criteria);
    }

    function handleSwitchChange(e) {
        setIsCompany(e.target.checked);       
    }

    function importContacts() {
        //setLoaderSchema({msg:"Process will take a few seconds..."})
        setShowLoader(true);            
        setTimeout(() => {
            setTimeout(() => {
                setShowLoader(false);
            }, "1000");
        }, "50");
    }



    function showDialog() {
        var msg = 'You are about to delete this contact record. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Contacts Created', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); okAction() } })
        setOpenDialog(true);
    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog()} })
        setOpenDialog(true);
    }

    function handleBulkDeleteConfirm(gridId) {

    }

    function createDemoContacts(gridId) {
        alert('create demo contacts');
    }
}

export default AppControls;