import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Products from '../../pages/Products';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import helpers from '../../helpers/Helpers';
import OpenInvoices from '../../pages/OpenInvoices';
import dayjs from 'dayjs';

function PaymentDetailCtrl({ details, onChange, customerId, paymentId }) {

    const [detailsObj, setDetailsObj] = useState(details);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    function openInvoiceSearch() {
        var invoiceList = "0";
        var comma = ",";
        for (var i = 0; i < detailsObj.length; i++) {
            if (detailsObj[i].internalState != 'deleted') {
                invoiceList += comma + detailsObj[i].invoiceId;
                comma = ",";
            }
        }
        var msg = '';
        setGenericDialogSchema({ title: 'Invoice Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <OpenInvoices handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true} customerId={customerId} paymentId={paymentId} invoiceList={invoiceList}></OpenInvoices>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('GetOpenInvoice', returnValues); } });
        setOpenDialog(true);
    }

    function onLineItemFieldBlur(e, index, propertyName, value) {
        var tmpDetailsObj = [...detailsObj];
        tmpDetailsObj[index][propertyName] = value;

        switch (propertyName) {
            case 'qty':
            case 'price':
                tmpDetailsObj[index].detailAmount = (tmpDetailsObj[index].price * tmpDetailsObj[index].qty).toFixed(2);
                break;
        }

        if (tmpDetailsObj[index].internalState != "added") {
            tmpDetailsObj[index].internalState = "modified";
        }
        onChange(tmpDetailsObj);
        setDetailsObj(tmpDetailsObj);

    }

    function onLineItemValueChange(e, index, propertyName, value) {
        var tmpDetailsObj = [...detailsObj];
        tmpDetailsObj[index][propertyName] = value;

        //switch (propertyName) {
        //    case 'qty':
        //    case 'price':
        //        tmpDetailsObj[index].detailAmount = (tmpDetailsObj[index].price * tmpDetailsObj[index].qty).toFixed(2);
        //        break;
        //}

        if (tmpDetailsObj[index].internalState != "added") {
            tmpDetailsObj[index].internalState = "modified";
        }
        setDetailsObj(tmpDetailsObj);
    }



    function removeLineItem(e, index) {
        var tmpDetailsObj = [...detailsObj];
        if (tmpDetailsObj[index].internalState != "added") {
            tmpDetailsObj[index].internalState = "deleted";
        } else {
            tmpDetailsObj.splice(index, 1);
        }
        onChange(tmpDetailsObj);
        setDetailsObj(tmpDetailsObj);
    }

    function addLineItem(selectedInvoiceInfo) {
        var tmpDetailsObj = [...detailsObj];
        var newLineItem = newLineItemShell();
        newLineItem.invoiceId = selectedInvoiceInfo.headerId;
        newLineItem.invoiceDate = selectedInvoiceInfo.dueDate;
        newLineItem.invoiceOriginalAmt = selectedInvoiceInfo.headerAmt;
        newLineItem.openBalance = selectedInvoiceInfo.openBalance;
        newLineItem.summary = "Invoice #: " + selectedInvoiceInfo.headerId;
        //newLineItem.detailAmount = (newLineItem.price * newLineItem.qty).toFixed(2);

        tmpDetailsObj.push(newLineItem);
        onChange(tmpDetailsObj);
        setDetailsObj(tmpDetailsObj);
    }

    function newLineItemShell() {
        return {
            "detailId": 0, "headerId": 0, "baid": 0, "productId": null,
            "variantId": null, "productName": null, "variantSummary":null,"summary": null,
            "price": 0, "qty": 1, "taxable": false, "detailAmount": 0,
            "invoiceId": null, "invoiceDate":null, "invoiceOriginalAmt":0,"paymentPlan": null, "posted": false,
            "datePosted": null, "cogsAmount": 0, "dateCreated": null,
            "createdBy": null, "dateModified": null, "modifiedBy": null,
            "recurringCharge": false, "proratedAmt": 0, "recurType":null,"payOption": null,
            "billInstallment": 0, "downpayment": 0, "billInterval": 0, "billPeriod": null,
            "billLength": 0, "freeTrialInterval": 0, "freeTrialPeriod": null,
            "syncDay": null, "prorate": false, "nextBillDate": null, "shippingCost": 0,
            "openBalance":0,
            "internalState":"added"
        }
    }

    function updateTotals() {

    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case 'GetOpenInvoice':
                addLineItem(returnValues);
                break;
        }
        
    }

    useEffect(() => {
        setDetailsObj(details);
    }, [details]);

    return (
        <>
            <div style={{marginBottom:"20px"}}>
                <Button variant="outlined" onClick={(e) => { openInvoiceSearch(); }}>Add</Button>
            </div>
            {detailsObj.map((detail, index) => (
                (detail.internalState != 'deleted' ?
                <div key={"transaction_detail_" + index} style={{marginBottom:"20px"}}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 90px 100px 100px 100px 35px", gridGap: "15px" }}>
                        <div>
                            <div style={{ fontSize: "13px", margin: "0px", padding: "0px 0px 3px 0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Invoice</div>
                            <div style={{fontWeight:"bold"}}>{detail.summary}</div>                            
                        </div>
                            <div style={{textAlign:"center"}}>
                                <div style={{ fontSize: "13px", margin: "0px", padding: "0px 0px 3px 0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Due Date</div>
                                <div>{dayjs(detail.invoiceDate).format('MM/DD/YYYY')}</div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <div style={{ fontSize: "13px", margin: "0px", padding: "0px 0px 3px 0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Original Amt.</div>
                                <div>{detail.invoiceOriginalAmt}</div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <div style={{ fontSize: "13px", margin: "0px", padding: "0px 0px 3px 0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Open Balance</div>
                                <div>{detail.openBalance}</div>
                            </div>
                        <div style={{ textAlign: "right" }}>
                            <div style={{ fontSize: "13px", margin: "0px", padding: "0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Applied Amt.</div>
                                <div><TextField id="detailAmount" fullWidth type="number" variant="standard" value={detail.detailAmount} style={{ marginBottom: "16px", }} inputProps={{ min: 0, style: { textAlign: 'right' },className:"fg-number-no-arrows" }} onChange={(e) => { onLineItemValueChange(e, index, 'detailAmount', e.target.value); }} onBlur={(e) => { onLineItemFieldBlur(e, index, 'detailAmount', e.target.value); }} /></div>
                        </div>
                        <div style={{ paddingTop: "0px",textAlign:"right" }}>
                            <DeleteIcon onClick={(e) => { removeLineItem (e, index)} } />
                        </div>
                    </div>
                    </div>
                : null)
            ))}
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default PaymentDetailCtrl;
