import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../dialogctrl/dialogctrl';
import FileManager from '../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '@mui/material/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';

function BasicFileListCtrl({imgList, imgType, onChange, label, returnFullPath, imgPath, imgWidth, imgHeight, recType, recId }) {

    const [imgListObj, setImgListObj] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    //function addVariant(e) {
    //    //var tmpVariantList = [...variantList];
    //    //tmpVariantList.push(getNewVariant());
    //    //reOrder(tmpVariantList);
    //    //setVariantList(tmpVariantList);
    //    //onChange(e, tmpVariantList);
    //}

    function openFileManager() {
        var actionType = "FullPath";
        var msg = '';
        setGenericDialogSchema({ title: 'File Manager', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={setReturnValue} returnFullPath={returnFullPath} recType={recType} recId={recId}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleUpdateImage(returnValues, actionType); } })
        setOpenDialog(true);
    }

    function handleOnOtherChange(value) {
        var tmpImgListObj = [...imgListObj];
        //tmpImgListObj.push(imgPath + value);
        tmpImgListObj.push(value);
        setImgListObj(tmpImgListObj);
        //setImgUrl((...imgUrl) => { return imgPath + value });   
        //var list = '';
        //var comma = '';
        //for (var i = 0; i < tmpImgListObj.length; i++) {
        //    list += comma + tmpImgListObj[i];
        //    comma = ',';
        //}
        onChange(tmpImgListObj);
    }

    function handleUpdateImage(imgUrl, actionType) {
        handleOnOtherChange(imgUrl);
    }

    function removeImage(e, index) {
        var tmpImgListObj = [...imgListObj];
        tmpImgListObj.splice(index, 1);
        setImgListObj(tmpImgListObj);
        onChange(tmpImgListObj);
    }

    function extractFileName(filePath) {
        var fileName = null;
        var parts = filePath.split('/');
        fileName = parts[parts.length - 1];
        return fileName;
    }

    useEffect(() => {
        if (imgList != '') {
            //var list = imgList.split(',');
            //var path = (imgPath != undefined ? imgPath : '');
            //list.push(imgList)
            setImgListObj(imgList);
        } else {
            setImgListObj([]);
        }        
    }, [imgList])

    return (
        <>
            {label != "" ?
                <div style={{ fontSize: "12px", marginBottom: "6px", lineHeight: "100%", color:"rgba(0, 0, 0, 0.6)"}}>{label}</div>
                : null}
            <div><Button variant="outlined" fullWidth onClick={(e) => { openFileManager(e); }}>Add File</Button></div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: "5px", marginTop: "10px" }}>
                {imgListObj.map((imgSrc, index) => (
                    <div style={{ display: "grid", gridTemplateColumns: "28px 50px 1fr 28px", backgroundColor: "#F0F0F0", padding: "5px",borderRadius:"4px",color:"grey" }}>
                        <div><AttachFileIcon/></div>
                        <div style={{ width:"50px",backgroundImage: "url('" + imgSrc + "')", backgroundRepeat:"no-repeat",backgroundSize:"contain"}}></div>
                        <div>    
                            {extractFileName(imgSrc)}
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }} onClick={(e) => { removeImage(e, index); }}><CloseIcon style={{ fontSize: "18px" }} /></div>
                        </div>
                    </div>
                ))}


            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );
}

export default BasicFileListCtrl;