import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import { BAFetchData } from '../../../customhooks/useBAFetch';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

function AOSMSActionCtrl({ objectSettings, onChange }) {

    const [objectSettingsObj, setObjectSettingsObj] = useState(objectSettings);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    function onValueChange(propertyName, value) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings[propertyName] = value;
        setObjectSettingsObj(tmpObjectSettingsObj);
        onChange(tmpObjectSettingsObj);
    }

    function newSetting() {
        return { "smsNumber": "", "smsMessge": ""};
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case '':
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);
    }, []);

    useEffect(() => {
        if (JSON.stringify(objectSettings.settings) == "{}") {
            objectSettings.settings = newSetting();
        }
        setObjectSettingsObj(objectSettings);
    }, [objectSettings]);

    return (
        <>
            <div>
                <TextField fullWidth autoComplete='off' label="SMS Number" variant="outlined" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: objectSettingsObj.settings.smsNumber }} value={objectSettingsObj.settings.smsNumber || ''} onChange={(e) => { onValueChange('smsNumber', e.target.value); }} />

                <TextField fullWidth autoComplete='off' label="SMS Message (160 characters max)" variant="outlined" multiline rows={2} style={{ marginBottom: "16px" }} inputProps={{ maxLength:160}} InputLabelProps={{ shrink: objectSettingsObj.settings.smsMessage }} value={objectSettingsObj.settings.smsMessage || ''} onChange={(e) => { onValueChange('smsMessage', e.target.value); }} />
            </div>

            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default AOSMSActionCtrl;
