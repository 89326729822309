import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { AutomationBrokerContext } from './AutomationBuilderCtrl';
import helpers from '../../helpers/Helpers';

function ForecastSummaryCtrl() {
    const { elements, setElements, formData, setFormData, showForecast, itemsLoaded, setItemsLoaded, imageLoadedCount, setImageLoadedCount, imgCountRef, handleOnDragFromCanvas, selectedItems, setSelectedItems, shiftKeyPressedRef, controlKeyPressedRef, mousePosRef, popupOpenRef } = useContext(AutomationBrokerContext);

    const [summary, setSummary] = useState(formData.forecastSummary);

    let waitList = {};


    function onValueChange(e, propertyName, value) {

        var tmpSummary = { ...summary };
        tmpSummary.goal[propertyName] = value;
        setSummary(tmpSummary);

        var tmpFormData = { ...formData };
        tmpFormData.forecastSummary = tmpSummary;

        if (propertyName == 'adSpend') {
            var tmpElements = { ...elements };
            var adSpend = value;
            var autoObj = null;
            for (const key in tmpElements) {
                autoObj = tmpElements[key];
                if (autoObj.autoType == 'TrafficSource') {
                    autoObj.forecast.goalMetric.budget = parseFloat(value) * parseFloat(autoObj.forecast.goalMetric.budgetAllocation / 100);

                    autoObj.forecast.goalMetric.impressions = Math.floor(parseFloat(autoObj.forecast.goalMetric.budget) / parseFloat(autoObj.forecast.goalMetric.cpm) * 1000);

                    autoObj.forecast.goalMetric.reach = Math.floor(parseFloat(autoObj.forecast.goalMetric.impressions) / parseFloat(autoObj.forecast.goalMetric.frequency));

                    var conversions = 0;
                    if (autoObj.forecast.goalMetric.lpCTR > 0) {
                        conversions = Math.floor(autoObj.forecast.goalMetric.reach * (parseFloat(autoObj.forecast.goalMetric.lpCTR) / 100));
                    }
                    autoObj.forecast.goalMetric.conversions = conversions;

                    var lpViewCost = 0;
                    if (autoObj.forecast.goalMetric.conversions > 0) {
                        lpViewCost = (parseFloat(autoObj.forecast.goalMetric.budget) / parseFloat(autoObj.forecast.goalMetric.conversions)).toFixed(2);
                    }
                    autoObj.forecast.goalMetric.lpViewCost = lpViewCost;
                }
            }
            setElements(tmpElements);
        }

        setFormData(tmpFormData);
    }

    function refreshForecast() {
        waitList = {};
        var tmpElements = { ...elements };
        var autoObj = null;

        //clear forecast traffic and conversion values 

        for (const key in tmpElements) {
            autoObj = tmpElements[key];
            if (autoObj.autoType != 'TrafficSource' && autoObj.autoType != 'Text') {
                autoObj.forecast.goalMetric.traffic = 0;
                autoObj.forecast.goalMetric.conversions = 0;

                waitList[key] = {totalInputs: autoObj.inputs.length, totalProcessed: 0, processInfo: {}};

                for (var i = 0; i < autoObj.inputs.length; i++) {
                    waitList[key].processInfo[autoObj.inputs[i]] = {processed: false};
                }
            }
        }
        
        for (const key in tmpElements) {
            autoObj = tmpElements[key]
            if (autoObj.autoType == 'TrafficSource') {               
                var goalMetric = autoObj.forecast.goalMetric;
                autoUpdateTrafficMetrics(goalMetric);
                for (const destId in autoObj.outputs.yes) {
                    forecastDestItems(tmpElements, goalMetric.conversions, destId, key);
                }                
                //for (const destId in autoObj.outputs.no) {
                //    forecastDestItems(tmpElements, goalMetric.conversions, destId);
                //}
            }
        }
        buildSummaryValues(tmpElements);
        setElements(tmpElements);
    }

    function autoUpdateTrafficMetrics(goalMetric) {
        goalMetric.budget = summary.goal.adSpend * parseFloat(goalMetric.budgetAllocation / 100);

        if (goalMetric.budget > 0) {
            goalMetric.impressions = Math.floor(parseFloat(goalMetric.budget) / parseFloat(goalMetric.cpm) * 1000);
        }

        goalMetric.reach = Math.floor(parseFloat(goalMetric.impressions) / parseFloat(goalMetric.frequency));

        var conversions = 0;
        if (goalMetric.lpCTR > 0) {
            conversions = Math.floor(goalMetric.reach * (parseFloat(goalMetric.lpCTR) / 100));
        }
        goalMetric.conversions = conversions;

        var lpViewCost = 0;
        if (goalMetric.conversions > 0) {
            if (goalMetric.budget > 0) {
                lpViewCost = (parseFloat(goalMetric.budget) / parseFloat(goalMetric.conversions)).toFixed(2);
            }
        }
        goalMetric.lpViewCost = lpViewCost;
    }


    function forecastDestItems(tmpElements, incomingTraffic,id, sourceId) {
        var autoObj = tmpElements[id];
        var goalMetric = autoObj.forecast.goalMetric;
       
        switch (autoObj.autoType) {
            case 'TrafficSource':                               
                break;
            case 'Page':
                goalMetric.traffic += parseInt(incomingTraffic);
                goalMetric.conversions = Math.floor(parseInt(goalMetric.traffic) * (parseFloat(goalMetric.cvr / 100)));
                break;
            case 'Event':
                goalMetric.traffic = incomingTraffic;
                goalMetric.conversions = Math.floor(parseInt(goalMetric.traffic) * parseFloat((goalMetric.cvr / 100)));
                break;
            case 'AutoAction':
                goalMetric.traffic = parseInt(incomingTraffic);
                goalMetric.conversions = Math.floor(parseInt(goalMetric.traffic) * parseFloat((goalMetric.cvr / 100)));
                break;
        }

        var processOutputs = false;
        if (waitList.hasOwnProperty(id) == true) {
            waitList[id].processInfo[sourceId].processed = true;
            waitList[id].totalProcessed++;
            if (waitList[id].totalInputs == waitList[id].totalProcessed) {
                processOutputs = true;
            }
        } else {
            processOutputs = true;
        }

        if (processOutputs == true) {
            for (const destId in autoObj.outputs.yes) {
                forecastDestItems(tmpElements, goalMetric.conversions, destId, id);
            }
            for (const destId in autoObj.outputs.no) {
                forecastDestItems(tmpElements, goalMetric.traffic - goalMetric.conversions, destId, id);
            }
        }
        //for (const destId in autoObj.outputs.no) {
        //    forecastDestItems(tmpElements, goalMetric.traffic - goalMetric.conversions, destId);
        //}
    }

    function buildSummaryValues(tmpElements) {
        var autoObj = null;
        var tmpSummary = { ...summary };

        tmpSummary.goal.visitors = parseInt(0);
        tmpSummary.goal.visitCost = parseFloat(0);
        tmpSummary.goal.leads = parseInt(0);
        tmpSummary.goal.cpl = parseFloat(0);
        tmpSummary.goal.customers = parseInt(0);
        tmpSummary.goal.cpc = parseFloat(0);
        tmpSummary.goal.orders = parseInt(0);
        tmpSummary.goal.aov = parseFloat(0);
        tmpSummary.goal.revenue = parseFloat(0);
        tmpSummary.goal.roas = parseFloat(0);
        tmpSummary.goal.cogs = parseFloat(0);
        tmpSummary.goal.totalReturns = parseInt(0);
        tmpSummary.goal.returnValue = parseFloat(0);
        tmpSummary.goal.totalChargebacks = parseInt(0);
        tmpSummary.goal.chargebackValue = parseFloat(0);
        tmpSummary.goal.otherExpensesValus = parseFloat(0);
        tmpSummary.goal.totalExpenses = parseFloat(0);
        tmpSummary.goal.profit = parseFloat(0);
        tmpSummary.goal.poas = parseFloat(0);


        for (const key in tmpElements) {
            autoObj = tmpElements[key];
            if (autoObj.autoType == 'TrafficSource' && autoObj.inputs.length == 0) {
                tmpSummary.goal.visitors += parseInt(autoObj.forecast.goalMetric.conversions);
            }
        }
        var visitCost = 0;
        if (tmpSummary.goal.visitors > 0) {
            visitCost = (tmpSummary.goal.adSpend / tmpSummary.goal.visitors).toFixed(2);
        }
        tmpSummary.goal.visitCost = visitCost;

        for (const key in tmpElements) {
            autoObj = tmpElements[key];
            if (autoObj.autoType == 'Event' && autoObj.autoName == 'Lead') {
                tmpSummary.goal.leads += parseInt(autoObj.forecast.goalMetric.conversions);
            }
        }

        if (tmpSummary.goal.leads > 0) {
            tmpSummary.goal.cpl = (tmpSummary.goal.adSpend / tmpSummary.goal.leads).toFixed(2);
        }

        var product = null;
        for (const key in tmpElements) {
            autoObj = tmpElements[key];
            if (autoObj.autoType == 'Event' && autoObj.autoName == 'Purchase') {
                tmpSummary.goal.customers += parseInt(autoObj.forecast.goalMetric.conversions);

                for (var i = 0; i < autoObj.products.length; i++) {
                    product = autoObj.products[i];
                    tmpSummary.goal.revenue += (product.price * autoObj.forecast.goalMetric.conversions);
                    var cogsRate = parseFloat((100 - parseFloat(product.profitMargin)) / 100);
                    tmpSummary.goal.cogs += (parseFloat(product.price) * cogsRate) * parseFloat(autoObj.forecast.goalMetric.conversions)
                }
                
            }
        }

        if (tmpSummary.goal.customers > 0) {
            tmpSummary.goal.cpc = (tmpSummary.goal.adSpend / tmpSummary.goal.customers).toFixed(2);
        }

        tmpSummary.goal.orders = tmpSummary.goal.customers;

        if (tmpSummary.goal.orders > 0) {
            tmpSummary.goal.aov = (tmpSummary.goal.revenue / tmpSummary.goal.orders).toFixed(2);
        }

        if (tmpSummary.goal.adSpend > 0) {
            tmpSummary.goal.roas = (tmpSummary.goal.revenue / tmpSummary.goal.adSpend).toFixed(1);
        }

        
        tmpSummary.goal.totalExpenses = parseFloat(parseFloat(tmpSummary.goal.adSpend) + parseFloat(tmpSummary.goal.cogs));
        
        tmpSummary.goal.profit = parseFloat(parseFloat(tmpSummary.goal.revenue) - parseFloat(tmpSummary.goal.totalExpenses));

        if (tmpSummary.goal.totalExpenses > 0) {
            tmpSummary.goal.poas = (parseFloat(tmpSummary.goal.profit) / parseFloat(tmpSummary.goal.totalExpenses)).toFixed(1);
        }
    }

    useEffect(() => {
        setSummary(formData.forecastSummary);
    }, [formData]);

    //useEffect(() => {
    //    refreshForecast();
    //}, [elements]);

    return (
        <>
            
            {summary != null ?               
                <div className="fgab-forecast-summary">
                    <button style={{ width: "100%",backgroundColor:"white",border:"1px solid #c0c0c0",borderRadius:"4px",marginBottom:"5px" }} onClick={() => { refreshForecast(); } }>Update Forecast Summary</button>
                    <div className="fgab-forecast-summary-section-title">Ad Costs</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "15px" }}>
                        <div className="fgab-forecast-metric-box">
                            <div className="fgab-forecast-metric-label">Visitor</div>
                            <div className="fgab-forecast-metric-value">{helpers.formatNumber(summary.goal.visitors,0)}</div>
                        </div>
                        <div className="fgab-forecast-metric-box">
                            <div className="fgab-forecast-metric-label">Visit Cost</div>
                            <div className="fgab-forecast-metric-value">${helpers.formatNumber(summary.goal.visitCost,2)}</div>
                        </div>
                        <div className="fgab-forecast-metric-box">
                            <div className="fgab-forecast-metric-label">Leads</div>
                            <div className="fgab-forecast-metric-value">{helpers.formatNumber(summary.goal.leads,0)}</div>
                        </div>
                        <div className="fgab-forecast-metric-box">
                            <div className="fgab-forecast-metric-label">Lead Cost</div>
                            <div className="fgab-forecast-metric-value">${helpers.formatNumber(summary.goal.cpl,2)}</div>
                        </div>
                        <div className="fgab-forecast-metric-box">
                            <div className="fgab-forecast-metric-label">Customers</div>
                            <div className="fgab-forecast-metric-value">{helpers.formatNumber(summary.goal.customers,0)}</div>
                        </div>
                        <div className="fgab-forecast-metric-box">
                            <div className="fgab-forecast-metric-label">Customer/Sale Cost</div>
                            <div className="fgab-forecast-metric-value">${helpers.formatNumber(summary.goal.cpc,2)}</div>
                        </div>
                    </div>
                    <div className="fgab-forecast-summary-section-title">Sales</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "15px" }}>
                        <div className="fgab-forecast-metric-box">
                            <div className="fgab-forecast-metric-label">Orders</div>
                            <div className="fgab-forecast-metric-value">{helpers.formatNumber(summary.goal.orders)}</div>
                        </div>
                        <div className="fgab-forecast-metric-box">
                            <div className="fgab-forecast-metric-label">Avg. Order Value</div>
                            <div className="fgab-forecast-metric-value">${helpers.formatNumber(summary.goal.aov,2)}</div>
                        </div>
                        <div className="fgab-forecast-metric-box">
                            <div className="fgab-forecast-metric-label">Revenue</div>
                            <div className="fgab-forecast-metric-value">${helpers.formatNumber(summary.goal.revenue,2)}</div>
                        </div>
                        <div className="fgab-forecast-metric-box">
                            <div className="fgab-forecast-metric-label">ROAS</div>
                            <div className="fgab-forecast-metric-value">{summary.goal.roas}x</div>
                        </div>
                    </div>
                    <div className="fgab-forecast-expense-box">
                        <div className="fgab-forecast-expense-section-title">Expenses</div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "0px" }}>
                            <div className="fgab-forecast-expense-label">Ad Spend</div>
                            <div><input type="text" className="form-control form-control-sm fgab-forecast-field" style={{ textAlign: "right" }} value={summary.goal.adSpend || ''} onChange={(e) => {onValueChange(e, 'adSpend',e.target.value)} } /></div>
                            <div className="fgab-forecast-expense-label">COGS</div>
                            <div className="fgab-forecast-expense-value">${helpers.formatNumber(summary.goal.cogs,2)}</div>
                            <div className="fgab-forecast-expense-label">Returns</div>
                            <div className="fgab-forecast-expense-value">$0.00</div>
                            <div className="fgab-forecast-expense-label">Chargebacks</div>
                            <div className="fgab-forecast-expense-value">$0.00</div>
                            <div className="fgab-forecast-expense-label">More Expenses</div>
                            <div className="fgab-forecast-expense-value">$0.00</div>
                            <div className="fgab-forecast-expense-total-label">Total</div>
                            <div className="fgab-forecast-expense-total-value">${helpers.formatNumber(summary.goal.totalExpenses,2)}</div>
                        </div>
                    </div>
                    <div className="fgab-forecast-profit-box" style={{ backgroundColor: (summary.goal.profit <= 0 ? "#FC4B6C" : "#26C6DA") }}>
                        <div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "15px" }}>
                                <div className="fgab-forecast-profit-label">PROFIT</div>
                                <div className="fgab-forecast-profit-label" style={{ textAlign: "right" }}>POAS</div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "15px" }}>
                                <div className="fgab-forecast-profit-value">${helpers.formatNumber(summary.goal.profit,2)}</div>
                                <div className="fgab-forecast-profit-value" style={{ textAlign: "right" }}>{summary.goal.poas}x</div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    );
}

export default ForecastSummaryCtrl;
