import React, { useContext, useState, useEffect, useRef } from 'react';
import DialogCtrl from '../dialogctrl/dialogctrl';
import FileManager from '../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';

function LookupFieldCtrl({ dataPackage, textFieldName, valueFieldName, onRemove, label, onClick, style, helperText }) {

    //const [dPackage, setDPackage] = useState(dataPackage);

    const [data, setData] = useState({ text: '', value: '' });

    //const dataRef = useRef({ text: '', value: '' });
    //dataRef.current = data;



    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    function handleOnClick() {
        onClick();
    }

    function handleRemoveData() {
        onRemove();
    }

    useEffect(() => {
        if (dataPackage != undefined) {
            setData({ text: dataPackage[textFieldName], value: dataPackage[valueFieldName] });
        } else {
            setData({ text: '', value: '' });
        }
        
    }, [dataPackage])

    return (
        <>
            <div style={style}>
                {label != undefined ? <label style={{ fontSize: "13px", marginBottom: "2px" }}>{label}</label> : null}
                <div style={Object.assign({ display: "grid", gridTemplateColumns: "1fr 30px 30px", borderBottom: "1px solid #c0c0c0", padding: "10px 0px 3px 0px" }, {})}>
                    <div><input className="form-control form-control fg-pb-property-field" value={data.text || ''} readOnly={true} style={{ borderRadius: "0px", border:"none", height:"100%", paddingLeft:"0px" }}  /></div>
                    <div style={{ textAlign: "center", color: "#757575", cursor: "pointer"}} onClick={(e) => { handleOnClick(e); }}><MoreHorizIcon /></div>
                    <div style={{ textAlign: "right", color: "#757575", cursor: "pointer" }} onClick={(e) => { handleRemoveData(e); } }><CloseIcon /></div>
                </div>
                <formHelperText className="fg-helper-text">{helperText}</formHelperText>
            </div>
            
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );
}

export default LookupFieldCtrl;