import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import ImageFieldCtrl from '../components/formcontrols/ImageFieldCtrl';
import { useGlobalState } from '../globalstates/GState';
import Checkbox from '@mui/material/Checkbox';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CourseOutlineCtrl from '../components/formcontrols/course/CourseOutlineCtrl';
import CourseSettingsCtrl from '../components/formcontrols/course/CourseSettingsCtrl';
import CourseStudentsCtrl from '../components/formcontrols/course/CourseStudentsCtrl';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';
import CourseModuleCtrl from '../components/formcontrols/course/CourseModuleCtrl';
//import ContactDetailCtrl from '../components/formcontrols/contacts/ContactDetailCtrl';
//import ContactTransactionsCtrl from '../components/formcontrols/contacts/ContactTransactionsCtrl';
//import ContactCardsCtrl from '../components/formcontrols/contacts/ContactCardsCtrl';
//import ContactFormsCtrl from '../components/formcontrols/contacts/ContactFormsCtrl';
//import ContactFilesCtrl from '../components/formcontrols/contacts/ContactFilesCtrl';

function CourseDetail({ dialogType, handlerReturnValue }) {

    const [currentUser] = useGlobalState("userInfo");
    const navigate = useNavigate();
    let params = useParams();
    const [tempFileRecId, setFileRecId] = useState(helpers.getUUID());
    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [refreshId, setRefreshId] = useState(0);
    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    //const [listDataSource, setListDataSource] = useState([]);
    //const [listSelected, setListSelected] = useState([]);

    //const [tagsDataSource, setTagsDataSource] = useState([]);
    //const [tagsSelected, setTagsSelected] = useState([]);

    const [formData, setFormData] = useState({});

    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.courseId);
    //const [data, setData] = useState({ isCompany: false });
    //const [searchRefetch, setSearchRefetch] = useState(false);

    const [tabValue, setTabValue] = React.useState('Outline');

    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_detail_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('contact_detail_page', 'header_section', 'Course Details');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs: [] };
    if (params.courseId != "0") {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
        //if (helpers.permissionAllowed(currentUser.permissions, 'CRM-Contacts', ['allowDelete']) == true) {
            headerOptions.elements[0].elements.push(
                { type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } },
            )
        //}
    }
    //headerOptions.elements.push({ type: "button", text: getTranslation('contact_detail_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == "0") {
            data.outline = [];
            //set default data
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data);
                setFormData(data);
                //setListSelected(data.segmentedListMappings);
                //setTagsSelected(data.tagMappings);

                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));

                break;
            case "SaveForm":
                setPrimaryKeyValue(data.courseId);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/courcedetail/' + data.courceId); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteModule':
                var msg = 'Module has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); refreshPage(); } })
                setOpenDialog(true);
                break;
            case 'DeleteLesson':
                var msg = 'Lesson has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); refreshPage(); } })
                setOpenDialog(true);
                break;
            case 'DeleteRecord':
                var msg = 'Record has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/courses'); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "lms/course/getrecord?courseid=" + primaryKeyValue,
                    token: "fg1234",
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas, refreshId]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "lms_courses", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete course. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }

    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "lms/course/delete?courseid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }


    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    function handleRemoveItemConfirm(recType, recId) {


        switch (recType) {
            case 'Module':
                var msg = 'You are about to delete module and all lessons associated with it. <span style="font-weight:bold;">Are you sure</span>?';
                setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); removeModule(recId) } })
                setOpenDialog(true);
                break;
            case 'Text':
            case 'Video':
            case 'Audio':
                var msg = 'You are about to delete lesson. <span style="font-weight:bold;">Are you sure</span>?';
                setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); removeLesson(recId) } })
                setOpenDialog(true);
                break;
            case 'Quiz':
                break;
        }
    }

    function removeModule(moduleId) {
        BAFetchData({
            callName: "DeleteModule", method: "DELETE", url: "lms/module/delete?moduleid=" + moduleId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function removeLesson(lessonId) {
        BAFetchData({
            callName: "DeleteLesson", method: "DELETE", url: "lms/lesson/delete?lessonId=" + lessonId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }


    function openItem(recType,recId) {
        switch (recType) {
            case 'Module':
                openModuleForm(recId);
                break;
            case 'Text':
            case 'Video':
            case 'Audio':
                openLessonForm(null,recId);
                break;
            case 'Quiz':
                break;
        }
    }

    function openModuleForm(moduleId) {
        var msg = '';
        setGenericDialogSchema({ title: 'Module Detail', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <CourseModuleCtrl courseId={primaryKeyValue} moduleId={moduleId} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></CourseModuleCtrl>, body: { innerHtml: { __html: msg } }, fullWidth: true, maxWidth: "lg", hideBackdrop: false, ignoreBGClick: true, showCancelBtn: false, showOKBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('ModuleInfo', returnValues); } })
        setOpenDialog(true);
    }

    function openLessonForm(moduleId, lessonId) {

    }

    function refreshPage() {
        setFormData({});
        setRefreshId(helpers.getUUID());
    };

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "ModuleInfo":
                refreshPage();
                break;
            case "LessonInfo":
                refreshPage();
                break;
        }
    }

    function addModule() {

    }

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&

                <div className="fg-app-page-box" style={{ maxWidth: "1400px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-2">
                            <img src={formData.thumbnail || '/images/image_placeholder.webp'} alt="" style={{ width: "100%", maxWidth: "200px" }} />
                        </div>
                        <div className="col-sm-10">
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 150px", gridGap: "10px", width: "100%" }}>
                                <div>
                                    <div style={{ fontSize:"20px",fontWeight: "bold" }}>{formData.title}</div>
                                <div>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider',  }}>
                                        <Tabs onChange={handleTabChange}
                                            value={tabValue}
                                            variant="scroll"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                            aria-label="scroll force tabs example">

                                            <Tab label="Outline" value="Outline" style={{padding: "3px 24px 3px 0px", minWidth: "60px" }} />
                                            <Tab label="Students" value="Students" style={{ padding: "3px 24px 3px 0px", minWidth: "60px" }} />
                                        </Tabs>
                                    </Box>
                                    </div>
                                </div>
                                <div>
                                    <div>                                        
                                        <Button
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                            style={{ display:"none",backgroundColor: "#000000", color: "white", border: "1px solid #000000" }} 
                                            endIcon={<KeyboardArrowDownIcon />}
                                        >
                                            Add Content
                                        </Button>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button'
                                            }}
                                        >
                                            <MenuItem onClick={(e) => { handleClose(); openModuleForm(0); }}>Module</MenuItem>
                                            <MenuItem onClick={(e) => { handleClose(); openModuleForm(0); }}>Module</MenuItem>
                                            <MenuItem onClick={(e) => { handleClose(); openLessonForm(0,0); }}>Lesson</MenuItem>
                                            <MenuItem onClick={handleClose} style={{display:"none"} }>Quiz</MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {tabValue == 'Outline' ? <CourseOutlineCtrl courseId={primaryKeyValue} refreshId={refreshId} onHandleOpenItem={(recType, recId) => openItem(recType, recId)} onHandleRemoveItem={(recType, recId) => handleRemoveItemConfirm(recType, recId)} /> : null}
                                {tabValue == 'Students' ? <CourseStudentsCtrl courseId={primaryKeyValue} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>

    );
}

export default CourseDetail;