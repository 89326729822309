import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function PaymentInfoCtrl({ formData, setFormData, errorList, cardOnly }) {
    //const [formData, setFormDataObj] = useState(formData);

    function onValueChange(e, propertyName, value) {
        var tmpFormDataObj = { ...formData };
        tmpFormDataObj[propertyName] = value;
    
        if (propertyName == 'paymentMethod') {
            if (value == 'Card') {
                clearCheckInfo(tmpFormDataObj);
            } else if (value == 'Cash') {
                clearCardInfo(tmpFormDataObj);
                clearCheckInfo(tmpFormDataObj);
            } else if (value == 'Check') {
                clearCardInfo(tmpFormDataObj);
            } else {
                clearCardInfo(tmpFormDataObj);
                clearCheckInfo(tmpFormDataObj);
            }
        } else if (propertyName == 'creditCardNumber') {
            if (value == '') {
                tmpFormDataObj.paymentRef = null;
            } else {
                tmpFormDataObj.paymentRef = value.substr(value.length - 4);
            }            
        }

        //setFormDataObj(tmpFormDataObj);
        setFormData(tmpFormDataObj);
    }

    function clearCardInfo(tmpFormDataObj) {
        tmpFormDataObj.cardholderName = null;
        tmpFormDataObj.cardType = null;
        tmpFormDataObj.paymentRef = null;
        clearBillingAddress(tmpFormDataObj);
    }

    function clearCheckInfo(tmpFormDataObj) {
        tmpFormDataObj.cardType = null;
        tmpFormDataObj.paymentRef = null;
    }

    function clearBillingAddress(tmpFormDataObj) {
        tmpFormDataObj.cardType = null;
        tmpFormDataObj.paymentRef = null;
        tmpFormDataObj.billingAddress1 = null;
        tmpFormDataObj.billingAddress2 = null;
        tmpFormDataObj.billingCity = null;
        tmpFormDataObj.billingState = null;
        tmpFormDataObj.billingZip = null;
        tmpFormDataObj.billingCountryCode = null;
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-6">
                    <div className="segment">
                        <div className="segment-title" style={{fontSize: "14px", fontWeight: "500" }}>How will you be paying?</div>
                        <div>
                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                        <InputLabel id="paymenttype">Payment Method</InputLabel>
                        <Select
                            value={formData.paymentMethod}
                             onChange={(e) => { onValueChange(e, 'paymentMethod',e.target.value)}}
                            labelId="paymenttype"
                            id="demo-simple-select-standard"
                            label="Payment type"
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                                    <MenuItem value="Card">Card</MenuItem>
                                    {cardOnly == undefined ?
                                            <>
                            <MenuItem value="Check">Check</MenuItem>
                            <MenuItem value="Cash">Cash</MenuItem>
                                        </>
                                        : null}
                            <MenuItem value="ExistingPaymentMethod">Use Existing Payment Method</MenuItem>
                                </Select>
                                <formHelperText className="fg-helper-text">{errorList['paymentMethod'] ? <span style={{ color: "red" }}>{errorList['paymentMethod']}</span> : ''} </formHelperText>
                    </FormControl>
                            {formData.paymentMethod == 'Card' ?
                                <div>
                                    <TextField fullWidth label="Cardholder Name" variant="standard" style={{ marginBottom: "16px" }} helperText={errorList['cardholderName'] ? <span style={{ color: "red" }}>{errorList['cardholderName']}</span> : ''} value={formData.cardholderName || ''} onChange={(e) => { onValueChange(e, 'cardholderName', e.target.value) }} />
                                    {formData.posted == false ?
                                        <>
                                            <TextField fullWidth label="Card Number" variant="standard" type="number" inputProps={{ className: "fg-number-no-arrows" }}  style={{ marginBottom: "16px" }} helperText={errorList['creditCardNumber'] ? <span style={{ color: "red" }}>{errorList['creditCardNumber']}</span> : ''} value={formData.creditCardNumber || ''} onChange={(e) => { onValueChange(e, 'creditCardNumber', e.target.value) }} />
                                            <div className="row">
                                                <div className="col-sm-5">
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="expiremonth">Expire Month</InputLabel>
                                                        <Select
                                                            value={formData.creditCardExpireMonth}
                                                            onChange={(e) => { onValueChange(e, 'creditCardExpireMonth', e.target.value) }}
                                                            labelId="expiremonth"
                                                            id="demo-simple-select-standard"
                                                            label="Expire Month"
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            <MenuItem value="01">January</MenuItem>
                                                            <MenuItem value="02">February</MenuItem>
                                                            <MenuItem value="03">March</MenuItem>
                                                            <MenuItem value="04">April</MenuItem>
                                                            <MenuItem value="05">May</MenuItem>
                                                            <MenuItem value="06">June</MenuItem>
                                                            <MenuItem value="07">July</MenuItem>
                                                            <MenuItem value="08">August</MenuItem>
                                                            <MenuItem value="09">September</MenuItem>
                                                            <MenuItem value="10">October</MenuItem>
                                                            <MenuItem value="11">November</MenuItem>
                                                            <MenuItem value="12">December</MenuItem>
                                                        </Select>
                                                        <formHelperText className="fg-helper-text">{errorList['creditCardExpireMonth'] ? <span style={{ color: "red" }}>{errorList['creditCardExpireMonth']}</span> : ''} </formHelperText>
                                                    </FormControl>
                                                </div>
                                                <div className="col-sm-5">
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="expireyear">Expire Year</InputLabel>
                                                        <Select
                                                            value={formData.creditCardExpireYear}
                                                            onChange={(e) => { onValueChange(e, 'creditCardExpireYear', e.target.value) }}
                                                            labelId="expireyear"
                                                            id="demo-simple-select-standard"
                                                            label="Expire Year"
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            <MenuItem value="2024">2024</MenuItem>
                                                            <MenuItem value="2025">2025</MenuItem>
                                                            <MenuItem value="2026">2026</MenuItem>
                                                            <MenuItem value="2027">2027</MenuItem>
                                                            <MenuItem value="2028">2028</MenuItem>
                                                            <MenuItem value="2029">2029</MenuItem>
                                                            <MenuItem value="2030">2030</MenuItem>
                                                            <MenuItem value="2031">2031</MenuItem>
                                                            <MenuItem value="2032">2032</MenuItem>
                                                            <MenuItem value="2033">2033</MenuItem>
                                                            <MenuItem value="2034">2034</MenuItem>
                                                        </Select>
                                                        <formHelperText className="fg-helper-text">{errorList['creditCardExpireYear'] ? <span style={{ color: "red" }}>{errorList['creditCardExpireYear']}</span> : ''} </formHelperText>
                                                    </FormControl>
                                                </div>

                                                <div className="col-sm-2">
                                                    <TextField fullWidth label="CVC" variant="standard" type="number" inputProps={{ className: "fg-number-no-arrows" }} helperText={errorList['creditCardCvv'] ? <span style={{ color: "red" }}>{errorList['creditCardCvv']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.creditCardCvv || ''} onChange={(e) => { onValueChange(e, 'creditCardCvv', e.target.value) }} />
                                                </div>
                                            </div>
                                        </>
                                        : 
                                        <TextField fullWidth label="Credit Card Last 4 Digits" variant="standard" inputProps={{ readOnly: true, }} style={{ marginBottom: "16px" }} value={formData.paymentRef || ''} onChange={(e) => { onValueChange(e, 'paymentRef', e.target.value) }} />
                                    }
                                </div>
                                : null}  
                            {formData.paymentMethod == 'Check' ?
                        <div>
                                    <TextField fullWidth label="Check #" variant="standard" style={{ marginBottom: "16px" }} helperText={errorList['paymentRef'] ? <span style={{ color: "red" }}>{errorList['paymentRef']}</span> : ''} value={formData.paymentRef || ''} onChange={(e) => { onValueChange(e, 'paymentRef', e.target.value) }} />
                        </div>
                                : null}
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    {formData.paymentMethod == 'Card' ?
                        <div className="segment">
                            <div className="segment-title" style={{ fontSize: "14px", fontWeight: "500" }}>Billing address associated with payment method (No PO Box)</div>
                            <div>
                                <div className="row">
                                    <div className="col-sm-7">
                                        <TextField fullWidth label="Street Address" variant="standard" style={{ marginBottom: "16px" }} helperText={errorList['billingAddress1'] ? <span style={{ color: "red" }}>{errorList['billingAddress1']}</span> : ''} value={formData.billingAddress1 || ''} onChange={(e) => { onValueChange(e, 'billingAddress1', e.target.value) }} />
                                    </div>
                                    <div className="col-sm-5">
                                        <TextField fullWidth label="Apt or Suite#" variant="standard" helperText={errorList['billingAddress2'] ? <span style={{ color: "red" }}>{errorList['billingAddress2']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.billingAddress2 || ''} onChange={(e) => { onValueChange(e, 'billingAddress2', e.target.value) }} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextField fullWidth label="City" variant="standard" helperText={errorList['billingCity'] ? <span style={{ color: "red" }}>{errorList['billingCity']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.billingCity || ''} onChange={(e) => { onValueChange(e, 'billingCity', e.target.value) }} />
                                    </div>
                                    <div className="col-sm-3">
                                        <TextField fullWidth label="State/Province" variant="standard" style={{ marginBottom: "16px" }} helperText={errorList['billingState'] ? <span style={{ color: "red" }}>{errorList['billingState']}</span> : ''} value={formData.billingState || ''} onChange={(e) => { onValueChange(e, 'billingState', e.target.value) }} />
                                    </div>
                                    <div className="col-sm-3">
                                        <TextField fullWidth label="Zip/Postal" variant="standard" style={{ marginBottom: "16px" }} helperText={errorList['billingZip'] ? <span style={{ color: "red" }}>{errorList['billingZip']}</span> : ''} value={formData.billingZip || ''} onChange={(e) => { onValueChange(e, 'billingZip', e.target.value) }} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <TextField fullWidth label="Country Code" variant="standard" style={{ marginBottom: "16px" }} inputProps={{ maxLength: 2 }} value={formData.billingCountryCode || ''} onChange={(e) => { onValueChange(e, 'billingCountryCode', e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </>
    );
}

export default PaymentInfoCtrl;
