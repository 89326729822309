import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { BAFetchData } from '../../../customhooks/useBAFetch';
import Forms from '../../../pages/Forms';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import LookupFieldCtrl from '../../formcontrols/LookupFieldCtrl';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';

function AOFormTriggerRuleCtrl({ phraseId, objectSettings, onChange }) {

    const [objectSettingsObj, setObjectSettingsObj] = useState(objectSettings);
    const [formInfo, setFormInfo] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});   
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});
    const [errorList, setErrorList] = useState({});
    const [triggerPhrase, setTriggerPhrase] = useState({});

    function handleOnRemove() {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings = {};
        setObjectSettingsObj(tmpObjectSettingsObj);

        setFormInfo(null);
        onChange(tmpObjectSettingsObj);
    }

    function openFormSearch(actionType) {
        var msg = '';
        setGenericDialogSchema({ title: 'Form Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <Forms handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></Forms>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('FormSearch', returnValues); } })
        setOpenDialog(true);
    }


    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "FormSearch":
                var tmpObjectSettingsObj = { ...objectSettingsObj };
                tmpObjectSettingsObj.settings['formId'] = returnValues;
                setObjectSettingsObj(tmpObjectSettingsObj);
                onChange(buildTriggerRule(tmpObjectSettingsObj), tmpObjectSettingsObj);
                getFormInfo(returnValues);
                break;
        }
    }


    function getFormInfo(formId) {
        BAFetchData({
            callName: "GetFormInfo",
            method: "GET",
            url: "marketing/form/getrecord?formid=" + formId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getTriggerPhrase(phraseId) {
        BAFetchData({
            callName: "GetTriggerPhrase",
            method: "GET",
            url: "automation/automationtriggerphrase/getrecord?phraseId=" + phraseId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function buildTriggerRule(tmpObjectSettingsObj) {
        var triggerRule = [
            {
                "tableName": "Web Form",
                "operation": [
                    {
                        "action": "INSERT"
                    },
                    {
                        "action": "UPDATE"
                    }
                ],
                "jsonFieldSettings": [

                ],
                "filtering": [
                    {
                        "LogicalOperator": "and",
                        "ConditionGroups": [
                            {
                                "LogicalOperator": "and",
                                "Conditions": [
                                    {
                                        "Name": "formId",
                                        "ConditionOperator": "EQUAL",
                                        "Value": tmpObjectSettingsObj.settings['formId'],
                                        "DataType": "String",
                                        "State": "after"
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "returnDataPackage": "WebFormInfo",
                "pkAlias": "formrecid",
                "returnPackageRules": [

                ]
            }
        ]
        return triggerRule;
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetFormInfo":
                setFormInfo(data)
                break;
            case "GetTriggerPhrase":
                setTriggerPhrase(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);
    }, []);

    useEffect(() => {
        if (JSON.stringify(objectSettings.settings) != "{}") {
            if (objectSettings.settings.formId != null) {
                getFormInfo(objectSettings.settings.formId);
            }
        }
        if (phraseId != undefined) {
            getTriggerPhrase(phraseId);
        }  
        setObjectSettingsObj(objectSettings);
    }, [objectSettings]);

    return (
        <>
            <div>
                <LookupFieldCtrl dataPackage={formInfo || ''} textFieldName="formName" valueFieldName="formId" label="Form" helperText={errorList['formId'] ? <span style={{ color: "red" }}>{errorList['formId']}</span> : ''} onClick={() => { openFormSearch('lookup'); }} onRemove={() => { handleOnRemove(); }}></LookupFieldCtrl>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default AOFormTriggerRuleCtrl;
