import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import { BAFetchData } from '../../../customhooks/useBAFetch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import SegmentedLists from '../../../pages/SegmentedLists';
import DeleteIcon from '@mui/icons-material/Delete';
import Tags from '../../../pages/Tags';
import DataPackageAliasListCtrl from '../DataPackageAliasListCtrl';


function AOCreateBusinessAccountCtrl({ objectSettings, onChange, dataPackageList }) {

    const [objectSettingsObj, setObjectSettingsObj] = useState(objectSettings);
    const [segmentedListInfo, setSegmentedListInfo] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    function onValueChange(propertyName, value) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings[propertyName] = value;
        updateSummary(tmpObjectSettingsObj);
        setObjectSettingsObj(tmpObjectSettingsObj);
        onChange(tmpObjectSettingsObj);
    }

    function updateSummary(tmpObjectSettingsObj) {
        var summary = "Create Business Account";
        tmpObjectSettingsObj.settings.summary = summary;
    }

    function newSetting() {
        //return { "action": "Add", "contactId": null, list: [], "summary": "" };
        return { "contactId": null, "detailId": null, productId: null, variantId: null, "summary": "" };
    }

    function openTagSearch(actionType) {
        var msg = '';
        setGenericDialogSchema({ title: 'Tag Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <Tags handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></Tags>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('TagSearch', returnValues); } })
        setOpenDialog(true);
    }


    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "TagSearch":
                var tmpObjectSettingsObj = { ...objectSettingsObj };
                tmpObjectSettingsObj.settings['tagId'] = returnValues.tagId;
                setObjectSettingsObj(tmpObjectSettingsObj);
                onChange(tmpObjectSettingsObj);
                getTagInfo(returnValues.tagId);
                break;
        }
    }


    function getTagInfo(tagId) {
        BAFetchData({
            callName: "GetTagInfo",
            method: "GET",
            url: "crm/tag/getrecord?tagid=" + tagId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addItem(data) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings.list.push({ "tagId": data.tagId, "tagName": data.tagName });
        updateSummary(tmpObjectSettingsObj);
        setObjectSettingsObj(tmpObjectSettingsObj);
        onChange(tmpObjectSettingsObj);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetTagInfo":
                addItem(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleRemoveItem(e, index) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings.list.splice(index, 1);
        updateSummary(tmpObjectSettingsObj);
        setObjectSettingsObj(tmpObjectSettingsObj);
        onChange(tmpObjectSettingsObj);
    }

    useEffect(() => {
        setShowLoader(false);
    }, []);

    useEffect(() => {
        if (JSON.stringify(objectSettings.settings) == "{}") {
            objectSettings.settings = newSetting();
        }
        setObjectSettingsObj(objectSettings);
    }, [objectSettings]);

    return (
        <>
            {JSON.stringify(objectSettings.settings) != "{}" &&
                <div style={{ display: "grid", gridTemplateColumns: "250px 1fr", gridGap: "40px" }}>
                    <div style={{ border: "1px solid #c0c0c0", padding: "10px", borderRadius: "12px" }}>
                        <DataPackageAliasListCtrl dataPackageList={dataPackageList} />
                    </div>
                    <div>
                        <div>
                            <div style={{ fontWeight: "bold", marginBottom: "5px" }}>This contact...</div>
                            <TextField fullWidth autoComplete='off' label="This Contact" variant="outlined" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: objectSettingsObj.settings.contactId }} value={objectSettingsObj.settings.contactId || ''} onChange={(e) => { onValueChange('contactId', e.target.value); }} />
                        </div>
                        <div>
                            <div style={{ fontWeight: "bold", marginBottom: "5px" }}>Detail Id</div>
                            <TextField fullWidth autoComplete='off' label="DetailId #" variant="outlined" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: objectSettingsObj.settings.detailId }} value={objectSettingsObj.settings.detailId || ''} onChange={(e) => { onValueChange('detailId', e.target.value); }} />
                        </div>
                    </div>
                </div>
            }

            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default AOCreateBusinessAccountCtrl;
