import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import PaymentCtrl from "../payment/PaymentCtrl";

function BookingConfirmCtrl({ payInfo, showPaymentMethod }){

    //useEffect(() => {
    //    if (selectedDate != oldSelectedDate){
    //        setSelectedSlot(null);
    //        setOldSelectedDate(selectedDate);
    //    }        
    //},[selectedDate])


    return (
        <>
            <div>
                Booking Confirmation
            </div>
        </>
    )
}

export default BookingConfirmCtrl;