import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import ImageFieldCtrl from '../components/formcontrols/ImageFieldCtrl';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function PageSettings({ dialogType,pageInfo, setPageInfo, handlerReturnValue}) {

    const [pageInfoObj, setPageInfoObj] = useState(pageInfo);
    //const styleSheetRef = useRef();
    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    //const [formData, setFormData] = useState({});


    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.siteId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "site_settings_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('site_settings_page', 'header_section', 'Page Settings Editor');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == 0) {
            //set default data
            data.active = true;
        }        
    }

    function handleOnValueChange(e, propertyName, value) {
        var tmpPageInfoObj = {...pageInfoObj}
        tmpPageInfoObj[propertyName] = value;
        setPageInfoObj(tmpPageInfoObj);
    }

    function handleOnImageChange(propertyName, value) {
        var tmpPageInfoObj = { ...pageInfoObj }
        tmpPageInfoObj[propertyName] = value;
        if (handlerReturnValue) {
            handlerReturnValue(tmpPageInfoObj);
        }
        setPageInfoObj(tmpPageInfoObj);
    }

    function handleOnCheckboxChange(e, propertyName, value) {
        var tmpPageInfoObj = { ...pageInfoObj }
        tmpPageInfoObj[propertyName] = value;
        if (propertyName == 'dynamicSeo') {
            if (value == true) {
                tmpPageInfoObj.seoTitle = '';
                tmpPageInfoObj.seoDescription = '';
                tmpPageInfoObj.seoImage = '';
                tmpPageInfoObj.seoKeywords = '';
            }
        }
        if (handlerReturnValue) {
            handlerReturnValue(tmpPageInfoObj);
        }
        setPageInfoObj(tmpPageInfoObj);
    }
    
    

    function handleOnBlur(e, propertyName, value) {
        var tmpPageInfoObj = { ...pageInfoObj }
        tmpPageInfoObj[propertyName] = value;
        if (handlerReturnValue) {
            handlerReturnValue(tmpPageInfoObj);
        }
        //if (setPageInfo) {
        //    setPageInfo(tmpPageInfoObj);
        //}
    }

    //function handleOnCssChange(e, value) {
    //    var tmpPageInfoObj = { ...pageInfoObj };
    //    if (handlerReturnValue) {
    //        handlerReturnValue(value);
    //    }
    //}

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        setPageInfoObj(pageInfo);
    }, [pageInfo]);
    
    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {pageInfoObj == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {pageInfoObj != null &&
                
                <div className="fg-app-page-box" style={{}}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title" style={{ marginLeft:"-12px" }}>SEO Information</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12" style={{ marginLeft: "-10px", marginBottom: "20px" }}>
                                                    <FormControlLabel style={{ marginBottom: "0px" }}
                                                        control={
                                                            <Switch checked={pageInfoObj.dynamicSeo} onChange={(e) => { handleOnCheckboxChange(e, 'dynamicSeo', e.target.checked); }} name="gilad" />
                                                        }
                                                        label="Use Dynamic SEO"
                                                    />
                                                    <div>**Recommended for blog view pages, product detail pages or other pages where the SEO information is should be dynamic.</div>
                                                </div>
                                                {pageInfoObj.dynamicSeo == false ?
                                                    <>
                                                        <div className="col-sm-12" style={{ margin: "0px", padding: "0px" }}>
                                                            <TextField fullWidth label="SEO Title" variant="standard" style={{ marginBottom: "16px" }} value={pageInfoObj.seoTitle || ''} InputLabelProps={{ shrink: pageInfoObj.seoTitle }} onChange={(e) => { handleOnValueChange(e, 'seoTitle', e.target.value); }} onBlur={(e) => { handleOnBlur(e, 'seoTitle', e.target.value); }} />
                                                        </div>
                                                        <div className="col-sm-12" style={{ margin: "0px", padding: "0px" }}>
                                                            <TextField multiline rows={4} fullWidth label="SEO Description" variant="standard" style={{ marginBottom: "16px" }} value={pageInfoObj.seoDescription || ''} InputLabelProps={{ shrink: pageInfoObj.seoDescription }} onChange={(e) => { handleOnValueChange(e, 'seoDescription', e.target.value); }} onBlur={(e) => { handleOnBlur(e, 'seoDescription', e.target.value); }} />
                                                        </div>
                                                        <div className="col-sm-12" style={{ margin: "0px", padding: "0px" }}>
                                                            <ImageFieldCtrl imgSrc={pageInfoObj.seoImage || ''} label="SEO Image" imgType="inline" returnFullPath={true} onChange={(imageValue) => { handleOnImageChange('seoImage', imageValue); }} onBlur={(e) => { handleOnBlur(e, 'seoImage', e.target.value); }}></ImageFieldCtrl>
                                                        </div>
                                                        <div className="col-sm-12" style={{ margin: "20px 0px 0px 0px", padding: "0px" }}>
                                                            <TextField multiline rows={4} fullWidth label="SEO Keywords" variant="standard" style={{ marginBottom: "16px" }} value={pageInfoObj.seoKeywords || ''} InputLabelProps={{ shrink: pageInfoObj.seoKeywords }} onChange={(e) => { handleOnValueChange(e, 'seoKeywords', e.target.value); }} onBlur={(e) => { handleOnBlur(e, 'seoKeywords', e.target.value); }} />
                                                        </div>
                                                    </>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title" style={{ marginLeft: "-12px" }}>Additional Scripts</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12" style={{ margin: "0px", padding: "0px" }}>
                                                    <TextField multiline rows={8} fullWidth label="Head Tag Scripts" variant="standard" style={{ marginBottom: "16px" }} value={pageInfoObj.headTagScripts || ''} InputLabelProps={{ shrink: pageInfoObj.headTagScripts }} onChange={(e) => { handleOnValueChange(e, 'headTagScripts', e.target.value); }} onBlur={(e) => { handleOnBlur(e, 'headTagScripts', e.target.value); }} />
                                                </div>
                                                <div className="col-sm-12" style={{ margin: "20px 0px 0px 0px", padding: "0px" }}>
                                                    <TextField multiline rows={8} fullWidth label="Bogy Tag Scripts" variant="standard" style={{ marginBottom: "16px" }} value={pageInfoObj.bodyTagScripts || ''} InputLabelProps={{ shrink: pageInfoObj.bodyTagScripts }} onChange={(e) => { handleOnValueChange(e, 'bodyTagScripts', e.target.value); }} onBlur={(e) => { handleOnBlur(e, 'seoKeywords', e.target.value); }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title" style={{ marginLeft: "-12px" }}>Tracking Events</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12" style={{ margin: "0px", padding: "0px" }}>
                                                    <TextField multiline rows={8} fullWidth label="Page Loaded Tracking Events" variant="standard" style={{ marginBottom: "16px" }} value={pageInfoObj.pageLoadedTrackingEvents || ''} InputLabelProps={{ shrink: pageInfoObj.pageLoadedTrackingEvents }} onChange={(e) => { handleOnValueChange(e, 'pageLoadedTrackingEvents', e.target.value); }} onBlur={(e) => { handleOnBlur(e, 'pageLoadedTrackingEvents', e.target.value); }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title" style={{ marginLeft: "-12px" }}>Security</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12" style={{marginLeft:"-10px"} }>
                                                    <FormControlLabel style={{ marginBottom: "0px" }}
                                                        control={
                                                            <Switch checked={pageInfoObj.loginEnabled} onChange={(e) => { handleOnCheckboxChange(e, 'loginEnabled', e.target.checked); }} name="gilad" />
                                                            }
                                                            label="Login Enabled"
                                                        />
                                                </div>
                                                <div className="col-sm-12" style={{ margin: "20px 0px 0px 0px", padding: "0px" }}>
                                                    <TextField fullWidth label="Login URL" variant="standard" style={{ marginBottom: "16px" }} value={pageInfoObj.loginUrl || ''} InputLabelProps={{ shrink: pageInfoObj.loginUrl }} onChange={(e) => { handleOnValueChange(e, 'loginUrl', e.target.value); }} onBlur={(e) => { handleOnBlur(e, 'loginUrl', e.target.value); }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title" style={{ marginLeft: "-12px" }}>Popup / Index</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12" style={{ marginLeft: "-10px" }}>
                                                    <FormControlLabel style={{ marginBottom: "0px" }}
                                                        control={
                                                            <Switch checked={pageInfoObj.containsPopup} onChange={(e) => { handleOnCheckboxChange(e, 'containsPopup', e.target.checked); }} name="gilad" />
                                                        }
                                                        label="Contains Popup"
                                                    />
                                                </div>
                                                <div className="col-sm-12" style={{ marginLeft: "-10px" }}>
                                                    <FormControlLabel style={{ marginBottom: "0px" }}
                                                        control={
                                                            <Switch checked={pageInfoObj.isPopup} onChange={(e) => { handleOnCheckboxChange(e, 'isPopup', e.target.checked); }} name="gilad" />
                                                        }
                                                        label="Is Popup"
                                                    />
                                                </div>
                                                <div className="col-sm-12" style={{ marginLeft: "-10px" }}>
                                                    <FormControlLabel style={{ marginBottom: "0px" }}
                                                        control={
                                                            <Switch checked={pageInfoObj.doNotIndex} onChange={(e) => { handleOnCheckboxChange(e, 'doNotIndex', e.target.checked); }} name="gilad" />
                                                        }
                                                        label="Do Not Index"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    //function handleRecordDeleteConfirm() {
    //    var msg = 'You are about to delete site. <span style="font-weight:bold;">Are you sure</span>?';
    //    setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
    //    setOpenDialog(true);
    //}


    //function deleteRecord() {
    //    BAFetchData({
    //        callName: "DeleteRecord", method: "DELETE", url: "cms/sites/delete?siteid=" + primaryKeyValue,
    //        token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
    //    });
    //}

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    //function handleSaveForm() {
    //    //perform form data validation
    //    setErrorList([]);

    //    //Set just in time values before save
    //    addJustInTimeData(formData);

    //    if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
    //        if (isCustomFormValidationValid() == true) {
    //            saveForm();
    //        }
    //    } else {
    //        var msg = 'There are form validation errors.';
    //        setGenericDialogSchema({ title: 'Validation Errors', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
    //        setOpenDialog(true);
    //    }                
    //}

    //function saveForm() {
    //    if (primaryKeyValue == '0') {
    //        BAFetchData({
    //            callName: "SaveForm", method: "POST", url: "cms/sites/add",
    //            token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
    //        });
    //    } else {
    //        BAFetchData({
    //            callName: "SaveForm", method: "PUT", url: "cms/sites/update",
    //            token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
    //        });
    //    }
    //}
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        //if (formData.isCompany == false) {
        //    if (formData.firstName == null && formData.lastName == null && formData.businessName == null) {
        //        isValid = false;
        //        tempErrorList['firstName'] = "First Name, Last Name or Business Name is required.";
        //    }
        //}
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default PageSettings;