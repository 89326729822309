import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { AutomationBrokerContext } from './AutomationBuilderCtrl';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import AutoItemSettingsCtrl from './AutoItemSettingsCtrl';
import helpers from '../../helpers/Helpers';

function AutoItemCtrl({ autoItemObj }) {
    const { elements, setElements, formData, setFormData, showForecast, itemsLoaded, setItemsLoaded, imageLoadedCount, setImageLoadedCount, imgCountRef, handleOnDragFromCanvas, selectedItems, setSelectedItems, shiftKeyPressedRef, controlKeyPressedRef, mousePosRef, popupOpenRef, switchMode } = useContext(AutomationBrokerContext);
    const d = new Date();
    const [autoItemData, setAutoItemData] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    //const handleOnImgLoad = useCallback((e) => {
    //    var count = imgCountRef.current;
    //    count = count + 1;
    //    imgCountRef.current = count;
    //    //setImageLoadedCount(count);
    //    //console.log('image loaded ' + count);
    //    if (imgCountRef.current == getTotalNonImgElements()) {
    //        //console.log('load connectors')
    //        setItemsLoaded('loaded');
    //    }
    //},[])

    function handleOnImgLoad(e) {
        var count = imgCountRef.current;
        count = count + 1;
        imgCountRef.current = count;
        //setImageLoadedCount(count);
        //console.log('image loaded ' + count);
        if (imgCountRef.current == getTotalNonImgElements()) {
            //console.log('load connectors')
            setItemsLoaded('loaded');
        }
    }

    function getTotalNonImgElements() {
        var total = 0;
        for (const key in elements) {
            if (elements[key].autoType != 'Text') {
                total++;
            }
        }
        return total;
    }

    function getImgWidth() {
        if (autoItemData.autoType == "Page") {
            return "100%";
        }else {
            return "50px";
        }        
    }

    function handleAOItemDblClick(e, id) {
        e.preventDefault();
        e.stopPropagation();
        popupOpenRef.current = true;
        
        var msg = '';
        setGenericDialogSchema({ title: 'Automation Object Settings', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <AutoItemSettingsCtrl handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true} autoItemObj={autoItemData}></AutoItemSettingsCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", hideBackdrop: true, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog(); popupOpenRef.current = false; }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('AutoItemEdit', returnValues); } })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValue) {
        popupOpenRef.current = false;

        var tmpAutoItemData = { ...autoItemData };
        tmpAutoItemData = returnValue.autoItemData;
        setAutoItemData(returnValue.autoItemData);

        var tmpElements = { ...elements };
        tmpElements[autoItemData.id] = returnValue.autoItemData;
        setElements(tmpElements);
        if (returnValue.action == "OpenDesigner") {            
            switchMode(returnValue.autoItemData.id);
        }        
    }

    function handleAOItemClick(e, id) {
        e.preventDefault();
        e.stopPropagation();
        if (shiftKeyPressedRef.current == true && controlKeyPressedRef.current == false) {
            console.log('Click with SHIFT key pressed')
            if (selectedItems.length > 0) {              
                for (var i = 0; i < selectedItems.length; i++) {
                    linkFromSource(selectedItems[i], id, 'yes');
                }

            }
            setSelectedItems([id]);
        } else if (controlKeyPressedRef.current == true && shiftKeyPressedRef.current == false) {
            console.log('Click with CONTROL key pressed')
            var tmpSelectedItems = [...selectedItems];
            var found = false;
            for (var i = 0; i < tmpSelectedItems.length; i++) {
                if (tmpSelectedItems[i] == id) {
                    found = true;
                    tmpSelectedItems.splice(i, 1);
                    break;
                }
            }
            if (found == false) {
                tmpSelectedItems.push(id);
            }            
            setSelectedItems(tmpSelectedItems);
        } else if (controlKeyPressedRef.current == true && shiftKeyPressedRef.current == true) {
            console.log('Click with CONTROL key & SHIFT key pressed')
            if (selectedItems.length > 0) {
                linkFromSource(selectedItems[0], id, 'no');
            }
            setSelectedItems([id]);
        } else {
            setSelectedItems([id]);
        }        
    }

    function linkFromSource(sourceId, destId, branch) {
        var tmpElements = { ...elements };

        var sourceElementObj = elements[sourceId];
        
        var sourceElHeight = document.getElementById(sourceId).offsetHeight;
        var sourceElWidth = document.getElementById(sourceId).offsetWidth;
        var sourceCenterPos = { top: sourceElementObj.position.top + (sourceElHeight / 2), left: sourceElementObj.position.left + (sourceElWidth / 2) };

        var sourceLeft = sourceElementObj.position.left;
        var sourceTop = sourceElementObj.position.top;
        var sourceBottom = sourceElementObj.position.top + sourceElHeight;
        var sourceRight = sourceElementObj.position.left + sourceElWidth;

        var destElementObj = elements[destId];
        var destElHeight = document.getElementById(destId).offsetHeight;
        var destElWidth = document.getElementById(destId).offsetWidth;
        var destCenterPos = { top: destElementObj.position.top + (destElHeight / 2), left: destElementObj.position.left + (destElWidth / 2) };
        var destLeft = destElementObj.position.left;
        var destTop = destElementObj.position.top;
        var destBottom = destElementObj.position.top + destElHeight;
        var destRight = destElementObj.position.left + destElWidth;


        //check center first Zone 13
        if (destCenterPos.left >= sourceLeft && destCenterPos.left <= sourceRight && destCenterPos.top >= sourceTop && destCenterPos.top <= sourceBottom) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-2', lineType: 'solid' };
        //check if dest is to the right of source and the center top is betwee source top and source bottom
        } else if (destLeft >= sourceRight && destCenterPos.top >= sourceTop && destCenterPos.top <= sourceBottom) { 
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-2', lineType: 'solid' };
        //check if dest is to the right of source and the center top is betwee source top and source bottom
        } else if (destRight <= sourceLeft && destCenterPos.top >= sourceTop && destCenterPos.top <= sourceBottom) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'r-2', lineType: 'solid' };
        //Check when dest top is above source top and dest bottom is below source bottom
        } else if (destLeft >= sourceRight && destTop <= sourceTop && destBottom >= sourceBottom) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-2', lineType: 'solid' };
            //Zone 1
        }else if (destRight < (sourceLeft - 50) && destBottom < (sourceTop - 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'r-4', lineType: 'solid' };
            //Zone 2
        } else if (destRight >= (sourceLeft - 50) && destRight < sourceLeft && destBottom <= (sourceTop - 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'b-4', lineType: 'solid' };
            //Zone 3
        } else if (destRight >= sourceLeft && destRight <= sourceRight && destBottom <= (sourceTop - 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'b-2', lineType: 'solid' };
            //Zone 3
        } else if (destLeft >= sourceLeft && destLeft <= sourceRight && destBottom <= (sourceTop - 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'b-2', lineType: 'solid' };
            //Zone 4
        } else if (destLeft >= sourceRight && destLeft <= (sourceRight + 50) && destBottom <= (sourceTop - 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'b-0', lineType: 'solid' };
            //Zone 5
        } else if (destLeft >= (sourceRight + 50) && destBottom <= (sourceTop - 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-4', lineType: 'solid' };
            //Zone 6
        } else if (destRight <= (sourceLeft - 50) && destBottom >= (sourceTop - 50) && destBottom <= sourceTop) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'r-4', lineType: 'solid' };
            //Zone 7
        } else if (destRight >= (sourceLeft - 50) && destRight <= sourceLeft && destBottom > (sourceTop - 50) && destBottom <= sourceTop) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'r-4', lineType: 'solid' };
            //Zone 8
        } else if (destRight >= sourceLeft && destRight <= sourceRight && destBottom >= (sourceTop - 50) && destBottom <= sourceTop) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'b-2', lineType: 'solid' };
            //Zone 8
        } else if (destLeft >= sourceLeft && destLeft <= sourceRight && destBottom >= (sourceTop - 50) && destBottom <= sourceTop) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'b-2', lineType: 'solid' };
            //Zone 9
        } else if (destLeft >= sourceRight && destLeft <= (sourceRight + 50) && destBottom >= (sourceTop - 50) && destBottom <= sourceTop) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-4', lineType: 'solid' };
            //Zone 10
        } else if (destLeft >= (sourceRight + 50) && destBottom >= (sourceTop - 50) && destBottom <= sourceTop) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-4', lineType: 'solid' };
            //Zone 11
        } else if (destRight <= (sourceLeft - 50) && destBottom >= sourceTop && destBottom <= sourceBottom) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'r-2', lineType: 'solid' };
            //Zone 12
        } else if (destRight >= (sourceLeft - 50) && destRight <= sourceLeft && destBottom >= sourceTop && destBottom <= sourceBottom) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'r-2', lineType: 'solid' };
            //Zone 14
        } else if (destLeft >= sourceRight && destLeft <= (sourceRight + 50) && destBottom >= sourceTop && destBottom <= sourceBottom) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-2', lineType: 'solid' };
            //Zone 15
        } else if (destLeft >= (sourceRight + 50) && destBottom >= sourceTop && destBottom <= sourceBottom) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-2', lineType: 'solid' };
            //Zone 16
        } else if (destRight <= (sourceLeft - 50) && destBottom >= sourceBottom && destBottom <= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'r-0', lineType: 'solid' };
            //Zone 17
        } else if (destRight >= (sourceLeft - 50) && destRight <= sourceLeft && destBottom >= sourceBottom && destBottom <= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'r-0', lineType: 'solid' };
            //Zone 18
        } else if (destRight >= sourceLeft && destRight <= sourceRight && destBottom >= sourceBottom && destBottom <= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 't-2', lineType: 'solid' };
            //Zone 18
        } else if (destLeft >= sourceLeft && destLeft <= sourceRight && destBottom >= sourceBottom && destBottom <= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 't-2', lineType: 'solid' };
            //Zone 19
        } else if (destLeft >= sourceRight && destLeft <= (sourceRight + 50) && destBottom >= sourceBottom && destBottom <= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-0', lineType: 'solid' };
            //Zone 20
        } else if (destLeft >= (sourceRight + 50) && destBottom >= sourceBottom && destBottom <= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-0', lineType: 'solid' };
            //Zone 21
        } else if (destRight <= (sourceLeft - 50) && destBottom >= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'r-0', lineType: 'solid' };
            //Zone 22
        } else if (destRight >= (sourceLeft - 50) && destRight <= sourceLeft && destBottom >= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 't-4', lineType: 'solid' };
            //Zone 23
        } else if (destRight >= sourceLeft && destRight <= sourceRight && destBottom >= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 't-2', lineType: 'solid' };
            //Zone 23
        } else if (destLeft >= sourceLeft && destLeft <= sourceRight && destBottom >= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 't-2', lineType: 'solid' };
            //Zone 24
        } else if (destLeft >= sourceRight && destLeft <= (sourceRight + 50) && destBottom >= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 't-0', lineType: 'solid' };
            //Zone 25
        } else if (destLeft >= (sourceRight + 50) && destBottom >= (sourceBottom + 50)) {
            tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-0', lineType: 'solid' };
        }


        //tmpElements[sourceId].outputs[branch][destId] = { connectPosition: 'l-2', lineType: 'solid' };
        tmpElements[destId].inputs.push(sourceId);
        setElements((...elements) => { return tmpElements });
        //tmpElements[sourceId].ouputs = { yes: { "autoitem_2222": { connectPosition: 'l-2', lineType: 'solid' } }, no: {} }
    }

    function renderBackground(autoItemData) {
        var hideBackClass = '';
        if (autoItemData.autoType == 'Text') {
            if (autoItemData.typography.hideBackground == 'true') {
                hideBackClass = ' fgao-auto-item-noback ';
            }            
        }
        return hideBackClass;
    }

    useEffect(() => {
        if (autoItemData == null) {
            setAutoItemData(autoItemObj);     
        } else if (autoItemData != null) {
            if (autoItemObj.id != autoItemData.id) {
                setAutoItemData(autoItemObj);
            }        
        }
    }, [autoItemObj]);
    return (
        <>
            {autoItemData != null ?
                <div id={autoItemData.id} className={"fgao-auto-item " + renderBackground(autoItemData) + (selectedItems.includes(String(autoItemData.id)) ? " fgao-auto-item-selected " : '')} draggable onDragStart={(e) => { handleOnDragFromCanvas(e, autoItemData.id, autoItemData.autoType); }} style={{ top: autoItemData.position.top, left: autoItemData.position.left, width:autoItemData.width + "px" }} onClick={(e) => { handleAOItemClick(e, autoItemData.id); }} onDoubleClick={(e) => { handleAOItemDblClick(e, autoItemData.id); }} >
                    {autoItemData.autoType != "Text" ?
                        <div style={{ pointerEvents: "none" }}>
                            <div className="fgao-auto-item-text" style={{ lineHeight: "110%", fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: autoItemData.caption }}></div>
                            <div style={{ pointerEvents: "none" }}>
                                <img className="fgao-auto-item-icon" src={"images/" + autoItemData.icon + "?v=" + d.getMilliseconds()} style={{ marginTop: "10px", width: getImgWidth() }} onLoad={(e) => { handleOnImgLoad(e); }} />
                            </div>
                            {showForecast == true ?
                                <div style={{ color: "#FC4B6C", fontSize:"12px" }}>{autoItemData.autoType == 'TrafficSource' ? (autoItemData.forecast.goalMetric.reach || '0') : (autoItemData.forecast.goalMetric.conversions || '0')}</div>
                                : null}
                        </div>                        
                        :
                        <div style={{ pointerEvents: "none" }}>
                            <div className="fgao-auto-item-text" style={{ lineHeight: "110%", fontSize: autoItemData.typography.fontSize + "px", fontWeight: autoItemData.typography.fontWeight, textAlign: autoItemData.typography.alignText }} dangerouslySetInnerHTML={{ __html: autoItemData.caption }}></div>
                        </div>
                    }

                </div>
                : null}
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default AutoItemCtrl;
