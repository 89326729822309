import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import LoginForm from '../components/loginforms/LoginForm';
import NewsReel from '../components/news/NewsReel';
import { fetchData } from '../customhooks/useFetch';
import { BAFetchData } from '../customhooks/useBAFetch';
import { setGlobalState } from '../globalstates/GState';
function Login() {

    const [formData, setFormData] = useState({ userName: null, password: null });
    const [errorList, setErrorList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            
    const [showLoader, setShowLoader] = useState(false);
    const [loaderSchema, setLoaderSchema] = useState({});
    const navigate = useNavigate();
    
    const [wlInfo, setWlInfo] = useState(null);


    async function getWLInfo() {
        var path = document.location.href;
        var domain = window.location.protocol + '//' + window.location.host;
        console.log('domain 1', domain);
        if (path.indexOf('localhost:3000') > -1) {
            domain = 'whitelabel1';
        } else if (path.indexOf('localhost') > -1) {
            domain = document.getElementById('base').href.replace('https://localhost', '').replace('http://localhost', '');
            console.log('domain 2', domain);
            domain = domain.replaceAll('\/', '');
        }
        console.log('domain 3', domain);

        var tmpWlInfo = await BAFetchData({
            callName: "GetData",
            method: "GET",
            url: "security/businessaccount/getwhitelabelinfo?wlappdomain=" + domain,
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        localStorage.setItem('wl-info', JSON.stringify(tmpWlInfo.data));
        setGlobalState("wlInfo", tmpWlInfo.data);

        setWlInfo(tmpWlInfo.data);
        //console.log('set wlInfo', tmpWlInfo.data);
    }

    function validateLogin(foundUser) {
        fetchData({
            callName: "ValidateLogin",
            method: "GET",
            url: "security/user/loginwithtoken?userToken=" + foundUser.token,
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName) {
        switch (callName) {
            case "ValidateLogin":
                var isValid = true;
                var tempErrorList = {};
                if (data == null || data == '') {
                    isValid = false;
                    tempErrorList['userName'] = "User Name or password is invalid";
                    tempErrorList['password'] = "User Name or password is invalid";
                    setErrorList(tempErrorList);
                } else {
                    // set the state of the user
                    //setUser(data)
                    // store the user in localStorage
                    localStorage.setItem('fg-user', JSON.stringify(data))
                    if (data.currentBusinessAccountDTO.active == true) {
                        navigate('/welcome');
                    } else {
                        navigate('/inactive');
                    }
                    
                }
                break;
        }
    }

    function onAPIError(error, callName) {
        //var msg = error.response.data.message;
        //setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        //setOpenDialog(true);
    }

    useEffect(() => {
        console.log('wlInfo', wlInfo);
        if (wlInfo == null) {
            console.log('get wlInfo', wlInfo);
            getWLInfo();
        }
        const loggedInUser = localStorage.getItem("fg-user");
        if (loggedInUser) {
            //check credentials        
            const foundUser = JSON.parse(loggedInUser);
            validateLogin(foundUser);
            //setUser(foundUser);
        }
    }, []); 

    return (
        <>
            <div style={{ height:"100%",width:"100%",position:"fixed" }}>
                <div style={{ display:"grid",gridTemplateColumns:"400px 1fr",gridGap:"0px", height:"100%"}}>
                    <div style={{padding:"32px",marginTop:"100px"}}>
                        <div className="segment">
                            <div style={{ marginBottom: "40px", textAlign: "center" }}>
                                {wlInfo != null ?
                                    (wlInfo.wlLoginLogo != null ?
                                        <img src={wlInfo.wlLoginLogo}></img>
                                        : 
                                        <div className="fgd-brand-nameonly" style={{ color: "#5C9EDF", paddingTop: "1px" }}><span className={wlInfo.wlIconLogo} style={{marginRight:"10px"}}></span>{wlInfo.wlName}</div>
                                    )
                                    : null}
                            </div>
                            <LoginForm></LoginForm>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#D9DADF" }}>
                        <div style={{ padding: "24px", borderRadius: "0px", marginTop: "100px", maxWidth: "400px", backgroundColor: "#ffffff", marginLeft: "auto", marginRight: "auto" }}>
                            <NewsReel></NewsReel>
                        </div>
                    </div>

                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default Login;

