import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';


function ConnectorSettings({ lineSettings, handleOnDeleteConnector, handlerReturnValue }) {

    const [dataSettings, setDataSettings] = useState(lineSettings);

    function onValueChange(propertyName, value) {
        var tmpDataSettings = { ...dataSettings };
        tmpDataSettings[propertyName] = value;
        setDataSettings(tmpDataSettings);
    }

    useEffect(() => {
        setDataSettings(lineSettings);
    }, [lineSettings]);

    useEffect(() => {
        if (handlerReturnValue) {
            handlerReturnValue(dataSettings);
        }
    }, [dataSettings]);

    return (
        <>
            <div style={{display:"grid",gridTemplateColumns:"125px 1fr",gridGap:"5px",width:"400px"} }>
                <div style={{paddingTop:"4px"} }>End Position</div>
                <div>
                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            onChange={(e) => {onValueChange('connectPosition',e.target.value); }}
                            label="Options"
                            value={dataSettings.connectPosition}
                            
                        >
                            <MenuItem value="t-0">Top Left</MenuItem>
                            <MenuItem value="t-1">Top Near Left</MenuItem>
                            <MenuItem value="t-2">Top Middle</MenuItem>
                            <MenuItem value="t-3">Top Near Right</MenuItem>
                            <MenuItem value="t-4">Top Right</MenuItem>
                            <MenuItem value="r-0">Right Top</MenuItem>
                            <MenuItem value="r-1">Right Near Top</MenuItem>
                            <MenuItem value="r-2">Right Middle</MenuItem>
                            <MenuItem value="r-3">Right Near Bottom</MenuItem>
                            <MenuItem value="r-4">Right Bottom</MenuItem>
                            <MenuItem value="b-0">Bottom Left</MenuItem>
                            <MenuItem value="b-1">Bottom Near Left</MenuItem>
                            <MenuItem value="b-2">Bottom Middle</MenuItem>
                            <MenuItem value="b-3">Bottom Near Right</MenuItem>
                            <MenuItem value="b-4">Bottom Right</MenuItem>
                            <MenuItem value="l-0">Left Top</MenuItem>
                            <MenuItem value="l-1">Left Near Top</MenuItem>
                            <MenuItem value="l-2">Left Middle</MenuItem>
                            <MenuItem value="l-3">Left Near Bottom</MenuItem>
                            <MenuItem value="l-4">Left Bottom</MenuItem>

                        </Select>
                    </FormControl>
                </div>
                <div style={{ paddingTop: "4px" }}>Line Type</div>
                <div>
                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            onChange={(e) => { onValueChange('lineType',e.target.value); }}
                            label="Options"
                            value={dataSettings.lineType}
                        >
                            <MenuItem value="solid">Solid</MenuItem>
                            <MenuItem value="dotted">Dotted</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <Button variant="outlined" color="primary" size="medium" style={{ border: "1px solid rgba(0,0,0,.12)", padding: "4.5px" }} onClick={(e) => { handleOnDeleteConnector(e); } }>Delete Line Connector</Button>
        </>

    );
}

export default ConnectorSettings;

