import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import CropSquare from '@mui/icons-material/CropSquare';
import Title from '@mui/icons-material/Title';
import AddIcon from '@mui/icons-material/Add';

import BorderTopIcon from '@mui/icons-material/BorderTop';
import BorderRightIcon from '@mui/icons-material/BorderRight';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';


import TextField from '@mui/material/TextField';
import { DataBrokerContext, PageBuilderVariablesContext } from '../PageBuilderCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

function CssPaddingCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [paddingType, setPaddingType] = useState(determinePaddingType(widgetData));
    const [measurementType, setMeasurementType] = useState(getMeasurementType(widgetData));

    function handleOnMeasurementTypeChange(tmpMeasurementType) {
        var tmpWidgetObj = { ...widgetObj };
        if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint)) == false) {
            tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
        }
        var mt = '';
        var value = getMergedStyleToBP(tmpWidgetObj, 'padding') + getMergedStyleToBP(tmpWidgetObj, 'paddingTop') + getMergedStyleToBP(tmpWidgetObj, 'paddingRight') + getMergedStyleToBP(tmpWidgetObj, 'paddingBottom') + getMergedStyleToBP(tmpWidgetObj, 'paddingLeft');
        if (value != '') {
            if (value.indexOf("px") > -1) {
                mt = "px";
            } else if (value.indexOf("%") > -1) {
                mt = "%";
            } else if (value.indexOf("em") > -1) {
                mt = "em";
            } else if (value.indexOf("rem") > -1) {
                mt = "rem";
            } else if (value.indexOf("vw") > -1) {
                mt = "vw";
            } else if (value.indexOf("vh") > -1) {
                mt = "vh";
            } else if (value.indexOf("dvw") > -1) {
                mt = "svw";
            } else if (value.indexOf("dvh") > -1) {
                mt = "svh";
            }
        }

        if (mt != tmpMeasurementType) {
            var padding = getMergedStyleToBP(tmpWidgetObj, 'padding');
            var paddingTop = getMergedStyleToBP(tmpWidgetObj, 'paddingTop');
            var paddingRight = getMergedStyleToBP(tmpWidgetObj, 'paddingRight');
            var paddingBottom = getMergedStyleToBP(tmpWidgetObj, 'paddingBottom');
            var paddingLeft = getMergedStyleToBP(tmpWidgetObj, 'paddingLeft');

            if (padding != '' && padding != 'unset') {
                padding = padding.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['padding'] = padding;                      
            }
            if (paddingTop != '' && paddingTop != 'unset') {
                paddingTop = paddingTop.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['paddingTop'] = paddingTop;                      
            }
            if (paddingRight != '' && paddingRight != 'unset') {
                paddingRight = paddingRight.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['paddingRight'] = paddingRight;                      
            }
            if (paddingBottom != '' && paddingBottom != 'unset') {
                paddingBottom = paddingBottom.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['paddingBottom'] = paddingBottom;
            }
            if (paddingLeft != '' && paddingLeft != 'unset') {
                paddingLeft = paddingLeft.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['paddingLeft'] = paddingLeft;
            }
        }



        //change measurementType for each css property
        setMeasurementType((...measurementType) => { return tmpMeasurementType });
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function getMeasurementType(tmpWidgetObj) {

        var value = getMergedStyleToBP(tmpWidgetObj, 'padding') + getMergedStyleToBP(tmpWidgetObj, 'paddingTop') + getMergedStyleToBP(tmpWidgetObj, 'paddingRight') + getMergedStyleToBP(tmpWidgetObj, 'paddingBottom') + getMergedStyleToBP(tmpWidgetObj, 'paddingLeft');

        var mt = "px";
        if (value != '') {
            if (value.indexOf("px") > -1) {
                mt = "px";
            } else if (value.indexOf("%") > -1) {
                mt = "%";
            } else if (value.indexOf("em") > -1) {
                mt = "em";
            } else if (value.indexOf("rem") > -1) {
                mt = "rem";
            } else if (value.indexOf("vw") > -1) {
                mt = "vw";
            } else if (value.indexOf("vh") > -1) {
                mt = "vh";
            } else if (value.indexOf("dvw") > -1) {
                mt = "svw";
            } else if (value.indexOf("dvh") > -1) {
                mt = "svh";
            }
        }

        return mt;
    }

    function removeProperty(tmpWidgetObj, propertyName) {
        if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
            delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
        }
    }

    function determinePaddingType(tmpWidgetObj) {
        var padding = getMergedStyleToBP(tmpWidgetObj, 'padding');
        var paddingTop = getMergedStyleToBP(tmpWidgetObj, 'paddingTop');
        var paddingRight = getMergedStyleToBP(tmpWidgetObj, 'paddingRight');
        var paddingBottom = getMergedStyleToBP(tmpWidgetObj, 'paddingBottom');
        var paddingLeft = getMergedStyleToBP(tmpWidgetObj, 'paddingLeft');

        var tmpPaddingType = 'None';
        if (padding != '') {
            if (padding == "unset") {
                tmpPaddingType = 'Unset';
            } else {
                tmpPaddingType = 'All';
            }
        } else if (paddingTop != '' || paddingRight != '' || paddingBottom != '' || paddingLeft != '') {
            if (paddingTop == paddingBottom && paddingLeft == paddingRight) {
                tmpPaddingType = 'TopBottomLeftRight';
            } else {
                tmpPaddingType = 'Custom';
            }
        }
        return tmpPaddingType;
    }

    function handlePaddingTypeChange(paddingType) {
        var tmpWidgetObj = { ...widgetObj };
        if (paddingType == "None") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'padding');
                removeProperty(tmpWidgetObj, 'paddingTop');
                removeProperty(tmpWidgetObj, 'paddingRight');
                removeProperty(tmpWidgetObj, 'paddingBottom');
                removeProperty(tmpWidgetObj, 'paddingLeft');
            }
            setPaddingType(paddingType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
            propertyChangeCallback(tmpWidgetObj);
        } else if (paddingType == "All") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'paddingTop');
                removeProperty(tmpWidgetObj, 'paddingRight');
                removeProperty(tmpWidgetObj, 'paddingBottom');
                removeProperty(tmpWidgetObj, 'paddingLeft');
            }
            setPaddingType(paddingType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        } else if (paddingType == "Custom" || paddingType == "TopBottomLeftRight") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'padding');
            }
            setPaddingType(paddingType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        } else if (paddingType == "Unset") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'paddingTop');
                removeProperty(tmpWidgetObj, 'paddingRight');
                removeProperty(tmpWidgetObj, 'paddingBottom');
                removeProperty(tmpWidgetObj, 'paddingLeft');
            }
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint]['padding'] = 'unset';
            setPaddingType(paddingType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
            propertyChangeCallback(tmpWidgetObj);
        }

    }

    function updateTopBottomLeftRight(targetPaddingType, value, mt) {
        if (targetPaddingType == "TopBottom") {
            updateCssProperty("paddingTop", value + mt);
            updateCssProperty("paddingBottom", value + mt);
        } else if (targetPaddingType == "LeftRight") {
            updateCssProperty("paddingLeft", value + mt);
            updateCssProperty("paddingRight", value + mt);
        }
    }

    function removeByTargetPaddingType(tmpWidgetObj, targetPaddingType) {
        if (targetPaddingType == "TopBottom") {
            removeProperty(tmpWidgetObj, "paddingTop");
            removeProperty(tmpWidgetObj, "paddingBottom");
        } else if (targetPaddingType == "LeftRight") {
            removeProperty(tmpWidgetObj, "paddingLeft");
            removeProperty(tmpWidgetObj, "paddingRight");
        }
    }

    function handleOnPropertyChange(propertyName, value) {
        if (value == '') {
            var tmpWidgetObj = { ...widgetObj };
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                if (paddingType != "TopBottomLeftRight") {
                    removeProperty(tmpWidgetObj, propertyName);
                } else {
                    if (propertyName == "paddingTop" || propertyName == "paddingBottom") {
                        removeByTargetPaddingType(tmpWidgetObj, "TopBottom");
                    } else if (propertyName == "paddingLeft" || propertyName == "paddingRight") {
                        removeByTargetPaddingType(tmpWidgetObj, "LeftRight");
                    }                    
                }
                setWidgetObj((...widgetObj) => { return tmpWidgetObj });
                propertyChangeCallback(tmpWidgetObj);
            }           
        } else if (Number(value) || value == 0) {
            if (paddingType != "TopBottomLeftRight") {
                updateCssProperty(propertyName, value + measurementType);
            } else {
                if (propertyName == "paddingTop" || propertyName == "paddingBottom") {
                    updateTopBottomLeftRight("TopBottom", value, measurementType)
                } else if (propertyName == "paddingLeft" || propertyName == "paddingRight") {
                    updateTopBottomLeftRight("LeftRight", value, measurementType);
                }
            }
        } else {            
            if (paddingType != "TopBottomLeftRight") {
                updateCssProperty(propertyName, value);
            } else {
                if (propertyName == "paddingTop" || propertyName == "paddingBottom") {
                    updateTopBottomLeftRight("TopBottom", value, "")
                } else if (propertyName == "paddingLeft" || propertyName == "paddingRight") {
                    updateTopBottomLeftRight("LeftRight", value, "");
                }
            }
        }
    }

    function parseValue(value) {
        var mtList = ['px', '%', 'em', 'rem', 'vw', 'vh', 'svw', 'svh', 'dvw', 'dvh'];
        for (var i = 0; i < mtList.length; i++) {
            value = value.toString().replace(mtList[i], '');
        }
        return value;
    }

    function updateCssProperty(propertyName, value) {
        var tmpValue = parseValue(value);

        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
        setPaddingType(determinePaddingType(widgetData));
    }, [widgetData])

    useEffect(() => {
        if (paddingType == 'None') {
            setPaddingType(determinePaddingType(widgetObj));
        }        
    }, [paddingType])


    return (
        <>
            <div>
                <div className="fg-pg-panel-sub-section">Padding</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 30px", marginBottom: "5px" }}>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={paddingType} onChange={(e) => { handlePaddingTypeChange(e.target.value); }}>
                                <option value="None">None</option>
                                <option value="All">All Sides</option>
                                <option value="TopBottomLeftRight">Top/Bottom & Left/Right</option>
                                <option value="Custom">Custom</option>
                            </select>
                        </div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" style={{textAlign:"center"}} value={measurementType} onChange={(e) => { handleOnMeasurementTypeChange(e.target.value); }}>
                                <option value="px">px</option>
                                <option value="%">%</option>
                                <option value="rem">rem</option>
                                <option value="vw">vw</option>
                                <option value="wh">vh</option>
                                <option value="svw">svw</option>
                                <option value="svh">svh</option>
                            </select>
                        </div>
                    </div>
                    {paddingType == "All" ?
                        <div style={{ marginBottom: "5px" }}>
                            <SliderCtrl value={getMergedStyleToBP(widgetObj, 'padding').replace(measurementType, '')} min={0} max={200} onDataChange={(padding) => { handleOnPropertyChange('padding', padding); }} ignoreMeasurementType={true} ></SliderCtrl>
                        </div>
                        : null}
                    {(paddingType == "Custom" || paddingType == "TopBottomLeftRight") ?
                        <div>
                            <div style={{ marginBottom: "5px" }}>
                                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                    <div><BorderTopIcon /></div>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'paddingTop').replace(measurementType, '')} onChange={(e) => { handleOnPropertyChange('paddingTop', e.target.value); }} /></div>
                                    <div style={{ textAlign: "center" }}>{measurementType}</div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "100px 1fr 100px", gridGap: "3px", marginBottom: "5px" }}>
                                    <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                        <div><BorderLeftIcon /></div>
                                        <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'paddingLeft').replace(measurementType, '')} onChange={(e) => { handleOnPropertyChange('paddingLeft', e.target.value); }} /></div>
                                        <div style={{ textAlign: "center" }}>{measurementType}</div>
                                    </div>
                                    <div>&nbsp;</div>
                                    <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                        <div><BorderRightIcon /></div>
                                        <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'paddingRight').replace(measurementType, '')} onChange={(e) => { handleOnPropertyChange('paddingRight', e.target.value); }} /></div>
                                        <div style={{ textAlign: "center" }}>{measurementType}</div>
                                    </div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                    <div><BorderBottomIcon /></div>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'paddingBottom').replace(measurementType,'')} onChange={(e) => { handleOnPropertyChange('paddingBottom', e.target.value); }} /></div>
                                    <div style={{ textAlign: "center" }}>{measurementType}</div>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </>
    );
}

export default CssPaddingCtrl;
