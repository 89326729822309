import React, { useState, useEffect, } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import { useGlobalState } from '../globalstates/GState';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';



//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function TagDetail({dialogType,handlerReturnValue}) {


    const [currentUser] = useGlobalState("userInfo");


    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});

    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.tagId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_detail_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('contact_detail_page', 'header_section', 'Tag Detail');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.tagId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
        if (helpers.permissionAllowed(currentUser.permissions, 'CRM-Tags', ['allowEdit']) == true) {
            headerOptions.elements[0].elements.push({ type: "button", text: getTranslation('contact_detail_page', 'header_section', 'Clear Tag'), name: "Clear Tag", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleClearTag(); } });
        }
        if (helpers.permissionAllowed(currentUser.permissions, 'CRM-Tags', ['allowDelete']) == true) {
            headerOptions.elements[0].elements.push({ type: "button", text: getTranslation('contact_detail_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
        }
    }
    if ((helpers.permissionAllowed(currentUser.permissions, 'CRM-Tags', ['allowEdit']) == true) || (helpers.permissionAllowed(currentUser.permissions, 'CRM-Tags', ['allowAdd']) == true && primaryKeyValue == 0)) {
        headerOptions.elements.push({ type: "button", text: getTranslation('contact_detail_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    }
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == 0) {
            //set default data
            data.active = true;
            data.tagType = 'Contact';
            data.total = '0';
        }        
    }



    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":

                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));

                break;
            case "SaveForm":                
                setPrimaryKeyValue(data.tagId);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/tagdetail/' + data.tagId); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/tags'); } })
                setOpenDialog(true);
                break;
            case "ClearTag":
                var msg = "Tag record has been cleared.";
                setGenericDialogSchema({ title: 'Tag Cleared', component: null, body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "crm/tag/getrecord?tagid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "crm_tags", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);



    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "900px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div className="segment-title">Tag Info</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <FormControlLabel style={{ marginBottom: "10px" }}
                                                control={
                                                    <Switch checked={formData.active} onChange={(e) => setFormData(formData => ({ ...formData, active: e.target.checked }))} name="gilad" />
                                                }
                                                label="Active"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <TextField id="tagName" autoFocus required fullWidth label="Tag Name" variant="standard" helperText={errorList['tagName'] ? <span style={{ color: "red" }}>{errorList['tagName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.tagName || ''} InputLabelProps={{ shrink: formData.tagName }} onChange={(e) => setFormData(formData => ({ ...formData, tagName: e.target.value }))} />
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Tag Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.tagType || ''}
                                                            onChange={(e) => setFormData(formData => ({ ...formData, tagType: e.target.value }))}
                                                            label="Tag Type"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value="Appointment">Appointment</MenuItem>
                                                            <MenuItem value="Automation">Automation</MenuItem>
                                                            <MenuItem value="Bug">Bug</MenuItem>
                                                            <MenuItem value="Contact">Contact</MenuItem>
                                                            <MenuItem value="Email Broadcast">Email Broadcast</MenuItem>
                                                            <MenuItem value="Incident">Incident</MenuItem>
                                                            <MenuItem value="Opportunity">Opportunity</MenuItem>
                                                            <MenuItem value="User Story">User Story</MenuItem>                                                            
                                                            <MenuItem value="Task">Task</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{errorList['tagType'] ? <span style={{ color: "red" }}>{errorList['tagType']}</span> : ''} </FormHelperText>
                                                    </FormControl>
                                                    <TextField id="description" multiline rows={4} fullWidth label="Description" variant="standard" helperText={errorList['description'] ? <span style={{ color: "red" }}>{errorList['description']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.description || ''} InputLabelProps={{ shrink: formData.description }} onChange={(e) => setFormData(formData => ({ ...formData, description: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete tag. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "crm/tag/delete?tagid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    async function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (await isCustomFormValidationValid() == true) {
                saveForm();
            }
        }                
    }

    function saveForm() {
        if (primaryKeyValue == 0) {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "crm/tag/add",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "crm/tag/update",
                token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    async function tagNameExists() {
        const data = await BAFetchData({
            callName: "TagNameExists",
            method: "GET",
            url: "crm/tag/tagnameexists?tagname=" + formData.tagName + "&tagid=" + formData.tagId,
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        return data;
    }

    async function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        //if (formData.isCompany == false) {
        //    if (formData.firstName == null && formData.lastName == null && formData.businessName == null) {
        //        isValid = false;
        //        tempErrorList['firstName'] = "First Name, Last Name or Business Name is required.";
        //    }
        //}
        if (formData.tagName != null && formData.tagName != '') {
            var total = await tagNameExists();
            if (total.data > 0) {
                isValid = false;
                tempErrorList['tagName'] = "Tag Name already exist. Enter a different tag name.";
            }
        }

        setErrorList(tempErrorList);
        return isValid;
    }

    function handleClearTag() {
        var msg = 'You are about to clear tag record. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); clearRecords() } })
        setOpenDialog(true);
    }

    function clearRecords() {
        var test = '';
        test = '1234';
        BAFetchData({
            callName: "ClearTag",
            method: "POST",
            url: "crm/tagmapping/cleartag?tagid=" + primaryKeyValue,
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }
}

export default TagDetail;