import React, { useEffect } from "react";


function BookingResourcesCtrl({ resources, selectedResourceId, onResourceChanged }){

    function determineSelectedStyle(resourceId){
        let styleBackgroundColor = "white";
        if (resourceId === selectedResourceId){
            styleBackgroundColor = "whitesmoke";
        }
        return styleBackgroundColor;
    }

    useEffect(() => {
        if (resources.length > 0) {
            if (resources.length == 1) {
                onResourceChanged(resources[0].id);
            } else {
                if (selectedResourceId != null) {
                    onResourceChanged(selectedResourceId);
                }
            }
        }
    }, [resources])

    return (
        <div>
            <div style={{marginBottom:"20px",fontWeight:"bold"}}>Select Resource</div>
            <div style={{display:"grid",gridTempalteColumns:"1fr",gridGap:"20px"}}>
                {resources.map((resource, index) => {
                    return (
                        <div key={resource.id} style={{ backgroundColor: determineSelectedStyle(resource.id), display: "grid", gridTemplateColumns: "1fr", gridGap: "10px", width: "100%", padding: "10px" }}>
                            <div>
                                <div style={{ fontWeight: "bold" }}>{resource.type}:</div>
                                <div>{resource.name}</div>
                                <div>{resource.email}</div>
                                <div>{resource.phone}</div>
                                <div><button style={{ border: "none", backgroundColor: "#D9DADF", padding: "5px 15px 5px 15px", borderRadius: "4px" }} onClick={(e) => { onResourceChanged(resource.id, resource.type) }}>Select</button></div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default BookingResourcesCtrl;