import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';


function CssEventTrackingScripts({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);


    function handleOnValue(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        if (tmpWidgetObj.hasOwnProperty('eventTracker') == false) {
            tmpWidgetObj['eventTracker'] = {};
        }
        tmpWidgetObj['eventTracker'][propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    }

    function handleOnBlur(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        if (tmpWidgetObj.hasOwnProperty('eventTracker') == false) {
            tmpWidgetObj['eventTracker'] = {};
        }
        tmpWidgetObj['eventTracker'][propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    //function handleOnPropertyChange(propertyName, value) {
    //    var tmpWidgetObj = { ...widgetObj };
    //    tmpWidgetObj[propertyName] = value;
    //    setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //    //propertyChangeCallback(tmpWidgetObj);
    //}

    //function handleOnBlur(propertyName, value) {
    //    var tmpWidgetObj = { ...widgetObj };
    //    tmpWidgetObj[propertyName] = value;
    //    setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //    propertyChangeCallback(tmpWidgetObj);
    //}

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Event Tracking Script</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    {(widgetData.elementName == 'button' || widgetData.elementName == 'linkblock') ?
                    <>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Raise Event On Click</div>
                        <div>
                            <textarea className="form-control form-control-sm fg-pb-property-field" value={widgetObj.eventTracker != undefined ? (widgetObj.eventTracker.onClick || '') : ''} defaultValue={widgetObj.eventTracker != undefined ? (widgetObj.eventTracker.onClick || '') : ''} onChange={(e) => { handleOnValue("onClick", e.target.value); }} onBlur={(e) => { handleOnBlur("onClick", e.target.value); }} rows={4}></textarea>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Raise Event On Success</div>
                        <div>
                            <textarea className="form-control form-control-sm fg-pb-property-field" value={widgetObj.eventTracker != undefined ? (widgetObj.eventTracker.onSuccess || '') : ''} defaultValue={widgetObj.eventTracker != undefined ? (widgetObj.eventTracker.onSuccess || '') : ''} onChange={(e) => { handleOnValue("onSuccess", e.target.value); }} onBlur={(e) => { handleOnBlur("onSuccess", e.target.value); }} rows={4}></textarea>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Raise Event On Error</div>
                        <div>
                            <textarea className="form-control form-control-sm fg-pb-property-field" value={widgetObj.eventTracker != undefined ? (widgetObj.eventTracker.onError || '') : ''} defaultValue={widgetObj.eventTracker != undefined ? (widgetObj.eventTracker.onError || '') : ''} onChange={(e) => { handleOnValue("onError", e.target.value); }} onBlur={(e) => { handleOnBlur("onError", e.target.value); }} rows={4}></textarea>
                        </div>
                            </div>
                        </>
                        : null}
                    {widgetData.elementName == 'field' ?
                        <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                            <div className="fg-pb-property-sub-label">Raise Event On Value Changed when Not Empty</div>
                            <div>
                                <textarea className="form-control form-control-sm fg-pb-property-field" value={widgetObj.eventTracker != undefined ? (widgetObj.eventTracker.onValueChanged || '') : ''} defaultValue={widgetObj.eventTracker != undefined ? (widgetObj.eventTracker.onValueChanged || '') : ''} onChange={(e) => { handleOnValue("onValueChanged", e.target.value); }} onBlur={(e) => { handleOnBlur("onValueChanged", e.target.value); }} rows={4}></textarea>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </>
    );

}

export default CssEventTrackingScripts;

