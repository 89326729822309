import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import helpers from '../../helpers/Helpers';
import Chip from '@mui/material/Chip';
import DialogCtrl from '../dialogctrl/dialogctrl';
import ContactSearch from '../../pages/ContactSearch';
import { BAFetchData } from '../../customhooks/useBAFetch';
import TextField from '@mui/material/TextField';


function MasterVariantsCtrl({ productTypeVarAttrList, onChange }) {

    const [variantList, setVariantList] = useState(productTypeVarAttrList);
    //const [openDialog, setOpenDialog] = useState(false);
    //const [genericDialogSchema, setGenericDialogSchema] = useState({});            
    
    function addVariant(e) {
        var tmpVariantList = [...variantList];
        tmpVariantList.push(getNewVariant());
        reOrder(tmpVariantList);
        setVariantList(tmpVariantList);
        onChange(e, tmpVariantList);
    }

    function onVariantChange(e, index,propertyName, value) {
        var tmpVariantList = [...variantList];
        tmpVariantList[index][propertyName] = value;
        if (tmpVariantList[index].internalState != "added") {
            tmpVariantList[index].internalState = "modified";
        }
        reOrder(tmpVariantList);
        setVariantList(tmpVariantList);
        onChange(e, tmpVariantList);
    }

    function onVariantDelete(e, index) {
        var tmpVariantList = [...variantList];
        if (tmpVariantList[index].internalState != "added") {
            tmpVariantList[index].internalState = "deleted";
        } else {
            tmpVariantList.splice(index, 1);
        }
        reOrder(tmpVariantList);
        setVariantList(tmpVariantList);
        onChange(e, tmpVariantList);
    }

    function reOrder(tmpVariantList) {
        for (var i = 0; i < tmpVariantList.length; i++) {
            if (tmpVariantList[i].internalState != 'deleted') {
                tmpVariantList[i].displayOrder = i;
            }
        }
    }

    function getNewVariant() {
        return { "baid": 0, "productTypeId": 0, "productId": "", "attrName": null, "attrLabel": null, "displayOrder": null, "options": null, "internalState":"added"};
    }

    useEffect(() => {
        setVariantList(productTypeVarAttrList);
    }, [productTypeVarAttrList]);

    return (
        <>
            {variantList.map((variant, index) => (
                variant.internalState != 'deleted' ?
                    <div key={"master_variant_" + index} style={{display:"grid",gridTemplateColumns:"200px 200px 1fr 40px",gridGap:"20px",width:"100%",borderBottom:"1px solid whitesmoke",marginBottom:"10px"}}>
                        <div>
                            <TextField required fullWidth label="Name" variant="standard" style={{ marginBottom: "16px" }} value={variant.attrName || ''} onChange={(e) => {onVariantChange(e, index, 'attrName',e.target.value);}} />
                        </div>
                        <div>
                            <TextField required fullWidth label="Label" variant="standard" style={{ marginBottom: "16px" }} value={variant.attrLabel || ''} onChange={(e) => { onVariantChange(e, index, 'attrLabel',e.target.value); }} />
                        </div>
                        <div>
                            <TextField required fullWidth label="Options" variant="standard" style={{ marginBottom: "16px" }} value={variant.options || ''} onChange={(e) => { onVariantChange(e, index, 'options', e.target.value); }} />
                        </div>
                        <div>
                            <DeleteIcon onClick={(e) => {onVariantDelete(e, index);}} />
                        </div>
                    </div>
                    : null
            ))}
            <div>
                <Button variant="outlined" onClick={(e) => { addVariant(e); }}>Add Variant</Button>
            </div>
        </>
    );
}

export default MasterVariantsCtrl;
