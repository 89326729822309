import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useGlobalState } from '../../../globalstates/GState';
import { BAFetchData } from '../../../customhooks/useBAFetch';

function ConfirmPasswordCtrl({ dialogType, handlerReturnValue, msg }) {

    const [currentUser] = useGlobalState("userInfo");
    const [loginInfo, setLoginInfo] = useState({"userName":"","password":""});

    function onValueChange(propertyName, value) {
        var tmpLoginInfo = { ...loginInfo };
        tmpLoginInfo[propertyName] = value;
        setLoginInfo(tmpLoginInfo);
    }

    //function handlePasswordKeyPress(event) {
    //    if (event.key === 'Enter') {
    //        validateLogin();
    //    }
    //}

    async function validateLogin(value) {
        var isValid = false;
        var result = "";
        if (loginInfo.userName != '' && loginInfo.password != '') {
            result = await BAFetchData({
                callName: "CheckLogin",
                method: "GET",
                url: "security/user/loginvalid?username=" + loginInfo.userName + "&password=" + loginInfo.password,
                token: "fg1234",
                body: null, onSuccess: null, onError: null
            });
        }

        if (result.data == true) {
            isValid = true;
        }
        if (handlerReturnValue) {
            handlerReturnValue(isValid);
        }
    }

    return (
        <>
            <div style={{maxWidth:"400px"}}>
                <div dangerouslySetInnerHTML={{ __html: msg }}></div>
                <TextField id="username" required fullWidth type="text" autoComplete='off' label="User Name" variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={loginInfo.userName || ''} InputLabelProps={{ shrink: loginInfo.userName }} onChange={(e) => onValueChange('userName',e.target.value)} onBlur={(e) => validateLogin(e.target.value)} />
                <TextField id="password" required fullWidth type="password" autoComplete='off' label="Password" variant="outlined" size="medium" style={{ marginBottom: "16px" }} value={loginInfo.password || ''} InputLabelProps={{ shrink: loginInfo.password }} onChange={(e) => onValueChange('password', e.target.value)} onBlur={(e) => validateLogin(e.target.value)} />
            </div>
        </>

    );
}

export default ConfirmPasswordCtrl;