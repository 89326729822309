import React, { useState } from "react";
import { useEffect } from "react";
import useBAFetch, { BAFetchData } from "../../../customhooks/useBAFetch";
import DialogCtrl from "../../dialogctrl/dialogctrl";
import LoaderSpinnerCtrl from "../../loaderspinnerctrl/loaderspinnerctrl";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import TextField from '@mui/material/TextField';
import helpers from "../../../helpers/Helpers";

function BookableLocationsCtrl({ locationConfig, onChange, onPreviewLink, onCopyLink }) {

    const [locationConfigObj, setLocationConfigObj] = useState([]);
    const [errorList, setErrorList] = useState({});
    const [accountMemberList, setAccountMemberList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [timezoneList, setTimezoneList] = useState([]);
    const [countryList, setCountryList] = useState([]);



    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "site_settings_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };

    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    function getAccountMembers() {
        BAFetchData({
            callName: "GetAccountMember",
            method: "GET",
            url: "security/businessaccountmember/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getResourceUserInfo(bamId, hostIndex) {
        BAFetchData({
            callName: "GetResourceUserInfo",
            method: "GET",
            url: "security/user/getuserrecordbybamid?bamid=" + bamId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError, packageObj: { hostIndex: hostIndex }
        });
    }

    useEffect(() => {
        setShowLoader(false);
        getAccountMembers();
        getCountryList();
        getTimezoneList((null));
    }, [])

    useEffect(() => {
        setLocationConfigObj(locationConfig);
    }, [locationConfig])

    function hostAlreadySelected(bamId) {
        let exists = false;
        let tmpLocationConfigObj = [...locationConfigObj];
        for (let i = 0; i < tmpLocationConfigObj.length; i++) {
            if (tmpLocationConfigObj[i].bamId == bamId) {
                exists = true;
                break;
            }
        }
        return exists;
    }

    function onResourceValueChange(propertyName, locationIndex, resourceIndex, value) {
        if (hostAlreadySelected(value) == false) {
            let tmpLocationConfigObj = [...locationConfigObj];
            tmpLocationConfigObj[locationIndex].resources[resourceIndex][propertyName] = value;
            setLocationConfigObj(tmpLocationConfigObj);


            //if (propertyName == 'bamId') {
            //    if (value != null && value != '') {
            //        getResourceUserInfo(value, locationIndex, resourceIndex);
            //    } else {
            //        autoUpdateHostLocation(lcoationIndex, resourceIndex, null);
            //    }
            //}

            onChange(tmpLocationConfigObj);
        } else {
            alert('Host already selected');
        }

    }

    function addLocationBtnClick() {
        let tmpLocationConfigObj = [...locationConfigObj];
        let location = getLocationSchema();
        tmpLocationConfigObj.push(location);
        setLocationConfigObj(tmpLocationConfigObj);
        onChange(tmpLocationConfigObj);
    }

    function removeLocation(locationIndex) {
        let tmpLocationConfigObj = [...locationConfigObj];
        tmpLocationConfigObj.splice(locationIndex, 1);
        setLocationConfigObj(tmpLocationConfigObj);
        onChange(tmpLocationConfigObj);
    }

    function addResourceBtnClick(locationIndex) {
        let tmpLocationConfigObj = [...locationConfigObj];
        let resource = getResourceSchema();
        tmpLocationConfigObj[locationIndex].resources.push(resource);
        setLocationConfigObj(tmpLocationConfigObj);
        onChange(tmpLocationConfigObj);
    }

    function removeResource(locationIndex, resourceIndex) {
        let tmpLocationConfigObj = [...locationConfigObj];
        tmpLocationConfigObj[locationIndex].resources.splice(resourceIndex, 1);
        setLocationConfigObj(tmpLocationConfigObj);
        onChange(tmpLocationConfigObj);
    }


    function addSlotBtnClick(locationIndex, resourceIndex, dayOfWeekIndex) {
        let tmpLocationConfigObj = [...locationConfigObj];
        let slot = getSlotSchema();
        tmpLocationConfigObj[locationIndex].resources[resourceIndex].daysAvailable[dayOfWeekIndex].slots.push(slot);
        setLocationConfigObj(tmpLocationConfigObj);
        onChange(tmpLocationConfigObj);
    }

    function removeSlot(locationIndex, resourceIndex, dayOfWeekIndex, slotIndex) {
        let tmpLocationConfigObj = [...locationConfigObj];
        tmpLocationConfigObj[locationIndex].resources[resourceIndex].daysAvailable[dayOfWeekIndex].slots.splice(slotIndex, 1);
        setLocationConfigObj(tmpLocationConfigObj);
        onChange(tmpLocationConfigObj);
    }

    function getLocationSchema() {
        var locationId = helpers.getUUID();
        return {
            locationId: locationId,
            locationType: 'Online',
            placeName: null,
            address: null,
            cost: 0,
            countryCode: null,
            timezoneName: null,
            resources:[]
        }
    }

    function getResourceSchema() {
        return {
            bamId: null,
            cost: 0,
            countryCode: null,
            timezoneName: null,
            daysAvailable: [
                {
                    day: "Sunday",
                    slots: []
                },
                {
                    day: "Monday",
                    slots: []
                },
                {
                    day: "Tuesday",
                    slots: []
                },
                {
                    day: "Wednesday",
                    slots: []
                },
                {
                    day: "Thursday",
                    slots: []
                },
                {
                    day: "Friday",
                    slots: []
                },
                {
                    day: "Saturday",
                    slots: []
                }
            ]
        };
    }

    function getSlotSchema() {
        return { startTime: null, endTime: null, cost: 0, seats: 1 };
    }

    function onSlotChange(locationIndex, resourceIndex, dayOfWeekIndex, slotIndex, propertyName, value) {
        let tmpLocationConfigObj = [...locationConfigObj];
        tmpLocationConfigObj[locationIndex].resources[resourceIndex].daysAvailable[dayOfWeekIndex].slots[slotIndex][propertyName] = value;
        setLocationConfigObj(tmpLocationConfigObj);
    }

    function onSlotBlur(locationIndex, resourceIndex, dayOfWeekIndex, slotIndex, propertyName, value) {
        let tmpLocationConfigObj = [...locationConfigObj];
        if (propertyName == 'startTime' || propertyName == 'endTime') {
            dayjs.extend(customParseFormat);
            let tmpValue = value.replaceAll(" ", "");
            let tmpHour = null;
            let tmpMinute = null;
            let meridiem = "am";
            if (tmpValue.slice(-2).toLowerCase() == "pm") {
                meridiem = "pm";
            }
            tmpValue = tmpValue.replace("am", "").replace("pm", "");
            tmpHour = tmpValue.split(":")[0];
            tmpMinute = tmpValue.split(":")[1] || "00";
            if (tmpHour.charAt(0) == "0") {
                tmpHour = tmpHour.replace("0", "");
            }
            if (tmpHour > 12) {
                meridiem = "pm";
            } else if (tmpHour < 13 && meridiem == "pm") {
                tmpHour = parseInt(tmpHour) + 12;
            }
            tmpHour = tmpHour.toString().padStart(2, '0');
            tmpMinute = tmpMinute.toString().padStart(2, '0');
            const finalTime = `T${tmpHour}:${tmpMinute}:00`;
            let time = dayjs(finalTime, "HH:mm").format('h:mm A');
            tmpLocationConfigObj[locationIndex].resources[resourceIndex].daysAvailable[dayOfWeekIndex].slots[slotIndex][propertyName] = time;
        } else {
            tmpLocationConfigObj[locationIndex].resources[resourceIndex].daysAvailable[dayOfWeekIndex].slots[slotIndex][propertyName] = value;
        }

        setLocationConfigObj(tmpLocationConfigObj);
        onChange(tmpLocationConfigObj);
    }

    function onLocationChange(locationIndex, propertyName, value) {
        let tmpLocationConfigObj = [...locationConfigObj];
        tmpLocationConfigObj[locationIndex][propertyName] = value;
        setLocationConfigObj(tmpLocationConfigObj);
    }

    function onLocationBlur(locationIndex, propertyName, value) {
        let tmpLocationConfigObj = [...locationConfigObj];
        tmpLocationConfigObj[locationIndex][propertyName] = value;
        setLocationConfigObj(tmpLocationConfigObj);
        onChange(tmpLocationConfigObj);
    }

    function getCountryList() {
        BAFetchData({
            callName: "GetCountryList",
            method: "GET",
            url: "location/country/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getTimezoneList(countryCode) {
        var filtering = {};
        if (countryCode != null) {
            filtering = {
                "LogicalOperator": "and", "ConditionGroups": [{
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "countrycode", "ConditionOperator": "EQUAL", "Value": countryCode, "DataType": "String" }
                    ]
                }]
            };
        }

        BAFetchData({
            callName: "GetTimezoneList",
            method: "GET",
            url: "time/timezone/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    //function autoUpdateHostLocation(locationIndex, resourceIndex, data) {
    //    let tmpLocationConfigObj = [...locationConfigObj];
    //    if (data != null) {
    //        for (let li = 0; li < tmpLocationConfigObj[locationIndex].resources[resourceIndex].length; li++) {
    //            tmpLocationConfigObj[locationIndex].resources[li].countryCode = data.countryCode;
    //            tmpLocationConfigObj[hostIndex].locations[li].timezoneName = data.timezoneName;
    //        }
    //    } else {
    //        for (let li = 0; li < tmpLocationConfigObj[hostIndex].locations.length; li++) {
    //            tmpLocationConfigObj[hostIndex].locations[li].countryCode = null;
    //            tmpLocationConfigObj[hostIndex].locations[li].timezoneName = null;
    //        }
    //    }
    //    setLocationConfigObj(tmpLocationConfigObj);
    //    onChange(tmpLocationConfigObj);
    //}

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case 'GetAccountMember':
                setAccountMemberList(data);
                break;
            case 'GetTimezoneList':
                setTimezoneList(data);
                break;
            case 'GetCountryList':
                setCountryList(data);
                break;
            case 'GetResourceUserInfo':
                //autoUpdateHostLocation(packageObj.hostIndex, data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    return (
        <>
            {locationConfigObj != null ?
                <div>
                    {locationConfigObj.map((location, locationIndex) => (
                        <>
                            <div className="row" style={{ backgroundColor: "whitesmoke", margin: "0px", paddingTop: "10px" }}>
                                <div className="col-sm-12">
                                    <div className="segment">

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 40px", gridGap: "20px" }}>
                                                    <div>
                                                        <div style={{ marginBottom: "15px", textTransform: "uppercase", fontWeight: "bold" }}>Location #{locationIndex + 1} <span style={{ marginLeft: "40px", textTransform: "none", color: "white", fontSize: "11px", fontWeight: "normal", backgroundColor: "black", padding: "3px 10px 3px 10px", borderRadius: "20px", cursor: "pointer" }} onClick={(e) => { onPreviewLink(location.locationId, null); }}>Preview Link</span><span style={{ marginLeft: "40px", textTransform: "none", color: "white", fontSize: "11px", fontWeight: "normal", backgroundColor: "black", padding: "3px 10px 3px 10px", borderRadius: "20px", cursor: "pointer" }} onClick={(e) => { onCopyLink(location.locationId, null); }}>Copy Link</span></div>
                                                        <div style={{ display: "grid", gridTemplateColumns: "125px 1fr 125px", gridGap: "20px" }}>
                                                            <div>
                                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                    <InputLabel id="demo-simple-select-standard-label">Location Type</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-standard-label"
                                                                        id="demo-simple-select-standard"
                                                                        value={location.locationType || 'Online'}
                                                                        onChange={(e) => { onLocationBlur(locationIndex, "locationType", e.target.value); }}
                                                                        label="Location Type"
                                                                    >
                                                                        <MenuItem value="Online">Online</MenuItem>
                                                                        <MenuItem value="InPerson">In Person</MenuItem>
                                                                        <MenuItem value="ByPhone">By Phone</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText>{errorList['locationType'] ? <span style={{ color: "red" }}>{errorList['locationType']}</span> : ''} </FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                            <div>
                                                                <TextField required fullWidth label="Meeting Place Name" variant="standard" helperText={errorList['placeName'] ? <span style={{ color: "red" }}>{errorList['placeName']}</span> : ''} style={{ marginBottom: "16px" }} defaultValue={location.placeName || ''} InputLabelProps={{ shrink: location.placeName }} onChange={(e) => { onLocationChange(locationIndex, "placeName", e.target.value); }} onBlur={(e) => { onLocationBlur(locationIndex, "placeName", e.target.value); }} />
                                                            </div>
                                                            <div>
                                                                <TextField fullWidth label="Default Cost" variant="standard" helperText={errorList['cost'] ? <span style={{ color: "red" }}>{errorList['cost']}</span> : ''} style={{ marginBottom: "16px" }} value={location.cost || ''} InputLabelProps={{ shrink: location.cost }} onChange={(e) => { onLocationChange(locationIndex, "cost", e.target.value); }} onBlur={(e) => { onLocationBlur(locationIndex, "cost", e.target.value); }} />
                                                            </div>
                                                        </div>

                                                        <TextField fullWidth label="Meeting Address (Optional)" variant="standard" helperText={errorList['address'] ? <span style={{ color: "red" }}>{errorList['address']}</span> : ''} style={{ marginBottom: "16px" }} defaultValue={location.address || ''} InputLabelProps={{ shrink: location.address }} onChange={(e) => { onLocationChange(locationIndex, "address", e.target.value); }} onBlur={(e) => { onLocationBlur(locationIndex, "address", e.target.value); }} />
                                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px" }}>
                                                            <div>
                                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                    <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-standard-label"
                                                                        id="demo-simple-select-standard"
                                                                        value={location.countryCode || ''}
                                                                        onChange={(e) => { onLocationBlur(locationIndex, "countryCode", e.target.value); getTimezoneList(e.target.value); }}
                                                                        label="Age"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        {countryList.map((country, ci) => (
                                                                            <MenuItem key={"country_" + ci} value={country.countryCode}>{country.countryName}</MenuItem>
                                                                        ))}

                                                                    </Select>
                                                                    <FormHelperText>{errorList['countryCode'] ? <span style={{ color: "red" }}>{errorList['countryCode']}</span> : ''} </FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                            <div>
                                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                    <InputLabel id="demo-simple-select-standard-label">Timezone</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-standard-label"
                                                                        id="demo-simple-select-standard"
                                                                        value={location.timezoneName || ''}
                                                                        onChange={(e) => { onLocationBlur(locationIndex, "timezoneName", e.target.value); }}
                                                                        label="Age"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        {timezoneList.map((timezone, tzi) => (
                                                                            <MenuItem key={"tz_" + tzi} value={timezone.timezoneName}>{timezone.timezoneName}</MenuItem>
                                                                        ))}

                                                                    </Select>
                                                                    <FormHelperText>{errorList['timezoneName'] ? <span style={{ color: "red" }}>{errorList['timezoneName']}</span> : ''} </FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: "15px", textTransform: "uppercase", fontWeight: "bold" }}>Resources</div>
                                                        {location.resources.map((resource, resourceIndex) => (
                                                            <div style={{ display: "grid", gridTemplateColumns: "1fr 40px", gridGap: "20px",backgroundColor:"white",marginBottom:"20px",padding:"10px" }}>
                                                                <div>
                                                                    <div style={{ marginBottom: "15px", textTransform: "uppercase", fontWeight: "bold" }}>Resource #{resourceIndex + 1} <span style={{ marginLeft: "40px", textTransform: "none", color: "white", fontSize: "11px", fontWeight: "normal", backgroundColor: "black", padding: "3px 10px 3px 10px", borderRadius: "20px", cursor: "pointer" }} onClick={(e) => { onPreviewLink(location.locationId, resource.bamId); }}>Preview Link</span><span style={{ marginLeft: "40px", textTransform: "none", color: "white", fontSize: "11px", fontWeight: "normal", backgroundColor: "black", padding: "3px 10px 3px 10px", borderRadius: "20px", cursor: "pointer" }} onClick={(e) => { onCopyLink(location.locationId, resource.bamId); }}>Copy Link</span></div>
                                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                        <InputLabel id="demo-simple-select-standard-label">Team Member</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-standard-label"
                                                                            id="demo-simple-select-standard"
                                                                            onChange={(e) => { onResourceValueChange("bamId",locationIndex, resourceIndex, e.target.value); }}
                                                                            value={resource.bamId || ''}
                                                                            label="Team Member"
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>None</em>
                                                                            </MenuItem>
                                                                            {accountMemberList.map((accountMember, ci) => (
                                                                                <MenuItem key={accountMember.bamId} value={accountMember.bamId}>{accountMember.userFullName}</MenuItem>
                                                                            ))}

                                                                        </Select>
                                                                        <FormHelperText>{errorList['contactId'] ? <span style={{ color: "red" }}>{errorList['contactId']}</span> : ''} </FormHelperText>
                                                                    </FormControl>
                                                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 125px", gridGap: "10px" }}>
                                                                        <div>
                                                                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                                <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-standard-label"
                                                                                    id="demo-simple-select-standard"
                                                                                    value={resource.countryCode || ''}
                                                                                    onChange={(e) => { onResourceValueChange("countryCode",locationIndex, resourceIndex, e.target.value); getTimezoneList(e.target.value); }}
                                                                                    label="Country"
                                                                                >
                                                                                    <MenuItem value="">
                                                                                        <em>None</em>
                                                                                    </MenuItem>
                                                                                    {countryList.map((country, ci) => (
                                                                                        <MenuItem key={"country_" + ci} value={country.countryCode}>{country.countryName}</MenuItem>
                                                                                    ))}

                                                                                </Select>
                                                                                <FormHelperText>{errorList['countryCode'] ? <span style={{ color: "red" }}>{errorList['countryCode']}</span> : ''} </FormHelperText>
                                                                            </FormControl>
                                                                        </div>
                                                                        <div>
                                                                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                                <InputLabel id="demo-simple-select-standard-label">Timezone</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-standard-label"
                                                                                    id="demo-simple-select-standard"
                                                                                    value={resource.timezoneName || ''}
                                                                                    onChange={(e) => { onResourceValueChange("timezoneName",locationIndex,resourceIndex, e.target.value); }}
                                                                                    label="Timezone"
                                                                                >
                                                                                    <MenuItem value="">
                                                                                        <em>None</em>
                                                                                    </MenuItem>
                                                                                    {timezoneList.map((timezone, tzi) => (
                                                                                        <MenuItem key={"tz_" + tzi} value={timezone.timezoneName}>{timezone.timezoneName}</MenuItem>
                                                                                    ))}

                                                                                </Select>
                                                                                <FormHelperText>{errorList['timezoneName'] ? <span style={{ color: "red" }}>{errorList['timezoneName']}</span> : ''} </FormHelperText>
                                                                            </FormControl>
                                                                        </div>
                                                                        <div>
                                                                            <TextField fullWidth label="Default Cost" variant="standard" helperText={errorList['cost'] ? <span style={{ color: "red" }}>{errorList['cost']}</span> : ''} style={{ marginBottom: "16px" }} value={resource.cost || ''} InputLabelProps={{ shrink: resource.cost }} onChange={(e) => { onResourceValueChange("cost",locationIndex,resourceIndex, e.target.value); }} onBlur={(e) => { onResourceValueChange("cost",locationIndex, resourceIndex, e.target.value); }} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: "15px", textTransform: "uppercase", fontWeight: "bold" }}>Availability</div>
                                                                    {resource.daysAvailable.map((dayOfWeek, dayOfWeekIndex) => (
                                                                        <div style={{ display: "grid", gridTemplateColumns: "100px 1fr", gridGap: "20px", width: "600px", marginTop: "10px" }}>
                                                                            <div>
                                                                                <div style={{ fontWeight: "bold" }}>{dayOfWeek.day}</div>
                                                                            </div>
                                                                            <div>
                                                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 30px", gridGap: "10px", fontWeight: "600" }}>
                                                                                    <div>From Time</div>
                                                                                    <div>To Time</div>
                                                                                    <div>Avail. Seats</div>
                                                                                    <div>Cost</div>
                                                                                    <div></div>
                                                                                </div>
                                                                                {dayOfWeek.slots.map((slot, slotIndex) => (
                                                                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 30px", gridGap: "10px", marginBottom: "5px" }}>
                                                                                        <div><input type="text" placeholder="From Time" value={slot.startTime || ''} defaultValue={slot.startTime || ''} style={{ width: "100%", border: "1px solid #d2d2d2", padding: "5px" }} onChange={(e) => { onSlotChange(locationIndex, resourceIndex, dayOfWeekIndex, slotIndex, "startTime", e.target.value) }} onBlur={(e) => { onSlotBlur(locationIndex, resourceIndex, dayOfWeekIndex, slotIndex, "startTime", e.target.value) }} /></div>
                                                                                        <div><input type="text" placeholder="To Time" value={slot.endTime || ''} defaultValue={slot.endTime || ''} style={{ width: "100%", border: "1px solid #d2d2d2", padding: "5px" }} onChange={(e) => { onSlotChange(locationIndex, resourceIndex, dayOfWeekIndex, slotIndex, "endTime", e.target.value) }} onBlur={(e) => { onSlotBlur(locationIndex, resourceIndex, dayOfWeekIndex, slotIndex, "endTime", e.target.value) }} /></div>
                                                                                        <div><input type="text" placeholder="Seats" defaultValue={slot.seats || ''} style={{ width: "100%", border: "1px solid #d2d2d2", padding: "5px" }} onBlur={(e) => { onSlotBlur(locationIndex, resourceIndex, dayOfWeekIndex, slotIndex, "seats", e.target.value) }} /></div>
                                                                                        <div><input type="text" placeholder="Cost" defaultValue={slot.cost || ''} style={{ width: "100%", border: "1px solid #d2d2d2", padding: "5px" }} onBlur={(e) => { onSlotBlur(locationIndex, resourceIndex, dayOfWeekIndex, slotIndex, "cost", e.target.value) }} /></div>
                                                                                        <div style={{ textAlign: "right", cursor: "pointer" }} onClick={(e) => { removeSlot(locationIndex, resourceIndex, dayOfWeekIndex, slotIndex); }}><DeleteForeverIcon /></div>
                                                                                    </div>
                                                                                ))}
                                                                                <div style={{ marginTop: "10px" }}>
                                                                                    <Button variant="outlined" size="small" style={{ display: "inline-block", backgroundColor: "#EEEEEE", color: "grey", border: "none" }} onClick={(e) => { addSlotBtnClick(locationIndex, resourceIndex, dayOfWeekIndex); }}>{getTranslation('contact_search_page', 'search_grid', 'Add Hours')}</Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div style={{ textAlign: "right" }}>
                                                                    <DeleteForeverIcon style={{ cursor: "pointer", fontSize: "24px", marginTop: "0px", }} onClick={(e) => { removeResource(locationIndex, resourceIndex); }} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div style={{ marginTop: "10px", marginBottom:"10px" }}>
                                                            <Button variant="outlined" size="small" style={{ display: "inline-block", backgroundColor: "#EEEEEE", color: "grey", border: "none" }} onClick={(e) => { addResourceBtnClick(locationIndex); }}>{getTranslation('contact_search_page', 'search_grid', 'Add Resource')}</Button>
                                                        </div>
                                                    </div>
                                                    <div style={{ textAlign: "right" }}>
                                                        <DeleteForeverIcon style={{ cursor: "pointer", fontSize: "24px", marginTop: "0px", }} onClick={(e) => { removeLocation(locationIndex);}} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div style={{ marginBottom: "20px" }}></div>
                        </>
                    ))}
                </div>
                : null}
            <div style={{ marginTop: "20px" }}>
                <Button variant="outlined" style={{ display: "inline-block", backgroundColor: "grey", color: "white", border: "1px solid grey" }} onClick={(e) => { addLocationBtnClick(); }} ><AddIcon />{getTranslation('contact_search_page', 'search_grid', 'Add Location')}</Button>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    )
}

export default BookableLocationsCtrl;