import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import { BAFetchData } from '../../../customhooks/useBAFetch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import SegmentedLists from '../../../pages/SegmentedLists';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DataPackageAliasListCtrl from '../DataPackageAliasListCtrl';


function AOListActionCtrl({ objectSettings, onChange, dataPackageList }) {

    const [objectSettingsObj, setObjectSettingsObj] = useState(objectSettings);
    const [segmentedListInfo, setSegmentedListInfo] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    function onValueChange(propertyName, value) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings[propertyName] = value;
        updateSummary(tmpObjectSettingsObj);
        setObjectSettingsObj(tmpObjectSettingsObj);
        onChange(tmpObjectSettingsObj);
    }

    function updateSummary(tmpObjectSettingsObj) {
        var summary = "";
        if (tmpObjectSettingsObj.settings.action == "Add") {
            summary += "Add to ";
        } else {
            summary += "Remove from ";
        }
        var comma = ""
        var counter = parseInt(0);
        for (var i = 0; i < tmpObjectSettingsObj.settings.list.length; i++) {
            counter++;
            if (counter > 1 && counter == tmpObjectSettingsObj.settings.list.length) {
                summary += " & " + tmpObjectSettingsObj.settings.list[i].listName;
            } else {
                summary += comma + tmpObjectSettingsObj.settings.list[i].listName;
            }
            comma = ", "
        }
        if (tmpObjectSettingsObj.settings.list.length == 0) {
            summary += "list"
        }else if (tmpObjectSettingsObj.settings.list.length == 1) {
            summary += " list"
        } else if (tmpObjectSettingsObj.settings.list.length > 1) {
            summary += " lists"
        }
        tmpObjectSettingsObj.settings.summary = summary ;
    }

    function newSetting() {
        return { "action": "Add", "contactId": null, list:[], "summary":"" };
    }

    function openSegmentedListSearch(actionType) {
        var msg = '';
        setGenericDialogSchema({ title: 'Segmented List Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <SegmentedLists handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></SegmentedLists>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('SegmentedListSearch', returnValues); } })
        setOpenDialog(true);
    }


    function onPopupOK(returnCategory, returnValues) {
        alert('onPopupOK');
        //switch (returnCategory) {
        //    case "SegmentedListSearch":
        //        var tmpObjectSettingsObj = { ...objectSettingsObj };
        //        tmpObjectSettingsObj.settings['listId'] = returnValues;
        //        setObjectSettingsObj(tmpObjectSettingsObj);
        //        onChange(tmpObjectSettingsObj);
        //        getSegmentedListInfo(returnValues);
        //        break;
        //}
    }


    function getSegmentedListInfo(listId) {
        BAFetchData({
            callName: "GetSegmentedListInfo",
            method: "GET",
            url: "crm/segmentedlist/getrecord?listid=" + listId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addItem(data) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings.list.push({ "listId": data.listId, "listName": data.listName });
        updateSummary(tmpObjectSettingsObj);
        setObjectSettingsObj(tmpObjectSettingsObj);
        onChange(tmpObjectSettingsObj);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetSegmentedListInfo":
                addItem(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }    

    function handleRemoveItem(e,index) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings.list.splice(index, 1);
        updateSummary(tmpObjectSettingsObj);
        setObjectSettingsObj(tmpObjectSettingsObj);
        onChange(tmpObjectSettingsObj);
    }

    useEffect(() => {
        setShowLoader(false);
    }, []);

    useEffect(() => {
        if (JSON.stringify(objectSettings.settings) == "{}") {
            objectSettings.settings = newSetting();
        }
        setObjectSettingsObj(objectSettings);
    }, [objectSettings]);

    return (
        <>
            {JSON.stringify(objectSettings.settings) != "{}" &&
                <div style={{display:"grid",gridTemplateColumns:"250px 1fr",gridGap:"40px"}}>
                    <div style={{ border: "1px solid #c0c0c0", padding: "10px", borderRadius: "12px" }}>
                        <DataPackageAliasListCtrl dataPackageList={dataPackageList} />
                    </div>
                    <div>
                    <div>
                        <div style={{ fontWeight: "bold" }}>
                            I want to...
                        </div>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={objectSettings.settings.action}
                                onChange={(e) => { onValueChange('action', e.target.value); }}
                            >
                                <FormControlLabel value="Add" control={<Radio />} label="Add" />
                                <FormControlLabel value="Remove" control={<Radio />} label="Remove" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div>
                        <div style={{ fontWeight: "bold", marginBottom: "10px" }}>This contact...</div>
                        <TextField fullWidth autoComplete='off' variant="outlined" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: objectSettingsObj.settings.contactId }} value={objectSettingsObj.settings.contactId || ''} onChange={(e) => { onValueChange('contactId', e.target.value); }} />
                    </div>
                    <div>
                        <div style={{ fontWeight: "bold", marginBottom: "5px" }}>{objectSettings.settings.action == "Add" ? "To" : "From"} the following list(s)...</div>
                        <div>
                            <Button variant="outlined" color="primary" size="medium" style={{ color: "#ffffff", backgroundColor: "#5E95DE", border: "1px solid rgba(0,0,0,.12)", padding: "4.5px", }} onClick={(e) => { openSegmentedListSearch(e); }}>Add List(s)</Button>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            {objectSettings.settings.list.map((item, index) => (
                                <div style={{ display: "grid", gridTemplateColumns: "70px 1fr 40px", gridGap: "5px", padding: "5px" }}>
                                    <div>{objectSettings.settings.action}</div>
                                    <div>{item.listName}</div>
                                    <div><DeleteIcon onClick={(e) => { handleRemoveItem(e, index); }} /></div>
                                </div>
                            ))}
                        </div>
                    </div>
                    </div>
                </div>
            }

            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default AOListActionCtrl;
