import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';


function CssListSettingsCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);



    function updateCssProperty(propertyName, value) {
        //var tmpValue = parseValue(value);
        var tmpValue = value;

        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnClassNameChange(value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.className = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

  

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">List Settings</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    {/*<div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>*/}
                    {/*    <div className="fg-pb-property-sub-label">List Type</div>*/}
                    {/*    <div>*/}
                    {/*        <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'position')} onChange={(e) => { updateCssProperty('position', e.target.value); }}>*/}
                    {/*            <option value="ul">Unordered</option>*/}
                    {/*            <option value="ol">Ordered</option>*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Style</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'listStyleType')} onChange={(e) => { updateCssProperty('listStyleType', e.target.value); }}>
                                <option value="unset">Show bullets</option>
                                <option value="none">Hide bullets</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CssListSettingsCtrl;

