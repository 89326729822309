import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';

function ApiConnectCtrl({ integrationId, iSchemaId, loginOnly, translationData, connected }) {

    const [currentUser] = useGlobalState("userInfo");
    const wlInfo = JSON.parse(localStorage.getItem("wl-info"));
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [integrationSchema, setIntegrationSchema] = useState({});
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    function onDisconnectBtnClick() {
        var test = '';
        test = '';
        BAFetchData({
            callName: "Disconnect",
            method: "GET",
            url: "integration/item/disconnect?integrationid=" + integrationId,
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function connectByLogin() {
        BAFetchData({
            callName: "TestApiConnection",
            method: "GET",
            url: "apiintegration/testapiconnection?integrationid=" + integrationId + "&userid=" + currentUser.userId,
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onConnectBtnClick() {
        if (integrationSchema.authType == 'user/password') {
            connectByLogin();
        } else {
            var wlAppDomain = wlInfo.wlAppDomain;
            console.log('wlAppDomain - line 41', wlAppDomain);
            if (process.env.REACT_APP_ENV == "Local") {
                wlAppDomain = 'https://localhost/' + wlInfo.wlAppDomain;
                console.log('wlAppDomain - line 44', wlAppDomain);
            } else if (process.env.REACT_APP_ENV == "Development") {
                wlAppDomain = 'http://localhost:3000';
                console.log('wlAppDomain - line 47', wlAppDomain);
            }

            var scopes = integrationSchema.settings.availableScopes.default;
            if (integrationSchema.platform == 'Google') {
                scopes = scopes.replaceAll(',', ' ');
            }
            if (loginOnly == true) {
                scopes = integrationSchema.settings.availableScopes.login;
            }
            var redirectUrl = wlAppDomain + '/authreturn';
            console.log('redirectUrl', redirectUrl);
            var queryStrings = '&display=page&scope=' + scopes;
            if (integrationSchema.platform == 'Google') {
                queryStrings += "&response_type=code&include_granted_scopes=true&access_type=offline&prompt=consent";
            }

            var state = '&state={"platform":"' + integrationSchema.platform + '","apiName":"' + integrationSchema.apiName + '","bamId":' + currentUser.currentBusinessAccountDTO.bamId + ',"integrationId":' + integrationId + ',"iSchemaId":' + iSchemaId + ',"scopes":"' + scopes + '","redirectUrl":"' + redirectUrl + '","loginOnly":true}';
            var authEndpointUrl = getHostUrlItemByName('Auth');
            var clientId = integrationSchema.settings.clientId;
            var url = authEndpointUrl.url + '?client_id=' + clientId + '&redirect_uri=' + redirectUrl + queryStrings + state
            window.location.href = url;
        }
    }

    function getHostUrlItemByName(name) {
        var hostUrlItem = null;
        var settings = integrationSchema.settings;
        for (var i = 0; i < settings.hostUrls.length; i++) {
            if (settings.hostUrls[i].name == name) {
                hostUrlItem = settings.hostUrls[i];
                break;
            }
        }
        return hostUrlItem
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                if (data.settings != null & data.settings != '') {
                    data.settings = JSON.parse(data.settings);
                }
                setIntegrationSchema(data);
                break;
            case "TestApiConnection":
                if (data == true) {
                    var msg = "";
                    setGenericDialogSchema({ title: 'Integration Connected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', showOKBtn: true, okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/integrations'); } })
                    setOpenDialog(true);
                } else {
                    var msg = "";
                    setGenericDialogSchema({ title: 'Connection Failed', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', showOKBtn: true, okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog();} })
                    setOpenDialog(true);
                }
                break;
            case "Disconnect":
                var msg = "";
                setGenericDialogSchema({ title: 'Integration Disconnected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', showOKBtn: true, okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/integrations'); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        alert(error);
        //var msg = error;
        //setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        //setOpenDialog(true);
    }

    useEffect(() => {
        if (iSchemaId != undefined) {
            BAFetchData({
                callName: "GetMainRecord",
                method: "GET",
                url: "integration/schema/getrecord?ischemaid=" + iSchemaId,
                token: "fg1234",
                onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }, [iSchemaId]);

    useEffect(() => {
        setShowLoader(false);
    }, []);

    return (
        <>
            <div>
                {connected == false ?
                    <>
                        <div style={{marginBottom:"10px"}}>
                            <div style={{ color: "#EB5454", fontWeight: "bold" }}>Not connected</div>
                        </div>                        
                        <Button variant="outlined" startIcon={<PowerIcon />} style={{ backgroundColor: "#000000", color: "white", border: "1px solid #000000" }} onClick={() => onConnectBtnClick()}>{getTranslation('contact_search_page', 'search_grid', 'Connect')}</Button>
                    </>
                    : null}

                {connected == true ?
                    <>
                        <div style={{ marginBottom: "10px" }}>
                            <div style={{ color: "green", fontWeight: "bold" }}>Connected</div>
                        </div>                       
                        <Button variant="outlined" startIcon={<PowerOffIcon />} style={{ backgroundColor: "#000000", color: "white", border: "1px solid #000000" }} onClick={() => onDisconnectBtnClick()}>{getTranslation('contact_search_page', 'search_grid', 'Disconnect')}</Button>
                    </>
                     : null}               
                
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default ApiConnectCtrl;
