import { buildAutomationWithAi, createNewAutoItem } from './utils';
import React, {
    useContext,
    useState,
    useEffect,
    createContext,
    useRef,
    useCallback,
} from "react";
import AutoItemCtrl from "./AutoItemCtrl";
import AutoItemLineConnectorsCtrl from "./AutoItemLineConnectorsCtrl";
import "./css/automationbuilderctrl.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import TitleIcon from "@mui/icons-material/Title";
import DescriptionIcon from "@mui/icons-material/Description";
import MouseIcon from "@mui/icons-material/Mouse";
import helpers from "../../helpers/Helpers";
import DialogCtrl from "../dialogctrl/dialogctrl";
import { BAFetchData } from "../../customhooks/useBAFetch";
import SettingsIcon from "@mui/icons-material/Settings";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ForecastSummaryCtrl from "./ForecastSummaryCtrl";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import DropdownMenuCtrl from "../dropdownmenuctrl/dropdownmenuctrl";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toPng } from "html-to-image";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ValueLadderCtrl from "./ValueLadderCtrl";
import PageBuilderCtrl from '../pagebuilderctrl/PageBuilderCtrl';
import { useGlobalState } from '../../globalstates/GState';

export const AutomationBrokerContext = createContext();

function AutomationBuilderCtrl({ automationId }) {

    const [currentUser] = useGlobalState("userInfo");
    const [mode, setMode] = useState('Automation');
    const [pagesToDelete, setPagesToDelete] = useState([]);
    const imgList = [
        "images/iconyoutube_off.png",
        "images/iconfacebook_off.png",
        "images/iconoptinpage_off.png",
        "images/iconoptinthankyoupage_off.png",
        "images/iconemail_off.png",
        "images/iconwait_off.png",
    ];
    const [imageLoadedCount, setImageLoadedCount] = useState(0);
    const imgCountRef = useRef(0);
    const cardList = useRef([]);
    const mouseDragRelPos = useRef(null);
    const [dragItem, setDragItem] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const shiftKeyPressedRef = useRef(false);
    const controlKeyPressedRef = useRef(false);
    const mousePosRef = useRef({});
    const startMousePosRef = useRef({});

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [itemsLoaded, setItemsLoaded] = useState("loading");

    const [formData, setFormData] = useState({});
    const [elements, setElements] = useState({});
    const [showObjPanel, setShowObjPanel] = useState(true);
    const popupOpenRef = useRef(false);
    const [showForecast, setShowForecast] = useState(false);
    const [zoom, setZoom] = useState(1);
    const [panelTab, setPanelTab] = useState("Objects");

    const canvasRef = useRef(null);

    //const [elements, setElements] = useState({
    //    autoitem_1111: {id: "autoitem_1111", autoType: "TrafficSource", autoName: "Facebook", caption: "Traffic Source", typography: {}, icon: "iconfacebook_off.png", width: "100",position: { top: 233, left: 50 }, inputs: [], outputs: { yes: { "autoitem_2222": { connectPosition: 'l-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_2222: { id: "autoitem_2222", autoType: "Page", autoName: "Optin", caption: "Sales Page", typography: {}, icon: "iconoptinpage_off.png", width: "100", position: { top: 200, left: 225 }, inputs: ['autoitem_1111'], outputs: { yes: { "autoitem_3333": { connectPosition: 'l-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_3333: { id: "autoitem_3333", autoType: "Event", autoName: "Purchase", caption: "Purchase", typography: {}, icon: "iconclick_off.png", width: "100", position: { top: 236, left: 400 }, inputs: ['autoitem_2222'], outputs: { yes: { "autoitem_4444": { connectPosition: 'l-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_4444: { id: "autoitem_4444", autoType: "Page", autoName: "Sales", caption: "Upsell", typography: {}, icon: "iconoptinpage_off.png", width: "100", position: { top: 200, left: 575 }, inputs: ['autoitem_3333'], outputs: { yes: { "autoitem_5555": { connectPosition: 'l-2', lineType: 'solid' } }, no: { "autoitem_6666": { connectPosition: 'l-1', lineType: 'solid' } } } },
    //    autoitem_5555: { id: "autoitem_5555", autoType: "Event", autoName: "Purchase", caption: "Purchase Upsell", typography: {}, icon: "iconclick_off.png", width: "100", position: { top: 231, left: 750 }, inputs: ['autoitem_4444'], outputs: { yes: { "autoitem_6666": { connectPosition: 't-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_6666: { id: "autoitem_6666", autoType: "Page", autoName: "Optin", caption: "Thankyou ", typography: {}, icon: "iconoptinpage_off.png", width: "100", position: { top: 400, left: 750 }, inputs: ['autoitem_5555', 'autoitem_4444'], outputs: { yes: {}, no: {} } },
    //});

    const moreMenuItems = [];
    moreMenuItems.push({
        menuType: "MenuItem",
        name: "AiBuilder",
        caption: getTranslation("application_shell", "profile_menu", "Ai Builder"),
        icon: null,
        link: null,
        callback: () => quickAction("Ai Builder"),
    });
    moreMenuItems.push({
        menuType: "Divider",
        name: "Divider",
        caption: null,
        icon: null,
        link: null,
        callback: null,
    });
    moreMenuItems.push({
        menuType: "MenuItem",
        name: "DoanloadBlueprint",
        caption: getTranslation(
            "application_shell",
            "profile_menu",
            "Download Blueprint Image"
        ),
        icon: null,
        link: null,
        callback: () => quickAction("Download Blueprint"),
    });
    if (helpers.permissionAllowed(currentUser.permissions, 'Marketing-Funnels/Automations', ['allowEdit']) == true) {
        moreMenuItems.push({
            menuType: "MenuItem",
            name: "BuildProject",
            caption: getTranslation(
                "application_shell",
                "profile_menu",
                "Build Project & Tasks"
            ),
            icon: null,
            link: null,
            callback: () => quickAction("Build Project"),
        });
    }
    moreMenuItems.push({
        menuType: "Divider",
        name: "Divider",
        caption: null,
        icon: null,
        link: null,
        callback: null,
    });

    if (helpers.permissionAllowed(currentUser.permissions, 'Marketing-Funnels/Automations', ['allowDelete']) == true) {
        moreMenuItems.push({
            menuType: "MenuItem",
            name: "Delete",
            caption: getTranslation("application_shell", "profile_menu", "Delete"),
            icon: null,
            link: null,
            callback: () => quickAction("Delete"),
        });
    }

    const [autoSurvey, setAutoSurvey] = useState({
        industry: "",
        budget: 0,
        maxResultDays: 90,
        moreMoneyThanTime: false,
        audienceSize: 0,
        valueLadder: [],
    });

    //const [autoSurvey, setAutoSurvey] = useState(
    //    {
    //        "industry": "Coaching",
    //        "budget": 0,
    //        "maxResultDays": 90,
    //        "moreMoneyThanTime": false,
    //        "audienceSize": 0,
    //        "valueLadder": [
    //            {
    //                "offerName": "7 ways to love your wife even when you're mad at her",
    //                "offerType": "Video",
    //                "offerTypeCaption": "Training Video",
    //                "price": 0,
    //                "displayOrder": 1,
    //                "salesCycle": 0,
    //                "moreOffers": { "includeTripwire": false, "includeUpsell": false, "includeDownsell": false }
    //            },
    //            {
    //                "offerName": "Marriage Challenge Journal",
    //                "offerType": "Book",
    //                "offerTypeCaption": "Marriage Journal",
    //                "price": 98,
    //                "displayOrder": 2,
    //                "salesCycle": 3,
    //                "moreOffers": { "includeTripwire": false, "includeUpsell": false, "includeDownsell": false }
    //            },
    //            {
    //                "offerName": "Marriage Retreat",
    //                "offerType": "Retreat",
    //                "offerTypeCaption": "Caribbean Retreat",
    //                "price": 2400,
    //                "displayOrder": 3,
    //                "salesCycle": 7,
    //                "moreOffers": { "includeTripwire": false, "includeUpsell": false, "includeDownsell": false }
    //            }
    //        ]
    //    });

    function onPageContentChange() {
        setMode('Automation')
    }

    const htmlToImageConvert = () => {
        var top = 0;
        var left = 0;
        var maxSizes = getCanvasMaxWidthHeight();
        var canvas = document.getElementById("fgao-canvas");
        canvas.style.width = maxSizes.width + "px";
        canvas.style.height = maxSizes.height + "px";
        toPng(canvasRef.current, { cacheBust: false })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = "my-image-name.png";
                link.href = dataUrl;
                link.click();
                canvas.style.width = "10000px";
                canvas.style.height = "10000px";
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function getCanvasMaxWidthHeight() {
        var top = 0;
        var left = 0;
        var width = 0;
        var height = 0;
        for (const key in elements) {
            var autoObj = elements[key];
            var el = document.getElementById(key);
            var elTopPos = autoObj.position.top + el.offsetHeight;
            var elLeftPos = autoObj.position.left + el.offsetWidth;
            if (elTopPos > height) {
                height = elTopPos;
            }
            if (elLeftPos > width) {
                width = elLeftPos;
            }
        }
        return { width: width + 50, height: height + 50 };
    }

    function quickAction(action) {
        switch (action) {
            case "Ai Builder":
                buildAutomationWithAi();
                break;
            case "Build Project":
                updateProject();
                break;
            case "Download Blueprint":
                htmlToImageConvert();
                break;
        }
    }

    function updateProject() {
        var projectId = null;
        var boardId = "140e8aa9ec0b4b1bab9b7bafe9ebcae0";
        var columnId = "column_0db891903d34ac2162fee654a5960deb";

        if (formData.projectId != null) {
            projectId = formData.projectId;
        } else {
            projectId = helpers.getUUID();
        }

        var project = {
            projectId: projectId,
            projectName: formData.name + " -- Funnel/Automation Project",
            baid: 0,
            ownerId: 18,
            managerId: 18,
            systemRecord: false,
            active: true,
            dateCreated: new Date().toISOString(),
            projectBoardMaps: [
                {
                    pbMapId: helpers.getUUID(),
                    baid: 0,
                    projectId: projectId,
                    boardId: boardId,
                    board: {
                        boardId: boardId,
                        boardMembers: [
                            {
                                boardMemberId: helpers.getUUID(),
                                baid: 0,
                                projectId: projectId,
                                boardId: boardId,
                                contactId: 18,
                                internalState: "added",
                            },
                        ],
                    },
                    internalState: "added",
                },
            ],
        };
        //var projectBoardMap = { pbMapId: helpers.getUUID(), baid: 0, projectId: projectId, boardId: boardId };
        //var boardMember = { boardMemberId: helpers.getUUID(), baid: 0, projectId: projectId, boardId: boardId, contactId: 18 };

        cardList.current = [];
        //var cardList = [];
        var card = null;
        var title = null;
        var addToList = false;
        for (const key in elements) {
            var autoObj = elements[key];
            addToList = false;
            if (
                autoObj.autoType == "TrafficSource" ||
                autoObj.autoType == "Page" ||
                autoObj.autoType == "AutoAction"
            ) {
                switch (autoObj.autoType) {
                    case "TrafficSource":
                        title =
                            "Create " +
                            autoObj.caption.replaceAll("<br/>", " ") +
                            " (" +
                            autoObj.autoName +
                            " " +
                            autoObj.postType +
                            ")";
                        addToList = true;
                        break;
                    case "Page":
                        title =
                            "Create " +
                            autoObj.caption.replaceAll("<br/>", " ") +
                            " (" +
                            autoObj.autoName +
                            " page)";
                        addToList = true;
                        break;
                    case "AutoAction":
                        switch (autoObj.autoName) {
                            case "Email":
                            case "SMS":
                            case "Call API":
                            case "Call Zapier":
                                title =
                                    "Create " +
                                    autoObj.caption.replaceAll("<br/>", " ") +
                                    " (" +
                                    autoObj.autoName +
                                    " event)";
                                addToList = true;
                                break;
                        }
                        break;
                }
                if (addToList == true) {
                    card = {
                        cardId: helpers.getUUID(),
                        baid: 0,
                        title: title,
                        projectId: projectId,
                        boardId: boardId,
                        columnId: columnId,
                        cardType: "User Story",
                        estDur: "8h",
                        estCost: 200,
                        dateCreated: new Date().toISOString(),
                        cardMembers: [],
                    };
                    cardList.current.push(card);
                }
            }
        }

        if (cardList.current.length > 0) {
            BAFetchData({
                callName: "GenerateProject",
                method: "POST",
                url: "automation/automation/generateproject",
                token: "fg1234",
                body: JSON.stringify({ project: project, cards: cardList.current }),
                onSuccess: onAPISuccess,
                onError: onAPIError,
            });
        }
    }

    function createProjectSummary() {
        var estDurMin = parseFloat(0);
        var estCost = parseFloat(0);
        var estTime = "";
        var aiEstTime = "";
        var aiCostSavings = parseFloat(0);
        var userCost = parseFloat(0);
        for (var i = 0; i < cardList.current.length; i++) {
            var cardObj = cardList.current[i];
            estDurMin += parseInt(
                helpers.convertShorthandToMin(cardObj.estDur || "")
            );
            estCost += cardObj.estCost;
        }

        estTime = helpers.convertMinToShorthandReadable(estDurMin);
        aiEstTime = helpers.convertMinToShorthandReadable(estDurMin * 0.2);
        aiCostSavings = estCost * 0.8;
        userCost = estCost * 0.2;
        var msg = "";
        msg +=
            "<div style='display:grid;grid-template-columns:250px 1fr;grid-gap:30px;max-width:700px;'>";
        msg += "    <div style='background-color:whitesmoke;position:relative;'>";
        msg +=
            "        <img alt='' src='images/fg_ai_avatar_ma.png' style='width:100%;position:absolute;bottom:0;'/>";
        msg += "    </div>";
        msg += "    <div>";
        msg +=
            "        <p>I created all user stories along with the associated tasks so you and I can track the progress of the project and ensure it is completed on-time.</p>";
        msg +=
            "        <p>I took the liberty to provide you a project summary below:";
        msg += "            <ul>";
        msg +=
            "                <li>This project contains <span style='display;font-weight:bold;'>" +
            cardList.current.length +
            " user stories</span>.</li>";
        msg +=
            "                <li>This project will take roughly <span style='display;font-weight:bold;'>" +
            estTime +
            "</span> to complete.</li>";
        msg +=
            "                <li>This project will cost roughly <span style='display;font-weight:bold;'>$" +
            helpers.formatNumber(estCost, 2) +
            "</span> to build (if you're not building it all yourself.).</li>";
        msg += "            </ul></p>";
        msg += "        </p>";
        msg +=
            "        <p>If you like... I can complete roughly 80% of the tasks myself and save you <span style='display;font-weight:bold;'>$" +
            helpers.formatNumber(aiCostSavings, 2) +
            "</span>, which means you should only have to spend around <span style='display;font-weight:bold;'>$" +
            helpers.formatNumber(userCost, 2) +
            "</span>. I can have my work done in 5 to 10 minutes, which leaves you with only <span style='display;font-weight:bold;'>" +
            aiEstTime +
            "</span> of work.</p>";
        msg += "        <p>Would you like me to begin?</p>";
        msg += "    </div>";
        msg += "</div>";

        setGenericDialogSchema({
            title: "Project Creation Completed",
            component: null,
            body: { innerHtml: { __html: msg } },
            dialogType: "normal",
            fullWidth: true,
            maxWidth: "600",
            showCancelBtn: true,
            showOKBtn: true,
            cancelBtnText: "No... not at this time",
            okBtnText: "Sure",
            colorClass: "fg-modal-success",
            closeModal: () => {
                setOpenDialog();
            },
            okModal: () => {
                setOpenDialog();
            },
        });
        setOpenDialog(true);
        cardList.current = [];
    }

    function getTranslation(a, b, value) {
        return value;
    }

    function handleOnDragFromList(e: React.DragEvent, autoType) {
        e.dataTransfer.setData(
            "autoItemInfo",
            JSON.stringify({ id: null, autoType: autoType })
        );
        //setDragItem({ id: null, autoType: autoType });
        mouseDragRelPos.current = {
            x: e.nativeEvent.offsetX,
            y: e.nativeEvent.offsetY,
        };
    }

    function handleOnDragFromCanvas(e: React.DragEvent, id, autoType) {
        startMousePosRef.current = { ...mousePosRef.current };
        //document.getElementById(id).style.display = 'none';
        e.dataTransfer.setData(
            "autoItemInfo",
            JSON.stringify({ id: id, autoType: autoType })
        );
        //setDragItem({ id: id, autoType: autoType });
        mouseDragRelPos.current = {
            x: e.nativeEvent.offsetX,
            y: e.nativeEvent.offsetY,
        };
    }

    function allowDrop(e) {
        e.preventDefault();
    }

    function handleOnDrop(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();

        const autoItemInfoStr = e.dataTransfer.getData("autoItemInfo");
        const autoItemInfo = JSON.parse(autoItemInfoStr);

        if (autoItemInfo.id == null) {
            var newId = addNewAutoItem(
                autoItemInfo.autoType,
                e.nativeEvent.offsetY - mouseDragRelPos.current.y,
                e.nativeEvent.offsetX - mouseDragRelPos.current.x
            );
            setSelectedItems([newId]);
        } else {
            var tmpElements = { ...elements };
            if (e.target.className.indexOf("fgao-auto-item") > -1) {
                var canvasPos = document
                    .getElementById("fgao-canvas")
                    .getBoundingClientRect();
                tmpElements[autoItemInfo.id].position = {
                    top: e.nativeEvent.y - canvasPos.y - mouseDragRelPos.current.y,
                    left: e.nativeEvent.x - canvasPos.x - mouseDragRelPos.current.x,
                };
            } else {
                tmpElements[autoItemInfo.id].position = {
                    top: e.nativeEvent.offsetY - mouseDragRelPos.current.y,
                    left: e.nativeEvent.offsetX - mouseDragRelPos.current.x,
                };
            }
            setElements((...elements) => {
                return tmpElements;
            });
            setSelectedItems([autoItemInfo.id]);
        }
    }

    function addNewAutoItem(autoType, top, left) {
        var newId = "fgao_" + helpers.getUUID();
        var newAutoItemDetails = createNewAutoItem(autoType);

        newAutoItemDetails.id = newId;
        newAutoItemDetails.position = { top: top, left: left };
        var tmpElements = { ...elements };
        tmpElements[newId] = newAutoItemDetails;
        setElements((...elements) => {
            return tmpElements;
        });

        return newId;
    }
    //useEffect(() => {
    //    imgList.forEach(image => (new Image().src = image));
    //}, []);

    function updateLoaded(key, ei) {
        if (ei + 1 == Object.keys(elements).length) {
            setItemsLoaded("loaded");
        }
        return (
            <>
                <AutoItemCtrl
                    key={"autoitem_" + ei}
                    autoItemData={elements[key]}
                ></AutoItemCtrl>
            </>
        );
    }



    function handleDeleteAOObjectsConfirm() {
        if (selectedItems.length > 0) {
            var plural = helpers.getPlural(selectedItems.length);
            var msg =
                "You are about to delete " +
                selectedItems.length +
                " object" +
                plural +
                '. <span style="font-weight:bold;">Are you sure</span>?';
            setGenericDialogSchema({
                title: "Delete Confirmation",
                component: null,
                body: { innerHtml: { __html: msg } },
                dialogType: "normal",
                fullWidth: true,
                maxWidth: "sm",
                showCancelBtn: true,
                showOKBtn: true,
                cancelBtnText: "Cancel",
                okBtnText: "OK",
                colorClass: "fg-modal-success",
                closeModal: () => {
                    setOpenDialog();
                },
                okModal: () => {
                    setOpenDialog();
                    deleteAutoItems();
                },
            });
            setOpenDialog(true);
        } else {
            var msg = "One or more objects must be selected first.";
            setGenericDialogSchema({
                title: "No Objects Selected",
                component: null,
                body: { innerHtml: { __html: msg } },
                dialogType: "normal",
                fullWidth: true,
                maxWidth: "sm",
                showCancelBtn: false,
                showOKBtn: true,
                cancelBtnText: "Cancel",
                okBtnText: "OK",
                colorClass: "fg-modal-success",
                closeModal: () => {
                    setOpenDialog();
                },
                okModal: () => {
                    setOpenDialog();
                },
            });
            setOpenDialog(true);
        }
    }

    function deleteAutoItems() {
        var tmpPagesToDelete = [...pagesToDelete];
        
        imgCountRef.current = 0;
        setItemsLoaded("loading");

        var tmpElements = { ...elements };
        for (var i = 0; i < selectedItems.length; i++) {
            if (tmpElements[selectedItems[i]].autoType == "Page") {
                tmpPagesToDelete.push(selectedItems[i]);
            } else if (tmpElements[selectedItems[i]].autoType == "AutoAction" && tmpElements[selectedItems[i]].autoName == "Email") {
                tmpPagesToDelete.push(selectedItems[i]);
            }
            deleteAutoItem(tmpElements, selectedItems[i]);
        }
        setElements((...elements) => {
            return tmpElements;
        });
        setSelectedItems([]);
        setPagesToDelete(tmpPagesToDelete);
    }

    function clearCanvas() {
        imgCountRef.current = 0;
        setItemsLoaded("loading");

        var tmpElements = { ...elements };

        for (const key in elements) {
            deleteAutoItem(tmpElements, key);
        }
        setElements((...elements) => {
            return tmpElements;
        });
        setSelectedItems([]);
    }

    function deleteAutoItem(tmpElements, id) {
        var elementObj = tmpElements[id];
        var sourceId = null;
        var destId = null;
        var lineId = null;

        //remove inputs data
        for (var ii = elementObj.inputs.length - 1; ii >= 0; ii--) {
            sourceId = elementObj.inputs[ii];

            //elementObj.inputs.splice(ii, 1);

            //remove source - yes
            var sourceElementObj = tmpElements[sourceId];
            if (sourceElementObj != null) {
                for (const key in sourceElementObj.outputs.yes) {
                    if (key == id) {
                        delete sourceElementObj.outputs.yes[id];
                        break;
                    }
                }

                //remove source - no
                for (const key in sourceElementObj.outputs.no) {
                    if (key == id) {
                        delete sourceElementObj.outputs.no[id];
                    }
                }
            }
        }

        //remove outputs data
        //remove source - yes
        for (const key in elementObj.outputs.yes) {
            var destId = key;
            //delete elementObj.outputs.yes[destId];

            var destElementObj = tmpElements[destId];
            if (destElementObj != null) {
                for (var ii = destElementObj.inputs.length - 1; ii >= 0; ii--) {
                    if (destElementObj.inputs[ii] == id) {
                        destElementObj.inputs.splice(ii, 1);
                        break;
                    }
                }
            }
        }

        //remove source - no
        for (const key in elementObj.outputs.no) {
            var destId = key;
            //delete elementObj.outputs.no[destId];

            var destElementObj = tmpElements[destId];
            if (destElementObj != null) {
                for (var ii = destElementObj.inputs.length - 1; ii >= 0; ii--) {
                    if (destElementObj.inputs[ii] == id) {
                        destElementObj.inputs.splice(ii, 1);
                        break;
                    }
                }
            }
        }
        delete tmpElements[id];
    }

    function deleteConnector(lineId) {
        //var htmlElement = document.getElementById(id);
        //htmlElement.remove();
    }

    function handleCanvasClick(e) {
        setSelectedItems([]);
    }

    function handleSelectAllClick(e) {
        var tmpSelectedItems = [];
        for (const key in elements) {
            tmpSelectedItems.push(key);
        }
        setSelectedItems(tmpSelectedItems);
    }

    function handleDuplicateAllClick(e) {
        cloneSelectedObjects();
    }

    function cloneSelectedObjects() {
        var tmpSelectedItems = [];
        var newCloneId = null;
        var sourceId = null;
        var el = null;
        var cloneStr = null;
        var cloneObj = null;
        var tmpElements = { ...elements };

        for (var i = 0; i < selectedItems.length; i++) {
            sourceId = selectedItems[i];
            newCloneId = "fgao_" + helpers.getUUID();

            el = document.getElementById(sourceId);
            cloneStr = JSON.stringify(elements[sourceId]);

            cloneObj = JSON.parse(cloneStr);
            cloneObj.id = newCloneId;
            cloneObj.outputs.yes = {};
            cloneObj.outputs.no = {};
            cloneObj.inputs = [];
            cloneObj.position.left = cloneObj.position.left + el.offsetWidth + 50;

            tmpElements[newCloneId] = cloneObj;
            tmpSelectedItems.push(newCloneId);

            if (cloneObj.autoType == "Page") {
                BAFetchData({
                    callName: "CopyPage",
                    method: "GET",
                    url: "cms/sitepages/copy?oldSitePageId=" + sourceId + "&newSitePageId=" + newCloneId,
                    token: "fg1234",
                    body: null, onSuccess: onAPISuccess, onError: onAPIError
                });
            } else if (cloneObj.autoType == "AutoAction" && cloneObj.autoName == "Email") {
                BAFetchData({
                    callName: "CopyEmail",
                    method: "GET",
                    url: "automation/automationobject/copy?oldAutoObjectId=" + sourceId + "&newAutoObjectId=" + newCloneId,
                    token: "fg1234",
                    body: null, onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
        setElements(tmpElements);
        setSelectedItems(tmpSelectedItems);
    }

    function toggleObjPanel() {
        if (showObjPanel == true) {
            setShowObjPanel(false);
        } else {
            setShowObjPanel(true);
        }
    }

    function alignItems(e, alignType) {
        var firstId = selectedItems[0];
        var tmpElements = { ...elements };
        var firstElementObj = tmpElements[firstId];
        var firstElementWidth = document.getElementById(firstId).offsetWidth;
        var firstElementHeight = document.getElementById(firstId).offsetHeight;
        var siblingElementWidth = 0;
        var siblingElementHeight = 0;
        var siblingId = null;
        for (var i = 0; i < selectedItems.length; i++) {
            if (i > 0) {
                siblingId = selectedItems[i];
                siblingElementWidth = document.getElementById(siblingId).offsetWidth;
                siblingElementHeight = document.getElementById(siblingId).offsetHeight;
                switch (alignType) {
                    case "top":
                        tmpElements[siblingId].position.top = firstElementObj.position.top;
                        break;
                    case "right":
                        tmpElements[siblingId].position.left =
                            firstElementObj.position.left +
                            firstElementWidth -
                            siblingElementWidth;
                        break;
                    case "bottom":
                        tmpElements[siblingId].position.top =
                            firstElementObj.position.top +
                            firstElementHeight -
                            siblingElementHeight;
                        break;
                    case "left":
                        tmpElements[siblingId].position.left =
                            firstElementObj.position.left;
                        break;
                    case "vcenter":
                        if (firstElementHeight == siblingElementHeight) {
                            tmpElements[siblingId].position.top =
                                firstElementObj.position.top;
                        } else if (firstElementHeight > siblingElementHeight) {
                            tmpElements[siblingId].position.top =
                                firstElementObj.position.top +
                                (firstElementHeight - siblingElementHeight) / 2;
                        } else if (firstElementHeight < siblingElementHeight) {
                            tmpElements[siblingId].position.top =
                                firstElementObj.position.top -
                                (siblingElementHeight - firstElementHeight) / 2;
                        }
                        break;
                    case "hcenter":
                        tmpElements[siblingId].position.left =
                            firstElementObj.position.left;

                        if (firstElementWidth == siblingElementWidth) {
                            tmpElements[siblingId].position.left =
                                firstElementObj.position.left;
                        } else if (firstElementWidth > siblingElementWidth) {
                            tmpElements[siblingId].position.left =
                                firstElementObj.position.left +
                                (firstElementWidth - siblingElementWidth) / 2;
                        } else if (firstElementWidth < siblingElementWidth) {
                            tmpElements[siblingId].position.left =
                                firstElementObj.position.left -
                                (siblingElementWidth - firstElementWidth) / 2;
                        }
                        break;

                        break;
                }
            }
        }
        setElements(tmpElements);
    }

    function nudge(direction) {
        var nudgeSize = 15;
        if (controlKeyPressedRef.current == true) {
            nudgeSize = 1;
        }
        var id = null;
        var tmpElements = { ...elements };
        for (var i = 0; i < selectedItems.length; i++) {
            id = selectedItems[i];
            switch (direction) {
                case "up":
                    tmpElements[id].position.top =
                        tmpElements[id].position.top - nudgeSize;
                    break;
                case "right":
                    tmpElements[id].position.left =
                        tmpElements[id].position.left + nudgeSize;
                    break;
                case "down":
                    tmpElements[id].position.top =
                        tmpElements[id].position.top + nudgeSize;
                    break;
                case "left":
                    tmpElements[id].position.left =
                        tmpElements[id].position.left - nudgeSize;
                    break;
            }
            setElements(tmpElements);
        }
    }

    function removeLinkedSitePages() {
        BAFetchData({
            callName: "BulkPagesDelete",
            method: "POST",
            url: "cms/sitepages/bulkdelete",
            token: "fg1234",
            body: pagesToDelete, onSuccess: onAPISuccess, onError: onAPIError
        });
        BAFetchData({
            callName: "BulkEmailDelete",
            method: "POST",
            url: "automation/automationobject/bulkdelete",
            token: "fg1234",
            body: pagesToDelete, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function eventAnscestorExists(key) {
        var exists = false;
        var autoObj = elements[key];

        for (var i = 0; i < autoObj.inputs.length; i++) {
            if (eventAnscestorExists(autoObj.inputs[i]) == true) {
                exists = true;
                break;
            }
        }
        return exists;
    }

    function publishEntryPoints(instanceId) {
        var keyEntryPoints = [];
        for (const key in elements) {
            if (elements[key].autoType == 'Event') {
                if (eventAnscestorExists(key) == false) {
                    keyEntryPoints.push(key);

                    var params = "automationid=" + automationId + "&instanceid=" + instanceId + "&phraseid=" + elements[key].phraseId + "&autoobjectid=" + elements[key].id + "&autotype=" + elements[key].autoType;

                    BAFetchData({
                        callName: "PublishEntryPoint",
                        method: "GET",
                        url: "automation/automation/publishentrypoint?" + params,
                        token: "fg1234",
                        body: null, onSuccess: onAPISuccess, onError: onAPIError
                    });

                }
            }
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                //addDefaultData(data);
                data.elements = JSON.parse(data.elements);
                data.forecastSummary = JSON.parse(data.forecastSummary);
                data.autoSurvey = JSON.parse(data.autoSurvey);
                setFormData(data);
                setElements(data.elements);
                //setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                break;
            case "SaveForm":
                //setPrimaryKeyValue(data.blogId);
                //setFormData(data);
                removeLinkedSitePages();
                var msg = "Form has been saved.";
                setGenericDialogSchema({
                    title: "Form Saved",
                    body: { innerHtml: { __html: msg } },
                    showCancelBtn: false,
                    cancelBtnText: "Cancel",
                    okBtnText: "OK",
                    colorClass: "fg-modal-success",
                    closeModal: () => {
                        setOpenDialog();
                    },
                    okModal: () => {
                        setOpenDialog();
                    },
                });
                setOpenDialog(true);

                break;
            case "PublishAutomation":
                publishEntryPoints(data);
                var msg = "Automation has been published.";
                setGenericDialogSchema({
                    title: "Automation Published",
                    body: { innerHtml: { __html: msg } },
                    showCancelBtn: false,
                    showOKBtn: true,
                    cancelBtnText: "Cancel",
                    okBtnText: "OK",
                    colorClass: "fg-modal-success",
                    closeModal: () => {
                        setOpenDialog();
                    },
                    okModal: () => {
                        setOpenDialog();
                    },
                });
                setOpenDialog(true);
                break;
            case "GenerateProject":
                createProjectSummary();
                //var msg = 'Project has been created';
                //setGenericDialogSchema({ title: 'Automation Project Created', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                //setOpenDialog(true);
                break;
            case "BulkEmailDelete":
                setPagesToDelete([]);
                break;
        }
    }

    function handleOnForecastSummaryChange(value) {
        var tmpFormData = { ...formData };
        tmpFormData.forecastSummary = value;
        setFormData(tmpFormData);
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({
            title: "API Call Error",
            component: null,
            body: { innerHtml: { __html: msg } },
            dialogType: "normal",
            fullWidth: true,
            maxWidth: "sm",
            showCancelBtn: false,
            cancelBtnText: "Cancel",
            okBtnText: "OK",
            colorClass: "fg-modal-success",
            closeModal: () => {
                setOpenDialog();
            },
            okModal: () => {
                setOpenDialog();
            },
        });
        setOpenDialog(true);
    }

    function switchMode(id) {
        if ((elements[selectedItems[0]].autoType == "Page") && (elements[selectedItems[0]].pageInfo.pageName == null || elements[selectedItems[0]].pageInfo.pageName == '')) {
            var msg = "Page name is required";
            setGenericDialogSchema({
                title: "Error",
                component: null,
                body: { innerHtml: { __html: msg } },
                dialogType: "normal",
                fullWidth: true,
                maxWidth: "600",
                showCancelBtn: false,
                showOKBtn: true,
                cancelBtnText: "Cancel",
                okBtnText: "OK",
                colorClass: "fg-modal-success",
                closeModal: () => {
                    setOpenDialog();
                },
                okModal: () => {
                    setOpenDialog();
                },
            });
            setOpenDialog(true);
        } else {
            popupOpenRef.current = true;
            setSelectedItems([id]);
            setMode('Page Builder');
            console.log("E key pressed");
        }
    }

    function publishAutomation(e) {
        BAFetchData({
            callName: "PublishAutomation",
            method: "GET",
            url: "automation/automation/publish?automationid=" + automationId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess,
            onError: onAPIError,
        });
    }

    function saveAutomation(e) {
        var tmpFormData = { ...formData };
        tmpFormData.elements = JSON.stringify(elements);
        tmpFormData.forecastSummary = JSON.stringify(tmpFormData.forecastSummary);
        tmpFormData.autoSurvey = JSON.stringify(autoSurvey);
        tmpFormData.republishStatus = "Re-publish";
        BAFetchData({
            callName: "SaveForm",
            method: "PUT",
            url: "automation/automation/update",
            token: "fg1234",
            body: JSON.stringify(tmpFormData),
            onSuccess: onAPISuccess,
            onError: onAPIError,
        });
    }

    useEffect(() => {
        BAFetchData({
            callName: "GetMainRecord",
            method: "GET",
            url: "automation/automation/getrecord?automationid=" + automationId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess,
            onError: onAPIError,
        });
    }, []);

    useEffect(() => {
        function handlePageKeyDown(e) {
            if (popupOpenRef.current == false) {
                if (e.key == "Shift") {
                    if (selectedItems.length > 0) {
                        shiftKeyPressedRef.current = true;
                        console.log("Shift pressed");
                    }
                } else if (e.key == "Control") {
                    if (selectedItems.length > 0) {
                        controlKeyPressedRef.current = true;
                        console.log("Control pressed");
                    }
                } else if (e.key == "e") {
                    if (selectedItems.length > 0) {
                        if (elements[selectedItems[0]].autoType == "Page" || (elements[selectedItems[0]].autoType == "AutoAction" && elements[selectedItems[0]].autoName == "Email")) {
                            e.preventDefault();
                            switchMode(selectedItems[0]);
                        }
                    }
                } else if (e.key == "Delete") {
                    if (selectedItems.length > 0) {
                        handleDeleteAOObjectsConfirm();
                        console.log("Delete key pressed");
                    }
                } else if (["27", "Escape"].includes(String(e.key))) {
                    setSelectedItems([]);
                    console.log("ESC key pressed");
                } else if (e.key == "ArrowUp") {
                    if (selectedItems.length > 0) {
                        e.preventDefault();
                        nudge("up");
                    }
                } else if (e.key == "ArrowRight") {
                    if (selectedItems.length > 0) {
                        e.preventDefault();
                        nudge("right");
                    }
                } else if (e.key == "ArrowDown") {
                    if (popupOpenRef.current == false) {
                        if (selectedItems.length > 0) {
                            e.preventDefault();
                            nudge("down");
                        }
                    }
                } else if (e.key == "ArrowLeft") {
                    if (selectedItems.length > 0) {
                        e.preventDefault();
                        nudge("left");
                    }
                }
            }
            //if (['27', 'Escape'].includes(String(e.key))) {
            //hideHighlighters();
            //}
        }
        window.addEventListener("keydown", handlePageKeyDown);
        return (_) => {
            window.removeEventListener("keydown", handlePageKeyDown);
        };
    });

    useEffect(() => {
        function handlePageKeyUp(e) {
            switch (e.key) {
                case "Shift":
                    if (selectedItems.length > 0) {
                        shiftKeyPressedRef.current = false;
                        console.log("Shift released");
                    }
                    break;
                case "Control":
                    if (selectedItems.length > 0) {
                        controlKeyPressedRef.current = false;
                        console.log("Control released");
                    }
                    break;
            }

            //if (['27', 'Escape'].includes(String(e.key))) {
            //hideHighlighters();
            //}
        }
        window.addEventListener("keyup", handlePageKeyUp);
        return (_) => {
            window.removeEventListener("keyup", handlePageKeyUp);
        };
    });

    useEffect(() => {
        const handleMouseMove = (event) => {
            mousePosRef.current = { x: event.clientX, y: event.clientY };
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    });

    useEffect(() => {
        if (autoSurvey.valueLadder.length == 0) {
            console.log('STOP HERE!')
            return;
        }

        const result = buildAutomationWithAi(autoSurvey);
        console.log('result', result)
        setElements(result);
    }, [autoSurvey]);

    function changeZoom(e, direction) {
        var tmpZoom = parseFloat(parseFloat(zoom).toFixed(2));
        var increment = parseFloat(0.2);
        switch (direction) {
            case "In":
                if (tmpZoom == 2) {
                    tmpZoom = 2;
                } else {
                    tmpZoom += increment;
                }
                break;
            case "Out":
                if (tmpZoom == 0.4) {
                    tmpZoom = 0.4;
                } else {
                    tmpZoom -= increment;
                }
                break;
            case "Reset":
                tmpZoom = 1;
                break;
        }
        setZoom(tmpZoom);
    }

    function changeTab(tabName) {
        setPanelTab(tabName);
    }

    function showActiveTab(tabName) {
        var className = "";
        if (panelTab == tabName) {
            className = "fg-pb-panel-tab-active";
        }
        return className;
    }

    return (
        <>
            {mode == 'Automation' ?
                <AutomationBrokerContext.Provider
                    value={{
                        elements: elements,
                        setElements: setElements,
                        formData: formData,
                        setFormData: setFormData,
                        showForecast: showForecast,
                        itemsLoaded: itemsLoaded,
                        setItemsLoaded: setItemsLoaded,
                        imageLoadedCount: imageLoadedCount,
                        setImageLoadedCount: setImageLoadedCount,
                        imgCountRef: imgCountRef,
                        handleOnDragFromCanvas: (e, id, autoType) => {
                            handleOnDragFromCanvas(e, id, autoType);
                        },
                        selectedItems: selectedItems,
                        setSelectedItems: setSelectedItems,
                        shiftKeyPressedRef: shiftKeyPressedRef,
                        controlKeyPressedRef: controlKeyPressedRef,
                        mousePosRef: mousePosRef,
                        popupOpenRef: popupOpenRef,
                        switchMode: (id) => { switchMode(id) }
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            backgroundColor: "#ffffff",
                            padding: "7px 10px 7px 10px",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "25px 1fr",
                                gridGap: "5px",
                                width: "100%",
                                paddingTop: "5px",
                            }}
                        >
                            <div>
                                <SettingsIcon style={{ color: "#768387" }} />
                            </div>
                            <div style={{ fontSize: "18px", color: "#768387" }}>
                                {formData.name}
                            </div>
                        </div>

                        <div style={{ textAlign: "right" }}>
                            <div style={{ display: "inline-block", marginRight: "20px" }}>
                                <div
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "50px 20px 20px 1fr",
                                        gridGap: "2px",
                                        width: "170px",
                                        border: "1px solid #9E9E9E",
                                        borderRadius: "4px",
                                        padding: "0px 4px 0px 4px",
                                    }}
                                >
                                    <div style={{ paddingTop: "5px", textAlign: "center" }}>
                                        {parseFloat(zoom * 100).toFixed(0) + "%"}
                                    </div>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            style={{
                                                fontSize: "18px",
                                                backgroundColor: "#ffffff",
                                                color: "#9E9E9E",
                                                margin: "0px",
                                                minWidth: "20px",
                                                border: "none",
                                                padding: "4.5px",
                                            }}
                                            onClick={(e) => {
                                                changeZoom(e, "In");
                                            }}
                                        >
                                            <AddIcon />
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            style={{
                                                fontSize: "18px",
                                                backgroundColor: "#ffffff",
                                                color: "#9E9E9E",
                                                margin: "0px",
                                                minWidth: "20px",
                                                border: "none",
                                                padding: "4.5px",
                                            }}
                                            onClick={(e) => {
                                                changeZoom(e, "Out");
                                            }}
                                        >
                                            <RemoveIcon />
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            style={{
                                                backgroundColor: "#ffffff",
                                                color: "#9E9E9E",
                                                margin: "0px",
                                                border: "none",
                                                padding: "4.5px",
                                            }}
                                            onClick={(e) => {
                                                changeZoom(e, "Reset");
                                            }}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <FormControlLabel
                                style={{ marginBottom: "0px" }}
                                control={
                                    <Switch
                                        checked={showForecast}
                                        onChange={(e) => setShowForecast(e.target.checked)}
                                        name="gilad"
                                    />
                                }
                                label="Forecast"
                            />
                            <div style={{ display: "inline-block" }}>
                                <DropdownMenuCtrl
                                    buttonType="Button"
                                    buttonText="Actions"
                                    imageSrc=""
                                    avatarText=""
                                    toolTip={getTranslation(
                                        "application_shell",
                                        "profile_menu",
                                        "Profile Menu"
                                    )}
                                    menuItems={moreMenuItems}
                                    iconStyle={{
                                        width: 28,
                                        height: 28,
                                        marginBottom: "0px",
                                        fontSize: "14px",
                                    }}
                                ></DropdownMenuCtrl>
                            </div>
                            {helpers.permissionAllowed(currentUser.permissions, 'Marketing-Funnels/Automations', ['allowEdit']) == true ?
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    style={{
                                        color: "#ffffff",
                                        backgroundColor: "#26C6DA",
                                        marginLeft: "5px",
                                        border: "1px solid rgba(0,0,0,.12)",
                                        padding: "4.5px",
                                    }}
                                    onClick={(e) => {
                                        publishAutomation(e);
                                    }}
                                >
                                    {(formData.republishStatus == null || formData.republishStatus == '') ? "Publish" : formData.republishStatus}
                                </Button>
                                : null}
                            {helpers.permissionAllowed(currentUser.permissions, 'Marketing-Funnels/Automations', ['allowEdit']) == true ?
                            <Button
                                variant="outlined"
                                color="primary"
                                size="medium"
                                style={{
                                    color: "#ffffff",
                                    backgroundColor: "#5E95DE",
                                    marginLeft: "5px",
                                    border: "1px solid rgba(0,0,0,.12)",
                                    padding: "4.5px",
                                }}
                                onClick={(e) => {
                                    saveAutomation(e);
                                }}
                                accessKey="s"
                            >
                                Save
                                </Button>
                            : null}
                        </div>
                    </div>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns:
                                showObjPanel == true
                                    ? "300px 1fr" + (showForecast == true ? " 300px" : "")
                                    : "25px 1fr" + (showForecast == true ? " 300px" : ""),
                            height: "850px",
                        }}
                    >
                        <div style={{ height: "850px" }}>
                            {showObjPanel == true ? (
                                <div
                                    style={{
                                        height: "850px",
                                        padding: "10px 10px 10px 10px",
                                        overflow: "auto",
                                    }}
                                >
                                    <div
                                        style={{ display: "grid", gridTemplateColumns: "1fr 30px" }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns: "1fr 1fr",
                                                    gridGap: "5px",
                                                    width: "100%",
                                                    borderBottom: "1px solid #C0C0C0",
                                                }}
                                            >
                                                <div
                                                    className={
                                                        "fg-pb-panel-tab " + showActiveTab("Objects")
                                                    }
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        changeTab("Objects");
                                                    }}
                                                >
                                                    Objects
                                                </div>
                                                <div
                                                    className={
                                                        "fg-pb-panel-tab " + showActiveTab("ValueLadder")
                                                    }
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        changeTab("ValueLadder");
                                                    }}
                                                >
                                                    Value Ladder
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                toggleObjPanel(e);
                                            }}
                                            style={{ cursor: "default", textAlign: "right" }}
                                        >
                                            <HighlightOffIcon
                                                style={{ color: "#768387", cursor: "default" }}
                                            />
                                        </div>
                                    </div>
                                    {panelTab == "Objects" ? (
                                        <div>
                                            <div
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns: "75px 1fr 1fr 1fr 1fr 1fr 1fr",
                                                    gridGap: "3px",
                                                    width: "100%",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <div>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        style={{
                                                            color: "#000000",
                                                            backgroundColor: "white",
                                                            minWidth: "unset",
                                                            width: "100%",
                                                            border: "1px solid rgba(0,0,0,.12)",
                                                            padding: "4px",
                                                            fontSize: "10px",
                                                        }}
                                                        onClick={(e) => {
                                                            handleSelectAllClick(e);
                                                        }}
                                                        accessKey="a"
                                                    >
                                                        Select All
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        style={{
                                                            color: "#000000",
                                                            backgroundColor: "white",
                                                            minWidth: "unset",
                                                            width: "100%",
                                                            border: "1px solid rgba(0,0,0,.12)",
                                                            padding: "6px",
                                                        }}
                                                        onClick={(e) => {
                                                            alignItems(e, "top");
                                                        }}
                                                    >
                                                        <AlignVerticalTopIcon fontSize="26" />
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        style={{
                                                            color: "#000000",
                                                            backgroundColor: "white",
                                                            minWidth: "unset",
                                                            width: "100%",
                                                            border: "1px solid rgba(0,0,0,.12)",
                                                            padding: "6px",
                                                        }}
                                                        onClick={(e) => {
                                                            alignItems(e, "bottom");
                                                        }}
                                                    >
                                                        <AlignVerticalBottomIcon fontSize="26" />
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        style={{
                                                            color: "#000000",
                                                            backgroundColor: "white",
                                                            minWidth: "unset",
                                                            width: "100%",
                                                            border: "1px solid rgba(0,0,0,.12)",
                                                            padding: "6px",
                                                        }}
                                                        onClick={(e) => {
                                                            alignItems(e, "vcenter");
                                                        }}
                                                    >
                                                        <AlignVerticalCenterIcon fontSize="26" />
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        style={{
                                                            color: "#000000",
                                                            backgroundColor: "white",
                                                            minWidth: "unset",
                                                            width: "100%",
                                                            border: "1px solid rgba(0,0,0,.12)",
                                                            padding: "6px",
                                                        }}
                                                        onClick={(e) => {
                                                            alignItems(e, "left");
                                                        }}
                                                    >
                                                        <AlignHorizontalLeftIcon fontSize="26" />
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        style={{
                                                            color: "#000000",
                                                            backgroundColor: "white",
                                                            minWidth: "unset",
                                                            width: "100%",
                                                            border: "1px solid rgba(0,0,0,.12)",
                                                            padding: "6px",
                                                        }}
                                                        onClick={(e) => {
                                                            alignItems(e, "right");
                                                        }}
                                                    >
                                                        <AlignHorizontalRightIcon fontSize="26" />
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        style={{
                                                            color: "#000000",
                                                            backgroundColor: "white",
                                                            minWidth: "unset",
                                                            width: "100%",
                                                            border: "1px solid rgba(0,0,0,.12)",
                                                            padding: "6px",
                                                        }}
                                                        onClick={(e) => {
                                                            alignItems(e, "hcenter");
                                                        }}
                                                    >
                                                        <AlignHorizontalCenterIcon fontSize="26" />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns: "108px 1fr 1fr 1fr 1fr 1fr 1fr",
                                                    gridGap: "3px",
                                                    width: "100%",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                <div>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        style={{
                                                            color: "#000000",
                                                            backgroundColor: "white",
                                                            minWidth: "unset",
                                                            width: "100%",
                                                            border: "1px solid rgba(0,0,0,.12)",
                                                            padding: "4px",
                                                            fontSize: "10px",
                                                        }}
                                                        onClick={(e) => {
                                                            handleDuplicateAllClick(e);
                                                        }}
                                                        accessKey="c"
                                                    >
                                                        Duplicate All
                                                    </Button>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    color: "#768387",
                                                    textAlign: "center",
                                                    margin: "10px 0px 10px 0px",
                                                }}
                                            >
                                                Objects
                                            </div>
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    fontSize: "14px",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                Drag the desired object on the canvas.
                                            </div>
                                            <div
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns: "1fr 1fr 1fr",
                                                    gridGap: "10px",
                                                }}
                                            >
                                                <div
                                                    className="fgao-panel-section-item"
                                                    draggable
                                                    onDragStart={(e) =>
                                                        handleOnDragFromList(e, "TrafficSource")
                                                    }
                                                >
                                                    <div>
                                                        <DirectionsWalkIcon
                                                            className="fgao-panel-section-item-icon"
                                                            fontSize="18"
                                                            style={{ pointerEvents: "none" }}
                                                        />
                                                    </div>
                                                    <div className="fgao-panel-section-item-text">
                                                        Traffic
                                                        <br />
                                                        Source
                                                    </div>
                                                </div>
                                                <div
                                                    className="fgao-panel-section-item"
                                                    draggable
                                                    onDragStart={(e) => handleOnDragFromList(e, "Event")}
                                                >
                                                    <div style={{ pointerEvents: "none" }}>
                                                        <MouseIcon
                                                            className="fgao-panel-section-item-icon"
                                                            fontSize="18"
                                                            style={{ pointerEvents: "none" }}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{ pointerEvents: "none" }}
                                                        className="fgao-panel-section-item-text"
                                                    >
                                                        User
                                                        <br />
                                                        Event
                                                    </div>
                                                </div>
                                                <div
                                                    className="fgao-panel-section-item"
                                                    draggable
                                                    onDragStart={(e) => handleOnDragFromList(e, "Page")}
                                                >
                                                    <div style={{ pointerEvents: "none" }}>
                                                        <DescriptionIcon
                                                            className="fgao-panel-section-item-icon"
                                                            fontSize="18"
                                                            style={{ pointerEvents: "none" }}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{ pointerEvents: "none" }}
                                                        className="fgao-panel-section-item-text"
                                                    >
                                                        Page
                                                    </div>
                                                </div>
                                                <div
                                                    className="fgao-panel-section-item"
                                                    draggable
                                                    onDragStart={(e) =>
                                                        handleOnDragFromList(e, "AutoAction")
                                                    }
                                                >
                                                    <div style={{ pointerEvents: "none" }}>
                                                        <SettingsApplicationsIcon
                                                            className="fgao-panel-section-item-icon"
                                                            fontSize="18"
                                                            style={{ pointerEvents: "none" }}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{ pointerEvents: "none" }}
                                                        className="fgao-panel-section-item-text"
                                                    >
                                                        Auto
                                                        <br />
                                                        Action
                                                    </div>
                                                </div>
                                                <div
                                                    className="fgao-panel-section-item"
                                                    draggable
                                                    onDragStart={(e) => handleOnDragFromList(e, "Text")}
                                                >
                                                    <div style={{ pointerEvents: "none" }}>
                                                        <TitleIcon
                                                            className="fgao-panel-section-item-icon"
                                                            fontSize="18"
                                                            style={{ pointerEvents: "none" }}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{ pointerEvents: "none" }}
                                                        className="fgao-panel-section-item-text"
                                                    >
                                                        Text
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {panelTab == "ValueLadder" ? (
                                        <div style={{ paddingTop: "20px", backgroundColor: "white" }}>
                                            <ValueLadderCtrl
                                                valueLadder={formData.autoSurvey}
                                                onChange={(surveyValue) => {
                                                    clearCanvas();
                                                    setAutoSurvey(surveyValue);
                                                    var tmpFormData = { ...formData };
                                                    tmpFormData.forecastSummary.goal.adSpend = surveyValue.budget;
                                                    setFormData(tmpFormData);
                                                }}
                                                createAutomation={false}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        width: "25px",
                                        height: "850px",
                                        backgroundColor: "rgb(128,128,128)",
                                    }}
                                    onClick={(e) => {
                                        toggleObjPanel(e);
                                    }}
                                >
                                    <div style={{ cursor: "pointer", height: "250px" }}>
                                        <div className="fgao-slideout-tab-text">Object Panel</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div style={{ height: "850px", overflow: "auto" }}>
                            <div style={{ height: "10000px", width: "10000px" }}>
                                <div
                                    ref={canvasRef}
                                    id="fgao-canvas"
                                    style={{
                                        height: "10000px",
                                        width: "10000px",
                                        transform: "scale(" + zoom + ")",
                                        transformOrigin: "0% 0% 0px",
                                        backgroundImage: "url(images/grid_back_01.jpg)",
                                        position: "relative",
                                    }}
                                    onDragOver={(e) => {
                                        allowDrop(e);
                                    }}
                                    onDrop={(e) => {
                                        handleOnDrop(e);
                                    }}
                                    onClick={(e) => {
                                        handleCanvasClick(e);
                                    }}
                                >
                                    {Object.keys(elements).map((key, ei) => (
                                        <AutoItemCtrl
                                            key={"autoitem_" + ei}
                                            autoItemObj={elements[key]}
                                        ></AutoItemCtrl>
                                    ))}
                                    <svg
                                        id="fgpb-canvas-connectors"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 10000 10000"
                                        width="10000px"
                                        style={{
                                            zIndex: "0",
                                            position: "relative",
                                            pointerEvents: "none",
                                        }}
                                    >
                                        <defs>
                                            <marker
                                                id="arrowhead-yes"
                                                markerWidth="4"
                                                markerHeight="4"
                                                refX="0"
                                                refY="2"
                                                orient="auto"
                                            >
                                                <polygon points="0 0, 4 2, 0 4" fill="#A7ABB7"></polygon>
                                            </marker>
                                            <marker
                                                id="arrowhead-no"
                                                markerWidth="4"
                                                markerHeight="4"
                                                refX="0"
                                                refY="2"
                                                orient="auto"
                                            >
                                                <polygon points="0 0, 4 2, 0 4" fill="#FF0000"></polygon>
                                            </marker>
                                            <marker
                                                id="arrowhead-active"
                                                markerWidth="4"
                                                markerHeight="4"
                                                refX="0"
                                                refY="2"
                                                orient="auto"
                                            >
                                                <polygon points="0 0, 4 2, 0 4" fill="#A7ABB7"></polygon>
                                            </marker>
                                        </defs>

                                        {itemsLoaded == "loaded" &&
                                            Object.keys(elements).map((key, ei) => (
                                                <AutoItemLineConnectorsCtrl
                                                    key={"connector_" + ei}
                                                    autoItemData={elements[key]}
                                                />
                                            ))}
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {showForecast == true ? (
                            <div>
                                <ForecastSummaryCtrl />
                            </div>
                        ) : null}
                    </div>
                </AutomationBrokerContext.Provider>
                :
                <div>
                    {elements[selectedItems[0]].pageInfo != null ?
                        <PageBuilderCtrl siteId={formData.siteId} buildModeId={automationId} buildMode="Automation" setMode={setMode} popupOpenRef={popupOpenRef} autoItemSummary={{ "aoId": selectedItems[0], "sitePageId": selectedItems[0], "pageName": elements[selectedItems[0]].pageInfo.pageName, "slug": elements[selectedItems[0]].pageInfo.slug }} onChangeCompleted={() => { onPageContentChange(); }}></PageBuilderCtrl>
                        :
                        <PageBuilderCtrl siteId={formData.siteId} buildModeId={automationId} buildMode="Automation-Email" setMode={setMode} popupOpenRef={popupOpenRef} autoItemSummary={{ "aoId": selectedItems[0], "sitePageId": selectedItems[0], "pageName": "", "slug": "" }} onChangeCompleted={() => { onPageContentChange(); }}></PageBuilderCtrl>
                    }
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );
}

export default AutomationBuilderCtrl;
