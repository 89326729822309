import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import PageEngineUtil from '../components/pagebuilderctrl/PageEngineUtil';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function SiteFeatures({dialogType,handlerReturnValue}) {

    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [availableFeatures, setAvailableFeatures] = useState([]);


    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.siteId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "site_settings_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('site_settings_page', 'header_section', 'Site Features');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.siteId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('site_settings_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [
                    { type: "dropdown", text: getTranslation('site_settings_page', 'header_section', 'Build Pages'), name: "Build Pages", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleBuildPages(); } },
                    { type: "dropdown", text: getTranslation('site_settings_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } }
                ]
            }
        )
    }
    headerOptions.elements.push({ type: "button", text: getTranslation('site_settings_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == 0) {
            //set default data
            data.fontList = JSON.stringify(["Open Sans", "Oswald"]);
            data.styleList = JSON.stringify({ "#h1": { "id": "#h1", "referenceType": "class", "breakpointStyles": { "768": { "fontSize": "32px" }, "2500": { "fontSize": "44px", "fontFamily": "Oswald" } } }, "#button": { "id": "#button", "referenceType": "class", "breakpointStyles": { "2500": { "backgroundColor": "black", "color": "white", "paddingTop": "10px", "paddingBottom": "10px", "paddingLeft": "20px", "paddingRight": "20px", "border": "none" } } }, "#p": { "id": "#p", "referenceType": "class", "breakpointStyles": { "2500": { "lineHeight": "140%", "fontSize": "16px", "fontFamily": "Open Sans" } } }, "#input": { "id": "#input", "referenceType": "class", "breakpointStyles": { "2500": { "width": "100%", "marginBottom": "10px", "backgroundColor": "#ffffff" } } }, "#label": { "id": "#label", "referenceType": "class", "breakpointStyles": { "2500": { "color": "grey", "fontFamily": "Open Sans", "marginBottom": "5px" } } } });
            data.active = true;
        }        
    }

    function getAvailableFeatures() {
        BAFetchData({
            callName: "GetAvailableFeatures",
            method: "GET",
            url: "cms/sitefeature/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: searchRefetch,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                //addDefaultData(data);
                setSelectedFeatures(data);
                //setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                getAvailableFeatures();
                break;
            case "GetAvailableFeatures":
                setAvailableFeatures(data);
                break;
            case "SaveForm":                
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/sitefeatures/' + primaryKeyValue); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/sites'); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                var filtering = {
                    "LogicalOperator": "and", "ConditionGroups": [{
                        "LogicalOperator": "and", "Conditions": [
                            { "Name": "siteId", "ConditionOperator": "EQUAL", "Value": primaryKeyValue, "DataType": "string" },
                        ]
                    }]
                };
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "cms/sitefeaturemap/getlist?filtering=" + JSON.stringify(filtering),
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "crm_tags", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);

    function isSelected(featureId) {
        var found = false;
        for (var i = 0; i < selectedFeatures.length; i++) {
            if (selectedFeatures[i].featureId == featureId && selectedFeatures[i].internalState != 'deleted') {
                found = true;
                break;
            }
        }
        return found;
    }

    function removeFeature(e, featureId) {
        var tmpSelectedFeatures = [...selectedFeatures];
        for (var i = 0; i < tmpSelectedFeatures.length; i++) {
            if (tmpSelectedFeatures[i].featureId == featureId) {
                switch (tmpSelectedFeatures[i].internalState) {
                    case 'unchanged':
                        tmpSelectedFeatures[i].internalState = 'deleted';
                        break;
                    case 'added':
                        tmpSelectedFeatures.splice(i, 1);
                        break;
                }
                break;
            }
        }
        setSelectedFeatures(tmpSelectedFeatures);
    }


    function addFeature(e, featureId) {
        var found = false;
        var tmpSelectedFeatures = [...selectedFeatures];
        for (var i = 0; i < tmpSelectedFeatures.length; i++) {
            if (tmpSelectedFeatures[i].featureId == featureId) {
                found = true;
                switch (tmpSelectedFeatures[i].internalState) {
                    case 'deleted':
                        tmpSelectedFeatures[i].internalState = 'unchanged';
                        break;
                }
                break;
            }
        }
        if (found == false) {
            tmpSelectedFeatures.push({ "siteFeatureMapId": 0, "siteId": primaryKeyValue, "featureId": featureId, "internalState":"added" });
        }        
        setSelectedFeatures(tmpSelectedFeatures);
    }

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "900px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            {availableFeatures.length > 0 ?
                                <>
                            <div className="segment" style={{marginBottom:"40px"}}>
                                <div className="segment-title">Features included:</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridGap: "20px" }}>
                                                {availableFeatures.map((feature, index) => (
                                                    <>
                                                        {isSelected(feature.featureId) == true ?
                                                            <div key={"feature_" + feature.featureId} style={{ border: "1px solid rgba(224, 224, 224, 1)", padding: "10px", borderRadius: "6px", backgroundColor: "whitesmoke" }}>
                                                                <FormControlLabel style={{ marginBottom: "10px" }}
                                                                    control={
                                                                        <Switch checked={true} name="gilad" onChange={(e) => removeFeature(e, feature.featureId)} />
                                                                    }
                                                                    label="Include"
                                                                />
                                                                <div style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "18px" }}>{feature.featureName}</div>
                                                                <div>{feature.description}</div>
                                                            </div>
                                                            : null}
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment">
                                <div className="segment-title">Additional features you can include on your site:</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr",gridGap:"20px" }}>
                                                {availableFeatures.map((feature, index) => (
                                                    <>
                                                        {isSelected(feature.featureId) == false ?
                                                            <div key={"feature_" + feature.featureId} style={{ border: "1px solid rgba(224, 224, 224, 1)", padding: "10px", borderRadius: "6px", backgroundColor: "whitesmoke" }}>
                                                                <FormControlLabel style={{ marginBottom: "10px" }}
                                                                    control={
                                                                        <Switch name="gilad" onChange={(e) => addFeature(e, feature.featureId)} />
                                                                    }
                                                                    label="Include"
                                                                />
                                                                <div style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "18px" }}>{feature.featureName}</div>
                                                                <div>{feature.description}</div>
                                                            </div>
                                                            : null}
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                </>
                                : null}
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete site. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "cms/sites/delete?siteid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function handleBuildPages() {
        PageEngineUtil.CreatePagesByFeature(null, primaryKeyValue);

        var msg = 'Site pages are built';
        setGenericDialogSchema({ title: 'Pages Added to Site', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog();} })
        setOpenDialog(true);

    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    function handleSaveForm() {
        //perform form data validation
        //setErrorList([]);

        //Set just in time values before save
        //addJustInTimeData(formData);

        saveForm();

        //if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
        //    if (isCustomFormValidationValid() == true) {
        //        saveForm();
        //    }
        //} else {
        //    var msg = 'There are form validation errors.';
        //    setGenericDialogSchema({ title: 'Validation Errors', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        //    setOpenDialog(true);
        //}                
    }

    function saveForm() {
        BAFetchData({
            callName: "SaveForm", method: "POST", url: "cms/sitefeaturemap/bulksave",
            token: "fg1234", body: JSON.stringify(selectedFeatures), onSuccess: onAPISuccess, onError: onAPIError
        });
    }
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        //if (formData.isCompany == false) {
        //    if (formData.firstName == null && formData.lastName == null && formData.businessName == null) {
        //        isValid = false;
        //        tempErrorList['firstName'] = "First Name, Last Name or Business Name is required.";
        //    }
        //}
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default SiteFeatures;