import React, { useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner'

export default function LoaderSpinnerCtrl({schema}) {
    return (
        <div style={{ position: "fixed", zIndex: "5", left: "0", top: "0", backgroundColor: "rgba(0,0,0,.1)", width: "100%", height: "100%", textAlign: "center" }}>
            <div style={{ position:"relative",display: "inline-Block", width:"400px",top:"45%",marginLeft:"auto",marginRight:"auto"}}>
        <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
                />
                {schema.msg != null && <div>Process will take a few seconds...</div>}                
            </div>
        </div>
    );
}