import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import CloseIcon from '@mui/icons-material/Close';
import FileManager from '../../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import Dropdown from 'react-bootstrap/Dropdown';
import Divider from '@mui/material/Divider';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {timelineOppositeContentClasses,} from '@mui/lab/TimelineOppositeContent';

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import helpers from '../../../helpers/Helpers';

function TimedAnimationItemCtrl({ eventData, propertyChangeCallback, toggleContainer, handleEndAnimationSelection, actionElementId }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    //const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            
    const [eventItem, setEventItem] = useState(eventData);
    const [actionItemId, setActionItemId] = useState(null);
    const [actionItemObj, setActionItemObj] = useState(null);

    function onValueChange(propertyName,value) {
        var tmpEventItem = { ...eventItem };
        tmpEventItem.action.config[propertyName] = value;
        setEventItem(tmpEventItem);
    }

    function getNewTimedActionJson(id, timedActionTypeId, targetId) {
        return {
            "actionItems": [
                {
                    "id": id, "actionTypeId": timedActionTypeId, config: {
                        "delay": 0, "easing": "", "duration": 0, "value": "", "target": { "id": targetId }
                    }
                }
            ]
        };
    }

    function newTimedAction(timedActionTypeId) {
        var tmpEventItem = { ...eventItem };
        var newId = helpers.getUUID();
        tmpEventItem.action.config.actionItemGroups.push(getNewTimedActionJson(newId, timedActionTypeId, actionElementId));
        setEventItem(tmpEventItem);
        propertyChangeCallback(tmpEventItem);
    }

    function timeActionValueChange(propertyName, value) {
        var tmpActionItemObj = { ...actionItemObj };
        tmpActionItemObj.config[propertyName] = value;

        var tmpEventItem = { ...eventItem };
        var found = false;
        for (var i = 0; i < tmpEventItem.action.config.actionItemGroups.length; i++) {
            var actionItems = tmpEventItem.action.config.actionItemGroups[i];
            for (var aii = 0; aii < actionItems.actionItems.length; aii++) {
                var actionItem = actionItems.actionItems[aii];
                if (actionItem.id == actionItemId) {
                    actionItem = tmpActionItemObj;
                    found = true;
                    break;
                }
            }
            if (found == true) {
                break;
            }
        }
        setActionItemObj(tmpActionItemObj)
        setEventItem(tmpEventItem);
        propertyChangeCallback(tmpEventItem);
    }

    function selectActionItemObj(id) {
        var tmpActionItemObj = null;
        var tmpEventItem = { ...eventItem };
        var found = false;
        for (var i = 0; i < tmpEventItem.action.config.actionItemGroups.length; i++) {
            var actionItems = tmpEventItem.action.config.actionItemGroups[i];
            for (var aii = 0; aii < actionItems.actionItems.length; aii++) {
                var actionItem = actionItems.actionItems[aii];
                if (actionItem.id == id) {
                    tmpActionItemObj = actionItem;
                    found = true;
                    break;
                }
            }
            if (found == true) {
                break;
            }
        }
        setActionItemObj(tmpActionItemObj);
        //actionItemObj
    }

    //function handleOnIconChange(value) {
    //    var tmpWidgetObj = { ...widgetObj };
    //    tmpWidgetObj.className = value;
    //    setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //    propertyChangeCallback(tmpWidgetObj);
    //}

    //function openFileManager() {
    //    var actionType = "FullPath";
    //    var msg = 'This is a test';
    //    setGenericDialogSchema({ title: 'Icon Manager', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={dialogType, setReturnValue} returnFullPath={false} iconOnly={true}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleUpdateImage(returnValues, actionType); } })
    //    setOpenDialog(true);
    //}

    //function handleUpdateImage(imgUrl, actionType) {
    //    handleOnClassNameChange(imgUrl);
    //}

    useEffect(() => {
        handleEndAnimationSelection(true);
        setEventItem(eventData);
    }, [eventData])



    return (
        <>
            <div>
                <div className="fg-pg-panel-sub-section">
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 50px" }}>
                        <div>Animation Sequence</div>
                        <div style={{ textAlign: "right", cursor: "pointer" }} onClick={() => { toggleContainer('eventItem', eventData.id); handleEndAnimationSelection(false);  }} ><div style={{ backgroundColor:"#5E95DE",display:"inline-block",padding:"0px 4px 0px 4px",borderRadius:"4px",color:"white"}}>Done</div></div>
                    </div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px", padding:"5px" }}>
                    <div className="fg-pb-property-sub-label">Name</div>
                    <div>
                        <input className="form-control form-control-sm fg-pb-property-field" value={eventItem.action.config.title || ''} onChange={(e) => onValueChange('title',e.target.value)} />
                    </div>
                </div>
                <div className="fg-pg-panel-sub-section">Actions</div>                
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{fontSize:"12px",marginBottom:"10px"}}>Create a new timed action for:<br />
                        <span style={{fontSize:"12px"}}>{actionElementId}</span>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Dropdown style={{ display: "inline-block", marginLeft: "5px" }} size="small">
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" style={{ color: "black", border: "1px solid black", padding: "0px 5px 0px 5px", margin: "0px" }} size="small">
                                New Timed Actions
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>Move</Dropdown.Item>
                                <Dropdown.Item>Scale</Dropdown.Item>
                                <Dropdown.Item>Rotate</Dropdown.Item>
                                <Dropdown.Item>Skew</Dropdown.Item>
                                <Divider />
                                <Dropdown.Item>Opacity</Dropdown.Item>
                                <Dropdown.Item>BG Color</Dropdown.Item>
                                <Dropdown.Item>Border Color</Dropdown.Item>
                                <Dropdown.Item>Text Color</Dropdown.Item>
                                <Divider />
                                <Dropdown.Item>Size</Dropdown.Item>
                                <Dropdown.Item onClick={() => newTimedAction('GENERAL_DISPLAY')}>Hide/Show</Dropdown.Item>
                                <Divider />
                                <Dropdown.Item onClick={() => newTimedAction('CUSTOM_FUNCTION')}>Custom Function</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div style={{ backgroundColor: "whitesmoke", padding: "5px" }}>
                    {eventItem.action.config.actionItemGroups.length == 0 ?
                        <div style={{ color: "grey", fontSize: "12px", textAlign: "center" }}>No Timed Actions</div>
                        : null}
                    {eventItem.action.config.actionItemGroups.length > 0 ?
                        <div>                            
                            <Timeline
                                sx={{
                                    [`& .${timelineOppositeContentClasses.root}`]: {
                                        flex: 0.1,
                                    }, margin: 0, padding: 0,
                                }}
                            >   
                                {eventItem.action.config.actionItemGroups.map((actionItems, aisi) => (                                    
                                    <TimelineItem sx={{ minHeight: "50px", maxHeight: "70px" }}>
                                        <TimelineOppositeContent color="textSecondary" sx={{ fontSize: 12 }}>
                                            0.00
                                        </TimelineOppositeContent>
                                        <TimelineSeparator sx={{}} >
                                            <TimelineDot sx={{ padding: "1px", maxWidth: "10px", maxHeight: "10px" }} />
                                            <TimelineConnector sx={{}} />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ fontSize: 10, }}>
                                            <div style={{ backgroundColor: "grey", padding: "4px 6px 4px 6px", borderRadius: "4px", color: "white" }}>
                                                {actionItems.actionItems.map((actionItem, aisi) => (
                                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }} onClick={() => { selectActionItemObj(actionItem.id);}}>
                                                        <div>{elements[actionItem.config.target.id].elementName}</div>
                                                        <div style={{ textAlign: "right" }} >Hide/Show</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                                <TimelineItem sx={{ minHeight: "50px", maxHeight: "70px" }}>
                                    <TimelineOppositeContent color="textSecondary" sx={{ fontSize: 12 }}>
                                        0.50
                                    </TimelineOppositeContent>
                                    <TimelineSeparator sx={{}} >
                                        <TimelineDot sx={{ padding: "1px", maxWidth: "10px", maxHeight: "10px" }} />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ fontSize: 12 }}>End</TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </div>
                        : null}
                    <div style={{ borderTop: "1px solid grey", marginTop: "20px",paddingTop:"10px" }}>
                    {actionItemObj == null ?
                        <div style={{textAlign:"center"}}>
                            <h2 style={{fontSize:"20px",fontWeight:"bold"}}>No timed action selected</h2>
                            <p style={{fontSize:"14px"} }>Select a timed action in the list above to adjust it's settings</p>
                        </div>
                        : 
                        <div>
                            <div>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox checked={eventItem.action.config.useFirstGroupAsInitialState || false} onChange={(e) => onValueChange('useFirstGroupAsInitialState', e.target.checked)} />}
                                    label="Set as initial state"
                                    labelPlacement="end"
                                />
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 100px", gridGap: "10px" }}>
                                <div>
                                    <div className="fg-pb-property-sub-label">Start</div>
                                    <div>
                                        <select className="form-control form-control-sm fg-pb-property-field">
                                            <option value="After">After Previous Action</option>
                                            <option value="Width">After Previous Action</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <div className="fg-pb-property-sub-label">Delay</div>
                                    <div>
                                            <input type="number" className="form-control form-control-sm fg-pb-property-field" value={actionItemObj.config.delay || '0'} onChange={(e) => { timeActionValueChange('delay', e.target.value); }} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 100px", gridGap: "10px" }}>
                                <div>
                                    <div className="fg-pb-property-sub-label">Easing</div>
                                    <div>
                                            <select className="form-control form-control-sm fg-pb-property-field" value={actionItemObj.config.easing || ''}>
                                            <option value="">Select...</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <div className="fg-pb-property-sub-label">Duration</div>
                                    <div>
                                            <input type="number" className="form-control form-control-sm fg-pb-property-field" value={actionItemObj.config.duration || '0'} onChange={(e) => { timeActionValueChange('duration', e.target.value); }} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>Hide/Show</h2>
                                <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                    <div className="fg-pb-property-sub-label">Display</div>
                                    <div>
                                            <select className="form-control form-control-sm fg-pb-property-field" value={actionItemObj.config.value || ''} onChange={(e) => { timeActionValueChange('value', e.target.value); }}>
                                                <option value="">Select...</option>
                                                <option value="block">Block</option>
                                                <option value="flex">Flex</option>
                                                <option value="grid">Grid</option>
                                                <option value="inline-block">Inline-Block</option>
                                                <option value="inline">Inline</option>
                                                <option value="none">None</option>
                                            </select>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    }
                    </div>    
                </div>

            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default TimedAnimationItemCtrl;

