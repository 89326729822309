import React, { useEffect, useState } from "react";
import "./css/availcalslots.css"
import dayjs from "dayjs";
import helpers from "../../../helpers/Helpers";

function AvailCalSlots({selectedDate,availSlots, handleOnConfirm}){


    //const [isHovered, setIsHovered] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [oldSelectedDate, setOldSelectedDate] = useState(null);

    function onConfirmClick(slot){
        let selectedDateTime = new Date(selectedDate);
        const hours = slot.time.split(':')[0];
        const minutes = slot.time.split(':')[1];
        selectedDateTime.setHours(hours);
        selectedDateTime.setMinutes(minutes);
        handleOnConfirm(selectedDateTime, slot.cost);
    }

    useEffect(() => {
        if (selectedDate != oldSelectedDate){
            setSelectedSlot(null);
            setOldSelectedDate(selectedDate);
        }        
    },[selectedDate])


    return (
        <>
            {selectedDate != null ?        
            <div className="cal-avail-slot">
                <div className="slot-header">{dayjs(selectedDate).format('dddd, MMMM D')}</div>
                <div className="slot-box">
                {availSlots.length === 0 ? <div style={{color:"grey"}}>No available time slots</div> : null}
                {availSlots.map((slot, index) => {
                    return (
                        slot.time !== selectedSlot ? (
                            <div key={slot.time} className="slot" onMouseEnter={() => { setSelectedSlot(slot.time);}}>
                                <div>
                                    <span>{dayjs(slot.time, 'HH:mm').format('h:mma')}</span>
                                    {(slot.cost != null && slot.cost != '') ?
                                        <span> -- {helpers.formatCurrencyGlobal(slot.cost)}</span>
                                    : null}
                                </div>
                        </div>
                        ) : 
                            <div key={slot.time} style={{display:"grid",gridTemplateColumns:"1fr 1fr",gridGap:"5px"}}>                        
                                <div className="slot active" onMouseEnter={() => { setSelectedSlot(slot.time); }}>
                                    <div>{dayjs(slot.time, 'HH:mm').format('h:mma')}</div>
                                    {(slot.cost != null && slot.cost != '') ?
                                        <div style={{ fontSize: "10px" }}>Cost: {helpers.formatCurrencyGlobal(slot.cost)}</div>
                                        : null}
                            </div>                        
                                <div className="slot confirm" onMouseEnter={() => { setSelectedSlot(slot.time); }} onClick={() => { onConfirmClick(slot); }} style={{paddingTop:"18px"} }>
                                Confirm
                            </div>                                                    
                        </div>
                    );
                })}
                </div>
            </div>
            : null}
        </>
    )
}

export default AvailCalSlots;