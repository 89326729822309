import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import { generateVideoThumbnails } from '../../../helpers/VideoThumbnailGeneratorByUrl';

function ThumbnailGeneratorCtrl({ videoUrl, onChange }) {

    const [currentUser] = useGlobalState("userInfo");
    const wlInfo = JSON.parse(localStorage.getItem("wl-info"));
    const [totalThumbnails, setTotalThumbnails] = useState(1);
    const [thumbnails, setThumbnails] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});
    

    function generateThumbnailsStart() {
        setShowLoader(true);
        setTimeout(() => {
            generateThumbnails();
        }, "500");
    }

    function generateThumbnails() {
        console.log('videoUrl', videoUrl);
        //var url = cdnFolder + "accountfiles/" + currentUser.currentBusinessAccountDTO.baid + "/files/" + searchResultData.data[selectedIndex].original;
        //generateVideoThumbnails(video, thumbNumber).then((thumbs) => {
        generateVideoThumbnails(videoUrl, totalThumbnails).then((thumbs) => {
            //console.log('thumbs', thumbs);
            //var test = '';
            setThumbnails(thumbs);
            //console.log('thumbnails done');
            //setShowLoader(false);
            //test = '';
            //if (refs.current.loader) {
            //    refs.current.loader.style.display = "none";
            //}
        });
        //console.log('thumbnails done');
        //setShowLoader(false);
    }

    function handleOnChange(index, thumbnailType) {
        onChange(thumbnails[index], thumbnailType);
    }

    //useEffect(() => {
    //    setThumbnails(['/images/sample_portrait_video.png', '/images/sample_portrait_video.png', '/images/sample_portrait_video.png', '/images/sample_portrait_video.png', '/images/sample_portrait_video.png', '/images/sample_portrait_video.png', '/images/sample_portrait_video.png', '/images/sample_portrait_video.png']);
    //}, []);

    useEffect(() => {
        setShowLoader(false);
    }, [thumbnails]);


    return (
        <>
            <div>
                <div style={{display:"grid",gridTemplateColumns:"1fr",gridGap:"10px", width:"200px"}}>
                    <div>
                        <TextField id="pixelName" fullWidth label="Total Thumbnails" variant="standard" style={{ marginBottom: "16px" }} defaultValue={1} onChange={(e) => { setTotalThumbnails(e.target.value, 'generated'); } } />
                    </div>
                    <div>
                        <Button variant="outlined" style={{ backgroundColor: "#grey", color: "black", border: "1px solid grey" }} onClick={() => generateThumbnailsStart()}>Generate Thumbnails</Button>
                    </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px"}}>
                    {thumbnails.map((thumbnail, index) => (
                        <div style={{ marginRight: "20px", marginBottom: "20px", cursor: "pointer" }} onClick={() => { handleOnChange(index,"generated"); }}>
                            <img src={thumbnail} style={{width:"150px"}} />
                        </div>
                    ))}                   
                </div>
                
            </div>
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default ThumbnailGeneratorCtrl;
