import React, { useContext, useState, useEffect, useRef } from 'react';
import { DataBrokerContext } from '../PageBuilderCtrl';
import '../css/pagebuilderctrl.css';
import helpers from '../../../helpers/Helpers';

function CssObjCustomIdCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const oldIdRef = useRef(null);
    function handleOnValueChange(value) {
        value = helpers.fieldIdFormatter(value);
        var tmpWidgetObj = { ...widgetObj };
        var oldId = tmpWidgetObj.id;
        oldIdRef.current = oldId;
        tmpWidgetObj['customId'] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function replaceId(newId) {
        if (oldIdRef.current != null) {
            //if (newId == '' || newId == null) {
                //newId = 'fpgwidget_' + helpers.getUUID();
            //}
            //var newId = widgetObj.id;
            var tmpWidgetObj = { ...widgetObj }
            var originalId = tmpWidgetObj.id;
            if (newId == '' || newId == null) {
                newId = originalId;
            }
            var tmpElements = { ...elements }
            var tmpElementObj = null;
            for (const key in tmpElements) {
                if (key != originalId) {
                    tmpElementObj = tmpElements[key];
                    tmpElementObj = JSON.parse(JSON.stringify(tmpElementObj).replaceAll(oldIdRef.current, newId));
                }
            }
            setElements(tmpElements);

            //var tmpElements = { ...elements }
            //tmpElements = JSON.parse(JSON.stringify(tmpElements).replaceAll(oldIdRef.current, newId));
            //setElements(tmpElements);
        }
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])

    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Object Id</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div className="fg-pb-subsection-instruction">Enter unique id for this object.</div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Custom Id</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.customId != undefined ? widgetObj.customId : ""} onChange={(e) => { handleOnValueChange(e.target.value); }} onBlur={(e) => { replaceId(e.target.value); }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CssObjCustomIdCtrl;

