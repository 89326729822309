import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import TwoValueReportCtrl from '../../dashboardcontrols/TwoValueReportWCtrl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function RecipientListCtrl({ broadcastId, defaultEventType}) {

    const [primaryKey, setPrimaryKey] = useState(null);
    const [currentUser] = useGlobalState("userInfo");
    const [formData, setFormData] = useState({});
    const [reportData, setReportData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});
    const [errorList, setErrorList] = useState({});

    let internalTimeout = null;
    let criteria = null;
    const [searchResultData, setSearchResultData] = useState([]);
    const [rowIds, setRowIds] = useState([]);

    let eType = defaultEventType;
    const [eventType, setEventType] = useState(defaultEventType);

    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const columns = [
        //{
        //    field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
        //   renderCell: ({ row }) =>
        //        <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('contact_search_page', 'search_grid', 'Open')}</Button>,
        //},
        {
            field: 'email',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Email'),
            width: 400
        },
    ];

    function resetResults() {
        setRowIds([]);
        beginSearch();
    }

    function beginSearch() {
        var filtering = {};
        var conditions = [];

        conditions.push({ "Name": "BroadcastId", "ConditionOperator": "EQUAL", "Value": broadcastId, "DataType": "String" });
        if (eventType == 'senttoserver') {
            conditions.push({ "Name": "SentToServer", "ConditionOperator": "EQUAL", "Value": 1, "DataType": "Number" });
        } else if (eventType == 'accepted') {
            conditions.push({ "Name": "Accepted", "ConditionOperator": "EQUAL", "Value": 1, "DataType": "Number" });
        } else if (eventType == 'delivered') {
            conditions.push({ "Name": "Delivered", "ConditionOperator": "EQUAL", "Value": 1, "DataType": "Number" });
        } else if (eventType == 'opened') {
            conditions.push({ "Name": "Opened", "ConditionOperator": "EQUAL", "Value": 1, "DataType": "Number" });
        } else if (eventType == 'clicked') {
            conditions.push({ "Name": "Clicked", "ConditionOperator": "EQUAL", "Value": 1, "DataType": "Number" });
        } else if (eventType == 'bounced') {
            conditions.push({ "Name": "Bounced", "ConditionOperator": "EQUAL", "Value": 1, "DataType": "Number" });
        } else if (eventType == 'complaints') {
            conditions.push({ "Name": "Complaints", "ConditionOperator": "EQUAL", "Value": 1, "DataType": "Number" });
        } else if (eventType == 'unsubscribed') {
            conditions.push({ "Name": "Unsubscribed", "ConditionOperator": "EQUAL", "Value": 1, "DataType": "Number" });
        }
        
        if (criteria != null && criteria != '') {          
            conditions.push({ "Name": "Email", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" });
        }

        filtering = {
            "LogicalOperator": "and", "ConditionGroups": [
                {
                    "LogicalOperator": "and", "Conditions": conditions
                }]
        };


        BAFetchData({
            callName: "GetData",
            method: "GET",
            url: "marketing/emailstat/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetData":
                setSearchResultData(data);
                break;
            case "BulkDelete":
                resetResults(); //Reset search results and clear records selected.               
                var msg = "Record(s) has been deleted.";
                setGenericDialogSchema({ title: 'Record(s) Deleted', component: null, body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleEventTypeChange(eventType) {
        eType = eventType;
        setEventType(eventType);
    }

    function handleOnCriteriaChange(value) {
        criteria = value.toLowerCase();
        if (internalTimeout != undefined) {
            clearTimeout(internalTimeout);
        }
        internalTimeout = setTimeout(() => {
            beginSearch();
        }, 250);
    }

    useEffect(() => {
        //resetGridColumns();
        //setTransactionType(params.transactionType);
        beginSearch();
    }, [eventType]);

    useEffect(() => {
        beginSearch();
        setShowLoader(false);
    }, []);

    return (
        <>
            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&

                <div className="fg-app-page-box" style={{ maxWidth: "900px",borderTop:"none",padding:"0px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div style={{padding:"5px", fontStyle:"italic"}}>Enter criteria to filter list</div>
                    <div style={{ display:"grid",gridTemplateColumns:"1fr 1fr",gridGap:"10px",padding:"5px" }}>
                        <div>
                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "0px"}}>
                                <InputLabel id="demo-simple-select-standard-label">Event Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={eventType || ''}
                                    onChange={(e) => { handleEventTypeChange(e.target.value); }}
                                    label="Age"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="senttoserver">Sent to Server</MenuItem>
                                    <MenuItem value="accepted">Accepted</MenuItem>
                                    <MenuItem value="delivered">Delivered by Server</MenuItem>
                                    <MenuItem value="opened">Opens</MenuItem>
                                    <MenuItem value="clicked">Clicks</MenuItem>
                                    <MenuItem value="bounced">Bounces</MenuItem>
                                    <MenuItem value="complaints">Complaints</MenuItem>
                                    <MenuItem value="unsubscribed">Unsubscribes</MenuItem>
                                </Select>
                                <FormHelperText>{errorList['recipientsPerMsg'] ? <span style={{ color: "red" }}>{errorList['recipientsPerMsg']}</span> : ''} </FormHelperText>
                            </FormControl>
                        </div>
                        <div>
                            <TextField fullWidth label="Enter search keyword" variant="standard" style={{ marginBottom: "16px" }} onChange={(e) => { handleOnCriteriaChange(e.target.value); }} />
                        </div>

                    </div>
                    <div>
                        <Box sx={{ height: 630, width: '100%' }}>
                            <DataGrid
                                sx={{
                                    border: 0,
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within": {
                                        outline: "none !important",
                                    },
                                }}
                                rows={searchResultData}
                                getRowId={(row) => row.emailId}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                //checkboxSelection
                                disableRowSelectionOnClick
                                onRowSelectionModelChange={(ids) => {
                                    setRowIds(ids);
                                }}
                            />
                        </Box>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>

    );
}

export default RecipientListCtrl;
