import React, { useContext, useState, useEffect, useRef } from 'react';
import { DataBrokerContext } from '../PageBuilderCtrl';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import '../css/pagebuilderctrl.css';
import Switch from '@mui/material/Switch';

function InputStateListCtrl({ propertyValue, propertyChangeCallback, propertyLabel}) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [propertyValueObj, setPropertyValueObj] = useState(propertyValue);

    function handleOnValueChange(index, propertyName, value) {
        var tmpPropertyValueObj = [...propertyValueObj];
        tmpPropertyValueObj[index][propertyName] = value;
        setPropertyValueObj(tmpPropertyValueObj);
        propertyChangeCallback(tmpPropertyValueObj);
    }

    function handleOnBlur(index, propertyName, value) {
        var tmpPropertyValueObj = [...propertyValueObj];
        tmpPropertyValueObj[index][propertyName] = value;
        propertyChangeCallback(tmpPropertyValueObj);
    }

    function addItem() {
        var tmpPropertyValueObj = [...propertyValueObj];
        tmpPropertyValueObj.push({ "name": "", "value": "", "designValue": "", valueType:"", isReferenceObj:false, runAfter:"" });
        setPropertyValueObj(tmpPropertyValueObj);
        propertyChangeCallback(tmpPropertyValueObj);
    }

    function removeItem(index) {
        var tmpPropertyValueObj = [...propertyValueObj];
        tmpPropertyValueObj.splice(index, 1);
        setPropertyValueObj(tmpPropertyValueObj);
        propertyChangeCallback(tmpPropertyValueObj);
    }

    useEffect(() => {
        //if (widgetData.hasOwnProperty('customAttributes') == false) {
        //    widgetData.customAttributes = [];
        //}
        setPropertyValueObj(propertyValue);
    }, [propertyValue])

    return (
        <>

            <div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 25px", marginBottom: "5px" }}>
                    <div className="fg-pb-subsection-instruction" style={{ paddingTop: "3px" }}>Add {propertyLabel}</div>
                    <div onClick={(e) => { addItem(); }}><AddIcon></AddIcon></div>
                </div>
                {propertyValueObj != undefined ?
                    (propertyValueObj.map((item, index) => (
                        <div style={{ borderBottom: "1px dotted grey", marginBottom: "10px", paddingBottom: "10px" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 25px", marginBottom: "5px" }}>
                                <div className="fg-pb-subsection-instruction">{propertyLabel} Item</div>
                                <div onClick={(e) => { removeItem(index); }}><DeleteForeverIcon></DeleteForeverIcon></div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Name:</div>
                                <div>
                                    <input className="form-control form-control-sm fg-pb-property-field" value={item.name != undefined ? item.name : ""} onChange={(e) => { handleOnValueChange(index, "name", e.target.value); }} onBlur={(e) => { handleOnBlur(index, "name", e.target.value); }} />
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Value</div>
                                <div>
                                    <input className="form-control form-control-sm fg-pb-property-field" value={item.value != undefined ? item.value : ""} onChange={(e) => { handleOnValueChange(index, "value", e.target.value); }} onBlur={(e) => { handleOnBlur(index, "value", e.target.value); }} />
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Design Value</div>
                                <div>
                                    <input className="form-control form-control-sm fg-pb-property-field" value={item.designValue != undefined ? item.designValue : ""} onChange={(e) => { handleOnValueChange(index, "designValue", e.target.value); }} onBlur={(e) => { handleOnBlur(index, "designValue", e.target.value); }} />
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Value Type</div>
                                <div>
                                    <select className="form-control form-control-sm fg-pb-property-field" value={item.valueType != undefined ? item.valueType : "string"} onChange={(e) => { handleOnValueChange(index, "valueType", e.target.value); }}>
                                        <option value="">Select</option>
                                        <option value="string">String</option>
                                        <option value="number">Number</option>
                                        <option value="date">Date</option>
                                        <option value="boolean">Boolean</option>
                                        <option value="function">Function</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Is Ref. Obj.?</div>
                                <div>
                                    <Switch checked={item.isReferenceObj != undefined ? item.isReferenceObj : false} onChange={(e) => { handleOnValueChange(index, "isReferenceObj", e.target.checked); }} name="gilad" />
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                                <div className="fg-pb-property-sub-label">Run After</div>
                                <div>
                                    <input className="form-control form-control-sm fg-pb-property-field" value={item.runAfter != undefined ? item.runAfter : ""} onChange={(e) => { handleOnValueChange(index, "runAfter", e.target.value); }} onBlur={(e) => { handleOnBlur(index, "runAfter", e.target.value); }} />
                                </div>
                            </div>
                        </div>
                    )))
                    : null}

            </div>

        </>
    );

}

export default InputStateListCtrl;

