import React, { useContext, useState, useEffect } from 'react';
import ColorPicker from 'react-best-gradient-color-picker'
import ColorSelectorCtrl from '../colors/ColorSelectorCtrl';
import DialogCtrl from '../dialogctrl/dialogctrl';
import ColorFieldCtrl from './ColorFieldCtrl';

import BorderTopIcon from '@mui/icons-material/BorderTop';
import BorderRightIcon from '@mui/icons-material/BorderRight';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';
import BorderOuterIcon from '@mui/icons-material/BorderOuter';

function BorderCtrl({ defaultBorder, propertyName, onBorderChange,}) {

    const [borderInfo, setBorderInfo] = useState(formatBorderInfo(defaultBorder));

    function formatBorderInfo(tmpValue) {
        if (tmpValue != undefined && tmpValue != '' && tmpValue != 'unset') {
            var size = tmpValue.toString().split("px ")[0];
            tmpValue = tmpValue.replace(size + 'px ','');
            var borderType = tmpValue.toString().split(" rgba")[0];
            tmpValue = tmpValue.replace(borderType + ' ', '');
            var color = tmpValue;
            //var values = parseValue(value).split(" ");
            return { size: size, borderType: borderType, color: color, unset: false };
        } else if (tmpValue == "unset") {
            return { size: '', borderType: 'unset', color: '', unset: true };
        } else if (tmpValue == "") {
            return { size: '', borderType: '', color: '', unset: false };
        } else {
            return { size: '', borderType: '', color: '', unset: false };
        }
    }

    function parseValue(value) {
        var mtList = ['px', '%', 'em', 'rem', 'vw', 'vh', 'svw', 'svh', 'dvw', 'dvh'];
        for (var i = 0; i < mtList.length; i++) {
            value = value.toString().replace(mtList[i], '');
        }
        return value;
    }

    function handleOnBorderSizeChange(e, size) {
        var tmpBorderInfo = { ...borderInfo };
        tmpBorderInfo.size = size;
        tmpBorderInfo.unset = false;
        setBorderInfo((...borderInfo) => { return tmpBorderInfo });
        sendUpdate(tmpBorderInfo);
    }

    function handleOnColorChange(newColor) {
        var tmpBorderInfo = { ...borderInfo };
        tmpBorderInfo.color = newColor;
        tmpBorderInfo.unset = false;
        setBorderInfo((...borderInfo) => { return tmpBorderInfo });
        sendUpdate(tmpBorderInfo);
    }

    function handleOnBorderTypeChange(e, borderType) {
        var tmpBorderInfo = { ...borderInfo };
        if (borderType != 'unset') {
            tmpBorderInfo.borderType = borderType;
            tmpBorderInfo.unset = false;
        } else {
            tmpBorderInfo.unset = true;
        }
        
        setBorderInfo((...borderInfo) => { return tmpBorderInfo });
        sendUpdate(tmpBorderInfo);
    }

    function sendUpdate(tmpBorderInfo) {
        if (tmpBorderInfo.unset == true) {
            onBorderChange('unset');
        } else if (tmpBorderInfo.size != '' && tmpBorderInfo.borderType != '' && tmpBorderInfo.color != '') {
            onBorderChange(tmpBorderInfo.size + 'px ' + tmpBorderInfo.borderType + ' ' + tmpBorderInfo.color);
        } else if (tmpBorderInfo.size == '' && tmpBorderInfo.borderType == '' && tmpBorderInfo.color == '') {
            onBorderChange('');
        }
    }

    useEffect(() => {
        setBorderInfo(formatBorderInfo(defaultBorder));
    }, [defaultBorder])

    //useEffect(() => {
    //    if (borderInfo.unset == true) {
    //        onBorderChange('unset');
    //    } else if (borderInfo.size != '' && borderInfo.borderType != '' && borderInfo.color != '') {
    //        onBorderChange(borderInfo.size + 'px ' + borderInfo.borderType + ' ' + borderInfo.color);
    //    } else {
    //        onBorderChange('');
    //    }
    //}, [borderInfo])

    return (
        <>
            <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px 60px 30px", gridGap: "5px", width: "100%" }}>
                <div>                    
                    {propertyName == "border" ? <BorderOuterIcon /> : null}
                    {propertyName == "borderTop" ? <BorderTopIcon /> : null}
                    {propertyName == "borderRight" ? <BorderRightIcon /> : null}
                    {propertyName == "borderBottom" ? <BorderBottomIcon /> : null}
                    {propertyName == "borderLeft" ? <BorderLeftIcon /> : null}
                </div>
                <div>
                    <select className="form-control form-control-sm fg-pb-property-field" value={borderInfo.borderType} onChange={(e) => { handleOnBorderTypeChange(e,e.target.value); }}>
                        <option value="">None</option>
                        <option value="solid">Solid</option>
                        <option value="dashed">Dashed</option>
                        <option value="dotted">Dotted</option>
                        <option value="unset">Unset</option>
                    </select>
                </div>
                <div><ColorFieldCtrl defaultColor={borderInfo.color} onColorChange={(color) => { handleOnColorChange(color); }} hideColorLabel={true}></ColorFieldCtrl></div>
                <div><input className="form-control form-control-sm fg-pb-property-field" value={borderInfo.size} onChange={(e) => { handleOnBorderSizeChange(e, e.target.value); }} /></div>
                <div style={{textAlign:"center"}}>px</div>
            </div>
        </>
    );
}

export default BorderCtrl;