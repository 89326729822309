import React from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BsHouseFill, BsBarChartFill, BsPeopleFill, BsGearFill, BsMegaphoneFill, BsFillBoxSeamFill, BsCart4, BsFolderFill, BsTools, BsFillKanbanFill, BsMortarboardFill, BsFillCalendar3WeekFill } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import './css/appmainnav.css';
import useBAFetch from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';


function AppMainNav() {

    const [currentUser] = useGlobalState("userInfo");
    const navigate = useNavigate();
    
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "application_shell", "DataType": "String" }, { "Name": "category", "ConditionOperator": "EQUAL", "Value": "main_menu", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const { data, loading, error } = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < data.length; i++) {
            if (data[i].object == object && data[i].category == category && data[i].key == key) {
                value = data[i].translation;
                break;
            };
        }
        return value;
    }

    const expandChildNav = (e) => {

        var sb = document.getElementById('app-sidebar');
        var activeList = sb.querySelectorAll('.nav-link-item-active');
        for (var i = 0; i < activeList.length; i++) {
            activeList[i].classList.remove('nav-link-item-active');
        }

        activeList = sb.querySelectorAll('.app-menu-level-2-active');
        for (var i = 0; i < activeList.length; i++) {
            activeList[i].classList.remove('app-menu-level-2-active');
            activeList[i].classList.add('app-menu-level-2-inactive');
        }

        var el = e.target;

        if (el.nodeName == 'A') {
            el.classList.add('nav-link-item-active');
        } else {
            el = el.closest('a');
            el.classList.add('nav-link-item-active');
        }

        if (el.nodeName != 'LI') {
            el = el.closest('li');
        }

        var ul = el.childNodes[1];
        if (ul != undefined) {
            if (ul.classList.contains('app-menu-level-2-inactive')) {
                ul.classList.remove('app-menu-level-2-inactive');
                ul.classList.add('app-menu-level-2-active');
            } else {
                ul.classList.remove('app-menu-level-2-active');
                ul.classList.add('app-menu-level-2-inactive');
            }
        }
     }

    function navToPage(path, e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        navigate(path);

        var sb = document.getElementById('app-sidebar');
        var activeList = sb.querySelectorAll('.nav-link-child .nav-link-item-active');
        for (var i = 0; i < activeList.length; i++) {
            activeList[i].classList.remove('nav-link-item-active');
        }


        var linkElement = e.target;
        if (linkElement.nodeName != 'A') {
            linkElement = linkElement.closest('a');
        }
        linkElement.classList.add('nav-link-item-active');


    }


      return (
          <div className="app-menu">
              <nav className="flex-column">
                  <ul>
                      {currentUser.currentBusinessAccountDTO.active == false ?
                          <li className="nav-link-parent" onClick={expandChildNav}>
                              <Link to="/inactive" className="nav-link-item">
                                  <div className="icon-text-container">
                                      <BsHouseFill className="nav-link-icon" /><span className="nav-link-parent-caption">{getTranslation('application_shell', 'main_menu', 'Inactive')}</span>
                                  </div>
                              </Link>
                          </li>
                          : null}
                      {currentUser.currentBusinessAccountDTO.active == true ?
                          <>
                      <li className="nav-link-parent" onClick={expandChildNav}>
                          <Link to="/welcome" className="nav-link-item">
                              <div className="icon-text-container">
                                  <BsHouseFill className="nav-link-icon" /><span className="nav-link-parent-caption">{getTranslation('application_shell', 'main_menu', 'Home')}</span>
                              </div>
                          </Link>
                      </li>
                      {currentUser.currentBusinessAccountDTO != null ?
                          <>
                                {helpers.permissionFunctionAllowed(currentUser.permissions, 'Reporting-ViewReports') == true ?
                                    <li className="nav-link-parent" onClick={expandChildNav}>
                                        <Link to="/overview" className="nav-link-item">
                                            <div className="icon-text-container">
                                                <BsBarChartFill className="nav-link-icon" /><span className="nav-link-parent-caption">{getTranslation('application_shell', 'main_menu', 'Reports')}</span>
                                            </div>
                                        </Link>
                                    </li>
                                : null}
                          </>
                          : null}
                      {currentUser.currentBusinessAccountDTO != null ?
                          <>
                              <li className="nav-link-parent" onClick={expandChildNav} style={{display:"none"} }>
                          <Link to="/appcontrols" className="nav-link-item">
                              <div className="icon-text-container">
                                  <BsTools className="nav-link-icon" /><span className="nav-link-parent-caption">{getTranslation('application_shell', 'main_menu', 'Application Controls')}</span>
                              </div>
                          </Link>
                      </li>
                              <li className="nav-link-parent" onClick={expandChildNav} style={{display:"none"} }>
                          <Link to="/contactsearch" className="nav-link-item">
                              <div className="icon-text-container">
                                  <BsBarChartFill className="nav-link-icon" /><span className="nav-link-parent-caption">{getTranslation('application_shell', 'main_menu', 'Dashboard')}</span>
                              </div>
                          </Link>
                              </li>
                              
                              {helpers.permissionCategoryAllowed(currentUser.permissions, 'CRM') == true ?
                                  <li className="nav-link-parent" onClick={expandChildNav}>
                                      <a className="nav-link-item">
                                          <div className="icon-text-container noclick">
                                              <BsPeopleFill className="nav-link-icon noclick" /><span className="nav-link-parent-caption noclick">{getTranslation('application_shell', 'main_menu', 'Contact Management')}</span>
                                          </div>
                                      </a>
                                      <ul className="app-menu-level-2 app-menu-level-2-inactive">
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'CRM-Contacts') == true ?
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/contactsearch', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Contacts')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                              : null}
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'CRM-SegmentedLists') == true ?
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/segmentedlists', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Segmented Lists')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                              : null}
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'CRM-Tags') == true ?
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/tags', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Tags')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                              : null}
                                      </ul>
                                  </li>
                                  : null}
                              {helpers.permissionCategoryAllowed(currentUser.permissions, 'Marketing') == true ?
                                  <li className="nav-link-parent" onClick={expandChildNav}>
                                      <a className="nav-link-item">
                                          <div className="icon-text-container noclick">
                                              <BsMegaphoneFill className="nav-link-icon noclick" /><span className="nav-link-parent-caption noclick">{getTranslation('application_shell', 'main_menu', 'Marketing')}</span>
                                          </div>
                                      </a>
                                      <ul className="app-menu-level-2 app-menu-level-2-inactive">
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'Marketing-Websites') == true ?
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/sites', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Websites')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                              : null}
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'Marketing-Forms') == true ?
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/forms', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Forms')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                                      : null}
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'Marketing-Funnels/Automations') == true ?
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/automations', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Funnels / Automations')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                              : null}
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/emailbroadcasts', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Email Broadcasts')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/smsbroadcasts', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'SMS Broadcasts')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'Marketing-Blogs') == true ?
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/blogs', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Blogs')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                                      : null}
                                                  <li className="nav-link-child">
                                                      <a className="nav-link-item" onClick={(e) => { navToPage('/integrationscheduledsmposts', e); }}>
                                                          <div className="icon-text-container">
                                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Social Post Manager')}</span>
                                                          </div>
                                                      </a>
                                                  </li>
                                      </ul>
                                  </li>
                                          : null}
                                      <li className="nav-link-parent" onClick={expandChildNav}>
                                          <a className="nav-link-item">
                                              <div className="icon-text-container noclick">
                                                  <BsFillCalendar3WeekFill className="nav-link-icon noclick" /><span className="nav-link-parent-caption noclick">{getTranslation('application_shell', 'main_menu', 'Booking Management')}</span>
                                              </div>
                                          </a>
                                          <ul className="app-menu-level-2 app-menu-level-2-inactive">
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/bookableitems', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Bookable Items')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/reservations', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Reservations/Meetings')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                          </ul>
                                      </li>



                                      <li className="nav-link-parent" onClick={expandChildNav}>
                                          <Link to="/courses" className="nav-link-item">
                                              <div className="icon-text-container">
                                                  <BsMortarboardFill className="nav-link-icon" /><span className="nav-link-parent-caption">{getTranslation('application_shell', 'main_menu', 'Courses')}</span>
                                              </div>
                                          </Link>
                                      </li>
                              {helpers.permissionCategoryAllowed(currentUser.permissions, 'ProjectManagement') == true ?
                                  <li className="nav-link-parent" onClick={expandChildNav}>
                                      <a className="nav-link-item">
                                          <div className="icon-text-container noclick">
                                              <BsFillKanbanFill className="nav-link-icon noclick" /><span className="nav-link-parent-caption noclick">{getTranslation('application_shell', 'main_menu', 'Project Management')}</span>
                                          </div>
                                      </a>
                                      <ul className="app-menu-level-2 app-menu-level-2-inactive">
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'ProjectManagement-Projects') == true ?
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/projects', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Projects')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                              : null}
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'ProjectManagement-ProjectBoards') == true ?
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/boards', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Workflow Boards')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                              : null}
                                      </ul>
                                  </li>
                                  : null}
                              {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-Products') == true ?
                                  <li className="nav-link-parent" onClick={expandChildNav}>
                                      <a className="nav-link-item">
                                          <div className="icon-text-container noclick">
                                              <BsFillBoxSeamFill className="nav-link-icon noclick" /><span className="nav-link-parent-caption noclick">{getTranslation('application_shell', 'main_menu', 'Product Management')}</span>
                                          </div>
                                      </a>
                                      <ul className="app-menu-level-2 app-menu-level-2-inactive">
                                          <li className="nav-link-child">
                                              <a className="nav-link-item" onClick={(e) => { navToPage('/products', e); }}>
                                                  <div className="icon-text-container">
                                                      <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Products / Services')}</span>
                                                  </div>
                                              </a>
                                          </li>
                                          {(currentUser.currentBusinessAccountDTO.baid == 1 && currentUser.firstName == 'System') ?
                                              <>
                                                  <li className="nav-link-child">
                                                      <a className="nav-link-item" onClick={(e) => { navToPage('/collections', e); }}>
                                                          <div className="icon-text-container">
                                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Collections')}</span>
                                                          </div>
                                                      </a>
                                                  </li>
                                                  <li className="nav-link-child">
                                                      <a className="nav-link-item" onClick={(e) => { navToPage('/producttypes', e); }}>
                                                          <div className="icon-text-container">
                                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Product Types')}</span>
                                                          </div>
                                                      </a>
                                                  </li>
                                                  <li className="nav-link-child">
                                                      <a className="nav-link-item" onClick={(e) => { navToPage('/discountcodes', e); }}>
                                                          <div className="icon-text-container">
                                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Discount Codes')}</span>
                                                          </div>
                                                      </a>
                                                  </li>
                                              </>
                                              : null}
                                      </ul>
                                  </li>
                                  : null}
                              {helpers.permissionCategoryAllowed(currentUser.permissions, 'ECommerce') == true ?
                                  <li className="nav-link-parent" onClick={expandChildNav}>
                                      <a className="nav-link-item">
                                          <div className="icon-text-container noclick">
                                              <BsCart4 className="nav-link-icon noclick" /><span className="nav-link-parent-caption noclick">{getTranslation('application_shell', 'main_menu', 'Transactions')}</span>
                                          </div>
                                      </a>
                                      <ul className="app-menu-level-2 app-menu-level-2-inactive">
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-Orders') == true ?
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/transactions/order', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Orders')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                                      : null}
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-Quotes') == true ?
                                                      <li className="nav-link-child">
                                                          <a className="nav-link-item" onClick={(e) => { navToPage('/transactions/quote', e); }}>
                                                              <div className="icon-text-container">
                                                                  <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Quotes')}</span>
                                                              </div>
                                                          </a>
                                                      </li>
                                                      : null}
                                                  {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-Subscriptions') == true ?
                                                      <li className="nav-link-child">
                                                          <a className="nav-link-item" onClick={(e) => { navToPage('/transactions/subscription', e); }}>
                                                              <div className="icon-text-container">
                                                                  <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Subscriptions')}</span>
                                                              </div>
                                                          </a>
                                                      </li>
                                                      : null}
                                                  {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-Invoices') == true ?
                                                      <li className="nav-link-child">
                                                          <a className="nav-link-item" onClick={(e) => { navToPage('/transactions/invoice', e); }}>
                                                              <div className="icon-text-container">
                                                                  <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Invoices')}</span>
                                                              </div>
                                                          </a>
                                                      </li>
                                                      : null}
                                                  {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-Payments') == true ?
                                                      <li className="nav-link-child">
                                                          <a className="nav-link-item" onClick={(e) => { navToPage('/transactions/payment', e); }}>
                                                              <div className="icon-text-container">
                                                                  <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Payments')}</span>
                                                              </div>
                                                          </a>
                                                      </li>
                                                      : null}
                                          {helpers.permissionFunctionAllowed(currentUser.permissions, 'ECommerce-AbandonedCarts') == true ?
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/transactions/cart', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Abandoned Carts')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                              : null}
                                      </ul>
                                  </li>
                                  : null}
                              {helpers.permissionCategoryAllowed(currentUser.permissions, 'FileManager') == true ?
                                  <li className="nav-link-parent" onClick={expandChildNav}>
                                      <Link to="/filemanager" className="nav-link-item">
                                          <div className="icon-text-container">
                                              <BsFolderFill className="nav-link-icon" /><span className="nav-link-parent-caption">{getTranslation('application_shell', 'main_menu', 'File Management')}</span>
                                          </div>
                                      </Link>
                                  </li>
                                  : null}

                                  <li className="nav-link-parent" onClick={expandChildNav}>
                                      <Link to="/ma" className="nav-link-item">
                                          <div className="icon-text-container" style={{ marginLeft: "10px" }}>
                                              <img alt='' src="images/fg_ai_avatar_ma_profile_60_x_60.png" style={{ width: "24px", height: "24px", borderRadius: "50%" }} /><span style={{ marginLeft: "10px" }} className="nav-link-parent-caption">{getTranslation('application_shell', 'main_menu', 'MA - Ai Model')}</span>
                                          </div>
                                      </Link>
                                  </li>

                          </>
                          : null}
                          </>
                          : null}
                          <li className="nav-link-parent" onClick={expandChildNav}>
                              <a className="nav-link-item">
                                  <div className="icon-text-container noclick">
                                      <BsGearFill className="nav-link-icon noclick" /><span className="nav-link-parent-caption noclick">{getTranslation('application_shell', 'main_menu', 'Settings')}</span>
                                  </div>
                              </a>
                              <ul className="app-menu-level-2 app-menu-level-2-inactive">
                                  <li className="nav-link-child">
                                      <a className="nav-link-item" onClick={(e) => { navToPage('/businessaccounts', e); }}>
                                          <div className="icon-text-container">
                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Business Accounts')}</span>
                                          </div>
                                      </a>
                              </li>
                              {currentUser.currentBusinessAccountDTO.active == true ?
                                  <>
                                  {
                                      currentUser.currentBusinessAccountDTO != null ?
                                          <>
                                              {helpers.permissionFunctionAllowed(currentUser.permissions, 'Security-BusinessAccountInfo') == true ?
                                                  <li className="nav-link-child">
                                                      <a className="nav-link-item" onClick={(e) => { navToPage('/businessaccountinfo/' + currentUser.currentBusinessAccountDTO.baid, e); }}>
                                                          <div className="icon-text-container">
                                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Account Info')}</span>
                                                          </div>
                                                      </a>
                                                  </li>
                                                  : null}
                                              {helpers.permissionFunctionAllowed(currentUser.permissions, 'Security-BusinessAccountMembers') == true ?
                                                  <li className="nav-link-child">
                                                      <a className="nav-link-item" onClick={(e) => { navToPage('/accountmembers', e); }}>
                                                          <div className="icon-text-container">
                                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Account Members')}</span>
                                                          </div>
                                                      </a>
                                                  </li>
                                                  : null}
                                              {helpers.permissionFunctionAllowed(currentUser.permissions, 'Security-SecurityRoles') == true ?
                                                  <li className="nav-link-child">
                                                      <a className="nav-link-item" onClick={(e) => { navToPage('/securityroles', e); }}>
                                                          <div className="icon-text-container">
                                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Security Roles')}</span>
                                                          </div>
                                                      </a>
                                                  </li>
                                                  : null}
                                              {helpers.permissionFunctionAllowed(currentUser.permissions, 'Reporting-TrackingPixels') == true ?
                                                  <li className="nav-link-child">
                                                      <a className="nav-link-item" onClick={(e) => { navToPage('/trackingpixels', e); }}>
                                                          <div className="icon-text-container">
                                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Tracking Pixels')}</span>
                                                          </div>
                                                      </a>
                                                  </li>
                                                  : null}
                                              {helpers.permissionFunctionAllowed(currentUser.permissions, 'Integrations-Integrations') == true ?
                                                  <li className="nav-link-child">
                                                          <a className="nav-link-item" onClick={(e) => { navToPage('/integrations', e); }}>
                                                          <div className="icon-text-container">
                                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Integrations')}</span>
                                                          </div>
                                                      </a>
                                                  </li>
                                                  : null}
                                              {(currentUser.currentBusinessAccountDTO.baid == 1 && currentUser.firstName == 'System') ?
                                                  <li className="nav-link-child">
                                                      <a className="nav-link-item" onClick={(e) => { navToPage('/accountusage', e); }}>
                                                          <div className="icon-text-container">
                                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Account Usage')}</span>
                                                          </div>
                                                      </a>
                                                  </li>
                                                  : null}
                                                  {currentUser.currentBusinessAccountDTO.whiteLabel == true ?
                                                      <li className="nav-link-child">
                                                          <a className="nav-link-item" onClick={(e) => { navToPage('/whitelabelsettings/' + currentUser.currentBusinessAccountDTO.baid, e); }}>
                                                              <div className="icon-text-container">
                                                                  <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'White Label Settings')}</span>
                                                              </div>
                                                          </a>
                                                      </li>
                                                      : null}
                                                  {helpers.permissionFunctionAllowed(currentUser.permissions, 'Security-AccountCancellation') == true ?
                                                  <li className="nav-link-child">
                                                      <a className="nav-link-item" onClick={(e) => { navToPage('/cancelaccount', e); }}>
                                                          <div className="icon-text-container">
                                                              <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'Cancel Account')}</span>
                                                          </div>
                                                      </a>
                                                  </li>
                                                  : null}
                                              <li className="nav-link-child">
                                                  <a className="nav-link-item" onClick={(e) => { navToPage('/userprofile', e); }}>
                                                      <div className="icon-text-container">
                                                          <span className="nav-link-child-caption">{getTranslation('application_shell', 'main_menu', 'User Profile')}</span>
                                                      </div>
                                                  </a>
                                              </li>
                                          </>
                                          : null
                                      }
                                  </>
                                  : null}
                              </ul>
                          </li>
                  </ul>
              </nav>
          </div>
    );


}

export default AppMainNav;
