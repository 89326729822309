import React, {useEffect} from "react";


function BookingLocationsCtrl({ locationConfigurations, selectedLocationId, onLocationChanged }){

    function determineSelectedStyle(locationId){
        let styleBackgroundColor = "white";
        if (locationId === selectedLocationId){
            styleBackgroundColor = "whitesmoke";
        }
        return styleBackgroundColor;
    }

    useEffect(() => {
        if (locationConfigurations.length > 0) {
            if (locationConfigurations.length == 1) {
                onLocationChanged(locationConfigurations[0].locationId);
            } else {
                if (selectedLocationId != null) {
                    onLocationChanged(selectedLocationId);
                }
            }
        }
    }, [locationConfigurations])

    return (
        <div>
            <div style={{marginBottom:"20px",fontWeight:"bold"}}>Select Location</div>
            <div style={{display:"grid",gridTempalteColumns:"1fr",gridGap:"20px"}}>
                {locationConfigurations.map((location, index) => {
                    return (
                        <div key={location.locationId} style={{ backgroundColor: determineSelectedStyle(location.locationId), display: "grid", gridTemplateColumns: "1fr", gridGap: "10px", width: "100%", padding: "10px" }}>
                            <div>
                                <div style={{ fontWeight: "bold" }}>{location.locationType}:</div>
                                <div>{location.placeName}</div>
                                <div>{location.address}</div>
                                <div><button style={{ border: "none", backgroundColor: "#D9DADF", padding: "5px 15px 5px 15px", borderRadius: "4px" }} onClick={(e) => { onLocationChanged(location.locationId) }}>Select</button></div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default BookingLocationsCtrl;