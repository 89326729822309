import React, { useContext, useState, useEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import helpers from '../../../helpers/Helpers';
import dayjs from 'dayjs';

function GridReportColumnCtrl({ props }) {

    function formatText(value) {
        var finalValue = value;
        switch (props.columnObj.dataType) {
            case 'money':
                finalValue = '$' + helpers.formatNumber(finalValue, 2);
                break;
            case 'decimal':
                finalValue = helpers.formatNumber(finalValue, 2);
                break;
            case 'number':
                finalValue = helpers.formatNumber(finalValue, 0);
                break;
            case 'date':
                if (finalValue != null) {
                    finalValue = dayjs(finalValue).format("MM-DD-YYYY");
                }
                break;

        }
        return finalValue;
    }

    function determineTextAlign(dataType) {
        var textAlign = "";
        switch (dataType) {
            case 'string':
                textAlign = "left";
                break;
            case 'money':
            case 'decimal':
            case 'number':
                textAlign = "right";
                break;

        }
        return textAlign;
    }

    const borderColor = "#DADCDE";

    function getHeaderGridTemplateColumns() {
        var headerGridTemplateColumns = "";
        if (props.columnObj.showSettings == true) {
            headerGridTemplateColumns += "16px ";
        }
        headerGridTemplateColumns += "1fr ";
        if (props.columnObj.showIcon == true) {
            headerGridTemplateColumns += "16px ";
        }
        if (props.columnObj.showSort == true) {
            headerGridTemplateColumns += "20px ";
        }

        //if (props.columnObj.showSettings == true) {
        //    headerGridTemplateColumns = "16px 1fr 16px 20px";
        //} else {
        //    headerGridTemplateColumns = "1fr 20px";
        //}
        return headerGridTemplateColumns;
    }
    
    useEffect(() => {


    },[])

    
    return (
        <>
            <div style={{ border: "0px solid whitesmoke", padding: "0px", borderRight: "1px solid " + borderColor }}>
                {props.internalCtrl == false ?
                    <div style={{ minHeight:"35px",padding: "5px 10px 5px 10px", display: "grid", gridTemplateColumns: getHeaderGridTemplateColumns(), borderTop: "1px solid " + borderColor }}>
                        {props.columnObj.showSettings == true ?
                            <div style={{ textAlign: "left", position: "relative" }}><MoreVertIcon style={{ color: "#c0c0c0", position: "absolute", left: "0px", marginLeft: "-9px" }} /></div>
                            : null}
                        <div style={{ paddingTop: "3px", fontWeight: "bold", fontSize: "14px", lineHeight: "120%" }}>{props.columnObj.caption}</div>
                        {props.columnObj.showIcon == true ?
                            <div><img src={"images/" + props.columnObj.icon} style={{ width: "14px", height: "14px", marginTop: "-2px", padding: "0px" }} /></div>
                            : null}
                        {props.columnObj.showSort == true ?
                        <div style={{ position: "relative" }}>
                            <div style={{ height: "12px", position: "absolute", top: "-6px", color: "#c0c0c0" }}><ArrowDropUpIcon /></div>
                            <div style={{ height: "12px", position: "absolute", top: "6px", color: "#c0c0c0" }}><ArrowDropDownIcon /></div>
                        </div>
                            : null}
                    </div>
                    : 
                    <div style={{ minHeight:"35px",padding: "5px 10px 5px 10px", borderTop: "1px solid " + borderColor }}>
                        <input type="checkbox" style={{ transform: "scale(1.5, 1.5)", appearance: "auto",cursor:"pointer" }} />
                    </div>
                }
                <div>
                    <>
                    {props.internalCtrl == true ?
                            (props.data.rows.map((rowData, index) => (
                                <div style={{ height: "52px", fontSize: "14px", color: "#1c1e21", color: "#212529", padding: "5px 10px 5px 10px", borderTop: "1px solid " + borderColor }}>
                                    <input type="checkbox" style={{ transform: "scale(1.5, 1.5)", appearance: "auto", cursor: "pointer" }} />
                                </div>
                            )))
                        : 

                            (props.data.rows != null ?
                                <>
                                {
                                    props.data.rows.map((rowData, index) => (
                                        <div style={{ textAlign: determineTextAlign(props.columnObj.dataType), height: "52px", fontSize: "14px", color: "#1c1e21", color:"#212529",padding: "5px 10px 5px 10px", borderTop: "1px solid " + borderColor }}>
                                            {formatText(rowData[props.columnObj.name])}
                                            {props.columnObj.hasOwnProperty('secondValue') == true ?
                                                <div style={{color:"#c0c0c0",fontSize:"14px",lineHeight:"100%"}}>{props.columnObj.secondValue.caption}</div>
                                            : null}                                    
                                        </div>
                                    ))
                                }
                                </>
                            : null)
                        }
                    </>
                    {props.data.totals != null ?
                        <>
                            {props.columnObj != undefined ?
                                <div style={{ textAlign: determineTextAlign(props.columnObj.dataType), fontWeight: "bold", height: "52px", fontSize: "14px", color: "#1c1e21", color: "#212529", padding: "5px 10px 5px 10px", borderTop: "1px solid " + borderColor, borderBottom: "1px solid " + borderColor }}>
                                    {formatText(props.data.totals[props.columnObj.name])}
                                </div>
                                : 
                                <div style={{fontWeight: "bold", height: "52px", fontSize: "14px", color: "#1c1e21", color: "#212529", padding: "5px 10px 5px 10px", borderTop: "1px solid " + borderColor, borderBottom: "1px solid " + borderColor }}>
                                    
                                </div>
                            }
                        </>
                        : null}
                </div>
                
            </div>
        </>
    );
}

export default GridReportColumnCtrl;