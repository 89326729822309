import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../dialogctrl/dialogctrl';
import LoaderSpinnerCtrl from '../loaderspinnerctrl/loaderspinnerctrl';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch, { fetchData } from '../../customhooks/useFetch';

function ChangePasswordForm({userToken}) {

    const [formData, setFormData] = useState({ password: null, passwordRepeat: null});
    const [errorList, setErrorList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [loaderSchema, setLoaderSchema] = useState({});
    const navigate = useNavigate();
    const [status, setStatus] = useState("Open");


    return (
        <>
            <div className="segment-title" style={{ marginBottom: "5px", fontSize: "34px", textAlign: "center" }}>Change Password</div>
            {status == "Open" ?
                <div>
                    <div style={{ marginBottom: "40px", textAlign: "center" }}>Enter your new password.</div>
                    <div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <TextField id="password" autoFocus required type="password" fullWidth label="Password" variant="outlined" size="medium" helperText={errorList['password'] ? <span style={{ color: "red" }}>{errorList['password']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.password || ''} InputLabelProps={{ shrink: formData.password }} onChange={(e) => setFormData(formData => ({ ...formData, password: e.target.value }))} />
                                        <TextField id="passwordRepeat" required type="password" fullWidth label="Password Confirm" variant="outlined" size="medium" helperText={errorList['passwordRepeat'] ? <span style={{ color: "red" }}>{errorList['passwordRepeat']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.passwordRepeat || ''} InputLabelProps={{ shrink: formData.passwordRepeat }} onChange={(e) => setFormData(formData => ({ ...formData, passwordRepeat: e.target.value }))} />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-sm-12">
                                        <Button variant="contained" size="large" fullWidth onClick={() => { changePassword(); }}>CHANGE PASSWORD</Button>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "20px", textAlign: "center" }}>
                                    <div className="col-sm-12">
                                        <p><a href="javascript:void(0)" onClick={() => { navigate('/login'); }}>Back to login page</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
            {status == "Completed" ?
                <div>
                    <div style={{ marginBottom: "20px", textAlign: "center", fontSize:"20px" }}>Password Changed</div>
                    <div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row" style={{ marginTop: "20px", textAlign: "center" }}>
                                    <div className="col-sm-12">
                                        <p><a href="javascript:void(0)" onClick={() => { navigate('/login'); }}>Back to login page</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
            {status == "Error" ?
                <div>
                    <div style={{ marginBottom: "20px", textAlign: "center", fontSize: "20px", color:"red" }}>Change Password Error</div>
                    <div style={{ marginBottom: "20px", textAlign: "center" }}><small>Sorry... We cannot change your password. Please contact our support team.</small>.</div>
                    <div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row" style={{ marginTop: "20px", textAlign: "center" }}>
                                    <div className="col-sm-12">
                                        <p><a href="javascript:void(0)" onClick={() => { navigate('/login'); }}>Back to login page</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );

    function tryAgain() {
        setStatus("Open");
    }

    function changePassword() {
        if (isCustomFormValidationValid() == true) {
            fetchData({
                callName: "ChangePassword",
                method: "POST",
                url: "security/user/changepassword?userToken=" + userToken + "&newpassword=" + formData.password,
                token: "fg1234",
                body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function onAPISuccess(data, header, callName) {
        switch (callName) {
            case "ChangePassword":
                if (data == true) {
                    setStatus("Completed");
                } else {
                    setStatus("Error");
                }
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error.message;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function isCustomFormValidationValid() {
        var isValid = true;
        var tempErrorList = {};
        if (formData.password == null || formData.password == '') {
            isValid = false;
            tempErrorList['password'] = "Password is required";
        }
        if (formData.passwordRepeat == null || formData.passwordRepeat == '') {
            isValid = false;
            tempErrorList['passwordRepeat'] = "Password Repeat is required";
        }
        if (formData.password != null && formData.password != '' && formData.passwordRepeat != null && formData.passwordRepeat != '') {
            if (formData.password != formData.passwordRepeat) {
                isValid = false;
                tempErrorList['password'] = "Passwords should match";
                tempErrorList['passwordRepeat'] = "Passwords should match";
            }
        }

        setErrorList(tempErrorList);
        return isValid;
    }
}

export default ChangePasswordForm;