import { useCallback, useEffect, useState } from "react";
//import axios from "axios";
import axiosConfig from '../components/AdminAxiosConfig';

const methodNameMapping = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete'
}

export const fetchData = async ({
    callName,
    method,
    body,
    url,
    token,
    config = {},
    onSuccess,
    onError
}) => {

    

    const enrichedConfig = {
        headers: {
            'Content-Type': 'application/json',
            //'Authorization': 'Bearer ' + token,
        },
        ...config,
    };
    try {
        let response;
        const axiosMethodName = methodNameMapping[method] || method;
        if (body) {
            response = (await axiosConfig[axiosMethodName](url, body, enrichedConfig));
        } else {
            response = (await axiosConfig[axiosMethodName](url, enrichedConfig));
        }
        const { data, headers } = response;
        if (onSuccess) {
            onSuccess(data, headers, callName);
        } else {
            return { data, headers, callName };
        }
        
    } catch (error) {
        console.log(error, callName);
        onError(error)
    }
};

const useFetch = ({ callName, method, url, token, body, toggleRefetch }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleSuccess = useCallback((dt) => {
        setData(dt);
        setLoading(false);
    }, [])

    const handleError =useCallback((error) => {
        setError(error.message);
        setLoading(false);
    }, [])

    useEffect(() => {

        setLoading(true);
        fetchData({
            callName,
            url,
            body,
            method,
            token,
            onSuccess: handleSuccess,
            onError: handleError
        });
    }, [url, body, method, token, handleSuccess, handleError , toggleRefetch]);

    return { data, loading, error };
};

export default useFetch;
