import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import Button from '@mui/material/Button';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { KanbanBrokerContext } from './KanbanCtrl';
import KanbanColumnCtrl from './KanbanColumnCtrl';

function KanbanBoardCtrl({ board }) {
    const { cards, setCards, boards, setBoards, projectId, handleOnSaveCard } = useContext(KanbanBrokerContext);


    const [boardObj, setBoardObj] = useState(board);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    //const [elements, setElements] = useState({
    //    autoitem_1111: {id: "autoitem_1111", autoType: "TrafficSource", autoName: "Facebook", caption: "Traffic Source", typography: {}, icon: "iconfacebook_off.png", width: "100",position: { top: 233, left: 50 }, inputs: [], outputs: { yes: { "autoitem_2222": { connectPosition: 'l-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_2222: { id: "autoitem_2222", autoType: "Page", autoName: "Optin", caption: "Sales Page", typography: {}, icon: "iconoptinpage_off.png", width: "100", position: { top: 200, left: 225 }, inputs: ['autoitem_1111'], outputs: { yes: { "autoitem_3333": { connectPosition: 'l-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_3333: { id: "autoitem_3333", autoType: "Event", autoName: "Purchase", caption: "Purchase", typography: {}, icon: "iconclick_off.png", width: "100", position: { top: 236, left: 400 }, inputs: ['autoitem_2222'], outputs: { yes: { "autoitem_4444": { connectPosition: 'l-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_4444: { id: "autoitem_4444", autoType: "Page", autoName: "Sales", caption: "Upsell", typography: {}, icon: "iconoptinpage_off.png", width: "100", position: { top: 200, left: 575 }, inputs: ['autoitem_3333'], outputs: { yes: { "autoitem_5555": { connectPosition: 'l-2', lineType: 'solid' } }, no: { "autoitem_6666": { connectPosition: 'l-1', lineType: 'solid' } } } },
    //    autoitem_5555: { id: "autoitem_5555", autoType: "Event", autoName: "Purchase", caption: "Purchase Upsell", typography: {}, icon: "iconclick_off.png", width: "100", position: { top: 231, left: 750 }, inputs: ['autoitem_4444'], outputs: { yes: { "autoitem_6666": { connectPosition: 't-2', lineType: 'solid' } }, no: {} } },
    //    autoitem_6666: { id: "autoitem_6666", autoType: "Page", autoName: "Optin", caption: "Thankyou ", typography: {}, icon: "iconoptinpage_off.png", width: "100", position: { top: 400, left: 750 }, inputs: ['autoitem_5555', 'autoitem_4444'], outputs: { yes: {}, no: {} } },
    //});

    useEffect(() => {
        setBoardObj(board);
    }, [board]);

    return (
        <>
            <div className="fgkb-board" >
                <div style={{ display: "grid", gridTemplateColumns: "25px 1fr", gridGap: "5px", width: "100%", marginBottom: "20px" }}>
                    <div><SettingsIcon style={{ color: "#768387" }} /></div>
                    <div>{boardObj.boardName} <small style={{ fontSize: "10px", fontWeight: "200" }}>BOARD</small></div>
                </div>
                <div style={{width:"100%",overflow:"auto"}}>
                    <div style={{ width: ((boardObj.columns.length + 1) * 280) + "px" }}>
                        {boardObj.columns.map((column, ci) => (
                            <KanbanColumnCtrl key={"column_" + ci} column={column} pipeline={boardObj.pipeline} />
                        ))}
                    </div>
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default KanbanBoardCtrl;



