import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import CropSquare from '@mui/icons-material/CropSquare';
import Title from '@mui/icons-material/Title';
import AddIcon from '@mui/icons-material/Add';

import BorderTopIcon from '@mui/icons-material/BorderTop';
import BorderRightIcon from '@mui/icons-material/BorderRight';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';


import TextField from '@mui/material/TextField';
import { DataBrokerContext, PageBuilderVariablesContext } from '../PageBuilderCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

function CssMarginCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [marginType, setMarginType] = useState(determineMarginType(widgetData));
    const [measurementType, setMeasurementType] = useState(getMeasurementType(widgetData));

    function handleOnMeasurementTypeChange(tmpMeasurementType) {
        var tmpWidgetObj = { ...widgetObj };
        if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint)) == false) {
            tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
        }
        var mt = '';
        var value = getMergedStyleToBP(tmpWidgetObj, 'margin') + getMergedStyleToBP(tmpWidgetObj, 'marginTop') + getMergedStyleToBP(tmpWidgetObj, 'marginRight') + getMergedStyleToBP(tmpWidgetObj, 'marginBottom') + getMergedStyleToBP(tmpWidgetObj, 'marginLeft');
        if (value != '') {
            if (value.indexOf("px") > -1) {
                mt = "px";
            } else if (value.indexOf("%") > -1) {
                mt = "%";
            } else if (value.indexOf("em") > -1) {
                mt = "em";
            } else if (value.indexOf("rem") > -1) {
                mt = "rem";
            } else if (value.indexOf("vw") > -1) {
                mt = "vw";
            } else if (value.indexOf("vh") > -1) {
                mt = "vh";
            } else if (value.indexOf("dvw") > -1) {
                mt = "svw";
            } else if (value.indexOf("dvh") > -1) {
                mt = "svh";
            }
        }

        if (mt != tmpMeasurementType) {
            var margin = getMergedStyleToBP(tmpWidgetObj, 'margin');
            var marginTop = getMergedStyleToBP(tmpWidgetObj, 'marginTop');
            var marginRight = getMergedStyleToBP(tmpWidgetObj, 'marginRight');
            var marginBottom = getMergedStyleToBP(tmpWidgetObj, 'marginBottom');
            var marginLeft = getMergedStyleToBP(tmpWidgetObj, 'marginLeft');

            if (margin != '' && margin != 'unset') {
                margin = margin.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['margin'] = margin;
            }
            if (marginTop != '' && marginTop != 'unset') {
                marginTop = marginTop.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['marginTop'] = marginTop;
            }
            if (marginRight != '' && marginRight != 'unset') {
                marginRight = marginRight.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['marginRight'] = marginRight;
            }
            if (marginBottom != '' && marginBottom != 'unset') {
                marginBottom = marginBottom.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['marginBottom'] = marginBottom;
            }
            if (marginLeft != '' && marginLeft != 'unset') {
                marginLeft = marginLeft.replace(measurementType, tmpMeasurementType);
                tmpWidgetObj.breakpointStyles[currentBreakpoint]['marginLeft'] = marginLeft;
            }
        }



        //change measurementType for each css property
        setMeasurementType((...measurementType) => { return tmpMeasurementType });
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function getMeasurementType(tmpWidgetObj) {
        var value = getMergedStyleToBP(tmpWidgetObj, 'margin') + getMergedStyleToBP(tmpWidgetObj, 'marginTop') + getMergedStyleToBP(tmpWidgetObj, 'marginRight') + getMergedStyleToBP(tmpWidgetObj, 'marginBottom') + getMergedStyleToBP(tmpWidgetObj, 'marginLeft');

        var mt = "px";
        if (value != '') {
            if (value.indexOf("px") > -1) {
                mt = "px";
            } else if (value.indexOf("%") > -1) {
                mt = "%";
            } else if (value.indexOf("em") > -1) {
                mt = "em";
            } else if (value.indexOf("rem") > -1) {
                mt = "rem";
            } else if (value.indexOf("vw") > -1) {
                mt = "vw";
            } else if (value.indexOf("vh") > -1) {
                mt = "vh";
            } else if (value.indexOf("dvw") > -1) {
                mt = "svw";
            } else if (value.indexOf("dvh") > -1) {
                mt = "svh";
            }
        }

        return mt;
    }

    function removeProperty(tmpWidgetObj, propertyName) {
        if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
            delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
        }
    }

    function determineMarginType(tmpWidgetObj) {
        var margin = getMergedStyleToBP(tmpWidgetObj, 'margin');
        var marginTop = getMergedStyleToBP(tmpWidgetObj, 'marginTop');
        var marginRight = getMergedStyleToBP(tmpWidgetObj, 'marginRight');
        var marginBottom = getMergedStyleToBP(tmpWidgetObj, 'marginBottom');
        var marginLeft = getMergedStyleToBP(tmpWidgetObj, 'marginLeft');

        var tmpMarginType = 'None';
        if (margin != '') {
            if (margin == "unset") {
                tmpMarginType = 'Unset';
            } else {
                tmpMarginType = 'All';
            }
        } else if (marginTop != '' || marginRight != '' || marginBottom != '' || marginLeft != '') {
            if (marginTop == marginBottom && marginLeft == marginRight) {
                tmpMarginType = 'TopBottomLeftRight';
            } else {
                tmpMarginType = 'Custom';
            }
        }
        return tmpMarginType;
    }

    function handleMarginTypeChange(marginType) {
        var tmpWidgetObj = { ...widgetObj };
        if (marginType == "None") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'margin');
                removeProperty(tmpWidgetObj, 'marginTop');
                removeProperty(tmpWidgetObj, 'marginRight');
                removeProperty(tmpWidgetObj, 'marginBottom');
                removeProperty(tmpWidgetObj, 'marginLeft');
            }
            setMarginType(marginType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
            propertyChangeCallback(tmpWidgetObj);
        } else if (marginType == "All") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'marginTop');
                removeProperty(tmpWidgetObj, 'marginRight');
                removeProperty(tmpWidgetObj, 'marginBottom');
                removeProperty(tmpWidgetObj, 'marginLeft');
            }
            setMarginType(marginType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        } else if (marginType == "Custom" || marginType == "TopBottomLeftRight") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'margin');
            }
            setMarginType(marginType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        } else if (marginType == "Unset") {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                removeProperty(tmpWidgetObj, 'marginTop');
                removeProperty(tmpWidgetObj, 'marginRight');
                removeProperty(tmpWidgetObj, 'marginBottom');
                removeProperty(tmpWidgetObj, 'marginLeft');
            }
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint]['margin'] = 'unset';
            setMarginType(marginType);
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
            propertyChangeCallback(tmpWidgetObj);
        }

    }

    function updateTopBottomLeftRight(targetMarginType, value, mt) {
        if (targetMarginType == "TopBottom") {
            updateCssProperty("marginTop", value + mt);
            updateCssProperty("marginBottom", value + mt);
        } else if (targetMarginType == "LeftRight") {
            updateCssProperty("marginLeft", value + mt);
            updateCssProperty("marginRight", value + mt);
        }
    }

    function removeByTargetMarginType(tmpWidgetObj, targetMarginType) {
        if (targetMarginType == "TopBottom") {
            removeProperty(tmpWidgetObj, "marginTop");
            removeProperty(tmpWidgetObj, "marginBottom");
        } else if (targetMarginType == "LeftRight") {
            removeProperty(tmpWidgetObj, "marginLeft");
            removeProperty(tmpWidgetObj, "marginRight");
        }
    }

    function handleOnPropertyChange(propertyName, value) {
        if (value == '') {
            var tmpWidgetObj = { ...widgetObj };
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(Number(currentBreakpoint))) {
                if (marginType != "TopBottomLeftRight") {
                    removeProperty(tmpWidgetObj, propertyName);
                } else {
                    if (propertyName == "marginTop" || propertyName == "marginBottom") {
                        removeByTargetMarginType(tmpWidgetObj, "TopBottom");
                    } else if (propertyName == "marginLeft" || propertyName == "marginRight") {
                        removeByTargetMarginType(tmpWidgetObj, "LeftRight");
                    }
                }
                setWidgetObj((...widgetObj) => { return tmpWidgetObj });
                propertyChangeCallback(tmpWidgetObj);
            }
        } else if (Number(value) || value == 0) {
            if (marginType != "TopBottomLeftRight") {
                updateCssProperty(propertyName, value + measurementType);
            } else {
                if (propertyName == "marginTop" || propertyName == "marginBottom") {
                    updateTopBottomLeftRight("TopBottom", value, measurementType)
                } else if (propertyName == "marginLeft" || propertyName == "marginRight") {
                    updateTopBottomLeftRight("LeftRight", value, measurementType);
                }
            }
        } else {
            if (marginType != "TopBottomLeftRight") {
                updateCssProperty(propertyName, value);
            } else {
                if (propertyName == "marginTop" || propertyName == "marginBottom") {
                    updateTopBottomLeftRight("TopBottom", value, "")
                } else if (propertyName == "marginLeft" || propertyName == "marginRight") {
                    updateTopBottomLeftRight("LeftRight", value, "");
                }
            }
        }
    }

    function parseValue(value) {
        var mtList = ['px', '%', 'em', 'rem', 'vw', 'vh', 'svw', 'svh', 'dvw', 'dvh'];
        for (var i = 0; i < mtList.length; i++) {
            value = value.toString().replace(mtList[i], '');
        }
        return value;
    }

    function updateCssProperty(propertyName, value) {
        var tmpValue = parseValue(value);

        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
        setMarginType(determineMarginType(widgetData));
    }, [widgetData])

    useEffect(() => {
        if (marginType == 'None') {
            setMarginType(determineMarginType(widgetObj));
        }
    }, [marginType])


    return (
        <>
            <div>
                <div className="fg-pg-panel-sub-section">Margin</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 30px", marginBottom: "5px" }}>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={marginType} onChange={(e) => { handleMarginTypeChange(e.target.value); }}>
                                <option value="None">None</option>
                                <option value="All">All Sides</option>
                                <option value="TopBottomLeftRight">Top/Bottom & Left/Right</option>
                                <option value="Custom">Custom</option>
                            </select>
                        </div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" style={{ textAlign: "center" }} value={measurementType} onChange={(e) => { handleOnMeasurementTypeChange(e.target.value); }}>
                                <option value="px">px</option>
                                <option value="%">%</option>
                                <option value="rem">rem</option>
                                <option value="vw">vw</option>
                                <option value="wh">vh</option>
                                <option value="svw">svw</option>
                                <option value="svh">svh</option>
                            </select>
                        </div>
                    </div>
                    {marginType == "All" ?
                        <div style={{ marginBottom: "5px" }}>
                            <SliderCtrl value={getMergedStyleToBP(widgetObj, 'margin').replace(measurementType, '')} min={0} max={200} onDataChange={(margin) => { handleOnPropertyChange('margin', margin); }} ignoreMeasurementType={true} ></SliderCtrl>
                        </div>
                        : null}
                    {(marginType == "Custom" || marginType == "TopBottomLeftRight") ?
                        <div>
                            <div style={{ marginBottom: "5px" }}>
                                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                    <div><BorderTopIcon /></div>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'marginTop').replace(measurementType, '')} onChange={(e) => { handleOnPropertyChange('marginTop', e.target.value); }} /></div>
                                    <div style={{ textAlign: "center" }}>{measurementType}</div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "100px 1fr 100px", gridGap: "3px", marginBottom: "5px" }}>
                                    <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                        <div><BorderLeftIcon /></div>
                                        <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'marginLeft').replace(measurementType, '')} onChange={(e) => { handleOnPropertyChange('marginLeft', e.target.value); }} /></div>
                                        <div style={{ textAlign: "center" }}>{measurementType}</div>
                                    </div>
                                    <div>&nbsp;</div>
                                    <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                        <div><BorderRightIcon /></div>
                                        <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'marginRight').replace(measurementType, '')} onChange={(e) => { handleOnPropertyChange('marginRight', e.target.value); }} /></div>
                                        <div style={{ textAlign: "center" }}>{measurementType}</div>
                                    </div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "3px", marginBottom: "5px", width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                                    <div><BorderBottomIcon /></div>
                                    <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'marginBottom').replace(measurementType, '')} onChange={(e) => { handleOnPropertyChange('marginBottom', e.target.value); }} /></div>
                                    <div style={{ textAlign: "center" }}>{measurementType}</div>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </>
    );
}

export default CssMarginCtrl;
