import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { useGlobalState } from '../globalstates/GState';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function OpenInvoices({ dialogType, handlerReturnValue }) {
    const [currentUser] = useGlobalState("userInfo");

    const navigate = useNavigate();
    let params = useParams();
    let internalTimeout = null;
    let criteria = null;
    let transType = params.transactionType
    const [transactionType, setTransactionType] = useState(params.transactionType);
    const [columns, setColumns] = useState([]);
    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "automations_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const [searchResultData, setSearchResultData] = useState([]);

    const [rowIds, setRowIds] = useState([]);
    const pageTitle = getTranslation('automations_search_page', 'header_section', getPageCaption());
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [gridId, setGridId] = useState(1);

    var newOptions = [];

        switch (transType) {
            case "order":
                newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Quick Sales Order'), name: "OrderQS", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/order/0") } })
                break;
            case "quote":
                newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Quote'), name: "Quote", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/quote/0") } });
                break;
            case "invoice":
                newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Invoice'), name: "Invoice", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/invoice/0") } });
                break;
            case "payment":
                newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Payment'), name: "Payment", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/payment/0") } });
                break;
            case "cart":
                //newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Quick Sales Order'), name: "OrderQS", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/order/0") } })
                break;
        }


    const headerOptions = {
        elements: [], breadcrumbs: [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }]
    }
    

    switch (transType) {
        case "order":
            if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Orders', ['allowDelete']) == true) {
                headerOptions.elements.push({ type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [] });
                headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } });
            }
            if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Orders', ['allowAdd']) == true) {
                headerOptions.elements.push({ type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'New'), name: "New", icon: "bi bi-plus-lg", url: null, dropdownClassNames: "fg-save-dropdown", dropdownBtnClassNames: "fg-save-dropdown-btn", color: "btn-success", elements: newOptions });
            }
            break;
        case "quote":
            if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Quotes', ['allowDelete']) == true) {
                headerOptions.elements.push({ type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [] });
                headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } });
            }
            if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Quotes', ['allowAdd']) == true) {
                headerOptions.elements.push({ type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'New'), name: "New", icon: "bi bi-plus-lg", url: null, dropdownClassNames: "fg-save-dropdown", dropdownBtnClassNames: "fg-save-dropdown-btn", color: "btn-success", elements: newOptions });
            }
            break;
        case "invoice":
            if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Invoices', ['allowDelete']) == true) {
                headerOptions.elements.push({ type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [] });
                headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } });
            }
            if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Invoices', ['allowAdd']) == true) {
                headerOptions.elements.push({ type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'New'), name: "New", icon: "bi bi-plus-lg", url: null, dropdownClassNames: "fg-save-dropdown", dropdownBtnClassNames: "fg-save-dropdown-btn", color: "btn-success", elements: newOptions });
            }
            break;
        case "payment":
            if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Payments', ['allowDelete']) == true) {
                headerOptions.elements.push({ type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [] });
                headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } });
            }
            if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Invoices', ['allowAdd']) == true) {
                headerOptions.elements.push({ type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'New'), name: "New", icon: "bi bi-plus-lg", url: null, dropdownClassNames: "fg-save-dropdown", dropdownBtnClassNames: "fg-save-dropdown-btn", color: "btn-success", elements: newOptions });
            }
            break;
        case "subscription":
            if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Subscriptions', ['allowDelete']) == true) {
                headerOptions.elements.push({ type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [] });
                headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } });
            }
            break;
        case "cart":
            break;
    }



    function resetGridColumns() {
        var tmpColumns = [];
        //transType == 'quote'
        if (transType != 'cart') {
            tmpColumns.push({
                field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
                renderCell: ({ row }) =>
                    <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('automations_search_page', 'search_grid', 'Open')}</Button>,
            });
        }

        tmpColumns.push({
            field: 'transactionNumber',
            headerName: getTranslation('automations_search_page', 'search_grid', '#'),
            width: 75,
            renderCell: function (params) {
                return <span style={{ fontSize: "22px", fontWeight: "bold" }}>{params.row.transactionNumber}</span>;
            }
        });
        if (transType == 'order') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Date'),
                width: 110,
                renderCell: function (params) {
                    return dayjs(params.row.transactionDate).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MM/DD/YYYY');
                }
            });
            tmpColumns.push({
                field: 'customerEmail',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
                width: 275,
                renderCell: function (params) {
                    return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
                }
            });
            tmpColumns.push({
                field: 'totalAmtDue',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Due'),
                width: 175,
                headerAlign: 'right',
                align: 'right',
                renderCell: function (params) {
                    return <div style={{ textAlign: "right" }}><span style={{ fontWeight: "bold" }}>{"$" + helpers.formatNumber(params.row.totalAmtDue, 2)}</span>
                        <br /><span style={{ fontSize: "12px" }}>Amt. Paid:</span><span style={{ display: "inline-block", fontSize: "12px", width: "60px" }}>{"$" + params.row.headerAmt}</span>
                    </div>
                }
            });
            tmpColumns.push(buildPostColumnSettings(params));
        } else if (transType == 'quote') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Dates'),
                width: 200,
                renderCell: function (params) {
                    return <div>
                        <span style={{ display: "inline-block", width: "90px" }}>Date: </span><span>{dayjs(params.row.transactionDate).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MM/DD/YYYY')}</span>
                        <br /><span style={{ display: "inline-block", width: "90px" }}>Expire Date:</span><span>{dayjs(params.row.transactionDate).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MM/DD/YYYY')}</span>
                    </div>;
                }
            });
            tmpColumns.push({
                field: 'customerEmail',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
                width: 275,
                renderCell: function (params) {
                    return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
                }
            });
            tmpColumns.push({
                field: 'headerAmt',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Quote Amount'),
                width: 145,
                renderCell: function (params) {
                    return <span style={{ fontWeight: "bold" }}>{params.row.headerAmt}</span>;
                }
            });
            tmpColumns.push(buildPostColumnSettings(params));
            tmpColumns.push({
                field: 'status',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Status'),
                width: 200,
                renderCell: function (params) {
                    if (params.row.status == "Draft") {
                        return <Chip label="Draft" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} />;
                    } else if (params.row.status == "Open") {
                        return <Chip label="Open" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} />;
                    } else if (params.row.status == "Approved") {
                        return <Chip label="Approved" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} />
                    } else if (params.row.status == "Denied") {
                        return <Chip label="Denied" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FC4B6C", color: "white", marginBottom: "3px" }} />;
                    }
                }
            });
        } else if (transType == 'invoice') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Dates'),
                width: 200,
                renderCell: function (params) {
                    return <div>
                        <span style={{ display: "inline-block", width: "90px" }}>Date: </span><span>{dayjs(params.row.transactionDate).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MM/DD/YYYY')}</span>
                        <br /><span style={{ display: "inline-block", width: "90px" }}>Due Date:</span><span>{dayjs(params.row.dueDate).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MM/DD/YYYY')}</span>
                    </div>;
                }
            });
            tmpColumns.push({
                field: 'customerEmail',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
                width: 275,
                renderCell: function (params) {
                    return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
                }
            });
            tmpColumns.push({
                field: 'headerAmt',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Due'),
                width: 175,
                headerAlign: 'right',
                align: 'right',
                renderCell: function (params) {
                    return <div style={{ textAlign: "right" }}><span style={{ fontWeight: "bold" }}>{"$" + helpers.formatNumber(params.row.headerAmt, 2)}</span>
                        <br /><span style={{ fontSize: "12px" }}>Amt. Paid:</span><span style={{ display: "inline-block", fontSize: "12px", width: "60px" }}>{"$" + helpers.formatNumber(params.row.totalPaid, 2)}</span>
                        <br /><span style={{ fontSize: "12px" }}>Balance:</span><span style={{ display: "inline-block", fontSize: "12px", width: "60px", color: "#FC4B6C" }}>{"$" + helpers.formatNumber(params.row.openBalance, 2)}</span>
                    </div>
                }
            });
            tmpColumns.push(buildPostColumnSettings(params));
            tmpColumns.push({
                field: 'lateInfo',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Late Info'),
                width: 200,
                headerAlign: 'left',
                align: 'left',
                renderCell: function (params) {
                    return <div>
                        <span style={{ display: "inline-block", fontSize: "12px", width: "85px", marginRight: "10px" }}>Days Late: </span><span style={{ display: "inline-block", fontSize: "12px", width: "80px", color: "red" }}>{params.row.daysLate}</span>
                        <br /><span style={{ display: "inline-block", fontSize: "12px", width: "85px", marginRight: "10px" }}>Attempt Date:</span><span style={{ display: "inline-block", fontSize: "12px", width: "80px" }}>{(params.row.lastPaymentAttemptDate != null ? dayjs(params.row.lastPaymentAttemptDate).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MM/DD/YYYY') : "")}</span>
                        <br /><Chip label={"Late Notice Attempts: " + (params.row.PaymentAttempts != null ? params.row.PaymentAttempts : 0)} sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FFB22B", color: "white" }} />
                    </div>
                }
            });
            tmpColumns.push({
                field: 'status',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Status'),
                width: 200,
                renderCell: function (params) {
                    if (params.row.status == "Draft") {
                        return <Chip label="Draft" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} />;
                    } else if (params.row.status == "Open") {
                        return <Chip label="Open" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} />;
                    } else if (params.row.status == "Paid") {
                        return <Chip label="Paid" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} />
                    } else if (params.row.status == "Partial Pmt.") {
                        return <Chip label="Partial Pmt." sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} />
                    } else if (params.row.status == "Past Due") {
                        return <Chip label="Past Due" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FC4B6C", color: "white", marginBottom: "3px" }} />;
                    } else if (params.row.status == "Cancelled") {
                        return <Chip label="Cancelled" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FC4B6C", color: "white", marginBottom: "3px" }} />;
                    }
                }
            });
        } else if (transType == 'payment') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Date'),
                width: 110,
                renderCell: function (params) {
                    return dayjs(params.row.transactionDate).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MM/DD/YYYY');
                }
            });
            tmpColumns.push({
                field: 'customerEmail',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
                width: 275,
                renderCell: function (params) {
                    return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
                }
            });
            tmpColumns.push({
                field: 'headerAmt',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Paid'),
                width: 145,
                renderCell: function (params) {
                    return <span style={{ fontWeight: "bold" }}>{params.row.headerAmt}</span>;
                }
            });
            tmpColumns.push(buildPostColumnSettings(params));
        } else if (transType == 'subscription') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Date'),
                width: 110,
                renderCell: function (params) {
                    return dayjs(params.row.transactionDate).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MM/DD/YYYY');
                }
            });
            tmpColumns.push({
                field: 'customerEmail',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
                width: 275,
                renderCell: function (params) {
                    return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
                }
            });
            tmpColumns.push({
                field: 'headerAmt',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Due'),
                width: 175,
                headerAlign: 'right',
                align: 'right',
                renderCell: function (params) {
                    return <div style={{ textAlign: "right" }}>
                        <span style={{ fontWeight: "bold" }}>{"$" + helpers.formatNumber(params.row.headerAmt, 2)}</span>
                        <br /><span style={{ display: "inline-block", fontSize: "12px" }}>{'every' + (params.row.billInterval > 1 ? params.row.billInterval : '') + ' ' + params.row.billPeriod}</span>
                    </div>
                }
            });
            tmpColumns.push({
                field: 'nextBillDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Next Bill Date'),
                width: 130,
                renderCell: function (params) {
                    return dayjs(params.row.nextBillDate).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MM/DD/YYYY');
                }
            });
            tmpColumns.push({
                field: 'daysLate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Days Late'),
                width: 130,
                renderCell: function (params) {
                    var daysLate = 0;
                    if (params.row.subscribeStatus == 'Renewing') {
                        var date1 = dayjs("11/01/2023");
                        var date2 = dayjs();
                        daysLate = date2.diff(date1, 'day');
                    }
                    return daysLate;
                }
            });
            tmpColumns.push({
                field: 'endDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'End Date'),
                width: 110,
                renderCell: function (params) {
                    if (params.row.billLength >= 1) {
                        var endDate = dayjs(params.row.transactionDate);
                        if (params.row.freeTrialInterval > 0) {
                            endDate = dayjs(endDate).add(params.row.freeTrialInterval, params.row.freeTrialPeriod);
                        }
                        endDate = dayjs(endDate).add((params.row.billLength * params.row.freeTrialInterval), params.row.freeTrialPeriod);
                        return dayjs(endDate).format('MM/DD/YYYY');
                    } else {
                        return "";
                    }
                }
            });
            tmpColumns.push({
                field: 'cancellationDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Cancel Date'),
                width: 130,
                renderCell: function (params) {
                    return (params.row.cancellationDate != null ? dayjs(params.row.cancellationDate).format('MM/DD/YYYY') : "");
                }
            });
            tmpColumns.push(buildPostColumnSettings(params));
            tmpColumns.push({
                field: 'subscribeStatus',
                headerName: getTranslation('products_search_page', 'search_grid', 'Status'),
                width: 160,
                renderCell: function (params) {
                    return <div>
                        {params.row.subscribeStatus == "Trial"
                            || params.row.subscribeStatus == "Active"
                            || params.row.subscribeStatus == "Internal Acct" ? 
                                <div>
                                <Chip label={params.row.subscribeStatus} sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} />
                                </div>
                            : null}
                        {params.row.subscribeStatus == "Renewing" ?
                            <div>
                                <Chip label="Active" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FFB22B", color: "white", marginBottom: "3px" }} />
                            </div>
                            : null}
                        {params.row.subscribeStatus == "Inactive"
                                    || params.row.subscribeStatus == "Cancelled" ?
                                    <div>
                                <Chip label={params.row.subscribeStatus} sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BC2130", color: "white", marginBottom: "3px" }} />
                            </div>
                            : null}
                                        {params.row.pendingStatus == "Cancellation Pending" ?
                                            <div>
                                <Chip label={params.row.pendingStatus} sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BC2130", color: "white", marginBottom: "3px" }} />
                            </div>
                            : null}
                                                {params.row.pendingStatus == "Renewing" ?
                                                    <div>
                                <Chip label="Renewing" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#FFB22B", color: "white", marginBottom: "3px" }} />
                            </div>
                            : null}
                    </div>
                }
            });
        } else if (transType == 'cart') {
            tmpColumns.push({
                field: 'transactionDate',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Date'),
                width: 110,
                renderCell: function (params) {
                    return dayjs(params.row.transactionDate).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MM/DD/YYYY');
                }
            });
            tmpColumns.push({
                field: 'customerEmail',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Customer'),
                width: 275,
                renderCell: function (params) {
                    if (params.row.customerEmail != '') {
                        return <div>Visitor Id:<span style={{ fontWeight: "bold" }}>{params.row.vId}</span></div>
                    } else {
                        return <div><span style={{ fontWeight: "bold" }}>{params.row.customerName}</span><br /><span style={{ fontSize: "12px" }}>{params.row.customerEmail}</span></div>
                    }
                    
                }
            });
            tmpColumns.push({
                field: 'totalAmtDue',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Amount Due'),
                width: 175,
                headerAlign: 'right',
                align: 'right',
                renderCell: function (params) {
                    return <div style={{ textAlign: "right" }}><span style={{ fontWeight: "bold" }}>{"$" + helpers.formatNumber(params.row.totalAmtDue, 2)}</span>                        
                    </div>
                }
            });
            tmpColumns.push({
                field: 'actions',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Follow-Up'),
                width: 120,
                renderCell: function (params) {
                    return <div>
                    {
                        params.row.emailSent == true ?
                        <>
                            <Chip label="Email Sent" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        :
                        <>
                            <Chip label="Email Not Sent" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        }
                    </div>
                }
            });

        }


        setColumns(tmpColumns);
    }

    function buildPostColumnSettings(params) {
        return {
            field: 'posted',
            headerName: getTranslation('products_search_page', 'search_grid', 'Posted'),
            width: 120,
            renderCell: function (params) {
                return <div>
                    {params.row.posted == true ?
                        <>
                            <Chip label="Posted" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        :
                        <>
                            <Chip label="Not-Posted" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>
                    }
                    {params.row.emailSent == true ?
                        <>
                            <Chip label="Email Sent" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        :
                        <>
                            <Chip label="Email Not Sent" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>
                    }
                    {params.row.fulfilled == true ?
                        <>
                            <Chip label="Fulfilled" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        :
                        <>
                            <Chip label="Not Fulfilled" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>
                    }
                </div>
            }
        };
    }


    function getPageCaption() {
        var title = "";
        switch (transType) {
            case 'order':
                title = "Orders";
                break;
            case 'quote':
                title = "Quotes";
                break;
            case 'invoice':
                title = "Invoices";
                break;
            case 'payment':
                title = "Payments";
                break;
            case 'subscription':
                title = "Subscriptions";
                break;
            case 'cart':
                title = "Abandoned Carts";
                break;
        }
        return title;
    }



    function onGridOpenBtnClick(row) {
        switch (transType) {
            case "order":
                navigate('/order/' + row.headerId);
                break;
            case "quote":
                navigate('/quote/' + row.headerId);
                break;
            case "invoice":
                navigate('/invoice/' + row.headerId);
                break;
            case "payment":
                navigate('/payment/' + row.headerId);
                break;
            case "subscription":
                navigate('/subscription/' + row.headerId);
                break;
        }        
    }

    function handleOnCriteriaChange(value) {
        criteria = value.toLowerCase();
        if (internalTimeout != undefined) {
            clearTimeout(internalTimeout);
        }
        internalTimeout = setTimeout(() => {
            beginSearch();
        }, 250);
    }

    function beginSearch() {

        var filtering = {};
        var conditionalGroups = [];
        var conditions = [];           

        conditions.push({ "Name": "transactionType", "ConditionOperator": "EQUAL", "Value": transType, "DataType": "String" });
        conditionalGroups.push({ "LogicalOperator": "and", "Conditions": conditions })

        if (criteria != null && criteria != '') {
            conditions = [];
            if (helpers.isNumeric(criteria)) {
                conditions.push({ "Name": "headerId", "ConditionOperator": "EQUAL", "Value": criteria, "DataType": "String" });
            } else {
                conditions.push({ "Name": "customerName", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" });
                conditions.push({ "Name": "customerEmail", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" });
            }
            conditionalGroups.push({ "LogicalOperator": "or", "Conditions": conditions })
        }

        filtering = {
            "LogicalOperator": "and", "ConditionGroups": conditionalGroups
        };


        if (transType == "invoice") {
            BAFetchData({
                callName: "GetData",
                method: "GET",
                url: "accounting/transactionheader/getinvoicelist?filtering=" + JSON.stringify(filtering),
                token: "fg1234",
                body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "GetData",
                method: "GET",
                url: "accounting/transactionheader/getlist?filtering=" + JSON.stringify(filtering),
                token: "fg1234",
                body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        }




    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleBulkDeleteConfirm(gridId) {
        if (rowIds.length > 0) {
            var plural = helpers.getPlural(rowIds.length);
            var msg = 'You are about to delete ' + rowIds.length + ' transaction' + plural + '. <span style="font-weight:bold;">Are you sure</span>?';
            setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecords() } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }

    function deleteRecords() {
        BAFetchData({
            callName: "BulkDelete",
            method: "POST",
            url: "accounting/transactionheader/BulkDelete",
            token: "fg1234",
            body: rowIds, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function resetResults() {
        setRowIds([]);
        beginSearch();
        //setSearchRefetch(true);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetData":
                setSearchResultData(data);
                break;
            case "BulkDelete":
                resetResults(); //Reset search results and clear records selected.               
                var msg = "Transaction record(s) has been deleted.";
                setGenericDialogSchema({ title: 'Transaction(s) Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }


    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        resetGridColumns();
        setTransactionType(params.transactionType);
        beginSearch();
    }, [params.transactionType]);

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />
            <div style={{ backgroundColor: "#ffffff", padding: "5px 10px 5px 10px", display: "grid", gridTemplateColumns: "1fr 70px", gridGap: "10px" }}>
                <div>
                    <TextField fullWidth placeholder="Enter search keywords" variant="standard" style={{ border: "1px solid whitesmoke" }} onChange={(e) => { handleOnCriteriaChange(e.target.value); }} />
                </div>
                <div>
                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { beginSearch(); }} style={{ width: "100%" }}>Search</button>
                </div>
            </div>            
            <div className="fg-app-page-box">
                <div>
                    <Box sx={{ height: 630, width: '100%' }}>
                        <DataGrid      
                            sx={{
                                border: 0,
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within": {
                                    outline: "none !important",
                                }, '& .MuiDataGrid-cell': {
                                    alignItems: 'start',paddingTop:'10px',paddingBottom:'10px'
                                },
                            }}
                            rowHeight={80}
                            rows={searchResultData}
                            getRowId={(row) => row.headerId}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={(ids) => {
                                setRowIds(ids);
                                if (handlerReturnValue) {
                                    handlerReturnValue(ids)
                                }
                            }}
                        />
                    </Box>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );


}

export default OpenInvoices;
