
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, {BAFetchData} from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Tags from "./Tags";
import SegmentedLists from "./SegmentedLists";
import EmailBroadcastDetail from "./EmailBroadcastDetail";
import { setGlobalState, useGlobalState } from '../globalstates/GState';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function IntegrationScheduledSMPosts() {

    const [currentUser] = useGlobalState("userInfo");

    const navigate = useNavigate();
    let internalTimeout = null;
    let criteria = null;
    const [searchResultData, setSearchResultData] = useState([]);

    let params = useParams();
    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );



    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const [toggleRefetch, setToggleRefetch] = useState(false)

    const [rowIds, setRowIds] = useState([]);



    const pageTitle = getTranslation('contact_search_page', 'header_section', 'Social Post Manager');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [gridId, setGridId] = useState(1);
    const headerOptions = {
        elements: [
            {
                type: "dropdown", text: getTranslation('contact_search_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }            
        ], breadcrumbs: [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }]
    }

    //if (helpers.permissionAllowed(currentUser.permissions, 'Reporting-TrackingPixels', ['allowDelete']) == true) {
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('contact_search_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } });
    //}
    //if (helpers.permissionAllowed(currentUser.permissions, 'Reporting-TrackingPixels', ['allowAdd']) == true) {
        headerOptions.elements.push({ type: "button", text: getTranslation('contact_search_page', 'header_section', 'New'), caption: "New", icon: "bi bi-plus-lg", url: "/integrationscheduledsmpost/0", target: "", color: "btn-success", options: null, function: null });
    //}

    const columns = [
        {
            field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
            renderCell: ({ row }) =>
                <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('contact_search_page', 'search_grid', 'Open')}</Button>,
        },
        {
            field: 'postType',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Type'),
            width: 150
        },
        {
            field: 'deliveryMethod',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Delivery Method'),
            width: 170
        },
        {
            field: 'scheduledDate',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Schedule Date'),
            width: 200,
            renderCell: function (params) {
                return (params.row.scheduledDate != null ? dayjs(params.row.scheduledDate).add(currentUser.currentTimezoneDTO.timezone, 'hour').format('MM/DD/YYYY h:mm a') : null);
            },
        },
        {
            field: 'message',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Content'),
            width: 400,
            renderCell: function (params) {
                return <>
                    <span><span style={{fontWeight:"bold"}}>{(params.row.title != null ? params.row.title : '')} </span><br/>
                    {(params.row.message != null ? params.row.message.substring(0, 100) : '') + '... ' + (params.row.linkUrl || '')}</span>
                </>
            },
        },
        //{
        //    field: 'message',
        //    headerName: getTranslation('contact_search_page', 'search_grid', 'Content'),
        //    width: 400,
        //    renderCell: function (params) {
        //        return (params.row.title != null ? params.row.title : '') + (params.row.message != null ? params.row.message.substring(0, 100) : '') + '... ' + (params.row.linkUrl || '')
        //    },
        //},
        {
            field: 'status',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Status'),
            width: 140,
            renderCell: function (params) {
                return <div>
                    {params.row.active == true ?
                        <>
                            <Chip label="Active" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        : 
                        <>
                            <Chip label="Draft" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>                    
                    }
                    {params.row.status == 'Published' ?
                        <>
                            <Chip label="Published" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#26C6DA", color: "white", marginBottom: "3px" }} /><br />
                        </>
                        :
                        <>
                            <Chip label="Not Published" sx={{ height: "auto", padding: "0px" }} style={{ padding: "0px", backgroundColor: "#BDBDBD", color: "white", marginBottom: "3px" }} /><br />
                        </>
                    }
                </div>
            }
        },
        {
            field: 'postId',
            headerName: getTranslation('contact_search_page', 'search_grid', 'Post Id'),
            width: 100
        },
    ];



    function onGridOpenBtnClick(row) {
        navigate('/integrationscheduledsmpost/' + row.postId);
    }

    useEffect(() => {
        beginSearch();
        setShowLoader(false);            
    },[]);

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />
            <div style={{ backgroundColor: "#ffffff", padding: "5px 10px 5px 10px", display: "grid", gridTemplateColumns: "1fr 70px", gridGap: "10px" }}>
                <div>
                    <TextField fullWidth placeholder="Enter search keywords" variant="standard" style={{ border: "1px solid whitesmoke" }} onChange={(e) => { handleOnCriteriaChange(e.target.value); }} />
                </div>
                <div>
                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { beginSearch(); }} style={{ width: "100%" }}>Search</button>
                </div>
            </div>
            <div className="fg-app-page-box">
                {/*<Button variant="outlined" onClick={showDialog}>*/}
                {/*    Open alert dialog*/}
                {/*</Button>*/}
                {/*<Button variant="contained" color="success" onClick={importContacts}>*/}
                {/*    Import Contacts*/}
                {/*</Button>*/}
                <div>
                    <Box sx={{ height: 630, width: '100%' }}>
                        <DataGrid   
                            rowHeight={60}
                            sx={{
                                border: 0,
                                whiteSpace: "normal !important",
                                lineHeight: "normal",
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within": {
                                    outline: "none !important",
                                },
                                "&.MuiDataGrid-root .MuiDataGrid-cell--withRenderer": {
                                    whiteSpace: "normal !important",
                                    lineHeight: "normal",
                                },
                            }}
                            rows={searchResultData}
                            getRowId={(row) => row.postId}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={(ids) => {
                                setRowIds(ids);
                            }}
                        />
                    </Box>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function importContacts() {
        //setLoaderSchema({msg:"Process will take a few seconds..."})
        setShowLoader(true);            
        setTimeout(() => {
            setTimeout(() => {
                setShowLoader(false);
            }, "1000");
        }, "50");
    }

    function showDialog() {
        var msg = 'You are about to delete these record. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Records Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); okAction() } })
        setOpenDialog(true);
    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog()} })
        setOpenDialog(true);
    }

    function handleBulkDeleteConfirm(gridId) {
        if (rowIds.length > 0) {
            var plural = helpers.getPlural(rowIds.length);
            var msg = 'You are about to delete ' + rowIds.length + ' record' + plural + '. <span style="font-weight:bold;">Are you sure</span>?';
            setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecords() } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }


    function deleteRecords() {
        var test = '';
        test = '1234';
        BAFetchData({
            callName: "BulkDelete",
            method: "POST",
            url: "integration/scheduled/sm/post/bulkdelete",
            token: "fg1234",
            body: JSON.parse('[' + rowIds + ']'), onSuccess: onAPISuccess, onError: onAPIError
        });
    }


    function resetResults() {
        setRowIds([]);
        beginSearch();
    }

    function handleOnCriteriaChange(value) {
        criteria = value.toLowerCase();
        if (internalTimeout != undefined) {
            clearTimeout(internalTimeout);
        }
        internalTimeout = setTimeout(() => {
            beginSearch();
        }, 250);
    }

    function beginSearch() {
        var filtering = {};

        if (criteria != null && criteria != '') {
            var conditions = [];

            conditions.push({ "Name": "ScheduleDate", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" });

            filtering = {
                "LogicalOperator": "and", "ConditionGroups": [
                    {
                        "LogicalOperator": "or", "Conditions": conditions
                    }]
            };

        }

        BAFetchData({
            callName: "GetData",
            method: "GET",
            url: "integration/scheduled/sm/post/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetData":
                setSearchResultData(data);
                break;
            case "BulkDelete":
                resetResults(); //Reset search results and clear records selected.               
                var msg = "Record(s) has been deleted.";
                setGenericDialogSchema({ title: 'Record(s) Deleted', component: null, body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);                
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

}

export default IntegrationScheduledSMPosts;
