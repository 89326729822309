import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';


function CssPositionCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [imageList, setImageList] = useState([{ name: "" }, { name: "" }]);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [widgetObj, setWidgetObj] = useState(widgetData);

    //const [widgetCss, setWidgetCss] = useState(elements[widgetId]);

    function handleOpenColorSelector() {
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Color Selector', component: (dialogType, setReturnValue) => <ColorSelectorCtrl defaultColor="red" hideControls={false} handlerReturnValue={dialogType, setReturnValue}></ColorSelectorCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: false, maxWidth: "200", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleReturnColor(returnValues); } })
        setOpenDialog(true);
    }

    function handleReturnColor(returnValues) {
        alert(JSON.stringify(returnValues));
    }

    function parseValue(value) {       
        var mtList = ['px', '%', 'em', 'rem', 'vw', 'vh', 'svw', 'svh', 'dvw', 'dvh'];
        for (var i = 0; i < mtList.length; i++) {
            value = value.toString().replace(mtList[i], '');
        }
        return value;
    }

    function updateCssProperty(propertyName, value) {
        var tmpValue = parseValue(value);

        console.log(tmpValue + ' ' + value);

        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnAlignChange(e, selectedAlign) {
        updateCssProperty("textAlign", selectedAlign);
    }

    function handleOnOverflowChange(e, selectedOverflow) {
        updateCssProperty("overflow", selectedOverflow);
    }

    

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Position</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Position</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'position')} onChange={(e) => { updateCssProperty('position', e.target.value); }}>
                                <option value="">Static</option>
                                <option value="relative">Relative</option>
                                <option value="absolute">Absolute</option>
                                <option value="fixed">Fixed</option>
                                <option value="sticky">Sticky</option>
                            </select>
                        </div>
                    </div>
                    {getMergedStyleToBP(widgetObj, 'position') != '' ?
                    <div>
                        <div style={{ marginBottom: "5px" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "50px 1fr", marginLeft:"auto",marginRight:"auto", width:"115px" }}>
                               <div className="fg-pb-property-sub-label">Top</div>
                                <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'top')} onDataChange={(top) => { updateCssProperty('top', top); }} /></div>
                            </div>
                        </div>
                        <div style={{ marginBottom: "5px", display:"grid",gridTemplateColumns:"1fr 25px 1fr",width:"100%" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "50px 1fr", marginLeft: "auto", marginRight: "auto", width: "115px" }}>
                                <div className="fg-pb-property-sub-label">Left</div>
                                <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'left')} onDataChange={(left) => { updateCssProperty('left', left); }} /></div>
                            </div>
                            <div>&nbsp;</div>
                            <div style={{ display: "grid", gridTemplateColumns: "50px 1fr", marginLeft: "auto", marginRight: "auto", width: "115px" }}>
                                <div className="fg-pb-property-sub-label">Right</div>
                                <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'right')} onDataChange={(right) => { updateCssProperty('right', right); }} /></div>
                            </div>
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "50px 1fr", marginLeft: "auto", marginRight: "auto", width: "115px" }}>
                                <div className="fg-pb-property-sub-label">Bottom</div>
                                <div><HtmlSizingCtrl value={getMergedStyleToBP(widgetObj, 'bottom')} onDataChange={(bottom) => { updateCssProperty('bottom', bottom); }} /></div>
                            </div>
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "70px 1fr"}}>
                                <div className="fg-pb-property-sub-label">Z-Index</div>
                                <div><input className="form-control form-control-sm fg-pb-property-field" value={getMergedStyleToBP(widgetObj, 'zIndex')} onChange={(e) => { updateCssProperty('zIndex',e.target.value); }} /></div>
                            </div>
                        </div>
                    </div>
                        : null}
                </div>
            </div>
        </>
    );

}

export default CssPositionCtrl;

