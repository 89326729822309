import React, { useState, useEffect } from 'react';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function EmailBroadcastDetail({dialogType,handlerReturnValue}) {
    
    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "tag_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const searchResultData = useBAFetch(
        {
            method: "GET",
            url: "crm/tag/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: searchRefetch
        }
    );

    const [rowIds, setRowIds] = useState([]);
    const pageTitle = getTranslation('tag_search_page', 'header_section', 'Email Broadcast Detail');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [gridId, setGridId] = useState(1);
    const headerOptions = {
        elements: [
            {
                type: "dropdown", text: getTranslation('tag_search_page', 'header_section', 'Actions'), name:"Actions",icon: "", url: null, color: "btn-outline-secondary", elements: [
                    { type: "dropdown", text: getTranslation('tag_search_page', 'header_section', 'Add to Automation'), name: "Add to Automation", icon: "", url: "https://www.google.com", target: "_blank", color: "btn-outline-secondary", options: null, function: null },
                    { type: "dropdown", text: getTranslation('tag_search_page', 'header_section', 'Send Email'), name: "Send Email", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: null }
                ]
            },
            { type: "button", text: getTranslation('tag_search_page', 'header_section', 'New'), caption: "New", icon: "bi bi-plus-lg", url: "/newcontact", target:"", color: "btn-success", options: null, function: null },
            { type: "button", text: getTranslation('tag_search_page', 'header_section', 'Import'), caption: "Import", icon: "bi bi-cloud-upload-fill", target:"", url: null, color: "btn-outline-secondary", options: null, function: null }
        ], breadcrumbs: [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }]
    }


    const columns = [
        {
            field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
            renderCell: ({ row }) =>
                <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('tag_search_page', 'search_grid', 'Open')}</Button>,
        },
        {
            field: 'tagName',
            headerName: getTranslation('tag_search_page', 'search_grid', 'Tag Name'),
            width: 300
        },
        {
            field: 'tagType',
            headerName: getTranslation('tag_search_page', 'search_grid', 'Tag Type'),
            width: 300
        },
        {
            field: 'active',
            headerName: getTranslation('tag_search_page', 'search_grid', 'Active'),
            width: 100,
        },
        {
            field: 'total',
            headerName: getTranslation('tag_search_page', 'search_grid', 'Total'),
            width: 100,
        },
        {
            field: 'dateCreated',
            headerName: getTranslation('tag_search_page', 'search_grid', 'Created'),
            width: 225,
        },
    ];



    function onGridOpenBtnClick(row) {
        alert('Row ' + row.tagId + ' clicked.');
    }

    useEffect(() => {
        setShowLoader(false);            
    },[]);

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            <div className="fg-app-page-box">
                {/*<Button variant="outlined" onClick={showDialog}>*/}
                {/*    Open alert dialog*/}
                {/*</Button>*/}
                {/*<Button variant="contained" color="success" onClick={importContacts}>*/}
                {/*    Import Contacts*/}
                {/*</Button>*/}
                <div>
                    <Box sx={{ marginTop:'10px',height: 630, width: '100%' }}>
                        <DataGrid      
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within": {
                                    outline: "none !important",
                                },
                            }}
                            rows={searchResultData.data}
                            getRowId={(row) => row.tagId}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={(ids) => {
                                setRowIds(ids);
                                handlerReturnValue(ids);
                            }}
                        />
                    </Box>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function importContacts() {
        //setLoaderSchema({msg:"Process will take a few seconds..."})
        setShowLoader(true);            
        setTimeout(() => {
            setTimeout(() => {
                setShowLoader(false);
            }, "1000");
        }, "50");
    }

    function showDialog() {
        var msg = 'You are about to delete this contact record. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Contacts Created', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); okAction() } })
        setOpenDialog(true);
    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog()} })
        setOpenDialog(true);
    }

    function handleBulkDeleteConfirm(gridId) {
        if (rowIds.length > 0) {
            var plural = helpers.getPlural(rowIds.length);
            var msg = 'You are about to delete ' + rowIds.length + ' tag' + plural + '. <span style="font-weight:bold;">Are you sure</span>?';
            setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecords() } })
            setOpenDialog(true);
        } else {
            var msg = 'One or many rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }


    function deleteRecords() {
        var msg = 'Record(s) has been deleted.';
        setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function createDemoContacts(gridId) {
        alert('create demo contacts');
    }
}

export default EmailBroadcastDetail;