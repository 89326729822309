import React, { useContext, useState, useEffect, useRef } from 'react';
import { DataBrokerContext } from '../PageBuilderCtrl';
import '../css/pagebuilderctrl.css';

function CssDataPaginationInfoCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    //const oldIdRef = useRef(null);
    function handleOnValueChange(value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj['showPagination'] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        
    }

    function replaceShowPagination(showPagination) {
        propertyChangeCallback(widgetObj);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])

    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Pagination Info</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div className="fg-pb-subsection-instruction" style={{display:"none"}}>Enter data source name.</div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Pagination</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.showPagination != undefined ? widgetObj.showPagination : ""} onChange={(e) => { handleOnValueChange((e.target.value === 'true')); }} onBlur={(e) => { replaceShowPagination((e.target.value === 'true')); }}>
                                <option value="">Select...</option>
                                <option value={true} >True</option>
                                <option value={false}>False</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CssDataPaginationInfoCtrl;

