import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import { Reorder } from "framer-motion";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import QuizIcon from '@mui/icons-material/Quiz';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CourseOutlineActivityCtrl from './CourseOutlineActivityCtrl';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import CourseModuleCtrl from './CourseModuleCtrl';

function CourseOutlineModuleCtrl({ courseId, outlineItem, setCourseOutline, courseOutline, onHandleOpenItem, onHandleRemoveItem }) {

    const [outlineItemObj, setOutlineItemObj] = useState(outlineItem);
    const [activities, setActivities] = useState(outlineItemObj.activities);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    function openModuleForm(moduleId) {
        var msg = '';
        setGenericDialogSchema({ title: 'Module Detail', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <CourseModuleCtrl courseId={courseId} moduleId={moduleId} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></CourseModuleCtrl>, body: { innerHtml: { __html: msg } }, fullWidth: true, maxWidth: "lg", hideBackdrop: false, ignoreBGClick: true, showCancelBtn: false, showOKBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('ModuleInfo', returnValues); } })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "ModuleInfo":
                var tmpOutlineItemObj = { ...outlineItemObj };
                tmpOutlineItemObj.title = returnValues.title;
                setOutlineItemObj(tmpOutlineItemObj);

                var tmpCourseOutline = [...courseOutline];
                for (var i = 0; i < tmpCourseOutline.length; i++) {
                    if (tmpCourseOutline[i].moduleId == tmpOutlineItemObj.moduleId) {
                        tmpCourseOutline[i] = tmpOutlineItemObj;
                        break;
                    }
                }
                setCourseOutline(tmpCourseOutline);
                break;
        }
    }

    async function addNewActivity(moduleId) {
        var newActivityId = helpers.getUUID()
        var newLmsActivityDTO = {
            "activityId": newActivityId,
            "baid": 0,
            "moduleId": moduleId,
            "title": "New Item",
            "description": null,
            "activityType": "Lesson",
            "presentationType": "Text",
            "assets": null,
            "status": "Draft",
            "displayOrder": 0,
            "questions": JSON.stringify([])
        };

        newLmsActivityDTO = await BAFetchData({
            callName: "SaveForm", method: "POST", url: "lms/activity/add",
            token: "fg1234", body: JSON.stringify(newLmsActivityDTO), onSuccess: null, onError: onAPIError
        });

        var newOutlineActivity = {
            "activityId": newLmsActivityDTO.data.activityId,
            "title": newLmsActivityDTO.data.title,
            "activityType": newLmsActivityDTO.data.activityType,
            "presentationType": newLmsActivityDTO.data.presentationType,
            "status": newLmsActivityDTO.data.status
        }

        var tmpActivities = [...activities];
        tmpActivities.push(newOutlineActivity);
        setActivities(tmpActivities);
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete activity. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, ignoreBGClick: true, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "lms/module/delete?moduleid=" + outlineItem.moduleId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }



    function handleAfterDelete() {
        var tmpCourseOutline = [...courseOutline];
        for (var i = 0; i < tmpCourseOutline.length; i++) {
            if (tmpCourseOutline[i].moduleId == outlineItemObj.moduleId) {
                tmpCourseOutline.splice(i, 1);
                break;
            }
        }
        setCourseOutline(tmpCourseOutline);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, ignoreBGClick: true, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); handleAfterDelete(); } })
                setOpenDialog(true);
                break;
        }
    }

    useEffect(() => {
        var tmpCourseOutline = [...courseOutline];
        for (var i = 0; i < tmpCourseOutline.length; i++) {
            if (tmpCourseOutline[i].moduleId == outlineItemObj.moduleId) {
                tmpCourseOutline[i].activities = activities;
                break;
            }
        }
        setCourseOutline(tmpCourseOutline);
    }, [activities]);


    useEffect(() => {
        setOutlineItemObj(outlineItem);
        setActivities(outlineItem.activities);
        setShowLoader(false);
    }, [outlineItem]);

    return (
        <>
            <Reorder.Item key={outlineItemObj.moduleId} value={outlineItemObj}>
                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 100px 90px 30px 30px", gridGap: "10px", width: "100%", padding: "10px", border: "1px solid #f0f0f0", marginBottom: "5px", borderRadius: "7px" }}>
                    <div>
                        <FolderOpenIcon />
                    </div>
                    <div style={{ fontWeight: "bold" }}>{outlineItemObj.title}</div>
                    <div style={{ paddingTop: "4px", color: "grey" }}>
                        <EditIcon style={{ marginLeft: "10px", cursor: "pointer" }} onClick={(e) => { openModuleForm(outlineItemObj.moduleId) }} />
                        <VisibilityIcon style={{ display: "none", marginLeft: "10px" }} />
                    </div>
                    <div style={{ color: "grey" }}>
                        <DescriptionOutlinedIcon style={{ cursor: "pointer" }} onClick={(e) => { addNewActivity(outlineItemObj.moduleId); }} />
                        <QuizIcon style={{ display: "none" }} />
                    </div>
                    <div><DeleteForeverIcon style={{ cursor: "pointer" }} onClick={() => { handleRecordDeleteConfirm(outlineItemObj.moduleId); }} /></div>
                    <div><ExpandMoreIcon /></div>
                </div>

                <div style={{paddingLeft:"20px"}}>
                    <Reorder.Group axis="y" onReorder={setActivities} values={activities}>
                        {activities.map((activity) => (
                            <CourseOutlineActivityCtrl key={activity.activityId} courseId={courseId} outlineItem={activity} activities={activities} setActivities={setActivities} />
                        ))}
                    </Reorder.Group>
                </div>

            </Reorder.Item>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default CourseOutlineModuleCtrl;
