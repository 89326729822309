import React, { useContext, useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '@mui/material/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ContactSearch from '../../pages/ContactSearch';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import { BAFetchData } from '../../customhooks/useBAFetch';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import TextsmsIcon from '@mui/icons-material/Textsms';


function SmsListCtrl({list, onChange, label}) {

    const [listObj, setListObj] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    function openContactSearch(actionType) {
        var msg = '';
        //setGenericDialogSchema({ title: 'Contact Search', component: (dialogType, setReturnValue) => <ContactSearch handlerReturnValue={dialogType, setReturnValue}></ContactSearch>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleContactSelected(returnValues, actionType); } })
        setGenericDialogSchema({ title: 'Contact Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <ContactSearch handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></ContactSearch>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleListUpdate('GetContactInfo', returnValues); } })
        setOpenDialog(true);
    }

    function handleOnOtherChange(value) {
        var tmpListObj = [...listObj];
        //tmpListObj.push(imgPath + value);
        tmpListObj.push(value);
        setListObj(tmpListObj);
        //setImgUrl((...imgUrl) => { return imgPath + value });   
        //var list = '';
        //var comma = '';
        //for (var i = 0; i < tmpListObj.length; i++) {
        //    list += comma + tmpListObj[i];
        //    comma = ',';
        //}
        onChange(tmpListObj);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case 'GetContactLookup':
                handleOnOtherChange(data.phone);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleListUpdate(returnCategory, contactId) {
        BAFetchData({
            callName: "GetContactLookup",
            method: "GET",
            url: "crm/contact/getrecord?contactid=" + contactId,
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function handleUpdateImage(imgUrl, actionType) {
        handleOnOtherChange(imgUrl);
    }

    function removeItem(e, index) {
        var tmpListObj = [...listObj];
        tmpListObj.splice(index, 1);
        setListObj(tmpListObj);
        onChange(tmpListObj);
    }

    useEffect(() => {
        if (list != '') {
            //var list = list.split(',');
            //var path = (imgPath != undefined ? imgPath : '');
            //list.push(list)
            setListObj(list);
        } else {
            setListObj([]);
        }        
    }, [list])

    return (
        <>
            {label != "" ?
                <div style={{ fontSize: "12px", marginBottom: "6px", lineHeight: "100%", color:"rgba(0, 0, 0, 0.6)"}}>{label}</div>
                : null}
            <div><Button variant="outlined" fullWidth onClick={(e) => { openContactSearch(e); }}>Add SMS</Button></div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: "5px", marginTop: "10px" }}>
                {listObj.map((listItem, index) => (
                    <div style={{ display: "grid", gridTemplateColumns: "28px 1fr 28px", backgroundColor: "#F0F0F0", padding: "5px",borderRadius:"4px",color:"grey" }}>
                        <div><TextsmsIcon /></div>
                        <div>    
                            {listItem}
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }} onClick={(e) => { removeItem(e, index); }}><CloseIcon style={{ fontSize: "18px" }} /></div>
                        </div>
                    </div>
                ))}


            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default SmsListCtrl;