import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { AutomationBrokerContext } from "./AutomationBuilderCtrl";
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import ConnectorSettings from './ConnectorSettings';

function AutoItemLineConnectorsCtrl({ autoItemData }) {
    const { elements, setElements, formData, setFormData, showForecast, itemsLoaded, setItemsLoaded, imageLoadedCount, setImageLoadedCount, imgCountRef, handleOnDragFromCanvas, selectedItems, setSelectedItems, shiftKeyPressedRef, controlKeyPressedRef, mousePosRef, popupOpenRef } = useContext(AutomationBrokerContext);
    const [elementData, setElementData] = useState(autoItemData)

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    useEffect(() => {
        setElementData(autoItemData);
    }, [autoItemData])


    function handleOnConnectorClick(e, connectionType, branch, key) {
        e.preventDefault();
        e.stopPropagation(); 

        var msg = '';
        setGenericDialogSchema({
            title: 'Connector Settings', component: (dialogType, setReturnValue) => <ConnectorSettings lineSettings={elementData[connectionType][branch][key]} handleOnDeleteConnector={(e) => deleteConnector(elementData.id, connectionType, branch, key)} handlerReturnValue={setReturnValue}></ConnectorSettings>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('Connector', connectionType, branch, key, returnValues); } })
        setOpenDialog(true);

    }

    function deleteConnector(sourceId, connectionType, branch, key) {
        var destId = key;
        var tmpElements = { ...elements };
        //var tmpElementData = { ...elementData };
        //delete tmpElementData[connectionType][branch][destId]
        //tmpElements[sourceId] = tmpElementData;

        delete tmpElements[sourceId][connectionType][branch][destId];

        //get dest element and remove associated input
        var destElementObj = tmpElements[destId];
        for (var ii = destElementObj.inputs.length - 1; ii >= 0; ii--) {
            if (destElementObj.inputs[ii] == sourceId) {
                destElementObj.inputs.splice(ii, 1);
            }
        }

        //update elements and close dialog
        setElements(tmpElements);
        setOpenDialog(false);
    }

    function onPopupOK(returnCategory, connectionType, branch, key, returnValues) {
        switch (returnCategory) {
            case 'Connector':
                //Update the connectPosition and lineType of the selected output
                //var tmpElementData = { ...elementData };
                //tmpElementData[connectionType][branch][key].connectPosition = returnValues.connectPosition;
                //tmpElementData[connectionType][branch][key].lineType = returnValues.lineType;
                //setElementData(tmpElementData);
                //update the entire element
                var tmpElements = { ...elements };
                tmpElements[elementData.id][connectionType][branch][key] = { connectPosition: returnValues.connectPosition, lineType: returnValues.lineType };
                //tmpElements[tmpElementData.id] = tmpElementData;
                setElements(tmpElements);
                break;
        }
    }


    function renderObj(key, index, connectionType, branch) {
        var sourceId = elementData.id;
        var destId = key;
        var sourceHtmlElement = document.getElementById(sourceId);
        if (sourceHtmlElement != null) {
            var sourceWidth = sourceHtmlElement.offsetWidth;
            var sourceHeight = sourceHtmlElement.offsetHeight;

            var x1 = elementData.position.left + (sourceWidth / 2);
            var y1 = elementData.position.top + (sourceHeight / 2);

            var destElementData = elements[destId];
            if (destElementData == undefined) {
                console.log('not found ' + destId);
            }

            var destHtmlElement = document.getElementById(destId);
            if (destHtmlElement == undefined) {
                console.log('stop');
            }
            var destWidth = destHtmlElement.offsetWidth;
            var destHeight = destHtmlElement.offsetHeight;

            var connectPosition = elementData[connectionType][branch][key].connectPosition;
            if (connectPosition == undefined) {
                console.log('Position not found for ' + elementData.caption);
            }
            var x2 = 0;
            var y2 = 0;


            var destX = null;
            var destY = null;
            var lineDistance = null;
            var xDistance = null;
            var yDistance = null;
            var sin = null;
            var cos = null;
            var arrowHeadLength = 20;
            var metricLength = 90;

            var metricX = 0;
            var metricY = 0;
            var metricSin = null;
            var metricCos = null;


            switch (connectPosition) {
                case 't-0':
                    destX = destElementData.position.left + 5;
                    destY = destElementData.position.top - 3;
                    break;
                case 't-1':
                    destX = destElementData.position.left + (destWidth / 4);
                    destY = destElementData.position.top - 3;
                    break;
                case 't-2':
                    destX = destElementData.position.left + (destWidth / 2);
                    destY = destElementData.position.top - 3;
                    break;
                case 't-3':
                    destX = destElementData.position.left + ((destWidth / 4) * 3);
                    destY = destElementData.position.top - 3;
                    break;
                case 't-4':
                    destX = destElementData.position.left + (destWidth - 5);
                    destY = destElementData.position.top - 3;
                    break;
                case 'r-0':
                    destX = destElementData.position.left + (destWidth + 3);
                    destY = destElementData.position.top + 5;
                    break;
                case 'r-1':
                    destX = destElementData.position.left + (destWidth + 3);
                    destY = destElementData.position.top + (destHeight / 4);
                    break;
                case 'r-2':
                    destX = destElementData.position.left + (destWidth + 3);
                    destY = destElementData.position.top + (destHeight / 2);
                    break;
                case 'r-3':
                    destX = destElementData.position.left + (destWidth + 3);
                    destY = destElementData.position.top + ((destHeight / 4) * 3);
                    break;
                case 'r-4':
                    destX = destElementData.position.left + (destWidth + 3);
                    destY = destElementData.position.top + (destHeight - 5);
                    break;
                case 'b-0':
                    destX = destElementData.position.left + 5;
                    destY = destElementData.position.top + (destHeight + 3);
                    break;
                case 'b-1':
                    destX = destElementData.position.left + (destWidth / 4);
                    destY = destElementData.position.top + (destHeight + 3);
                    break;
                case 'b-2':
                    destX = destElementData.position.left + (destWidth / 2);
                    destY = destElementData.position.top + (destHeight + 3);
                    break;
                case 'b-3':
                    destX = destElementData.position.left + ((destWidth / 4) * 3);
                    destY = destElementData.position.top + (destHeight + 3);
                    break;
                case 'b-4':
                    destX = destElementData.position.left + (destWidth - 5);
                    destY = destElementData.position.top + (destHeight + 3);
                    break;
                case 'l-0':
                    destX = destElementData.position.left - 3;
                    destY = destElementData.position.top + 5;
                    break;
                case 'l-1':
                    destX = destElementData.position.left - 3;
                    destY = destElementData.position.top + (destHeight / 4);
                    break;
                case 'l-2':
                    destX = destElementData.position.left - 3;
                    destY = destElementData.position.top + (destHeight / 2);
                    break;
                case 'l-3':
                    destX = destElementData.position.left - 3;
                    destY = destElementData.position.top + ((destHeight / 4) * 3);
                    break;
                case 'l-4':
                    destX = destElementData.position.left - 3;
                    destY = destElementData.position.top + (destHeight - 5);
                    break;
            }

            lineDistance = Math.sqrt(((destX - x1) * (destX - x1)) + ((destY - y1) * (destY - y1)));
            xDistance = (destX - x1);
            yDistance = (destY - y1);

            sin = Math.sin(yDistance / lineDistance) * arrowHeadLength;
            cos = Math.sin(xDistance / lineDistance) * arrowHeadLength;
            x2 = destX - (cos);
            y2 = destY - (sin);

            metricSin = Math.sin(yDistance / lineDistance) * metricLength;
            metricCos = Math.sin(xDistance / lineDistance) * metricLength;
            metricX = destX - (metricCos);
            metricY = destY - (metricSin);


            var lineId = "connector_" + sourceId + "_" + destId + "_" + branch;

            return <>                
                <line id={lineId} key={"connector-" + index} style={{ pointerEvents: "auto" }} className={"fgau-connector-" + branch} x1={x1} y1={y1} x2={x2} y2={y2} stroke="#E2E2E2" strokeWidth="3" strokeDasharray="" markerEnd={"url(#arrowhead-" + branch + ")"} onClick={(e) => { handleOnConnectorClick(e, connectionType, branch, key); }}></line>
                {showForecast == true ?
                    <rect y={metricY - 15} x={metricX} height="27" width="50" fill="white" stroke="#E2E2E2" strokeWidth="1" />
                    : null}                
                {showForecast == true ?
                    (branch == 'yes' ?
                        <text style={{ fontSize: "11px", backgroundColor: "#ffffff", color: "red", padding: "2px" }} y={metricY - 4} x={metricX + 3} fill="black">{(elementData.autoType == "TrafficSource" ? elementData.forecast.goalMetric.lpCTR : elementData.forecast.goalMetric.cvr)} %</text>
                        : 
                        <text style={{ fontSize: "11px", backgroundColor: "#ffffff", color: "red", padding: "2px" }} y={metricY - 4} x={metricX + 3} fill="black">{(elementData.autoType == "TrafficSource" ? elementData.forecast.goalMetric.lpCTR : (100 - elementData.forecast.goalMetric.cvr))} %</text>
                        )
                    : null}
                {showForecast == true ?
                    (branch == 'yes' ?
                        <text style={{ fontSize: "11px", backgroundColor: "#ffffff", color: "red", padding: "2px" }} y={metricY + 7} x={metricX + 3} fill="black">({elementData.forecast.goalMetric.conversions})</text>
                        : 
                        <text style={{ fontSize: "11px", backgroundColor: "#ffffff", color: "red", padding: "2px" }} y={metricY + 7} x={metricX + 3} fill="black">({elementData.forecast.goalMetric.traffic - elementData.forecast.goalMetric.conversions})</text>
                        )
                    : null}
            </>
        }
    }

    return (
        <>
            {Object.keys(elementData.outputs.yes).map((key, ei) => (
                renderObj(key,ei, 'outputs','yes')                
            ))}       
            {Object.keys(elementData.outputs.no).map((key, ei) => (
                renderObj(key, ei, 'outputs', 'no')
            ))}       
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );
}

export default AutoItemLineConnectorsCtrl;

