import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import VariantPickerCtrl from '../components/formcontrols/VariantPickerCtrl';
import ProductEngineUtil from '../helpers/ProductEngineUtil';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';
import TextField from '@mui/material/TextField';
import { useGlobalState } from '../globalstates/GState';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function Products({ handlerReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate, isLookup, returnIntent }) {

    const [currentUser] = useGlobalState("userInfo");

    const navigate = useNavigate();
    let internalTimeout = null;
    let criteria = null;
    const [searchResultData, setSearchResultData] = useState([]);
    const lookup = isLookup == true ? true : false;
    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [searchRefetch, setSearchRefetch] = useState(false);
    const [productId, setProductId] = useState(null);
    const [productSelected, setProductSelected] = useState({ "productId": null, "variantId": null, "productName": null, "taxable": null, "paymentOption":null,"price": 0, "recurType": null, "setupFee": 0, "downpayment": null, "summary": null });
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "automations_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const [rowIds, setRowIds] = useState([]);
    const pageTitle = getTranslation('product_search_page', 'header_section', 'Products');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [gridId, setGridId] = useState(1);
    const headerOptions = {
        elements: [
            {
                type: "dropdown", text: getTranslation('product_search_page', 'header_section', 'Actions'), name:"Actions",icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
            
        ], breadcrumbs: [{ name: "Home", url: "/" }, { name: "Products", url: null, function: null }]
    }

    if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Products', ['allowDelete']) == true) {
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('segmentedproduct_search_page_list_search_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } });
    }
    if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Products', ['allowAdd']) == true) {
        headerOptions.elements.push({ type: "button", text: getTranslation('product_search_page', 'header_section', 'New'), caption: "New", icon: "bi bi-plus-lg", url: "/product/0", target: "", color: "btn-success", options: null, function: null });
    }

    if (isLookup == true) {
        headerOptions.elements = [];
    }
    function priceSummary(params) {
        
    }

    const columns = [
        {
            field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
            renderCell: ({ row }) =>
                <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('product_search_page', 'search_grid', 'Open')}</Button>,
        },
        {
            field: 'productName',
            headerName: getTranslation('products_search_page', 'search_grid', 'Product Name'),
            width: 300,
            renderCell: function (params) {
                return <div style={{ textWrap: "wrap" }}><span style={{fontWeight:"bold"}}>{params.value}</span><span style={{ display: "block",color:"grey"}}><span>sku #:&nbsp;</span> {params.row.skuNum}</span></div>
            }
        },
        {
            field: 'pricenew',
            headerName: getTranslation('products_search_page', 'search_grid', 'Price Updated'),
            width: 400,
            renderCell: function (params) {
                var priceSummary = ProductEngineUtil.GetPriceSummary(params.row);
                return <div style={{ textWrap: "wrap" }}>{priceSummary.summary}<div style={{ fontSize: "12px", fontStyle:"italic" }}>{priceSummary.additionalMsg}</div></div>;
            }
        },
        {
            field: 'option',
            headerName: getTranslation('products_search_page', 'search_grid', ''),
            width: 200,
            renderCell: function (params) {
                switch (params.row.recurType) {
                    case "Payment Plan":
                        return <Chip label="Payment Plan Available" style={{ backgroundColor:"#FFB22B",color:"black"}} />
                        break;
                    case "SubscriptionOnly-Static":
                        return <Chip label="Subscription Only" style={{ backgroundColor: "#FFB22B", color: "black" }} />
                        break;
                    case "Subscription-Static":
                        return <Chip label="Subscription Availabe" style={{ backgroundColor: "#FFB22B", color: "black" }} />
                        break;
                    case "Subscription":
                        return <Chip label="Subscription Availabe" style={{ backgroundColor: "#FFB22B", color: "black" }} />
                        break;
                    case "SubscriptionOnly":
                        return <Chip label="Subscription Only" style={{ backgroundColor: "#FFB22B", color: "black" }} />
                        break;
                }
            }
        },
        {
            field: 'showOnSite',
            headerName: getTranslation('products_search_page', 'search_grid', 'Show on Site'),
            width: 145,
            renderCell: function (params) {
                if (params.row.showOnSite == true) {
                    return <Chip label="Yes" style={{ backgroundColor: "#26C6DA", color: "white" }} />
                } else {
                    return <Chip label="No" style={{ backgroundColor: "#BDBDBD", color: "white" }} />
                }
            }
        },
        {
            field: 'variantsExists',
            headerName: getTranslation('products_search_page', 'search_grid', 'Variants'),
            width: 125,
            renderCell: function (params) {
                if (params.row.variantsExists == true) {
                    return <Chip label="Yes" style={{ backgroundColor: "#26C6DA", color: "white" }} />
                } else {
                    return <Chip label="No" style={{ backgroundColor: "#BDBDBD", color: "white" }} />
                }
            }
        },
        {
            field: 'active',
            headerName: getTranslation('products_search_page', 'search_grid', 'Active'),
            width: 90,
            renderCell: function (params) {
                if (params.row.active == true) {
                    return <Chip label="Active" style={{ backgroundColor: "#26C6DA", color: "white" }} />
                } else {
                    return <Chip label="Inactive" style={{ backgroundColor: "#BDBDBD", color: "white" }} />
                }
            }
        },
    ];

    function onGridOpenBtnClick(row) {
        navigate('/product/' + row.productId);
    }

    function buildReturnValues(data) {
        if (handlerReturnValue) {
            setAcceptAfterUpdate(true);
            handlerReturnValue(data);
        }
    }

    function resetResults() {
        setRowIds([]);
        beginSearch();
        //setSearchRefetch(true);
    }



    useEffect(() => {
        beginSearch();
        setShowLoader(false);            
    },[]);

    
    function onProductSelected(selectedProductInfo) {       

        if (handlerReturnValue) {
            setAcceptAfterUpdate(true);
            handlerReturnValue(selectedProductInfo);
        }
    }

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />
            <div style={{ backgroundColor: "#ffffff", padding: "5px 10px 5px 10px", display: "grid", gridTemplateColumns: "1fr 70px", gridGap: "10px" }}>
                <div>
                    <TextField fullWidth placeholder="Enter search keywords" variant="standard" style={{ border: "1px solid whitesmoke" }} onChange={(e) => { handleOnCriteriaChange(e.target.value); }} />
                </div>
                <div>
                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { beginSearch(); }} style={{ width: "100%" }}>Search</button>
                </div>
            </div>
            <div className="fg-app-page-box">
                <div className="row">
                    <div className={(lookup == true && returnIntent == "AddToCart") ? "col-sm-8" : "col-sm-12"} >
                    <Box sx={{ height: 630, width: '100%' }}>
                        <DataGrid      
                            sx={{
                                border: 0,
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within": {
                                    outline: "none !important",
                                }
                            }}
                            rowHeight={70}
                            rows={searchResultData}
                            getRowId={(row) => row.productId}
                            columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                    columns: {
                                        columnVisibilityModel: {
                                            // Hide columns status and traderName, the other columns will remain visible
                                            open: lookup == true ? false : true,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                checkboxSelection={lookup == true ? false : true}
                                disableRowSelectionOnClick={lookup == true ? false : true}
                            onRowSelectionModelChange={(ids) => {
                                //setRowIds(ids);
                                //setProductId(ids[0]);
                                //const selectedIDs = new Set(ids);
                                //const selectedRowData = searchResultData.data.filter((row) =>
                                //    selectedIDs.has(row.productId)
                                //);
                                //if (selectedRowData.length > 0) {
                                //    buildReturnValues(selectedRowData[0]);
                                //} else {
                                //    buildReturnValues(null);
                                //}

                                setRowIds(ids);
                                setProductId(ids[0]);

                                if (returnIntent == 'ProductInfo') {
                                    if (lookup != true) {
                                        if (handlerReturnValue) {
                                            handlerReturnValue(ids)
                                        }
                                    } else {
                                        if (ids.length > 0) {
                                            const selectedIDs = new Set(ids);
                                            const selectedRowData = searchResultData.filter((row) =>
                                                selectedIDs.has(row.productId)
                                            );
                                            buildReturnValues(selectedRowData[0]);
                                        } else {
                                            buildReturnValues(null);
                                        }
                                    }
                                }



                            }}
                        />
                        </Box>
                    </div>
                    {lookup == true ?
                        <div className="col-sm-4">
                            <div><VariantPickerCtrl productId={productId} onSelect={(selectedProductInfo) => { onProductSelected(selectedProductInfo); }}></VariantPickerCtrl></div>
                        </div>
                        : null}
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function importContacts() {
        //setLoaderSchema({msg:"Process will take a few seconds..."})
        setShowLoader(true);            
        setTimeout(() => {
            setTimeout(() => {
                setShowLoader(false);
            }, "1000");
        }, "50");
    }

    function showDialog() {
        var msg = 'You are about to delete this form record. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Contacts Created', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); okAction() } })
        setOpenDialog(true);
    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog()} })
        setOpenDialog(true);
    }

    function handleBulkDeleteConfirm(gridId) {
        if (rowIds.length > 0) {
            var plural = helpers.getPlural(rowIds.length);
            var msg = 'You are about to delete ' + rowIds.length + ' project' + plural + '. <span style="font-weight:bold;">Are you sure</span>?';
            setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecords() } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }

    function deleteRecords() {
        BAFetchData({
            callName: "BulkDelete",
            method: "POST",
            url: "ecommerce/product/BulkDelete",
            token: "fg1234",
            body: rowIds, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function handleOnCriteriaChange(value) {
        criteria = value.toLowerCase();
        if (internalTimeout != undefined) {
            clearTimeout(internalTimeout);
        }
        internalTimeout = setTimeout(() => {
            beginSearch();
        }, 250);
    }

    function beginSearch() {
        var filtering = {};

        if (criteria != null && criteria != '') {
            var conditions = [];
            conditions.push({ "Name": "productName", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" });
            conditions.push({ "Name": "skuNum", "ConditionOperator": "EQUAL", "Value": criteria, "DataType": "String" });

            filtering = {
                "LogicalOperator": "and", "ConditionGroups": [
                    {
                        "LogicalOperator": "or", "Conditions": conditions
                    }]
            };

        }

        BAFetchData({
            callName: "GetData",
            method: "GET",
            url: "ecommerce/product/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetData":
                setSearchResultData(data);
                break;
            case "BulkDelete":
                resetResults(); //Reset search results and clear records selected.               
                var msg = "Product record(s) has been deleted.";
                setGenericDialogSchema({ title: 'Product(s) Deleted', component: null, body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog();}})
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }
}

export default Products;
