import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import helpers from '../../helpers/Helpers';
import Chip from '@mui/material/Chip';
import DialogCtrl from '../dialogctrl/dialogctrl';
import ContactSearch from '../../pages/ContactSearch';
import { BAFetchData } from '../../customhooks/useBAFetch';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FileManager from '../../pages/FileManager';

function VariantBuilderCtrl({ handlerReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate, productId, skuNum, price, billInstallment, masterVariants}) {


    const [varInfo, setVarInfo] = useState({"skuNum": skuNum, "price": price, "billInstallment": billInstallment, "qtyOnHand": 0, "active": true, "settings": {} });
    const [productVariants, setProductVariants] = useState([]);
    const [masterVariantsObj, setMasterVariantsObj] = useState(masterVariants);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [newShellObj, setNewShellObj] = useState({});
    const [displayOrder, setDisplayOrder] = useState({});

    function setupVariantSettings(masterVariants) {
        var tmpDisplayOrder = {};
        var tmpVarInfo = { ...varInfo };
        for (var i = 0; i < masterVariants.length; i++) {
            tmpVarInfo.settings[masterVariants[i].attrName] = [];
            tmpDisplayOrder[masterVariants[i].attrName] = { "order": i };
        }
        setVarInfo(tmpVarInfo);
        setDisplayOrder(tmpDisplayOrder);
    }



    function sendUpdates(varInfo) {
        //if (handlerReturnValue) {
        //    handlerReturnValue(varInfo);
        //}
    }

    function variantExists(tmpVariantSettings, attrName, value) {
        var attrInfo = null;
        var found = false;
        for (const key in tmpVariantSettings) {
            if (key == attrName) {
                attrInfo = tmpVariantSettings[key];
                for (var si = 0; si < attrInfo.length; si++) {
                    if (attrInfo[si].value == value) {
                        found = true;
                        break;
                    }
                }
                break;
            }
            if (found == true) {
                break;
            }
        }
        return found;
    }

    function updateVariant(e, attrName, index, propertyName, value) {
        var tmpVarInfo = { ...varInfo };
        if (variantExists(tmpVarInfo.settings, attrName, value) == false) {
            tmpVarInfo.settings[attrName][index][propertyName] = value;
            sendUpdates(tmpVarInfo);
            setVarInfo(tmpVarInfo);
        }        
    }

    function removeVariant(e, attrName, index) {
        var tmpVarInfo = { ...varInfo };
        tmpVarInfo.settings[attrName].splice(index, 1);
        sendUpdates(tmpVarInfo);
        setVarInfo(tmpVarInfo);
    }

    function addVariant(attrName, index) {
        var tmpVarInfo = { ...varInfo };
        tmpVarInfo.settings[attrName].push(getNewVariant(attrName));
        sendUpdates(tmpVarInfo);
        setVarInfo(tmpVarInfo);
    }

    function addAllVariants(attrName) {
        var tmpVarInfo = { ...varInfo };
        var item = null;
        var variant = null;
        var options = null;
        for (var i = 0; i < masterVariants.length; i++) {
            if (masterVariants[i].attrName == attrName) {
                variant = masterVariants[i];
                options = variant.options.split(',');
                for (var oi = 0; oi < options.length; oi++) {
                    if (attrName.toLowerCase() != 'color') {
                        item = getNewVariant(attrName);
                        item.value = options[oi];
                        tmpVarInfo.settings[attrName].push(item);
                    } else {
                        item = getNewVariant(attrName);
                        item.value = options[oi];
                        tmpVarInfo.settings[attrName].push(item);
                    }
                }
            }
        }
        sendUpdates(tmpVarInfo);
        //buildMatrix(tmpVarInfo);
        setVarInfo(tmpVarInfo);
    }

    function getNewVariant(attrName) {
        if (attrName.toLowerCase() == 'color') {
            return {"value":"","swatch":"","thumbnail":""};
        } else {
            return {"value":""};
        }        
    }

    function openFileManager(returnCategory, attrName, index, propertyName) {
        var actionType = "FullPath";
        var msg = '';
        setGenericDialogSchema({ title: 'File Manager', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={setReturnValue} returnFullPath={true} recType="product" recId={productId}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK(returnCategory, returnValues, attrName, index, propertyName); } })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, imgUrl, attrName, index, propertyName) {
        switch (returnCategory) {
            case 'GetSwatch':
                updateVariant(null, attrName, index, propertyName, imgUrl);
                break;
            case 'GetThumbnail':
                updateVariant(null, attrName, index, propertyName, imgUrl);
                break;
        }
    }

    function getVariantShellObj() {
        BAFetchData({
            callName: "GetShellObj",
            method: "GET",
            url: "ecommerce/productvariant/getrecord?variantid=0",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetShellObj":
                setNewShellObj(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function cancelPopup(){
        if (handlerReturnValue) {
            setAcceptAfterUpdate(true);
            handlerReturnValue([]);
        }
    }

    function buildMatrix() {
        var tmpVarInfo = { ...varInfo };
        var tmpProductVariants = [];
        var used = [];
        var stats = [];
        var counter = 0;
        var firstKey = null;
        var lastKey = null;
        var currentKey = null;
        var totalMatrix = 0;
        for (const key in tmpVarInfo.settings) {
            if (tmpVarInfo.settings[key].length > 0) {
                counter++;
                if (counter == 1) {
                    firstKey = key;
                    totalMatrix = parseInt(tmpVarInfo.settings[key].length);
                } else {
                    totalMatrix = parseInt(totalMatrix) * parseInt(tmpVarInfo.settings[key].length);
                }
                lastKey = key;

                stats.push({ "attrName": key, "total": tmpVarInfo.settings[key].length, "variantIndex": 0, "value": null });
            }
        }

        var list = buildArrayVariants(stats);
        if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
                createVariant(i + 1,tmpProductVariants, tmpVarInfo, list[i], stats);
            }

            //console.log(JSON.stringify(tmpProductVariants));

            if (handlerReturnValue) {
                setAcceptAfterUpdate(true);
                handlerReturnValue(tmpProductVariants);
                //handleDialogOnOK();
            }
        }
    }

    function buildArrayVariants(stats) {
        var keyIndex = 0;
        var combined = [];
        if (stats[keyIndex] != undefined) {
            for (var a = 0; a < stats[keyIndex].total; a++) {
                if (keyIndex + 1 == stats.length) {
                    combined.push([a]);
                }
                if (stats[keyIndex + 1] != undefined) {
                    for (var b = 0; b < stats[keyIndex + 1].total; b++) {
                        if (keyIndex + 2 == stats.length) {
                            combined.push([a,b]);
                        }
                        if (stats[keyIndex + 2] != undefined) {
                            for (var c = 0; c < stats[keyIndex + 2].total; c++) {
                                if (keyIndex + 3 == stats.length) {
                                    combined.push([a, b, c]);
                                }
                            }
                        }
                    }
                }
            }
        }
        return combined;
    }

    function createVariant(skuIndex,tmpProductVariants, tmpVarInfo,variantIndexList, stats) {
        //create variant
        var newVariant = JSON.parse(JSON.stringify(newShellObj));
        newVariant.variantId = helpers.getUUID();
        newVariant.skuNum = tmpVarInfo.skuNum + "_" + ("0000" + skuIndex).slice(-4);
        newVariant.unitPrice = tmpVarInfo.price;
        newVariant.unitBillInstallment = tmpVarInfo.billInstallment;
        newVariant.active = tmpVarInfo.active;
        newVariant.qtyOnHand = parseInt(tmpVarInfo.qtyOnHand);
        newVariant.allowBackorder = false;
        newVariant.internalState = "added";

        if (newVariant.qtyOnHand > 0) {
            newVariant.qtyStatus = "In Stock";
        } else {
            newVariant.qtyStatus = "Out of Stock";
        }

        for (var si = 0; si < stats.length; si++) {
            var statInfo = stats[si];
            var variantIndex = variantIndexList[si];
            if (tmpVarInfo.settings[statInfo.attrName].length > 0) {
                if (statInfo.attrName.toLowerCase() != "color") {
                    newVariant.productVariantAttributeList.push({ "variantAttrId": helpers.getUUID(), "baid": 0, "variantId": newVariant.variantId, "attrName": statInfo.attrName, "attrValue": tmpVarInfo.settings[statInfo.attrName][variantIndex].value, displayOrder: displayOrder[statInfo.attrName].order,"internalState": "added" });
                } else {
                    newVariant.photo = tmpVarInfo.settings[statInfo.attrName][statInfo.variantIndex].thumbnail;
                    newVariant.productVariantAttributeList.push({ "variantAttrId": helpers.getUUID(), "baid": 0, "variantId": newVariant.variantId, "attrName": statInfo.attrName, "attrValue": tmpVarInfo.settings[statInfo.attrName][variantIndex].value, displayOrder: displayOrder[statInfo.attrName].order, "internalState": "added" });
                    newVariant.productVariantAttributeList.push({ "variantAttrId": helpers.getUUID(), "baid": 0, "variantId": newVariant.variantId, "attrName": statInfo.attrName + "-Swatch", "attrValue": tmpVarInfo.settings[statInfo.attrName][variantIndex].swatch, displayOrder: displayOrder[statInfo.attrName].order, "internalState": "added" });
                    newVariant.productVariantAttributeList.push({ "variantAttrId": helpers.getUUID(), "baid": 0, "variantId": newVariant.variantId, "attrName": statInfo.attrName + "-Thumbnail", "attrValue": tmpVarInfo.settings[statInfo.attrName][variantIndex].thumbnail, displayOrder: displayOrder[statInfo.attrName].order, "internalState": "added" });
                }
            }
        }

        tmpProductVariants.push(newVariant);
    }

    useEffect(() => {        
        setMasterVariantsObj(masterVariants);
        setupVariantSettings(masterVariants);
        getVariantShellObj();
    }, [masterVariants]);

    return (
        <>
            {JSON.stringify(varInfo.settings) != "{}" ?
                <div>
                    <div className="row">
                        <div className="col-sm-12">
                        <div className="segment">
                            <div className="segment-title">Base Attributes</div>
                            <div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <TextField required fullWidth label="Sku #" variant="standard" style={{ marginBottom: "16px" }} value={varInfo.skuNum || ''} InputLabelProps={{ shrink: varInfo.skuNum }} onChange={(e) => setVarInfo(varInfo => ({ ...varInfo, skuNum: e.target.value }))} />
                                            </div>
                                            <div className="col-sm-2">
                                                    <TextField required fullWidth label="Price" variant="standard" style={{ marginBottom: "16px" }} value={varInfo.price >= 0 ? varInfo.price : ''} InputLabelProps={{ shrink: varInfo.price !== null && varInfo.price !== undefined && varInfo.price !== '' }} onChange={(e) => setVarInfo(varInfo => ({ ...varInfo, price: e.target.value }))} />
                                            </div>
                                            <div className="col-sm-2">
                                                    <TextField required fullWidth label="Installment Amt." variant="standard" style={{ marginBottom: "16px" }} value={varInfo.billInstallment >= 0 ? varInfo.billInstallment : ''} InputLabelProps={{ shrink: varInfo.billInstallment !== null && varInfo.billInstallment !== undefined && varInfo.billInstallment !== '' }} onChange={(e) => setVarInfo(varInfo => ({ ...varInfo, billInstallment: e.target.value }))} />
                                            </div>
                                            <div className="col-sm-2">
                                                    <TextField required fullWidth label="Qty on Hand" variant="standard" style={{ marginBottom: "16px" }} value={varInfo.qtyOnHand >= 0 ? varInfo.qtyOnHand : ''} InputLabelProps={{ shrink: varInfo.qtyOnHand !== null && varInfo.qtyOnHand !== undefined && varInfo.qtyOnHand !== '' }} onChange={(e) => setVarInfo(varInfo => ({ ...varInfo, qtyOnHand: e.target.value }))} />
                                            </div>
                                            <div className="col-sm-2">
                                                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                    <InputLabel id="demo-simple-select-standard-label">Active</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={varInfo.active || false}
                                                        onChange={(e) => setVarInfo(varInfo => ({ ...varInfo, active: e.target.value }))}
                                                        label="Active"
                                                    >
                                                        <MenuItem value={false}>False</MenuItem>
                                                        <MenuItem value={true}>True</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="segment" style={{ marginTop: "20px" }}>
                            <div className="segment-title">Add Desired Variants Per Attribute</div>
                            <div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                {masterVariantsObj.map((masterVariant, index) => (
                                                    masterVariant.internalState != 'deleted' ?
                                                        <>
                                                        <div key={"builder_master_variant_" + index} style={{ border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: "4px", padding: "20px", marginBottom: "20px" }}>
                                                            <div style={{marginBottom:"20px"}}><span>Available {masterVariant.attrLabel}s</span>
                                                                <Button variant="outlined" style={{ marginLeft: "15px" }} onClick={(e) => { addVariant(masterVariant.attrName); }}>Add {masterVariant.attrLabel}</Button>
                                                                <Button variant="outlined" style={{ marginLeft: "15px" }} onClick={(e) => { addAllVariants(masterVariant.attrName); }}>Add All {masterVariant.attrLabel}s</Button>
                                                            </div>

                                                            {varInfo.settings[masterVariant.attrName].map((selectedOption, si) => (
                                                                <div key={"builder_mv_" + si}>
                                                                    {masterVariant.attrName != 'Color' ?
                                                                        <>
                                                                            <FormControl variant="standard" sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                                <InputLabel id="demo-simple-select-standard-label">{masterVariant.attrLabel}</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-standard-label"
                                                                                    id="demo-simple-select-standard"
                                                                                    label={masterVariant.attrLabel}
                                                                                    value={selectedOption.value}
                                                                                    onChange={(e) => { updateVariant(e, masterVariant.attrName, si, 'value', e.target.value); }}
                                                                                >
                                                                                    {(masterVariant.options.split(',')).map((option, oi) => (
                                                                                        <MenuItem value={option}>{option}</MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                            <DeleteIcon style={{ marginLeft: "10px", marginTop: "0px" }} onClick={(e) => { removeVariant(e, masterVariant.attrName, si); }} />
                                                                        </>
                                                                        : 
                                                                        <>
                                                                            <div>
                                                                                <FormControl variant="standard" sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                                    <InputLabel id="demo-simple-select-standard-label">{masterVariant.attrLabel}</InputLabel>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-standard-label"
                                                                                        id="demo-simple-select-standard"
                                                                                        label={masterVariant.attrLabel}
                                                                                        value={selectedOption.value}
                                                                                        onChange={(e) => { updateVariant(e, masterVariant.attrName, si, 'value', e.target.value); }}
                                                                                    >
                                                                                        {(masterVariant.options.split(',')).map((option, oi) => (
                                                                                            <MenuItem value={option}>{option}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                                <div style={{padding:"0px 10px 0px 10px",display: "inline-grid", gridTemplateColumns: "60px 40px 90px" }}>
                                                                                    <div style={{paddingTop:"20px"}}>Swatch</div>
                                                                                    <div style={{ paddingTop: "10px" }}><img src={selectedOption.swatch || 'images/product_placeholder.jpg'} style={{ border:"1px solid grey",width: "37px", height: "37px" }}/></div>
                                                                                    <div style={{ paddingTop: "10px" }}><Button variant="outlined" style={{ marginLeft: "5px", padding: "6px 10px 5px 10px", color: "black", fontWeight: "normal", border: "1px solid grey", borderRadius: "0px" }} onClick={(e) => { openFileManager('GetSwatch', masterVariant.attrName, si, 'swatch'); }}>Browse...</Button></div>
                                                                                </div>
                                                                                <div style={{ padding: "0px 10px 0px 10px", display: "inline-grid", gridTemplateColumns: "85px 40px 90px" }}>
                                                                                    <div style={{ paddingTop: "20px" }}>Thumbnail</div>
                                                                                    <div style={{ paddingTop: "10px" }}><img src={selectedOption.thumbnail || 'images/product_placeholder.jpg'} style={{ border: "1px solid grey", width: "37px", height: "37px" }} /></div>
                                                                                    <div style={{ paddingTop: "10px" }}><Button variant="outlined" style={{ marginLeft: "5px", padding: "6px 10px 5px 10px", color: "black", fontWeight: "normal", border: "1px solid grey", borderRadius: "0px" }} onClick={(e) => { openFileManager('GetThumbnail', masterVariant.attrName, si, 'thumbnail'); }}>Browse...</Button></div>
                                                                                </div>
                                                                                <DeleteIcon style={{ marginLeft: "10px", marginTop: "0px" }} onClick={(e) => { removeVariant(e, masterVariant.attrName, si); }} />
                                                                            </div>                                                                    
                                                                        </>
                                                                    }
                                                                    
                                                                </div>
                                                            ))}
                                                        </div>
                                                            </>
                                                    : null
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button onClick={handleCancelClick}>Cancel</Button>
                        <Button onClick={buildMatrix}>OK</Button>
                    </div>
                </div>
                : null}
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );
}

export default VariantBuilderCtrl;
