import React, { useContext, useState, useEffect } from 'react';
import ColorPicker from 'react-best-gradient-color-picker'
import ColorSelectorCtrl from '../colors/ColorSelectorCtrl';
import DialogCtrl from '../dialogctrl/dialogctrl';

function ColorFieldCtrl({ defaultColor, onColorChange, openColorPicker, hideColorLabel, hideControls }) {

    const [color, setColor] = useState(defaultColor);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [layoutInfo, setLayoutInfo] = useState(determineLayout());

    function determineLayout() {
        var tmpGridTemplateLayout = { gridTemplateColumns: "30px 1fr" };
        if (hideColorLabel == true) {
            tmpGridTemplateLayout = { gridTemplateColumns: "1fr" };
        }
        return tmpGridTemplateLayout;
    }

    function handleReturnColor(returnColor) {
        var test = "test";
        test = "hello";
        setColor(returnColor);
        onColorChange(returnColor);
    }

    function handleOpenColorPicker(existingColor) {
        var msg = '';
        setGenericDialogSchema({ title: 'Color Selector', component: (dialogType, setReturnValue) => <ColorSelectorCtrl defaultColor={existingColor} hideControls={hideControls} handlerReturnValue={dialogType, setReturnValue}></ColorSelectorCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: false, maxWidth: "200", hideBackdrop: true, paperProps: {}, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'Close', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); }, onReturnValueChanged: (returnValues) => { handleReturnColor(returnValues); } })
        setOpenDialog(true);
    }

    function parseSolidColor(color) {
        const regexp = /(RGBA|rgba\().*?[)]/gm;
        const matches = color.matchAll(regexp);

        var colorList = [];
        for (const match of matches) {
            colorList.push(match[0]);
        }
        if (colorList.length == 2) {
            if (colorList[0] == colorList[1]) {
                color = colorList[0];
            }
        }
        return color;
    }

    useEffect(() => {
        setColor(defaultColor);
    }, [defaultColor])

    return (
        <>
            <div style={{ display: "grid", gridTemplateColumns: "30px 1fr", gridGap: "5px", width: "100%" }}>
                <div style={{ backgroundImage: "url('images/transparent.jpg')" }}>
                    <div style={{ background: color, height: "27px", width: "100%", border: "1px solid #c0c0c0", cursor: "pointer" }} onClick={() => { handleOpenColorPicker(parseSolidColor(color)); }}></div>
                </div>
                {hideColorLabel == false ?
                    <div><input className="form-control form-control-sm fg-pb-property-field" value={color} onChange={(e) => { handleReturnColor(e.target.value) }} /></div>
                    : null}
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );
}

export default ColorFieldCtrl;