import React, { useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import WidgetRouter, { BuildPropDefault, MergeStylesToCurrBP} from '../WidgetRouter';
import { DataBrokerContext } from "../PageBuilderCtrl";
import HoverCtrl, { OnWidgetClick, OnWidgetMouseEnter, OnWidgetMouseLeave, OnWidgetMouseOut, OnWidgetMouseOver, RenderWidgetLabel, RenderWidgetContextMenu } from '../HoverCtrl';
import { CollectionItemContext } from './CollectionListItemWidget';

function FormFieldWidget({ params, tmpElements, currBP, }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseOver, onWidgetMouseLeave, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, handleUpdateChanges, handleMergeVariables } = useContext(DataBrokerContext);

    const collectionItemContext = useContext(CollectionItemContext);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [hoverSettings, setHoverSettings] = useState({ style: { pointerEvents: "none", position: "absolute", top: 0, left: 0, width: 0, height: 0, border: "1px solid #5E95DE" }, labelPosition: "top", labels: [], show: false });
    const [selectedSettings, setSelectedSettings] = useState({ style: { pointerEvents: "none", position: "absolute", top: 0, left: 0, width: 0, height: 0, border: "1px solid #5E95DE" }, labelPosition: "top", labels: [], show: false });
    const [widgetData, setWidgetData] = useState(params);
    const [options, setOptions] = useState([]);

    var timer = 0;
    var delay = 100;
    var prevent = false;

    function handleOnClick(e, id, widgetData) {
        timer = setTimeout(() => {
            if (!prevent) {
                onWidgetClick(e, id, widgetData, (updatedWidgetData) => handleOnPropertyChange(updatedWidgetData), () => handleOnHideHighlight(), () => handleOnSave());
            }
        }, delay);
        if (e != undefined) {
            e.stopPropagation();
        }
    }

    function handleOnSave() {
        //setElements({
        //    ...elements, // copy the old fields
        //    [widgetData.id]: widgetData //override with updated target widget
        //});
        //console.log('save widget data')
    }


    function handleOnHideHighlight() {
        setSelectedSettings({
            ...selectedSettings, // copy the old fields
            show: false
        });
        setHoverSettings({
            ...hoverSettings, // copy the old fields
            show: false
        });
        setShowContextMenu(false);
    }

    function handleOnPropertyChange(updatedWidgetData) {
        setWidgetData((...widgetData) => { return updatedWidgetData });
        handleUpdateChanges(updatedWidgetData);
    }

    function handleOnSettingsClick() {
        alert('show settings');
    }

    useEffect(() => {
        if (params.fieldType == 'select') {
            if (params.fieldOptions != '') {
                setOptions(JSON.parse(params.fieldOptions));
            } else {
                setOptions([]);
            }
        }
        
    }, [params])

    return (
        <>  
            <div id={params.id} elementname={params.elementName} className={"pb-widget"} onMouseEnter={(e) => { onWidgetMouseEnter(e, params.id); }} onMouseOver={(e) => { onWidgetMouseOver(e, params.id); }} onMouseLeave={(e) => { onWidgetMouseLeave(e, params.id); }} onClick={(e) => { handleOnClick(e, params.id, params); }} onDragStart={(e) => handleOnDragFromCanvas(e, params.id, "formfield", "widget")} onDragOver={(e) => { handleOnDragOver(e, params.id); }} onDragLeave={(e) => { handleOnDragLeave(e, params.id); }} onDrop={(e) => { handleOnDrop(e, params.id); }}>
                {(params.fieldType == 'date' || params.fieldType == 'date-time' || params.fieldType == 'email' || params.fieldType == 'password' || params.fieldType == 'phone' || params.fieldType == 'textbox' || params.fieldType == 'time') ?
                    <input type="text" elementname={params.elementName} style={MergeStylesToCurrBP(params, currBP)} placeholder={params.fieldPlaceholder} className={"form-control pb-noclick " + params.className} value={handleMergeVariables((params.fieldValue != '' ? params.fieldValue : (params.fieldDefaultValue != '' ? params.fieldDefaultValue : "")), false, collectionItemContext, (collectionItemContext != undefined ? collectionItemContext.collectionDSRowIndex : null), (collectionItemContext != undefined ? collectionItemContext.parentCollectionListRowIndex : null))} />
                    : null}
                {params.fieldType == 'lookup' ?
                    <input type="text" elementname={params.elementName} style={MergeStylesToCurrBP(params, currBP)} placeholder={params.fieldPlaceholder} className={"form-control pb-noclick " + params.className} value={handleMergeVariables((params.fieldValue != '' ? params.fieldValue : (params.fieldDefaultValue != '' ? params.fieldDefaultValue : "")), false, collectionItemContext, (collectionItemContext != undefined ? collectionItemContext.collectionDSRowIndex : null), (collectionItemContext != undefined ? collectionItemContext.parentCollectionListRowIndex : null))} />
                    : null}
                {params.fieldType == 'image' ?
                    <input type="text" elementname={params.elementName} style={MergeStylesToCurrBP(params, currBP)} placeholder={params.fieldPlaceholder} className={"form-control pb-noclick " + params.className} value={handleMergeVariables((params.fieldValue != '' ? params.fieldValue : (params.fieldDefaultValue != '' ? params.fieldDefaultValue : "")), false, collectionItemContext, (collectionItemContext != undefined ? collectionItemContext.collectionDSRowIndex : null), (collectionItemContext != undefined ? collectionItemContext.parentCollectionListRowIndex : null))} />
                    : null}
                {params.fieldType == 'tag' ?
                    <input type="text" elementname={params.elementName} style={MergeStylesToCurrBP(params, currBP)} placeholder={params.fieldPlaceholder} className={"form-control pb-noclick " + params.className} value={handleMergeVariables((params.fieldValue != '' ? params.fieldValue : (params.fieldDefaultValue != '' ? params.fieldDefaultValue : "")), false, collectionItemContext, (collectionItemContext != undefined ? collectionItemContext.collectionDSRowIndex : null), (collectionItemContext != undefined ? collectionItemContext.parentCollectionListRowIndex : null))} />
                    : null}
                {params.fieldType == 'html' ?
                    <textarea elementname={params.elementName} style={MergeStylesToCurrBP(params, currBP)} placeholder={params.fieldPlaceholder} className={"form-control pb-noclick " + params.className}>{handleMergeVariables((params.fieldValue != null ? params.fieldValue : ""), false, collectionItemContext, (collectionItemContext != undefined ? collectionItemContext.collectionDSRowIndex : null), (collectionItemContext != undefined ? collectionItemContext.parentCollectionListRowIndex : null))}</textarea>
                    : null}
                {params.fieldType == 'switch' ?
                    <input type="checkbox" checked elementname={params.elementName} style={MergeStylesToCurrBP(params, currBP)} style={{ transform: "scale(1.5,1.5)", appearance: "auto" }} className={"form-control pb-noclick " + params.className} />
                    : null}
                {params.fieldType == 'checkbox' ?
                    <input type="checkbox" checked elementname={params.elementName} style={MergeStylesToCurrBP(params, currBP)} style={{transform:"scale(1.5,1.5)",appearance:"auto"}} className={"form-control pb-noclick " + params.className} />
                    : null}
                {params.fieldType == 'select' ?
                    <select elementname={params.elementName} style={MergeStylesToCurrBP(params, currBP)} placeholder={params.fieldPlaceholder} className={"form-control pb-noclick " + params.className} >
                        <option value={handleMergeVariables((params.fieldValue != null ? params.fieldValue : ""), false, collectionItemContext, (collectionItemContext != undefined ? collectionItemContext.collectionDSRowIndex : null), (collectionItemContext != undefined ? collectionItemContext.parentCollectionListRowIndex : null))}></option>
                            {options.map((option, index) => (
                                    <option value={option.value}>{option.text}</option>
                                ))}
                    </select>
                    : null}
                {params.fieldType == 'multilinetextbox' ?
                    <textarea elementname={params.elementName} style={MergeStylesToCurrBP(params, currBP)} placeholder={params.fieldPlaceholder} className={"form-control pb-noclick " + params.className}>{handleMergeVariables((params.fieldValue != null ? params.fieldValue : ""), false, collectionItemContext, (collectionItemContext != undefined ? collectionItemContext.collectionDSRowIndex : null), (collectionItemContext != undefined ? collectionItemContext.parentCollectionListRowIndex : null))}</textarea>
                    : null}
            </div>
        </>
    )
}

FormFieldWidget.getPropSchema = function () {
    return {
        "reactUseStates": BuildPropDefault("reactUseStates")
    };
};

export default FormFieldWidget;
