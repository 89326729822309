const SystemFonts = {
    "items": [
        { "package": "Google", "family": "Abel", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat", "Oswald", "Esteban"] },
        { "package": "Google", "family": "Abril+Fatface", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "WebFont", "family": "Arial", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "Google", "family": "Cabin", "paring": ["Open+Sans", "Roboto", "Raleway", "Source+Sans+Pro"] },
        { "package": "Google", "family": "Cormorant+Garamond", "paring": ["Lao", "Montserrat", "Roboto", "Raleway", "Playfair+Display"] },
        { "package": "WebFont", "family": "Courier_New", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "Google", "family": "Dosis", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat", "Oswald"] },
        { "package": "Google", "family": "Esteban", "paring": ["Open+Sans", "Roboto", "Poppins", "Source+Code+Pro", "Abel"] },
        { "package": "WebFont", "family": "Georgia", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "WebFont", "family": "Helvetica", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "Google", "family": "Josefin_Slab", "paring": ["Open+Sans", "Oswald", "Roboto", "Open+Sans+Condensed"] },
        { "package": "Google", "family": "Julius+Sans+One", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat", "Raleway", "Monda"] },
        { "package": "Google", "family": "Kaushan+Script", "paring": ["Open+Sans", "Oswald", "Merriweather", "Open+Sans+Condensed"] },
        { "package": "Google", "family": "Lato", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Source+Sans+Pro"] },
        { "package": "Google", "family": "Lora", "paring": ["Open+Sans", "Roboto", "Montserrat", "Raleway", "Lato"] },
        { "package": "Google", "family": "Lucida", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "Google", "family": "Merriweather", "paring": ["Open+Sans", "Montserrat", "Roboto", "Source+Sans+Pro", "Oswald", "Kaushan+Script"] },
        { "package": "Google", "family": "Monda", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat", "Raleway"] },
        { "package": "Google", "family": "Monoton", "paring": ["Roboto", "Montserrat", "Playfair+Display"] },
        { "package": "Google", "family": "Montserrat", "paring": ["Open+Sans", "Roboto", "Lato", "Oswald", "Raleway"] },
        { "package": "Google", "family": "Overpass", "paring": ["Open+Sans", "Roboto", "Playfair+Display", "Lato", "Permanent+Marker"] },
        { "package": "Google", "family": "Open+Sans", "paring": ["Roboto", "Lato", "Montserrat", "Oswald", "Raleway"] },
        { "package": "Google", "family": "Open+Sans+Condensed", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Josefin+Slab", "Kaushan+Script"] },
        { "package": "Google", "family": "Oswald", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat", "Raleway", "Esteban"] },
        { "package": "Google", "family": "Permanent+Marker", "paring": ["Open+Sans", "Roboto", "Lato", "Raleway", "Playfair+Display", "Overpass+Regular"] },
        { "package": "Google", "family": "Play", "paring": ["Open+Sans", "Roboto", "Montserrat", "Playfair+Display"] },
        { "package": "Google", "family": "Playfair+Display", "paring": ["Open+Sans", "Lato", "Montserrat", "Raleway"] },
        { "package": "Google", "family": "Poppins", "paring": ["Open+Sans", "Raleway", "Oswald", "Playfair+Display"] },
        { "package": "Google", "family": "Princess+Sofia", "paring": ["Roboto","Raleway"]},
        { "package": "Google", "family": "PT+Sans", "paring": ["Open+Sans", "Roboto", "Montserrat", "Raleway", "Lato"] },
        { "package": "Google", "family": "PT+Sans+Narrow", "paring": ["Open+Sans", "Roboto", "Oswald", "Roboto+Slab"] },
        { "package": "Google", "family": "Quicksand", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat", "Raleway"] },
        { "package": "Google", "family": "Raleway", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat", "Oswald"] },
        { "package": "Google", "family": "Roboto", "paring": ["Open+Sans", "Lato", "Montserrat", "Raleway"] },
        { "package": "Google", "family": "Roboto+Condensed", "paring": ["Open+Sans", "Lato", "Montserrat", "Roboto+Condensed"] },
        { "package": "Google", "family": "Ruda", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat"] },
        { "package": "Google", "family": "Rubik", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat", "Playfair+Display"] },
        { "package": "WebFont", "family": "Sans-Serif", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "WebFont", "family": "Serif", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "Google", "family": "Source+Code+Pro", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat", "Source+Sans+Pro", "Esteban"] },
        { "package": "Google", "family": "Source+Sans+Pro", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat", "Oswald"] },
        { "package": "WebFont", "family": "Tahoma", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "WebFont", "family": "Times", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "WebFont", "family": "Times+New+Roman", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "WebFont", "family": "Trebuchet", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "WebFont", "family": "Verdana", "paring": ["Open+Sans", "Roboto", "Oswald", "Raleway", "Playfair+Display"] },
        { "package": "Google", "family": "Wire+One", "paring": ["Open+Sans", "Roboto", "Oswald", "Merriweather", "Montserrat"] },
        { "package": "Google", "family": "Work+Sans", "paring": ["Open+Sans", "Roboto", "Lato", "Montserrat", "Poppins"] },
    ]
}
export default SystemFonts;
