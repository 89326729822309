import React, { useContext, useState, useEffect, useRef } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import CloseIcon from '@mui/icons-material/Close';
import FileManager from '../../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import Dropdown from 'react-bootstrap/Dropdown';
import InteractMouseClickCtrl from './InteractMouseClickCtrl';
import helpers from '../../../helpers/Helpers';
import TimedAnimationItemCtrl from './TimedAnimationItemCtrl';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

function InteractCtrl({ widgetData, propertyChangeCallback, handleEndAnimationSelection, actionElementId }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});  
    const [showHome, setShowHome] = useState(true);
    const [showEventItem, setShowEventItem] = useState(false);
    const [showAnimationItem, setShowAnimationItem] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const animationEventIdRef = useRef(null);


    //function handleOnClassNameChange(value) {
    //    var tmpWidgetObj = { ...widgetObj };
    //    tmpWidgetObj.className = value;
    //    setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //    propertyChangeCallback(tmpWidgetObj);
    //}

    //function handleOnIconChange(value) {
    //    var tmpWidgetObj = { ...widgetObj };
    //    tmpWidgetObj.className = value;
    //    setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //    propertyChangeCallback(tmpWidgetObj);
    //}

    //function openFileManager() {
    //    var actionType = "FullPath";
    //    var msg = 'This is a test';
    //    setGenericDialogSchema({ title: 'Icon Manager', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={dialogType, setReturnValue} returnFullPath={false} iconOnly={true}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleUpdateImage(returnValues, actionType); } })
    //    setOpenDialog(true);
    //}

    //function handleUpdateImage(imgUrl, actionType) {
    //    handleOnClassNameChange(imgUrl);
    //}

    function getNewEventJson(id, eventTypeId) {
        return {
            "id": id, "name": "", "animationType": "custom", "eventTypeId": eventTypeId, action: {},mediaQueries:[]
        };
    }

    function onNewInteraction(eventTypeId) {
        var tmpWidgetObj = { ...widgetObj };
        var newId = helpers.getUUID();
        if (tmpWidgetObj.hasOwnProperty('tmpWidgetObj') == false) {
            tmpWidgetObj['events'] = {};
        }        
        tmpWidgetObj['events'][newId] = getNewEventJson(newId, eventTypeId);
        tmpWidgetObj['events'][newId + '-2'] = getNewEventJson(newId + '-2', 'MOUSE_SECOND_CLICK');
        setWidgetObj(tmpWidgetObj);
        setSelectedEventId(newId);
        toggleContainer('eventItem');
        propertyChangeCallback(tmpWidgetObj);
    }

    function toggleContainer(name, eventId) {
        switch (name) {
            case 'home':
                animationEventIdRef.current = null;
                setShowEventItem(false);
                setShowAnimationItem(false);
                setShowHome(true);
                break;
            case 'eventItem':
                animationEventIdRef.current = null;
                setShowHome(false);
                setShowAnimationItem(false);
                setShowEventItem(true);                
                break;
            case 'animationItem':
                animationEventIdRef.current = (eventId != null ? eventId : selectedEventId);
                setShowHome(false);
                setShowEventItem(false);                
                setShowAnimationItem(true);
                break;
        }
    }

    function handleOnEventChange(eventItem) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.events[eventItem.id] = eventItem;
        setWidgetObj(tmpWidgetObj);
        propertyChangeCallback(tmpWidgetObj);
        //setSelectedEventId(newId);
        //toggleContainer('eventItem');
    }
    function removeEvent(key) {
        var tmpWidgetObj = { ...widgetObj };
        delete tmpWidgetObj.events[key];
        if (key.indexOf('-2') == -1) {
            delete tmpWidgetObj.events[key + '-2'];
        }
        setWidgetObj(tmpWidgetObj);
        propertyChangeCallback(tmpWidgetObj);
    }

    function openEvent(eventId) {
        setSelectedEventId(eventId);
        toggleContainer('eventItem');
    }

    useEffect(() => {
        if (widgetData.id != widgetObj.id) {
            toggleContainer('home');
            setSelectedEventId(null);
            setWidgetObj(widgetData);            
        }
    }, [widgetData])



    return (
        <>
            <div>
                {showHome == true ?
                    <>
                        <div className="fg-pg-panel-sub-section">
                            On Selected Element
                        </div>
                        <div style={{ backgroundColor: "white", padding: "5px"}}>
                            <div style={{ textAlign: "right" }}>
                                <Dropdown style={{ display: "inline-block", marginLeft: "5px" }} size="small">
                                    <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" style={{ color: "black", border: "1px solid black", padding: "0px 5px 0px 5px", margin: "0px" }} size="small">
                                        Add New Interaction
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => { onNewInteraction('MOUSE_CLICK');}}>Mouse Click (Tap)</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onNewInteraction('MOUSE_HOVER'); }}>Mouse Hover</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onNewInteraction('MOUSE_MOVE'); }}>Mouse Move over Element</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onNewInteraction('SCROLL_INTO_VIEW'); }}>Scroll into View</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onNewInteraction('WHILE_SCROLLING_IN_VIEW'); }}>While Scrolling in View</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {(widgetObj.events != null && JSON.stringify(widgetObj.events) != "{}") ?
                            <div>
                                {Object.keys(widgetObj.events).map((key, ei) => (
                                    <>
                                        {widgetObj.events[key].eventTypeId == "MOUSE_CLICK" ?
                                            <div key={"event-item_" + widgetObj.events[key].id} style={{ backgroundColor: "whitesmoke", padding: "5px", marginBottom: "5px" }}>
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 30px 30px" }}>
                                                    <div>{widgetObj.events[key].eventTypeId}</div>
                                                    <div style={{ textAlign: "right", cursor: "pointer" }} onClick={() => { openEvent(widgetObj.events[key].id); }}><EditIcon></EditIcon></div>
                                                    <div style={{ textAlign: "right", cursor: "pointer" }} onClick={() => { removeEvent(widgetObj.events[key].id); }}><DeleteForeverIcon></DeleteForeverIcon></div>
                                                </div>
                                            </div>
                                                : null}
                                    </>
                                ))}
                            </div>
                            : null}
                    </>
                    : null}
                {showEventItem == true ?
                    <>
                        <div className="fg-pg-panel-sub-section">
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 50px" }}>
                                <div>{widgetObj.events[selectedEventId].eventTypeId}</div>
                                <div style={{ textAlign: "right",cursor:"pointer" }} onClick={() => { toggleContainer('home'); }} >Close</div>
                            </div>                                      
                            <div style={{ textTransform: "none" }} >Define actions that occur on 1st or 2nd tap.</div>
                        </div>
                        <InteractMouseClickCtrl eventData={widgetObj.events[selectedEventId]} propertyChangeCallback={(eventItem) => { handleOnEventChange(eventItem); }} toggleContainer={(container) => toggleContainer(container, selectedEventId)} title="On 1st Click"></InteractMouseClickCtrl>
                        <InteractMouseClickCtrl eventData={widgetObj.events[selectedEventId + '-2']} propertyChangeCallback={(eventItem) => { handleOnEventChange(eventItem); }} toggleContainer={(container, eventId) => toggleContainer(container, eventId)} title="On 2nd Click"></InteractMouseClickCtrl>
                    </>
                    : null}
                {showAnimationItem == true ?
                    <TimedAnimationItemCtrl eventData={widgetObj.events[animationEventIdRef.current]} propertyChangeCallback={(eventItem) => { handleOnEventChange(eventItem); }} toggleContainer={(container, eventId) => toggleContainer(container, eventId)} handleEndAnimationSelection={handleEndAnimationSelection} actionElementId={actionElementId} ></TimedAnimationItemCtrl>
                    : null}
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default InteractCtrl;

