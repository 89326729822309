// First we need to import axios.js
import axios from 'axios';
// Next we make an 'instance' of it
const baInstance = axios.create({
    // .. where we make our configurations
    baseURL: process.env.REACT_APP_API_BASE_URL + "v1/"
    //baseURL: "http://localhost:7254/api/"
});

// Where you would set stuff like your 'Authorization' header, etc ...
baInstance.defaults.headers.common['Authorization'] = 'Bearer 000000';

// Also add/ configure interceptors && all the other cool stuff

//instance.interceptors.request...

export default baInstance;