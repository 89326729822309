import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import CloseIcon from '@mui/icons-material/Close';
import FileManager from '../../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';


function CssBackgroundVideoCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            



    function onVideoUrlChange(value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.backgroundVideo.url = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function parseValue(value) {
        var mtList = ['%'];
        for (var i = 0; i < mtList.length; i++) {
            value = value.toString().replace(mtList[i], '');
        }
        return value;
    }

    function updateCssProperty(propertyName, value) {
        var tmpValue = parseValue(value);

        var tmpWidgetObj = { ...widgetObj };
        if (tmpValue != '' && tmpValue != null) {
            if (tmpWidgetObj.backgroundVideo.breakpointStyles.hasOwnProperty(currentBreakpoint) == false) {
                tmpWidgetObj.backgroundVideo.breakpointStyles[currentBreakpoint] = {};
            }
            tmpWidgetObj.backgroundVideo.breakpointStyles[currentBreakpoint][propertyName] = value;
        } else {
            if (tmpWidgetObj.backgroundVideo.breakpointStyles.hasOwnProperty(currentBreakpoint) == true) {
                if (tmpWidgetObj.backgroundVideo.breakpointStyles[currentBreakpoint].hasOwnProperty(propertyName)) {
                    delete tmpWidgetObj.backgroundVideo.breakpointStyles[currentBreakpoint][propertyName];
                }
            }
        }
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }


    function openFileManager() {
        var actionType = "FullPath";
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Icon Manager', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={dialogType, setReturnValue} returnFullPath={false} iconOnly={true}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleUpdateImage(returnValues, actionType); } })
        setOpenDialog(true);
    }

    function handleUpdateImage(imgUrl, actionType) {
        //handleOnClassNameChange(imgUrl);
    }

    //const getCustomMergedStyleToBP = (widgetData, propertyName) => {
    //    var value = "";
    //    var bp = null;
    //    for (var i = 0; i < orderedBreakpoints.length; i++) {
    //        bp = orderedBreakpoints[i];
    //        if (widgetData.backgroundVideo.breakpointStyles.hasOwnProperty(bp)) {
    //            if (widgetData.backgroundVideo.breakpointStyles[bp].hasOwnProperty(propertyName)) {
    //                if (widgetData.backgroundVideo.breakpointStyles[bp][propertyName] != '') {
    //                    value = widgetData.backgroundVideo.breakpointStyles[bp][propertyName];
    //                }
    //            }
    //        }
    //        if (bp == Number(currentBreakpoint)) {
    //            break;
    //        }
    //    }
    //    return value;
    //}

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Background Video</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Video Url</div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 30px 30px" }}>
                            <div><input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.backgroundVideo.url || ''} onChange={(e) => { onVideoUrlChange(e.target.value); }} /></div>
                            <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }} onClick={() => { openFileManager(); }}><MoreHorizIcon /></div>
                            <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }}><CloseIcon /></div>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label" >Width</div>
                        <div><SliderCtrl value={getMergedStyleToBP(widgetObj, 'width', 'backgroundVideo')} min={0} max={250} measureTypeList={['%']} onDataChange={(width) => { updateCssProperty('width', width); }}></SliderCtrl></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr ", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Height</div>
                        <div><SliderCtrl value={getMergedStyleToBP(widgetObj, 'height', 'backgroundVideo')} min={0} max={250} measureTypeList={['%']} onDataChange={(height) => { updateCssProperty('height', height); }}></SliderCtrl></div>
                    </div>





                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default CssBackgroundVideoCtrl;

