import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';
import LookupFieldCtrl from '../../formcontrols/LookupFieldCtrl';
import Forms from '../../../pages/Forms';
import { BAFetchData } from '../../../customhooks/useBAFetch';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Products from '../../../pages/Products';


function CssAddToCartInfoCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    function handleOnPropertyChange(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        if (tmpWidgetObj.addToCartInfo == null) {
            tmpWidgetObj.addToCartInfo = { "products": [], "removeCartItem": false };
        }

        tmpWidgetObj.addToCartInfo.removeCartItem = value;

        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function openProductSearch() {
        var msg = '';
        setGenericDialogSchema({ title: 'Product Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <Products handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></Products>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('GetProductInfo', returnValues); } });
        setOpenDialog(true);
    }

 

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case 'GetProductInfo':
                addLineItem(returnValues);
                break;
        }

    }

    function addLineItem(selectedProductInfo) {
        var tmpWidgetObj = { ...widgetObj };
        if (tmpWidgetObj.addToCartInfo == null) {
            tmpWidgetObj.addToCartInfo = { "products": [], "removeCartItem": false };
        }

        tmpWidgetObj.addToCartInfo.products.push({ "productId": selectedProductInfo.productId, "variantId": selectedProductInfo.variantId, "skuNum": selectedProductInfo.skuNum, "productName": selectedProductInfo.productName });

        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    //function onAPISuccess(data, header, callName, packageObj) {
    //    switch (callName) {
    //        case 'GetFormRecord':
    //            var tmpWidgetObj = { ...widgetObj };
    //            tmpWidgetObj.formId = data.formId;
    //            tmpWidgetObj.formName = data.formName;
    //            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //            propertyChangeCallback(tmpWidgetObj);
    //            break;
    //    }
    //}

    //function onAPIError(error, callName) {
    //    var msg = error;
    //    setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
    //    setOpenDialog(true);
    //}

    function removeProduct(e, index) {
        var tmpWidgetObj = { ...widgetObj };

        if (tmpWidgetObj.addToCartInfo != null) {
            if (tmpWidgetObj.addToCartInfo.products.length > 0) {
                tmpWidgetObj.addToCartInfo.products.splice(index, 1);
            }
            setWidgetObj((...widgetObj) => { return tmpWidgetObj });
            propertyChangeCallback(tmpWidgetObj);
        }
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Products to Add to Cart</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 25px", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Click plus symbol to add product</div>
                        <div onClick={(e) => { openProductSearch(e); }} style={{cursor:"pointer"}}><AddIcon></AddIcon></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 25px", marginBottom: "5px" }}>
                        {widgetObj.addToCartInfo != null ?
                            (widgetObj.addToCartInfo.products.map((product, index) => (
                                    <>
                                    <div className="fg-pb-property-sub-label"><span style={{fontWeight:"bold"}}>{'#' + product.skuNum}</span>{' - ' + product.productName}</div>
                                    <div onClick={(e) => { removeProduct(e, index); }} style={{ cursor: "pointer" }}><DeleteForeverIcon></DeleteForeverIcon></div>
                                    </>
                                ))
                            )
                            : null}
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 70px", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Remove Existing Items From Cart?</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.addToCartInfo.removeCartItem || ''} onChange={(e) => { handleOnPropertyChange('removeCartItem', e.target.value); }}>
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );

}

export default CssAddToCartInfoCtrl;

