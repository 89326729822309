import React, { useState, useEffect } from 'react';
import { saveAs } from "file-saver";
import '../../../pages/css/filemanager.css';

import DialogCtrl from '../../dialogctrl/dialogctrl';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

function ContactFilesCtrl({ handlerReturnValue, handleDialogOnOK, setAcceptAfterUpdate, returnFullPath, iconOnly, recType, recId }) {
    const [currentUser] = useGlobalState("userInfo");

    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [fileList, setFileList] = useState([]);
    const [category, setCategory] = useState("main");
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [criteria, setCriteria] = useState("");
    const [searchResultData, setSearchResultData] = useState({ data: null, loading: true, error: null });
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationInfo, setPaginationInfo] = useState({ itemsPerPage: 24, totalPages: 0, totalRecords: 0, hasNext: false, hasPrevious: false });
    const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
    const [showIcons, setShowIcons] = useState(iconOnly || false);

    let cdnFolder = process.env.REACT_APP_CDN_BASE_URL;

    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "tag_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    //const searchResultData = useBAFetch(
    //    {
    //        method: "GET",
    //        url: "files/getlist?filtering={}",
    //        token: "fg1234",
    //        toggleRefetch: searchRefetch
    //    }
    //);

    const [rowIds, setRowIds] = useState([]);
    const pageTitle = getTranslation('tag_search_page', 'header_section', 'File Manager');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [gridId, setGridId] = useState(1);
    const headerOptions = {
        elements: [
            { type: "button", text: getTranslation('tag_search_page', 'header_section', 'Upload'), caption: "Upload", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-success", options: null, function: () => { openFileSelector(); } }
        ], breadcrumbs: [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }]
    }

    function openFileSelector() {
        document.getElementById('fg-fm-fileupload').click();
    }

    function upload(tmpFiles) {
        var formData = new FormData();
        var tmpFileList = [];
        if (tmpFiles.length > 0) {
            for (var i = 0; i < tmpFiles.length; i++) {
                formData.append('files', tmpFiles[i]);
            }
            formData.append('category', category);
            formData.append('recType', recType);
            formData.append('recId', recId);

            BAFetchData({
                callName: "UploadFiles", method: "POST", url: "files/addfiles",
                token: "fg1234", body: formData, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function importContacts() {
        //setLoaderSchema({msg:"Process will take a few seconds..."})
        setShowLoader(true);
        setTimeout(() => {
            setTimeout(() => {
                setShowLoader(false);
            }, "1000");
        }, "50");
    }

    function showDialog() {
        var msg = 'You are about to delete this contact record. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Contacts Created', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); okAction() } })
        setOpenDialog(true);
    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleBulkDeleteConfirm(gridId) {
        if (rowIds.length > 0) {
            var plural = helpers.getPlural(rowIds.length);
            var msg = 'You are about to delete ' + rowIds.length + ' tag record' + plural + '. <span style="font-weight:bold;">Are you sure</span>?';
            setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecords() } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }

    function copyUrl() {
        let url = cdnFolder + "accountfiles/1/files/" + searchResultData.data[selectedIndex].original
        helpers.copyText(url);
        setCopySnackbarOpen(true);
    }

    const downloadFile = () => {
        let fileName = searchResultData.data[selectedIndex].name + searchResultData.data[selectedIndex].extension;
        let url = cdnFolder + "accountfiles/1/files/" + searchResultData.data[selectedIndex].original;
        saveAs(url, fileName);
    }

    function deleteRecord() {
        var test = '';
        test = '1234';
        BAFetchData({
            callName: "DeleteRecord",
            method: "DELETE",
            url: "files/delete?fileid=" + searchResultData.data[selectedIndex].fileId,
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function deleteRecords() {
        var test = '';
        test = '1234';
        BAFetchData({
            callName: "BulkDelete",
            method: "POST",
            url: "crm/tag/BulkDelete",
            token: "fg1234",
            body: JSON.parse('[' + rowIds + ']'), onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function resetResults() {
        setSelectedIndex(null);
        beginSearch();
        //setRowIds([]);
        //setSearchRefetch(true);
    }

    function selectFileItem(e) {
        var index = e.target.id.toString().replace("filemanagef_fileitem_", "");
        if (handlerReturnValue) {
            if (returnFullPath == false) {
                handlerReturnValue(searchResultData.data[index].original);
            } else {
                handlerReturnValue(cdnFolder + "accountfiles/" + currentUser.currentBusinessAccountDTO.baid + "/files/" + searchResultData.data[index].original);
            }

        }
        setSelectedIndex(index);
    }

    function pageNavigation(action, page) {
        var tmpPaginationInfo = { ...paginationInfo };
        var tmpCurrentPage = currentPage;
        if (action == 'Next') {
            if (tmpPaginationInfo.hasNext == true) {
                setCurrentPage((...currentPage) => { return (tmpCurrentPage + 1) });
            }
        } else if (action == 'Prev') {
            if (tmpPaginationInfo.hasPrevious == true) {
                setCurrentPage((...currentPage) => { return (tmpCurrentPage - 1) });
            }
        } else if (action == 'GoTo') {
            if (page > 0 && page <= tmpPaginationInfo.totalPages) {
                setCurrentPage((...currentPage) => { return page });
            }
        }
        //setPaginationInfo((...paginationInfo) => { return tmpPaginationInfo });

    }

    function beginSearch() {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "icon", "ConditionOperator": "EQUAL", "Value": showIcons, "DataType": "Boolean" },
                ]
            }]
        };
        if (recId != undefined) {
            var conditions = []
            conditions.push({ "Name": "recType", "ConditionOperator": "EQUAL", "Value": recType, "DataType": "String" });
            conditions.push({ "Name": "recId", "ConditionOperator": "EQUAL", "Value": recId, "DataType": "String" });
            filtering.ConditionGroups.push({
                "LogicalOperator": "and", "Conditions": conditions
            });
        } else {
            var conditions = []
            conditions.push({ "Name": "recType", "ConditionOperator": "IS_EMPTY", "Value": "", "DataType": "String" });
            conditions.push({ "Name": "recId", "ConditionOperator": "IS_EMPTY", "Value": "", "DataType": "String" });
            filtering.ConditionGroups.push({
                "LogicalOperator": "and", "Conditions": conditions
            });
        }
        if (criteria != '') {
            var conditions =
                [
                    { "Name": "name", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" },
                    { "Name": "category", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" }
                ]
            filtering.ConditionGroups.push({
                "LogicalOperator": "or", "Conditions": conditions
            });
        }
        BAFetchData({
            callName: "BeginSearch", method: "GET", url: "files/getlist?filtering=" + JSON.stringify(filtering) + '&page=' + currentPage + '&itemsPerPage=' + paginationInfo.itemsPerPage,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }




    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "BeginSearch":
                var headerXPagination = JSON.parse(header['x-pagination']);
                setPaginationInfo((...paginationInfo) => { return { itemsPerPage: headerXPagination.itemsPerPage, totalPages: headerXPagination.totalPages, totalRecords: headerXPagination.totalCount, hasNext: headerXPagination.hasNext, hasPrevious: headerXPagination.hasPrevious } });
                setSearchResultData((...searchResultData) => { return { data: data, loading: false, error: null } });
                break;
            case "UploadFiles":
                var msg = "File(s) has been uploaded.";
                setGenericDialogSchema({ title: 'File(s) Uploaded', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);
                beginSearch();
                break;
            case "DeleteRecord":
                resetResults(); //Reset search results and clear records selected.               
                var msg = "File has been deleted.";
                setGenericDialogSchema({ title: 'File Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);
                break;
            case "BulkDelete":
                resetResults(); //Reset search results and clear records selected.               
                var msg = "Tag record(s) has been deleted.";
                setGenericDialogSchema({ title: 'Tag(s) Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    const handleCopySnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCopySnackbarOpen(false);
    };




    useEffect(() => {
        //beginSearch();
        setShowLoader(false);
    }, []);

    useEffect(() => {
        beginSearch();
    }, [criteria, currentPage]);


    return (
        <>
            <div style={{ width: "100%", minWidth: "1150px", height: "100%", minHeight: "700px", maxHeight: "700px" }}>
                <div style={{ display: "none" }}>
                    <input id="fg-fm-fileupload" type="file" multiple onChange={(e) => { upload(e.target.files); }} />
                </div>
                <div className="fg-app-page-box">
                    <div style={{ display: "grid", gridTemplateColumns: "110px 1fr 325px", gridGap: "20px", marginBottom: "10px" }}>
                        <div>
                            <Button variant="outlined" class="btn btn-sm btn-success" size="small" fullWidth onClick={() => { openFileSelector(); }} style={{width:"100%"}}>Upload File(s)</Button>
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 150px", gridGap: "10px" }}>
                            <div><input className="form-control form-control-sm fg-pb-property-field" style={{ padding: "4px" }} value={criteria} placeholder="Enter criteria" onChange={(e) => { setSelectedIndex(null); setCriteria(e.target.value); }} /></div>
                            <div><Button variant="outlined" size="small" fullWidth onClick={() => { beginSearch(); }}>Filter</Button></div>
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 70px 70px", gridGap: "10px" }}>
                            <div style={{ textAlign: "right", paddingTop: "5px", fontSize: "12px" }}>Total: {paginationInfo.totalRecords} | Page {currentPage} of {paginationInfo.totalPages}</div>
                            <div><Button variant="outlined" size="small" fullWidth onClick={() => { pageNavigation('Prev', null); }}>Prev</Button></div>
                            <div><Button variant="outlined" size="small" fullWidth onClick={() => { pageNavigation('Next', null); }}>Next</Button></div>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 325px", gridGap: "10px" }}>
                        <div style={{ display: "flex", flexWrap: "wrap", height: "600px", overflowY: "auto" }}>
                            {searchResultData.loading == false ?
                                searchResultData.data.map((row, index) => (
                                    <div id={"filemanagef_fileitem_" + index} key={"filemanage_fileitem_" + index} className="fg-fm-file-item" onClick={selectFileItem}>
                                        {row.icon == false ?
                                            <div className="fg-fm-file-item-img-box"><img src={cdnFolder + "accountfiles/" + currentUser.currentBusinessAccountDTO.baid + "/files/" + row.original} /></div>
                                            :
                                            <div className="fg-fm-file-item-img-box"><i className={row.original} style={{ fontSize: "40px" }}></i></div>
                                        }
                                        <div className="fg-fm-file-item-text">{row.name}</div>
                                    </div>
                                ))
                                : null}
                        </div>
                        <div>
                            <div style={{ width: "325px", height: "325px", backgroundColor: "whitesmoke", marginBottom: "10px", textAlign: "center" }}>
                                {selectedIndex ?
                                    (showIcons == false ?
                                        <img src={cdnFolder + "accountfiles/" + currentUser.currentBusinessAccountDTO.baid + "/files/" + searchResultData.data[selectedIndex].original} alt="" style={{ maxHeight: "325px", maxWidth: "325px" }} />
                                        : <div className="fg-fm-file-item-img-box"><i className={searchResultData.data[selectedIndex].original} style={{ fontSize: "120px" }}></i></div>
                                    )
                                    : null}
                            </div>
                            {showIcons == false ?
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: "10px", width: "100%" }}>
                                    <div><Button variant="outlined" size="small" fullWidth onClick={() => { copyUrl(); }}>Copy URL</Button></div>
                                    <div><Button variant="outlined" size="small" fullWidth onClick={() => { downloadFile(); }}>Download</Button></div>
                                    <div><Button variant="outlined" size="small" fullWidth onClick={() => { deleteRecord(); }}>Delete</Button></div>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
                <Snackbar
                    open={copySnackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCopySnackClose}
                    message="Copy completed" />
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>

    );
}

export default ContactFilesCtrl;
