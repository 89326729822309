import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import CardSettings from '../../../pages/CardSettings';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';


function ContactCardsCtrl({ contactId, defaultCardType }) {


    const navigate = useNavigate();
    let internalTimeout = null;
    let criteria = null;
    const [searchResultData, setSearchResultData] = useState([]);
    //let params = useParams();

    //let cardType = defaultCardType
    const [cardType, setCardType] = useState(defaultCardType);
    const [columns, setColumns] = useState([]);
    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "automations_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const [rowIds, setRowIds] = useState([]);
    const pageTitle = getTranslation('automations_search_page', 'header_section', getPageCaption());
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    const [gridId, setGridId] = useState(1);

    var newOptions = [];
    switch (cardType) {
        case "Opportunity":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Quick Sales Order'), name: "OrderQS", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/order/0") } })
            break;
        case "Incident":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Quote'), name: "Quote", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/quote/0") } });
            break;
        case "Task":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Invoice'), name: "Invoice", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/invoice/0") } });
            break;
        case "Appointment":
            newOptions.push({ type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Payment'), name: "Payment", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { navigate("/payment/0") } });
            break;
    }

    const headerOptions = {
        elements: [
            {
                type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [
                    { type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "", color: "btn-outline-secondary", options: null, function: () => { handleBulkDeleteConfirm(gridId) } }

                ]
            },
            {
                type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'New'), name: "New", icon: "bi bi-plus-lg", url: null, dropdownClassNames: "fg-save-dropdown", dropdownBtnClassNames: "fg-save-dropdown-btn", color: "btn-success", elements: newOptions
            }
        ], breadcrumbs: [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }]
    }



    function resetGridColumns() {
        var tmpColumns = [];

        if (cardType == 'Opportunity') {
            tmpColumns.push({
                field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
                renderCell: ({ row }) =>
                    <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('automations_search_page', 'search_grid', 'Open')}</Button>,
            });
            tmpColumns.push({
                field: 'title',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Title'),
                width: 300
            });
            tmpColumns.push({
                field: 'dealValue',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Deal Value'),
                width: 90
            });
            tmpColumns.push({
                field: 'winLikelihood',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Win Likelihood'),
                width: 110
            });
            tmpColumns.push({
                field: 'boardName',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Pipeline'),
                width: 175
            });
            tmpColumns.push({
                field: 'columnName',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Column/Stage'),
                width: 125
            });
            tmpColumns.push({
                field: 'dealStatus',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Deal Status'),
                width: 150
            });
        } else if(cardType == 'Appointment') {
            tmpColumns.push({
                field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
                renderCell: ({ row }) =>
                    <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('automations_search_page', 'search_grid', 'Open')}</Button>,
            });
            tmpColumns.push({
                field: 'title',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Title'),
                width: 300
            });
            tmpColumns.push({
                field: 'startDateTime',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Start Date/Time'),
                width: 160,
                renderCell: function (params) {
                    return dayjs(params.row.startDateTime).format('MM/DD/YYYY h:mm A');
                }
            });
        } else if (cardType == 'Task') {
            tmpColumns.push({
                field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
                renderCell: ({ row }) =>
                    <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('automations_search_page', 'search_grid', 'Open')}</Button>,
            });
            tmpColumns.push({
                field: 'title',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Title'),
                width: 300
            });
            tmpColumns.push({
                field: 'boardName',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Board Name'),
                width: 175
            });
            tmpColumns.push({
                field: 'columnName',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Column/Status'),
                width: 125
            });
            tmpColumns.push({
                field: 'completed',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Completed'),
                width: 90
            });
        } else if (cardType == 'Incident') {
            tmpColumns.push({
                field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
                renderCell: ({ row }) =>
                    <Button variant="outlined" onClick={() => onGridOpenBtnClick(row)}>{getTranslation('automations_search_page', 'search_grid', 'Open')}</Button>,
            });
            tmpColumns.push({
                field: 'title',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Title'),
                width: 300
            });
            tmpColumns.push({
                field: 'boardName',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Board Name'),
                width: 175
            });
            tmpColumns.push({
                field: 'columnName',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Column/Status'),
                width: 125
            });
            tmpColumns.push({
                field: 'completed',
                headerName: getTranslation('automations_search_page', 'search_grid', 'Completed'),
                width: 90
            });
        }
        setColumns(tmpColumns);
    }

    function getPageCaption() {
        var title = "";
        switch (cardType) {
            case 'order':
                title = "Orders";
                break;
            case 'quote':
                title = "Quotes";
                break;
            case 'invoice':
                title = "Invoices";
                break;
            case 'payment':
                title = "Payments";
                break;
            case 'subscription':
                title = "Subscriptions";
                break;
        }
        return title;
    }

    function newRecord() {
        switch (cardType) {
            case 'Incident':
            case 'Appointment':
            case 'Task':
                var msg = '';
                setGenericDialogSchema({
                    title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                        <CardSettings dialogType={dialogType} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} cardId={0} contactId={contactId} cardType={cardType}></CardSettings>,
                    body: { innerHtml: { __html: msg } }, dialogType: "popup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
                    cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('OrderEdit', returnValues); }
                })
                setOpenDialog(true);
                break;
        }
    }

    function onGridOpenBtnClick(row) {
        var msg = '';
        setGenericDialogSchema({
            title: '', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) =>
                <CardSettings dialogType={dialogType} handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} cardId={row.cardId} ></CardSettings>,
            body: { innerHtml: { __html: msg } }, dialogType: "popup", fullWidth: true, maxWidth: "1200px", hideBackdrop: false, showCancelBtn: true, showOKBtn: false,
            cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('OrderEdit', returnValues); }
        })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        resetResults();
    }

    function handleOnCriteriaChange(value) {
        criteria = value.toLowerCase();
        if (internalTimeout != undefined) {
            clearTimeout(internalTimeout);
        }
        internalTimeout = setTimeout(() => {
            beginSearch();
        }, 250);
    }

    function beginSearch() {
        var filtering = {};
        var conditionalGroups = [];
        var conditions = [];

        conditions.push({ "Name": "contactRefId", "ConditionOperator": "EQUAL", "Value": contactId, "DataType": "Number" });
        conditions.push({ "Name": "cardType", "ConditionOperator": "EQUAL", "Value": defaultCardType, "DataType": "String" });

        conditionalGroups.push({ "LogicalOperator": "and", "Conditions": conditions })

        if (criteria != null && criteria != '') {
            conditions = [];
            if (helpers.isNumeric(criteria)) {
                conditions.push({ "Name": "cardId", "ConditionOperator": "EQUAL", "Value": criteria, "DataType": "String" });
            } else {
                conditions.push({ "Name": "title", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" });
            }
            conditionalGroups.push({ "LogicalOperator": "or", "Conditions": conditions })
        }

        filtering = {
            "LogicalOperator": "and", "ConditionGroups": conditionalGroups
        };



        BAFetchData({
            callName: "GetData",
            method: "GET",
            url: "pm/card/getlistbasicsummary?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function okAction() {
        var msg = 'Record process has been completed.';
        setGenericDialogSchema({ title: 'Record Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function handleBulkDeleteConfirm(gridId) {
        if (rowIds.length > 0) {
            var plural = helpers.getPlural(rowIds.length);
            var msg = 'You are about to delete ' + rowIds.length + ' transaction' + plural + '. <span style="font-weight:bold;">Are you sure</span>?';
            setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecords() } })
            setOpenDialog(true);
        } else {
            var msg = 'One or more rows must be selected first.';
            setGenericDialogSchema({ title: 'No Rows Selected', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }

    function deleteRecords() {
        BAFetchData({
            callName: "BulkDelete",
            method: "POST",
            url: "accounting/transactionheader/BulkDelete",
            token: "fg1234",
            body: rowIds, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function resetResults() {
        setRowIds([]);
        beginSearch();
        //setSearchRefetch(true);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetData":
                setSearchResultData(data);
                break;
            case "BulkDelete":
                resetResults(); //Reset search results and clear records selected.               
                var msg = "Transaction record(s) has been deleted.";
                setGenericDialogSchema({ title: 'Transaction(s) Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); } })
                setOpenDialog(true);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }


    useEffect(() => {
        setShowLoader(false);
    }, []);

    useEffect(() => {
        resetGridColumns();
        //setCardType(params.cardType);
        beginSearch();
    }, [cardType]);

    //function handleTransactionTypeChange(cardType) {
    //    cardType = cardType;
    //    setCardType(cardType);
    //}
    return (
        <>
            <div style={{ backgroundColor: "#ffffff", padding: "5px 10px 5px 10px", display: "grid", gridTemplateColumns: "1fr 70px 70px", gridGap: "10px" }}>
                <div>
                    <TextField fullWidth placeholder="Enter search keywords" variant="standard" style={{ border: "1px solid whitesmoke" }} onChange={(e) => { handleOnCriteriaChange(e.target.value); }} />
                </div>
                <div>
                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { beginSearch(); }} style={{ width: "100%" }}>Search</button>
                </div>
                <div>
                    <Button variant="outlined" class="btn btn-sm btn-success" size="small" fullWidth onClick={() => { newRecord(); }} style={{ width: "100%" }}>New</Button>
                </div>
            </div>
            <div>
                <Box sx={{ height: 630, width: '100%' }}>
                    <DataGrid
                        sx={{
                            border: 0,
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within": {
                                outline: "none !important",
                            },
                        }}
                        rowHeight={80}
                        rows={searchResultData}
                        getRowId={(row) => row.cardId}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(ids) => {
                            setRowIds(ids);
                            //if (handlerReturnValue) {
                            //    handlerReturnValue(ids)
                            //}
                        }}
                    />
                </Box>
            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default ContactCardsCtrl;
