import React, { useContext, useState, useEffect } from 'react';
import './css/pagebuilderctrl.css';
import CropSquare from '@mui/icons-material/CropSquare';
import Title from '@mui/icons-material/Title';
import { DataBrokerContext } from "./PageBuilderCtrl";
import { BAFetchData } from '../../customhooks/useBAFetch';
import { useGlobalState } from '../../globalstates/GState';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DialogCtrl from '../dialogctrl/dialogctrl';


function ReferenceListCtrl() {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, siteInfo, setSiteInfo, selectedRefernece, setSelectedReference } = useContext(DataBrokerContext);
    const [currentUser] = useGlobalState("userInfo");

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});         
    const [sectionList, setSectionList] = useState([]);
    const [templateCategoryList, setTemplateCategoryList] = useState([]);
    const [category, setCategory] = useState('');



    function handleDeleteConfirm(e, key) {
        e.preventDefault();
        e.stopPropagation();
        var msg = 'You are about to delete style reference. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); handleOnDelete(key) } })
        setOpenDialog(true);
    }

    function handleOnDelete(key) {
        var tmpSiteInfo = { ...siteInfo };
        delete tmpSiteInfo.styleList[key];
        setSiteInfo(tmpSiteInfo);
    }


    //useEffect(() => {
    //    beginSearch();
    //},[category])

    return (
        <>
            <div>
                <div className="fg-pb-widget-list" style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: "10px" }}>
                    {Object.keys(siteInfo.styleList).map((key, index) => (
                        <div key={"reference_" + index}>
                            <div style={{display:"grid",gridTemplateColumns:"1fr 40px",width:"100%",marginTop:"5px"}}>
                                <div className="fg-pg-template-list-item-text" style={{paddingLeft:"5px"}}>{key}</div>
                                <div style={{ textAlign: "right",cursor:"pointer" }} onClick={(e) => handleDeleteConfirm(e, key)}><DeleteForeverIcon></DeleteForeverIcon></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default ReferenceListCtrl;