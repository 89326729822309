import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { BAFetchData } from '../../customhooks/useBAFetch';
import LoaderSpinnerCtrl from '../loaderspinnerctrl/loaderspinnerctrl';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import helpers from '../../helpers/Helpers';
import Snackbar from '@mui/material/Snackbar';

function DataPackageAliasListCtrl({dataPackageList }) {

    const [dataPackageAliasListObj, setDataPackageAliasListObj] = useState(dataPackageList);
    const [level, setLevel] = useState('ALIAS');
    const [dataPackageNameList, setDataPackageNameList] = useState([]);
    const [tableNameList, setTableNameList] = useState([]);
    const [columnNameList, setColumnNameList] = useState([]);
    const [selectedItem, setSelectedItem] = useState({alias:"",packageName:"",tableName:"",columnName:""});

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});
    const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);


    function resetList() {
        setSelectedItem({ alias: "", packageName: "", tableName: "", columnName: "" });
        setLevel('ALIAS');
    }

    function handleOnAliasClick(e, aliasName, packageName) {
        var tmpSelectedItem = { ...selectedItem };
        tmpSelectedItem.alias = aliasName;
        setSelectedItem(tmpSelectedItem);

        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [
                {
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "friendlyName", "ConditionOperator": "EQUAL", "Value": packageName, "DataType": "String" }
                    ]
                }
            ]
        };

        BAFetchData({
            callName: "GetReturnPackageName",
            method: "GET",
            url: "automation/automationfriendlytriggerschema/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });  

    }

    function handleOnPackageNameClick(e, criteria) {
        var tmpSelectedItem = { ...selectedItem };
        tmpSelectedItem.packageName = criteria;
        setSelectedItem(tmpSelectedItem);

        BAFetchData({
            callName: "GetPackageNameList",
            method: "GET",
            url: "automation/automation/GetDataPackageMappingList?level=ALIAS&criteria=" + criteria,
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });        
    }

    function handleOnTableNameClick(e, dataPackageId, tableName) {
        var tmpSelectedItem = { ...selectedItem };
        tmpSelectedItem.tableName = tableName;
        setSelectedItem(tmpSelectedItem);

        BAFetchData({
            callName: "GetPackageColumns",
            method: "GET",
            url: "automation/automation/getpackagecolumnschema?datapackageid=" + dataPackageId,
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function handleOnColumnNameClick(e, columnName) {
        var variable = "~" + selectedItem.alias + "." + selectedItem.packageName.replaceAll(" ", "_") + "." + selectedItem.tableName + "." + columnName + "~";
        helpers.copyText(variable);

        var tmpSelectedItem = { ...selectedItem };
        tmpSelectedItem.columnName = columnName;
        setSelectedItem(tmpSelectedItem);
        setCopySnackbarOpen(true);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetReturnPackageName":
                setLevel('PACKAGES');
                setDataPackageNameList(data);

                //var list = [];
                //list.push(packageName);
                //setDataPackageNameList(list);
                //setLevel('PACKAGES');


                break;
            case "GetPackageNameList":
                setLevel('TABLES');
                setTableNameList(data);
                break;
            case "GetPackageColumns":
                setLevel('COLUMNS');
                setColumnNameList(data);
                break;

        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);
    }, []);

    useEffect(() => {
        setDataPackageAliasListObj(dataPackageList);
    }, [dataPackageList]);

    const handleCopySnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCopySnackbarOpen(false);
    };

    return (
        <>
            <div style={{ color: "#67757c", fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>Data Packages</div>
            <div style={{ textAlign: "center", fontSize: "14px", color: "#67757C", lineHeight: "110%" }}>Drill down to the column list to add variable in your content or in parameter fields.</div>
            <div style={{ display: "grid", gridTemplateColumns: "30px 1fr", marginTop: "10px" }}>
                <div>{level != 'ALIAS' ? <KeyboardBackspaceIcon style={{cursor:"pointer"}}  onClick={(e) => { resetList(); }} /> : null}</div>
                <div style={{ textAlign: "right" }}>{level}</div>
            </div>
            <div style={{ borderTop: "1px solid #67757c", marginTop: "0px", paddingTop: "10px" }}>
                {level == 'ALIAS' ?
                    <>
                    {
                        dataPackageAliasListObj.map((dataPackageAlias, index) => (
                            <div key={"datapackage_" + index} style={{ color: "#67757C", cursor: "pointer" }} onClick={(e) => { handleOnAliasClick(e, dataPackageAlias.alias,dataPackageAlias.friendlyTableName) }}><span><ChevronRightIcon /></span>{dataPackageAlias.alias}</div>
                        ))
                        }
                    </>
                    : null}
                {level == 'PACKAGES' ?
                    <>
                    {
                        dataPackageNameList.map((record, index) => (
                            <div key={"datapackage_" + index} style={{ color: "#67757C", cursor: "pointer" }} onClick={(e) => { handleOnPackageNameClick(e, record.returnDataPackage) }}><span><ChevronRightIcon /></span>{record.returnDataPackage}</div>
                        ))
                        }
                    </>
                    : null}
                {level == 'TABLES' ?
                    <>
                        {
                            tableNameList.map((tableInfo, index) => (
                                <div key={"datapackage_" + index} style={{ color: "#67757C", cursor: "pointer" }} onClick={(e) => { handleOnTableNameClick(e, tableInfo.dataPackageId, tableInfo.packageTableName) }}><span><ChevronRightIcon /></span>{tableInfo.packageTableName}</div>
                            ))
                        }
                    </>
                    : null}
                {level == 'COLUMNS' ?
                    <>
                        {selectedItem.columnName != null && selectedItem.columnName != '' ?
                            <div style={{ fontSize: "12px", color: "white", backgroundColor: "black", padding: "5px" }}>Variable copied to clipboard.Locate desired field and paste variable (Ctrl + V to on PC | Command + V on Mac).</div>
                            : null}
                        {
                            columnNameList.map((columnName, index) => (
                                ( columnName != selectedItem.columnName ?
                                    <div key={"datapackage_" + index} style={{ color: "#67757C", cursor: "pointer" }} onClick={(e) => { handleOnColumnNameClick(e, columnName) }}><span><ChevronRightIcon /></span>{columnName}</div>
                                    : 
                                    <div key={"datapackage_" + index} style={{ backgroundColor:"#26C6DA",color: "white", cursor: "pointer" }} onClick={(e) => { handleOnColumnNameClick(e, columnName) }}><span><ChevronRightIcon /></span>{columnName}</div>
                                    )
                            ))
                        }
                    </>
                    : null}
            </div>
            <Snackbar
                open={copySnackbarOpen}
                autoHideDuration={6000}
                onClose={handleCopySnackClose}
                message="Variable copied to clipboard" />
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default DataPackageAliasListCtrl;

