import React, { useContext, useState, useEffect } from 'react';

import { BsGearFill } from "react-icons/bs";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import helpers from '../../helpers/Helpers';
import { BarChart } from '@mui/x-charts/BarChart';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import GridReportColumnCtrl from './gridreportcomponents/GridReportColumnCtrl';
function GridReportCtrl({ props }) {

    const [groupBy, setGroupBy] = useState("referrerhost");
    const [columns, setColumns] = useState(props.columns);
    const [data, setData] = useState(props.data);
    const [leftColumns, setLeftColumns] = useState([]);
    const [mainColumns, setMainColumns] = useState([]);
    const [rightColumns, setRightColumns] = useState([]);
    const [dynamicWidth, setDynamicWidth] = useState('10px');

    const checkboxWidth = parseInt(40);
    const borderColor = "#DADCDE";
    function getPlacementWidth(placement) {
        var totalWidth = parseInt(0);
        if (placement == "left") {
            if (props.showCheckbox == true) {
                totalWidth += checkboxWidth;
            }
        }
        for (var ci = 0; ci < columns.length; ci++) {
            if (columns[ci].placement == placement) {
                totalWidth += columns[ci].width;
            }
        }
        return totalWidth;
    }

    function getParentGridTemplateColumns() {
        var gridTemplateColumns = "";
        var leftWidth = getPlacementWidth('left');
        var mainWidth = getPlacementWidth('main');
        var rightWidth = getPlacementWidth('right');
        if (leftWidth > 0) {
            gridTemplateColumns += leftWidth + 'px ';
        }
        gridTemplateColumns += '1fr ';

        if (rightWidth > 0) {
            gridTemplateColumns += rightWidth + 'px';
        }

        return gridTemplateColumns;
    }

    function getPlacementGridTemplateColumns(placement) {
        var placementGridTemplateColumns = "";

        if (placement == "left") {
            if (props.showCheckbox == true) {
                placementGridTemplateColumns += checkboxWidth + "px ";
            }
        }

        for (var ci = 0; ci < columns.length; ci++) {
            if (columns[ci].placement == placement) {
                placementGridTemplateColumns += columns[ci].width + "px ";
            }
        }
        return placementGridTemplateColumns;
    }

    function getColumnListByPlacement(placement) {
        var placementColumnList = [];
        for (var ci = 0; ci < columns.length; ci++) {
            if (columns[ci].placement == placement) {
                placementColumnList.push(columns[ci]);
            }
        }
        return placementColumnList;
    }

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    function resize() {
        var gridreport01 = document.getElementById(props.id + "_grid_report");
        var gridreportMainPlacement = document.getElementById(props.id + "_main");
        gridreportMainPlacement.style.display = "none";
        //await delay(1000);
        //delay(500).then(() => {});
        var gridreport01W = gridreport01.offsetWidth;
        var pLeftW = getPlacementWidth('left');
        var pRightW = getPlacementWidth('right');
        var finalW = gridreport01W - (pLeftW + pRightW);
        gridreportMainPlacement.style.display = "block";
        setDynamicWidth(finalW + 'px');
    }

    useEffect(() => {
        resize();
    }, [props.resizeId])

    useEffect(() => {
        resize();

    }, [props.columns, props.data])

    useEffect(() => {
        if (props.columns.length > 0) {
            setColumns(props.columns);
        }
    }, [props.columns]);

    useEffect(() => {
        if (props.data.rows.length > 0) {
            setData(props.data);
        }
    }, [props.data]);

    function handleOnGroupByChange(value) {
        setGroupBy(value);
        props.onGroupByChange(value);
    }
   

    return (
        <>
            <div id={props.id + "_grid_report"} style={{ border: "1px solid whitesmoke", padding: "5px", height: props.height + "px" }}>
                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px", gridGap: "5px",marginBottom:"10px" }}>
                    <div>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            size="small"
                            onChange={(e) => {  handleOnGroupByChange(e.target.value)}}
                            value={groupBy}
                        >
                            {props.groupByOptions.map((groupByItem, index) => (
                                <MenuItem value={groupByItem.value}>{groupByItem.text}</MenuItem>
                            ))}
                            </Select>
                    </div>
                    <div style={{ textAlign: "center", color: "#808080", fontSize: "14px", fontWeight: "500", paddingTop: "3px" }}>{props.reportName}</div>
                    <div><BsGearFill className="nav-link-icon noclick" style={{ color: "#c0c0c0",display:"none" }} /></div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: getParentGridTemplateColumns(), borderTop: "1px solid " + borderColor, borderLeft: "1px solid " + borderColor }}>         
                    {getPlacementWidth("left") > 0 ?
                        <div style={{ display: "grid", gridTemplateColumns: getPlacementGridTemplateColumns('left'), borderRight: "1px solid " + borderColor, borderBottom: "1px solid " + borderColor }}>                                   
                            {props.showCheckbox == true ?
                                <GridReportColumnCtrl props={{ internalCtrl: true, controlType: "checkbox", data: props.data  }} />
                                : null}
                            {getColumnListByPlacement('left').map((column, index) => (
                                <GridReportColumnCtrl props={{ internalCtrl: false, columnObj:column, data:props.data}} />
                            ))}
                        </div>
                        : null}
                    <div>
                        <div id={props.id + "_main"} style={{ width: dynamicWidth ,overflowX:"auto"}}>
                            <div style={{ width: getPlacementWidth('main') + 'px', display: "grid", gridTemplateColumns: getPlacementGridTemplateColumns('main'), borderLeft: "1px solid " + borderColor, borderRight: "1px solid " + borderColor, borderBottom: "1px solid " + borderColor }}>         
                            {getColumnListByPlacement('main').map((column, index) => (
                                <GridReportColumnCtrl props={{ internalCtrl: false, columnObj: column, data: props.data }} />
                            ))}
                            </div>
                        </div>
                    </div>
                    {getPlacementWidth("right") > 0 ?
                        <div style={{ display: "grid", gridTemplateColumns: getPlacementGridTemplateColumns('right'), borderLeft: "1px solid " + borderColor, borderRight: "1px solid " + borderColor, borderBottom: "1px solid" + borderColor }}>         
                            {getColumnListByPlacement('right').map((column, index) => (
                                <GridReportColumnCtrl props={{ internalCtrl: false, columnObj: column, data: props.data }} />
                            ))}
                        </div>
                        : null}

                </div>
                </div>
        </>
    );
}

export default GridReportCtrl;