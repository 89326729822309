import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import { BAFetchData } from '../../../customhooks/useBAFetch';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import DataPackageAliasListCtrl from '../DataPackageAliasListCtrl';
import Autocomplete from "@mui/material/Autocomplete";

function AOWaitActionCtrl({ objectSettings, onChange, dataPackageList }) {

    const [objectSettingsObj, setObjectSettingsObj] = useState(objectSettings);
    const [timezoneNameList, setTimezoneNameList] = useState([]);
    const [countryList, setCountryList] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});

    function onValueChange(propertyName, value) {
        var tmpObjectSettingsObj = { ...objectSettingsObj };
        tmpObjectSettingsObj.settings[propertyName] = value;
        if (propertyName == 'countryCode') {
            getTimezoneList(value);
        } else if (propertyName == 'timezoneName') {
            updateTimezoneShortName(tmpObjectSettingsObj);
        } else if (propertyName == 'waitType') {
            if (value == 'For') {
                tmpObjectSettingsObj.settings['waitForNumber'] = 5;
                tmpObjectSettingsObj.settings['waitForInterval'] = 'Minute';
            } else if (value = 'Until') {
                tmpObjectSettingsObj.settings['waitUntil'] = 'ExactDate';
            }
        }
        updateSummary(tmpObjectSettingsObj);
        setObjectSettingsObj(tmpObjectSettingsObj);
        onChange(tmpObjectSettingsObj);
    }

    function updateTimezoneShortName(tmpObjectSettingsObj) {
        tmpObjectSettingsObj.settings.shortName = '';
        for (var i = 0; i < timezoneNameList.length; i++) {
            if (timezoneNameList[i].timezoneName == tmpObjectSettingsObj.settings.timezoneName) {
                tmpObjectSettingsObj.settings.timezoneShortName = timezoneNameList[i].shortName;
                break;
            }
        }
    }

    function updateSummary(tmpObjectSettingsObj) {
        var summary = 'Wait';
        if (tmpObjectSettingsObj.settings.waitType == 'For') {
            var plural = "";
            if (tmpObjectSettingsObj.settings.waitForNumber > 1) {
                plural = "s";
            }
            summary += ' ' + tmpObjectSettingsObj.settings.waitForNumber + ' ' + tmpObjectSettingsObj.settings.waitForInterval.toLowerCase() + plural;
        } else {
            switch (tmpObjectSettingsObj.settings.waitUntil) {
                case "ExactDate":
                    summary += ' until ' + dayjs(tmpObjectSettingsObj.settings.exactDate).format('MM/DD/YYYY');
                    break;
                case "DayOfWeek":
                    summary += ' until ' + tmpObjectSettingsObj.settings.dayOfWeek;
                    break;
                case "Weekend":
                    summary += ' until next weekend';
                    break;
                case "Weekday":
                    summary += ' until next weekday';
                    break;
                case "DayOfMonth":
                    summary += ' until ' + tmpObjectSettingsObj.settings.dayOfMonth + " day of the month";
                    break;
                case "MomentBeforeDateTime":
                    var plural = "";
                    if (tmpObjectSettingsObj.settings.waitUntilNumber > 1) {
                        plural = "s";
                    }
                    summary += ' until ' + tmpObjectSettingsObj.settings.waitUntilNumber;
                    if (tmpObjectSettingsObj.settings.waitUntilInterval != null) {
                        summary += " " + tmpObjectSettingsObj.settings.waitUntilInterval.toLowerCase() + plural + " before " + dayjs(tmpObjectSettingsObj.settings.waitUntilDateTime).format('MM/DD/YYYY h:mm A');
                    }
                    break;
                case "MomentAfterDateTime":
                    var plural = "";
                    if (tmpObjectSettingsObj.settings.waitUntilNumber > 1) {
                        plural = "s";
                    }
                    summary += ' until ' + tmpObjectSettingsObj.settings.waitUntilNumber;
                    if (tmpObjectSettingsObj.settings.waitUntilInterval != null) {
                        summary += " " + tmpObjectSettingsObj.settings.waitUntilInterval.toLowerCase() + plural + " after " + dayjs(tmpObjectSettingsObj.settings.waitUntilDateTime).format('MM/DD/YYYY h:mm A');
                    }
                    break;
            }           
        }
        //if (tmpObjectSettingsObj.settings.run == "Immediately") {
        //    summary += " and run immediately.";
        //} else {
        if (tmpObjectSettingsObj.settings.run == "RunAt") {
            summary += " and run at " + tmpObjectSettingsObj.settings.runTime + " (" + tmpObjectSettingsObj.settings.timezoneShortName + ").";
        }
        tmpObjectSettingsObj.settings.summary = summary;
    }

    function newSetting() {
        return { "waitType": "For", "waitForNumber": 5, "waitForInterval": "Minute", "waitUntil": null, "exactDate": null, "dayOfWeek": null, "dayOfMonth": null, "waitUntilNumber": null, "waitUntilInterval": null, "waitUntilDateTime": null, "run": "Immediately", "runTime": "12:00 PM", "countryCode": null, "timezoneName": Intl.DateTimeFormat().resolvedOptions().timeZone, "timezoneShortName": null, "summary": "" };
    }

    function getCountryList() {
        BAFetchData({
            callName: "GetCountryList",
            method: "GET",
            url: "location/country/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getTimezoneList(countryCode) {
        var filtering = {};
        //if (countryCode != null) {
        //    filtering = {
        //        "LogicalOperator": "and", "ConditionGroups": [{
        //            "LogicalOperator": "and", "Conditions": [
        //                { "Name": "countrycode", "ConditionOperator": "EQUAL", "Value": countryCode, "DataType": "String" }
        //            ]
        //        }]
        //    };
        //}

        BAFetchData({
            callName: "GetTimezoneList",
            method: "GET",
            url: "time/timezone/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case 'GetTimezoneList':
                setTimezoneNameList(data);
                break;
            case 'GetCountryList':
                setCountryList(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        getCountryList();
        setShowLoader(false);
    }, []);

    useEffect(() => {
        if (!objectSettings.settings || Object.keys(objectSettings.settings).length === 0) {
            objectSettings.settings = newSetting();
            updateSummary(objectSettings);
        } else {
            if (objectSettings.settings.countryCode != null) {
                getTimezoneList(objectSettings.settings.countryCode);    
            }
        }
        
        setObjectSettingsObj(objectSettings);
    }, [objectSettings]);

    return (
        <>
            <div style={{ display: "grid", gridTemplateColumns: "250px 1fr", gridGap: "40px" }}>
                <div style={{ border: "1px solid #c0c0c0", padding: "10px", borderRadius: "12px" }}>
                    <DataPackageAliasListCtrl dataPackageList={dataPackageList} />
                </div>
                <div>
                <div style={{ display: "grid", gridTemplateColumns: "125px 1fr", gridGap: "10px" }}>
                    <div style={{ paddingTop: "10px" }}>Wait Type</div>
                    <div>
                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                onChange={(e) => { onValueChange('waitType', e.target.value); }}
                                value={objectSettingsObj.settings.waitType || ''}
                            >
                                <MenuItem value="For">Wait for a specific duration of time</MenuItem>
                                <MenuItem value="Until">Wait until a specific date</MenuItem>
                            </Select>
                        </FormControl>                
                    </div>
                </div>
                {objectSettingsObj.settings.waitType == "For" ?
                    <div style={{ display: "grid", gridTemplateColumns: "125px 150px 1fr", gridGap: "10px" }}>
                        <div style={{ paddingTop: "10px" }}>Wait for</div>
                        <div>
                            <TextField fullWidth autoComplete='off' type="number" variant="outlined" size="small" InputProps={{ inputProps: { min: 1 } }} value={objectSettingsObj.settings.waitForNumber || ''} onChange={(e) => { onValueChange('waitForNumber', e.target.value); }} />
                        </div>
                        <div style={{ paddingTop: "8px" }}>
                            <FormControl variant="standard" fullWidth size="large" sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    onChange={(e) => { onValueChange('waitForInterval', e.target.value); }}
                                    value={objectSettingsObj.settings.waitForInterval || ''}
                                >
                                    <MenuItem value="Minute">Minute(s)</MenuItem>
                                    <MenuItem value="Hour">Hour(s)</MenuItem>
                                    <MenuItem value="Day">Day(s)</MenuItem>
                                    <MenuItem value="Month">Month(s)</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    :
                    <div style={{ display: "grid", gridTemplateColumns: "125px 1fr", gridGap: "10px" }}>
                        <div style={{ paddingTop: "10px" }}>Date Type</div>
                        <div style={{ paddingTop: "8px" }}>
                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    onChange={(e) => { onValueChange('waitUntil', e.target.value); }}
                                    value={objectSettingsObj.settings.waitUntil || ''}
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    <MenuItem value="ExactDate">Exact date</MenuItem>
                                    <MenuItem value="DayOfWeek">Day of week</MenuItem>
                                    <MenuItem value="Weekend">Weekend</MenuItem>
                                    <MenuItem value="Weekday">Weekday</MenuItem>
                                    <MenuItem value="DayOfMonth">Day of month</MenuItem>
                                    <MenuItem value="MomentBeforeDateTime">A moment before specific date/time</MenuItem>
                                    <MenuItem value="MomentAfterDateTime">A moment after specific date/time</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                }
                {objectSettingsObj.settings.waitType == "Until" ?
                <>
                {objectSettingsObj.settings.waitUntil == "ExactDate" ?
                    <div style={{ display: "grid", gridTemplateColumns: "125px 1fr", gridGap: "10px", marginBottom:"10px" }}>
                        <div style={{ paddingTop: "10px" }}>Enter Date</div>
                         <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer components={['DatePicker']} style={{ marginBottom: "10px" }}>
                                            <DesktopDatePicker
                                                variant="Standard"
                                                slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                                value={objectSettings.settings.exactDate != null ? dayjs(objectSettings.settings.exactDate) : null}
                                                onChange={(newValue) => { onValueChange('exactDate', newValue); }} />                                            
                                        </DemoContainer>
                                    </LocalizationProvider>
                        </div>
                    </div>
                    : null}
                {objectSettingsObj.settings.waitUntil == "DayOfWeek" ?
                    <div style={{ display: "grid", gridTemplateColumns: "125px 1fr", gridGap: "10px" }}>
                        <div style={{ paddingTop: "10px" }}>Select Day</div>
                        <div style={{ paddingTop: "8px" }}>
                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    onChange={(e) => { onValueChange('dayOfWeek', e.target.value); }}
                                    value={objectSettingsObj.settings.dayOfWeek || ''}  
                                >
                                    <MenuItem value="Sunday">Sunday</MenuItem>
                                    <MenuItem value="Monday">Monday</MenuItem>
                                    <MenuItem value="Tuesday">Tuesday</MenuItem>
                                    <MenuItem value="Wednesday">Wednesday</MenuItem>
                                    <MenuItem value="Thursday">Thursday</MenuItem>
                                    <MenuItem value="Friday">Friday</MenuItem>
                                    <MenuItem value="Saturday">Saturday</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    : null}
                {objectSettingsObj.settings.waitUntil == "DayOfMonth" ?
                    <div style={{ display: "grid", gridTemplateColumns: "125px 1fr", gridGap: "10px", marginBottom:"10px" }}>
                        <div style={{ paddingTop: "10px" }}>Day of Month</div>
                        <div>
                                    <TextField fullWidth autoComplete='off' type="number" variant="outlined" size="small" InputProps={{ inputProps: { min: 1,max:31 } }} value={objectSettingsObj.settings.dayOfMonth || ''} onChange={(e) => { onValueChange('dayOfMonth', e.target.value); }} />
                        </div>
                    </div>
                    : null}
                {(objectSettingsObj.settings.waitUntil == "MomentBeforeDateTime" || objectSettingsObj.settings.waitUntil == "MomentAfterDateTime") ?
                    <div style={{ display: "grid", gridTemplateColumns: "125px 1fr", gridGap: "10px" }}>
                        <div style={{ paddingTop: "10px" }}>Wait Until</div>
                        <div style={{ display: "grid", gridTemplateColumns: "80px 125px 80px 1fr", gridGap: "10px" }}>
                            <div>
                                        <TextField fullWidth autoComplete='off' type="number" variant="outlined" size="small" InputProps={{ inputProps: { min: 1 } }} value={objectSettingsObj.settings.waitUntilNumber || ''} onChange={(e) => { onValueChange('waitUntilNumber', e.target.value); }} />
                            </div>
                            <div style={{ paddingTop: "8px" }}>
                                <FormControl variant="standard" fullWidth size="large" sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => { onValueChange('waitUntilInterval', e.target.value); }}
                                        value={objectSettingsObj.settings.waitUntilInterval || ''}  
                                    >
                                        <MenuItem value="Minute">Minute(s)</MenuItem>
                                        <MenuItem value="Hour">Hour(s)</MenuItem>
                                        <MenuItem value="Day">Day(s)</MenuItem>
                                        <MenuItem value="Month">Month(s)</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ paddingTop: "13px", textAlign: "center" }}>before...</div>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDateTimePicker variant="Standard" slotProps={{ textField: { fullWidth: true,size:"small" } }}  value={objectSettingsObj.settings.waitUntilDateTime != null ? dayjs(objectSettingsObj.settings.waitUntilDateTime) : null} onChange={(newValue) => { onValueChange('waitUntilDateTime', newValue); }} />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                            : null}
                    </>
                    : null}
                <div style={{ display: "grid", gridTemplateColumns: "125px 1fr", gridGap: "10px"  }}>
                    <div style={{ paddingTop: "10px" }}>Run</div>
                    <div>
                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                onChange={(e) => { onValueChange('run', e.target.value); }}
                                value={objectSettingsObj.settings.run || ''}
                            >
                                <MenuItem value="Immediately">Immediately</MenuItem>
                                <MenuItem value="AtThisTime">At a specific time</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {objectSettingsObj.settings.run == "AtThisTime" ?
                    <div style={{ display: "grid", gridTemplateColumns: "125px 1fr", gridGap: "10px",marginBottom:"10px" }}>
                        <div style={{ paddingTop: "10px" }}>Run Time</div>
                        <div>
                            <TextField fullWidth autoComplete='off' variant="outlined" size="small" value={objectSettingsObj.settings.runTime || ''} onChange={(e) => { onValueChange('runTime', e.target.value); }} />
                        </div>
                    </div>
                    : null}
                <div style={{ display: "none", gridTemplateColumns: "125px 1fr", gridGap: "10px" }}>
                    <div style={{ paddingTop: "10px" }}>Country Code</div>
                    <div>
                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                onChange={(e) => { onValueChange('countryCode', e.target.value); }}
                                value={objectSettingsObj.settings.countryCode || ''}  
                            >
                                {countryList.map((country, ci) => (
                                    <MenuItem key={"country_" + ci} value={country.countryCode}>{country.countryName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div style={{ display: "none", gridTemplateColumns: "125px 1fr", gridGap: "10px" }}>
                    <div style={{ paddingTop: "10px" }}>Timezone</div>
                    <div>
                        {/*<FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>*/}
                        {/*    <Select*/}
                        {/*        labelId="demo-simple-select-standard-label"*/}
                        {/*        id="demo-simple-select-standard"*/}
                        {/*        onChange={(e) => { onValueChange('timezoneName', e.target.value); }}*/}
                        {/*        value={objectSettingsObj.settings.timezoneName || ''}  */}
                        {/*    >*/}
                        {/*        <MenuItem value=""><em>None</em></MenuItem>*/}
                        {/*        {timezoneNameList.map((timezone, tzi) => (*/}
                        {/*            <MenuItem key={"tz_" + tzi} value={timezone.timezoneName}>{timezone.timezoneName}</MenuItem>*/}
                        {/*        ))}*/}
                        {/*    </Select>*/}
                        {/*</FormControl>*/}
                            <Autocomplete
                                size="small"
                                options={timezoneNameList}
                                onChange={(event, newValue) => { onValueChange('timezoneName', newValue); }}
                                getOptionLabel={(option) => `${option}`}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => <TextField {...params} label="Select Timezone" />}
                                isOptionEqualToValue={(option, value) => option === value}
                                value={objectSettingsObj.settings.timezoneName}
                            />

                    </div>
                </div>
                <div style={{backgroundColor:"whitesmoke","padding":"10px"}}>{objectSettingsObj.settings.summary}</div>
                </div>
            </div>

            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default AOWaitActionCtrl;
