import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';



function ApiFacebookMoreSetupCtrl({ integrationId, iSchemaId, translationData, updateId }) {

    const [currentUser] = useGlobalState("userInfo");
    const wlInfo = JSON.parse(localStorage.getItem("wl-info"));
    const [adAccountList, setAdAccountList] = useState([]);
    const [errorList, setErrorList] = useState({});
    const [adAccountSwapInfo, setAdAccountSwapInfo] = useState({ 'oldId': null, 'newId': null });
    const [defaultIntFbAdAccountId, setDefaultIntFbAdAccountId] = useState(null);

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    function getFacebookAdAccount() {
        var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "integrationId", "ConditionOperator": "EQUAL", "Value": integrationId, "DataType": "Number" }] }] };
        BAFetchData({
            callName: "GetFacebookAdAccount",
            method: "GET",
            url: "integration/facebook/adaccount/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onFbAdAccountChange(intFbAdAccountId) {
        var tmpAdAccountSwapInfo = { ...adAccountSwapInfo }
        tmpAdAccountSwapInfo.newId = intFbAdAccountId;
        setAdAccountSwapInfo(tmpAdAccountSwapInfo);
        setDefaultIntFbAdAccountId(intFbAdAccountId);
    }


    function defaultSettings(data) {
        for (var i = 0; i < data.length; i++) {
            if (data[i].active == true) {
                setDefaultIntFbAdAccountId(data[i].intFbAdAccountId);
                break;
            }
        }
    }

    function saveData() {
        if (adAccountSwapInfo.newId != null) {
            BAFetchData({
                callName: "SaveData", method: "GET", url: "integration/facebook/adaccount/makeactive?intfbadaccountid=" + adAccountSwapInfo.newId,
                token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetFacebookAdAccount":
                defaultSettings(data)
                setAdAccountList(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        alert(error);
        //var msg = error;
        //setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        //setOpenDialog(true);
    }

    useEffect(() => {
        getFacebookAdAccount();
    }, []);

    useEffect(() => {
        saveData();
    }, [adAccountSwapInfo]);
  

    return (
        <>
            <div>
                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                    <InputLabel id="demo-simple-select-standard-label">Ad Account</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Ad Account"
                        onChange={(e) => { onFbAdAccountChange(e.target.value); }}
                        value={defaultIntFbAdAccountId || ''}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {adAccountList.map((adAccount, index) => (
                            <MenuItem value={adAccount.intFbAdAccountId}>{adAccount.name} -- [{adAccount.fbAdAccountId}]</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </>
    );
}

export default ApiFacebookMoreSetupCtrl;
