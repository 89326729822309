import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import Button from '@mui/material/Button';
import useBAFetch, { BAFetchData } from '../../customhooks/useBAFetch';
import WarningIcon from '@mui/icons-material/Warning';
import PaymentIcon from '@mui/icons-material/Payment';
import DoneIcon from '@mui/icons-material/Done';
import DialogPlusCtrl from '../dialogctrl/dialogplusctrl';
import LoaderSpinnerCtrl from '../loaderspinnerctrl/loaderspinnerctrl';
import helpers from '../../helpers/Helpers';
import dayjs from 'dayjs';
import PaymentInfoCtrl from '../formcontrols/payment/PaymentInfoCtrl';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function ViewInvoiceCtrl({ baToken, transactionToken }) {
    const [formData, setFormData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});
    const [paymentData, setPaymentData] = useState({});
    const [errorList, setErrorList] = useState([]);
    const paymentHeaderId = 0;
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: baToken
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    function loadTransaction() {
        BAFetchData({
            callName: "GetMainRecord",
            method: "GET",
            url: "accounting/transactionheader/getinvoicestatusinfobytoken?batoken=" + baToken + "&transactiontoken=" + transactionToken,
            token: baToken,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function loadPaymentData() {
        BAFetchData({
            callName: "GetPaymentMainRecord",
            method: "GET",
            url: "accounting/transactionheader/getpaymentrecord?headerid=" + paymentHeaderId,
            token: baToken,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                setFormData(data);                
                break;
            case "GetPaymentMainRecord":
                addPaymentDefaults(data);
                setPaymentData(data);
                setShowLoader(false);
                break;
            case "SaveForm":
            case 'SaveNewForm':
                //setPrimaryKeyValue(data.headerId);
                data.creditCardNumber = paymentData.creditCardNumber;
                data.creditCardCvv =  paymentData.creditCardCvv;
                //data.creditCardExpireDate = "";
                data.creditCardExpireMonth =  paymentData.creditCardExpireMonth;
                data.creditCardExpireYear = paymentData.creditCardExpireYear;
                setPaymentData(data);
                if (packageObj.postTransaction == true) {
                    postTransaction(data, data.headerId);
                }
                //if (packageObj.sendEmail == true && packageObj.postTransaction == false) {
                //    emailTransaction(data.headerId);
                //}
                //if (packageObj.postTransaction == false && packageObj.sendEmail == false) {
                //    var msg = 'Form has been saved.';
                //    if (dialogType == undefined) {
                //        setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/payment/' + data.headerId); refreshPage(); } })
                //    } else {
                //        setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, ignoreBGClick: true, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); setAcceptAfterUpdate(true); handlerReturnValue(data.headerId); } })
                //    }
                //    setOpenDialog(true);
                //}
                break;
            case "PostTransaction":
                emailTransaction(packageObj);
                //var msg = 'Payment has been processed and posted.';
                //setGenericDialogSchema({ title: 'Record Posted', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); refreshPage(); } })
                //setOpenDialog(true);
                break;
            case "EmailTransaction":
                var msg = 'Confirmation has been sent to your email.';
                setGenericDialogSchema({ title: 'Payment Received', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); refreshPage(); } })
                setOpenDialog(true);
                break;
        }
    }

    function refreshPage() {
        window.location = window.location.href;
    }

    function onAPIError(error, callName) {
        setShowLoader(false);
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function postTransaction(tmpFormData, headerId) {
        BAFetchData({
            callName: "PostTransaction", method: "PUT", url: "accounting/transactionheader/posttransaction",
            token: baToken, body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError,
            packageObj: headerId
        });
    }

    function emailTransaction(headerId) {
        BAFetchData({
            callName: "EmailTransaction", method: "GET", url: "accounting/transactionheader/emailtransaction?headerid=" + headerId,
            token: baToken, body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addPaymentDefaults(data) {
        data.transactionType = 'payment';
        data.status = "Draft";
        data.headerAmt = parseFloat(0);
        data.transactionDate = dayjs();
        data.shippingAmt = parseFloat(0);
        data.discountAmt = parseFloat(0);
        data.taxPercent = parseFloat(0);
        data.customerId = formData.customerId;
        data.customerEmail = formData.customerEmail;
        data.customerName = formData.customerName;
        var newLineItem = newLineItemShell();

        newLineItem.invoiceId = formData.headerId;
        newLineItem.invoiceDate = formData.dueDate;
        newLineItem.invoiceOriginalAmt = formData.headerAmt;
        newLineItem.openBalance = formData.openBalance;
        newLineItem.summary = "Invoice #: " + formData.headerId;
        data.details.push(newLineItem);
    }

    function newLineItemShell() {
        return {
            "detailId": 0, "headerId": 0, "baid": 0, "productId": null,
            "variantId": null, "productName": null, "variantSummary": null, "summary": null,
            "price": 0, "qty": 1, "taxable": false, "detailAmount": 0,
            "invoiceId": null, "invoiceDate": null, "invoiceOriginalAmt": 0, "paymentPlan": null, "posted": false,
            "datePosted": null, "cogsAmount": 0, "dateCreated": null,
            "createdBy": null, "dateModified": null, "modifiedBy": null,
            "recurringCharge": false, "proratedAmt": 0, "recurType": null, "payOption": null,
            "billInstallment": 0, "downpayment": 0, "billInterval": 0, "billPeriod": null,
            "billLength": 0, "freeTrialInterval": 0, "freeTrialPeriod": null,
            "syncDay": null, "prorate": false, "nextBillDate": null, "shippingCost": 0,
            "openBalance": 0,
            "internalState": "added"
        }
    }

    function addJustInTimeData(paymentData) {
        paymentData.dateCreated = new Date().toISOString();
    }

    function onPayNowBtnClick() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(paymentData);

        //if (helpers.isBasicFormValidationValid(columnSchemas, paymentData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm(true, true);
            }
        //}  
    }

    function saveForm(postTransaction, sendEmail) {
        var additionalActions = { "postTransaction": postTransaction, "sendEmail": sendEmail };
        var tmpFormData = { ...paymentData };
        //tmpFormData.forecastSummary = JSON.stringify(tmpFormData.forecastSummary);
        //tmpFormData.autoSurvey = JSON.stringify(tmpFormData.autoSurvey);
        BAFetchData({
            callName: "SaveNewForm", method: "POST", url: "accounting/transactionheader/addpayment",
            token: baToken, body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError,
            packageObj: additionalActions
        });
    }


    function onPayOptionChange(value) {
        var tmpPaymentData = { ...paymentData };
        switch (value) {
            case "entire balance":
                tmpPaymentData.headerAmt = formData.openBalance;
                tmpPaymentData.details[0].detailAmount = formData.openBalance;
                break;
            case "other amount":
                tmpPaymentData.headerAmt = 0;
                tmpPaymentData.details[0].detailAmount = 0;
                break;
        }
        setPaymentData(tmpPaymentData);
    }


    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        if (paymentData.customerId == null || paymentData.customerId == '') {
            isValid = false;
            tempErrorList['customerId'] = "Is required.";
        }
        if (paymentData.headerAmt == '0' || paymentData.headerAmt == null) {
            isValid = false;
            tempErrorList['headerAmt'] = "Should be greater than zero.";
        }

        if (helpers.isListEmpty(paymentData.details) == true) {
            isValid = false;
            tempErrorList['details'] = "At least one invoice is required.";
        }
        if (paymentData.paymentMethod == null || paymentData.paymentMethod == '') {
            isValid = false;
            tempErrorList['paymentMethod'] = "Is required.";
        }
        if (paymentData.paymentMethod != null && paymentData.paymentMethod != '') {
            if (paymentData.paymentMethod == 'Check') {
                if (paymentData.paymentRef == null || paymentData.paymentRef == '') {
                    isValid = false;
                    tempErrorList['paymentRef'] = "Is required.";
                }
            } else if (paymentData.paymentMethod == 'Card') {
                if (paymentData.cardholderName == null || paymentData.cardholderName == '') {
                    isValid = false;
                    tempErrorList['cardholderName'] = "Is required.";
                }
                if (paymentData.creditCardNumber == null || paymentData.creditCardNumber == '') {
                    isValid = false;
                    tempErrorList['creditCardNumber'] = "Is required.";
                }
                if (paymentData.creditCardExpireMonth == null || paymentData.creditCardExpireMonth == '') {
                    isValid = false;
                    tempErrorList['creditCardExpireMonth'] = "Is required.";
                }
                if (paymentData.creditCardExpireYear == null || paymentData.creditCardExpireYear == '') {
                    isValid = false;
                    tempErrorList['creditCardExpireYear'] = "Is required.";
                }
                if (paymentData.creditCardExpireMonth != null && paymentData.creditCardExpireMonth != "" && paymentData.creditCardExpireYear != null && paymentData.creditCardExpireYear != "") {
                    var month = paymentData.creditCardExpireMonth;
                    var year = paymentData.creditCardExpireYear;
                    var expireFullDate = dayjs(month + '/01/' + year).add(1, 'month');
                    if (expireFullDate < dayjs()) {
                        isValid = false;
                        tempErrorList['creditCardExpireDate'] = "Invalid expiration date.";
                    }
                }
                if (paymentData.creditCardCvv == null || paymentData.creditCardCvv == '') {
                    isValid = false;
                    tempErrorList['creditCardCvv'] = "Is required.";
                } else if (helpers.isNumeric(paymentData.creditCardCvv) == false) {
                    isValid = false;
                    tempErrorList['creditCardCvv'] = "Must be numeric only.";
                }
                if (paymentData.billingZip == null || paymentData.billingZip == '') {
                    isValid = false;
                    tempErrorList['billingZip'] = "Is required.";
                }
            }
        }
        setErrorList(tempErrorList);
        return isValid;
    }

    function handleOnPayAmountChanged(paymentAmt) {
        var tmpPaymentData = { ...paymentData };
        tmpPaymentData.details[0].detailAmount = paymentAmt;
        setPaymentData(tmpPaymentData);
    }

    useEffect(() => {
        loadTransaction();
    }, []);

    useEffect(() => {
        if (JSON.stringify(formData) != '{}') {
            loadPaymentData();
        }
    }, [formData]);


    return (
        <>
            {formData.openBalance > 0 ?
                <>
                    {formData.daysLate == 0 ?
                        <div style={{ display: "grid", gridTemplateColumns: "50px 1fr 125px", gridGap: "10px", maxWidth: "900px", backgroundColor: "white", padding: "20px", marginBottom: "20px", borderRadius: "12px" }}>
                            <div><PaymentIcon style={{ fontSize: "40px" }} /></div>
                            <div style={{ paddingTop: "5px" }}>Conviently make your payment. Click on the pay now button.</div>
                            <div style={{ textAlign: "right" }}>
                                <Button variant="outlined" style={{ backgroundColor: "black", color: "white", border: "1px solid black" }} onClick={() => onPayNowBtnClick()}>{getTranslation('contact_search_page', 'search_grid', 'Pay Now')}</Button>
                            </div>
                        </div>
                        : null}
                    {formData.daysLate > 0 ?
                        <div style={{ display: "grid", gridTemplateColumns: "50px 1fr 125px", gridGap: "10px", maxWidth: "900px", backgroundColor: "#EB5454", color: "white", padding: "20px", marginBottom: "20px", borderRadius: "12px" }}>
                            <div><WarningIcon style={{ fontSize: "40px", color: "white" }} /></div>
                            <div style={{ paddingTop: "5px" }}>Pay overdue invoice balance of ${helpers.formatNumber(formData.openBalance, 2) || '0'}.</div>
                            <div style={{ textAlign: "right" }}>
                                <Button variant="outlined" style={{ backgroundColor: "white", color: "#EB5454", border: "1px solid white" }} onClick={() => onPayNowBtnClick()}>{getTranslation('contact_search_page', 'search_grid', 'Pay Now')}</Button>
                            </div>
                        </div>
                        : null}
                </>
            : null}
            {formData.openBalance == 0 ?
                <div style={{ display: "grid", gridTemplateColumns: "50px 1fr", gridGap: "10px", maxWidth: "900px", backgroundColor: "#198754", color:"white", padding: "20px", marginBottom: "20px", borderRadius: "12px" }}>
                    <div><DoneIcon style={{ fontSize: "40px" }} /></div>
                    <div style={{ paddingTop: "5px" }}>Thank you for your payment</div>
                </div>
                : null}
            <div style={{ backgroundColor: "whitesmoke", maxWidth: "900px" }}>
                <div style={{ backgroundColor: "white", padding: "40px", borderTop: "10px solid black" }}>
                    <div style={{ textAlign: "center" }}>
                        <div>
                            <img src="./images/fg_email_logo_200x30.png" style={{ maxWidth: "200px" }} />
                        </div>
                        <div>Funnel Goal</div>
                        <div>3824 Brownes Ferry Rd., Charlotte, NC 28269</div>
                        <div>704-498-2901</div>
                        <div>support@funnelgoal.io</div>
                        <div style={{ fontSize: "44px", fontWeight: "bold" }}>Invoice</div>
                    </div>
                    <div style={{ textAlign: "center", padding: "20px 10px 20px 10px", borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                        <div style={{ fontWeight: "500" }}>Bill To</div>
                        <div>{formData.customerName}</div>
                        <div>{formData.customerEmail}</div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: "10px", width: "100%", padding: "20px 10px 20px 10px" }}>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ fontSize: "12px", color: "grey" }}>Invoice #</div>
                            <div>{formData.headerId}</div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ fontSize: "12px", color: "grey" }}>Invoice Date</div>
                            <div>{dayjs(formData.transactionDate).format('MM/DD/YYYY')}</div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ fontSize: "12px", color: "grey" }}>Due Date</div>
                            <div>{dayjs(formData.dueDate).format('MM/DD/YYYY')}</div>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "30px 50px 1fr 30px 75px 30px 75px", gridGap: "10px", width: "100%", padding: "0px 10px 0px 10px" }}>
                        <div style={{ textAlign: "center", fontSize: "12px", color: "grey" }}>#</div>
                        <div style={{ textAlign: "center", fontSize: "12px", color: "grey" }}>Img</div>
                        <div style={{ fontSize: "12px", color: "grey" }}>Item & Description</div>
                        <div style={{ textAlign: "center", fontSize: "12px", color: "grey" }}>Tax</div>
                        <div style={{ textAlign: "right", fontSize: "12px", color: "grey" }}>Unit Price</div>
                        <div style={{ textAlign: "center", fontSize: "12px", color: "grey" }}>Qty</div>
                        <div style={{ textAlign: "right", fontSize: "12px", color: "grey" }}>Total</div>
                    </div>
                    {formData != null ?
                        <>
                            {formData.details != null ?
                                <>
                                    {formData.details.map((item, index) => (
                                        <div style={{ display: "grid", gridTemplateColumns: "30px 50px 1fr 30px 75px 30px 75px", gridGap: "10px", width: "100%", padding: "10px 10px 10px 10px", borderBottom: "1px solid #c0c0c0" }}>
                                            <div style={{ textAlign: "center", fontSize: "16px" }}>{index + 1}</div>
                                            <div style={{ textAlign: "center", fontSize: "16px" }}>
                                                {item.thumbnail != null ?
                                                    <img src={item.thumbnail} style={{ width: "50px", height: "50px", border: "1px solid #c0c0c0" }} />
                                                    : null}
                                            </div>
                                            <div style={{ fontSize: "16px" }}>
                                                <div style={{ fontWeight: "500" }}>{item.productName}</div>
                                                <div style={{ fontSize: "13px" }}>{item.variantSummary}</div>
                                                <div style={{ fontSize: "13px", fontStyle: "italic" }}>{item.summary}</div>
                                            </div>
                                            <div style={{ textAlign: "center", fontSize: "16px" }}>{item.taxable}</div>
                                            <div style={{ textAlign: "right", fontSize: "16px" }}>{helpers.formatCurrencyGlobal(item.price, 2) || '0'}</div>

                                            <div style={{ textAlign: "center", fontSize: "16px" }}>{helpers.formatNumber(item.qty, 2) || '0'}</div>
                                            <div style={{ textAlign: "right", fontSize: "16px" }}>{helpers.formatCurrencyGlobal(item.detailAmount, 2) || '0'}</div>
                                        </div>
                                    ))}
                                </>
                                : null}
                        </>
                        : null}
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 75px", gridGap: "10px", width: "100%", padding: "40px 10px 0px 10px" }}>
                        <div style={{ textAlign: "right" }}>Sub Total</div>
                        <div style={{ textAlign: "right" }}>{helpers.formatCurrencyGlobal(formData.netAmt, 2) || '0'}</div>
                        <div style={{ textAlign: "right" }}>Discount</div>
                        <div style={{ textAlign: "right" }}>{helpers.formatCurrencyGlobal(formData.discountAmt, 2) || '0'}</div>
                        <div style={{ textAlign: "right" }}>Tax</div>
                        <div style={{ textAlign: "right" }}>{helpers.formatCurrencyGlobal(formData.taxAmt, 2) || '0'}</div>
                        <div style={{ textAlign: "right" }}>Shipping</div>
                        <div style={{ textAlign: "right" }}>{helpers.formatCurrencyGlobal(formData.shippingAmt, 2) || '0'}</div>
                        <div style={{ textAlign: "right", fontWeight: "500" }}>Invoice Total</div>
                        <div style={{ textAlign: "right", fontWeight: "500" }}>{helpers.formatCurrencyGlobal(formData.headerAmt, 2) || '0'}</div>
                        <div style={{ textAlign: "right", fontWeight: "500" }}>Amount Paid</div>
                        <div style={{ textAlign: "right", fontWeight: "500" }}>{helpers.formatCurrencyGlobal(formData.totalPaid, 2) || '0'}</div>
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "1fr 75px", gridGap: "10px", width: "100%", marginTop: "20px", padding: "20px 10px 20px 10px", borderTop: "2px solid black", borderBottom: "2px solid black", fontSize: "20px" }}>
                        <div style={{ textAlign: "right", fontWeight: "bold" }}>Balance Due</div>
                        <div style={{ textAlign: "right", fontWeight: "bold" }}>{helpers.formatCurrencyGlobal(formData.openBalance, 2) || '0'}</div>
                    </div>
                    <div style={{ width: "100%", marginTop: "20px", padding: "20px 10px 20px 10px" }}>
                        <div>
                            {formData.note != null ?
                                <>
                                    <p style={{ margin: "0px", fontSize: "14px", color: "grey" }}>Notes</p>
                                    <p>{formData.note}</p>
                                </>
                                : null}
                            {formData.termsAndConditions != null ?
                                <>
                                    <p style={{ margin: "0px", fontSize: "14px", color: "grey" }}>Terms & Conditions</p>
                                    <p>{formData.termsAndConditions}</p>
                                </>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
            {formData.openBalance > 0 ?
                <div style={{ backgroundColor: "#f0f0f0", padding: "20px" }}>
                    <div style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "20px" }}>Make Payment Here</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "20px" }}>
                        <div>
                            <div style={{ marginBottom: "10px" }}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(e) => { onPayOptionChange(e.target.value) }}
                                    >
                                        <FormControlLabel value="entire balance" control={<Radio />} label="Pay entire balance" />
                                        <FormControlLabel value="other amount" control={<Radio />} label="Pay other amount" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <TextField fullWidth label="Amt to pay" variant="standard" type="number" inputProps={{ className: "fg-number-no-arrows" }} helperText={errorList['headerAmt'] ? <span style={{ color: "red" }}>{errorList['headerAmt']}</span> : ''} style={{ marginBottom: "16px" }} value={paymentData.headerAmt || ''} onChange={(e) => { setPaymentData(paymentData => ({ ...paymentData, headerAmt: e.target.value })); handleOnPayAmountChanged(e.target.value); }} />
                        </div>
                        <div></div>
                    </div>
                    <PaymentInfoCtrl formData={paymentData} setFormData={setPaymentData} errorList={errorList} cardOnly={true} />
                    <Button variant="outlined" style={{ backgroundColor: "black", color: "white", border: "1px solid black" }} onClick={() => onPayNowBtnClick()}>{getTranslation('contact_search_page', 'search_grid', 'Submit Payment')}</Button>
                </div>
                : null}
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default ViewInvoiceCtrl;
