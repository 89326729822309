import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';


function DomainNameChangeCtrl({handlerReturnValue }) {

    function onValueChange(value) {
        if (handlerReturnValue) {
            handlerReturnValue(value);
        }
    }

    return (
        <>
            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                <TextField id="domainName" required fullWidth label="Enter Your Domain Name" variant="standard" style={{ marginBottom: "16px" }} onChange={(e) => onValueChange(e.target.value)} />
            </FormControl>
        </>

    );
}

export default DomainNameChangeCtrl;