import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { AutomationBrokerContext } from "./AutomationBuilderCtrl";
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

function EventProductItemCtrl({ products, onChange}) {
    const { elements, setElements, formData, setFormData, showForecast, itemsLoaded, setItemsLoaded, imageLoadedCount, setImageLoadedCount, imgCountRef, handleOnDragFromCanvas, selectedItems, setSelectedItems, shiftKeyPressedRef, controlKeyPressedRef, mousePosRef, popupOpenRef } = useContext(AutomationBrokerContext);

    const [productList, setProductList] = useState(products);

    function onValueChange(e, index, propertyName, value) {
        var tmpProductList = [...productList];
        tmpProductList[index][propertyName] = value;

        onChange(tmpProductList);

        setProductList(tmpProductList);
    }

    function handleOnProductAddBtnClick(e) {
        var tmpProductList = [...productList];
        tmpProductList.push(getNewProductJson());
        onChange(tmpProductList);
        setProductList(tmpProductList);
    }

    function getNewProductJson() {
        return { allocation: 0, sku: 0, name: null, price: null, profitMargin: null, payments: null, interval: null, intervalPeriod: null, purchaseDelay: null, churnRate: null, disputeRate: null, returnRate: null, chargeBackRate: null, chargeBackFee: null, leadTime: null };
    }

    function handleOnProductRemoveBtnClick(e, index) {
        var tmpProductList = [...productList];
        tmpProductList.splice(index, 1);
        setProductList(tmpProductList);
    }

    return (
        <>
            {productList.map((product, index) => (
                <div style={{ backgroundColor: "#F0F0F0", padding: "10px", borderRadius: "6px", marginBottom: "20px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "100px 125px 1fr 25px", gridGap: "10px", width: "100%", marginBottom: "10px" }}>
                        <div>
                            <div>Allocation</div>
                            <input type="text" className="form-control form-control-sm" value={product.allocation || ''} onChange={(e) => { onValueChange(e, index, 'allocation', e.target.value);}} />
                        </div>
                        <div>
                            <div>SKU#</div>
                            <input type="text" className="form-control form-control-sm" value={product.sku || ''} onChange={(e) => { onValueChange(e, index, 'sku', e.target.value); }} />
                        </div>
                        <div>
                            <div>Name</div>
                            <input type="text" className="form-control form-control-sm" value={product.name || ''} onChange={(e) => { onValueChange(e, index, 'name', e.target.value); }} />
                        </div>
                        <div style={{ paddingTop: "26px" }} onClick={(e) => { handleOnProductRemoveBtnClick(e, index); }}><DeleteIcon /></div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", gridGap: "10px", width: "100%", marginBottom: "10px" }}>
                        <div>
                            <div>Price</div>
                            <input type="text" className="form-control form-control-sm" value={product.price || ''} onChange={(e) => { onValueChange(e, index, 'price', e.target.value); }} />
                        </div>
                        <div>
                            <div>Profit Margin</div>
                            <input type="text" className="form-control form-control-sm" value={product.profitMargin || ''} onChange={(e) => { onValueChange(e, index, 'profitMargin', e.target.value); }} />
                        </div>
                        <div>
                            <div>Payments</div>
                            <select className="form-control form-control-sm" value={product.payments || ''} onChange={(e) => { onValueChange(e, index, 'payments', e.target.value); }}>
                                <option value="">Indefinite</option>                                
                                <option value="1">1 Payment</option>
                                <option value="2">2 Payments</option>
                                <option value="3">3 Payments</option>
                                <option value="4">4 Payments</option>
                                <option value="5">5 Payments</option>
                                <option value="6">6 Payments</option>
                                <option value="7">7 Payments</option>
                                <option value="8">8 Payments</option>
                                <option value="9">9 Payments</option>
                                <option value="10">10 Payments</option>
                                <option value="11">11 Payments</option>
                                <option value="12">12 Payments</option>
                                <option value="13">13 Payments</option>
                                <option value="14">14 Payments</option>
                                <option value="15">15 Payments</option>
                                <option value="16">16 Payments</option>
                                <option value="17">17 Payments</option>
                                <option value="18">18 Payments</option>
                                <option value="19">19 Payments</option>
                                <option value="20">20 Payments</option>
                                <option value="21">21 Payments</option>
                                <option value="22">22 Payments</option>
                                <option value="23">23 Payments</option>
                                <option value="24">24 Payments</option>
                            </select>
                        </div>
                        <div>
                            <div>Every</div>
                            <select className="form-control form-control-sm" value={product.interval || ''} onChange={(e) => { onValueChange(e, index, 'interval', e.target.value); }}>
                                <option value=""></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                        </div>
                        <div>
                            <div>Period</div>
                            <select className="form-control form-control-sm" value={product.intervalPeriod || ''} onChange={(e) => { onValueChange(e, index, 'intervalPeriod', e.target.value); }}>
                                <option value=""></option>
                                <option value="month">Month</option>
                            </select>
                        </div>
                        <div>
                            <div>Purchase Delay</div>
                            <select className="form-control form-control-sm" value={product.purchaseDelay || ''} onChange={(e) => { onValueChange(e, index, 'purchaseDelay', e.target.value); }}>
                                <option value=""></option>
                                <option value="1">1 Month</option>
                                <option value="2">2 Months</option>
                                <option value="3">3 Months</option>
                                <option value="4">4 Months</option>
                                <option value="5">5 Months</option>
                                <option value="6">6 Months</option>
                                <option value="7">7 Months</option>
                                <option value="8">8 Months</option>
                                <option value="9">9 Months</option>
                                <option value="10">10 Months</option>
                                <option value="11">11 Months</option>
                                <option value="12">12 Months</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", gridGap: "10px", width: "100%", marginBottom: "10px" }}>
                        <div>
                            <div>Churn Rate</div>
                            <input type="text" className="form-control form-control-sm" value={product.churnRate || ''} onChange={(e) => { onValueChange(e, index, 'churnRate', e.target.value); }} />
                        </div>
                        <div>
                            <div>Dispute Rate</div>
                            <input type="text" className="form-control form-control-sm" value={product.disputeRate || ''} onChange={(e) => { onValueChange(e, index, 'disputeRate', e.target.value); }} />
                        </div>
                        <div>
                            <div>Return Rate</div>
                            <input type="text" className="form-control form-control-sm" value={product.returnRate || ''} onChange={(e) => { onValueChange(e, index, 'returnRate', e.target.value); }} />
                        </div>
                        <div>
                            <div>CB Rate</div>
                            <input type="text" className="form-control form-control-sm" value={product.chargeBackRate || ''} onChange={(e) => { onValueChange(e, index, 'chargeBackRate', e.target.value); }} />
                        </div>
                        <div>
                            <div>CB Fee</div>
                            <input type="text" className="form-control form-control-sm" value={product.chargeBackFee || ''} onChange={(e) => { onValueChange(e, index, 'chargeBackFee', e.target.value); }} />
                        </div>
                        <div>
                            <div>Lead Time</div>
                            <input type="text" className="form-control form-control-sm" value={product.leadTime || ''} onChange={(e) => { onValueChange(e, index, 'leadTime', e.target.value); }} />
                        </div>
                    </div>
                </div>
            ))}
            <Button variant="outlined" color="primary" size="medium" style={{ border: "1px solid rgba(0,0,0,.12)", padding: "4.5px 12px 4.5px 12px" }} onClick={(e) => { handleOnProductAddBtnClick(e); }}>Add Product</Button>
        </>
    );
}

export default EventProductItemCtrl;
