import React, { useState, useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ProgressBarCtrl from '../formcontrols/ProgressBarCtrl';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import useBAFetch, { BAFetchData } from '../../customhooks/useBAFetch';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import DialogCtrl from '../dialogctrl/dialogctrl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';


function LinkSelector({ handlerReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate, isLookup, resourceType}) {

    const navigate = useNavigate();

    const [finalUrl, setFinalUrl] = useState('');
    const finalUrlRef = useRef('');
    finalUrlRef.current = finalUrl;

    const [resultData, setResultData] = useState(null);
    const [criteria, setCriteria] = useState(null);
    const [primaryKeyName, setPrimaryKeyName] = useState(null);
    const [percentComplete, setPercentCompleted] = useState(50);
    const [currentStep, setCurrentStep] = useState(1);
    const [stepMsg, setStepMsg] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            
    const [columns, setColumns] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [siteId, setSiteId] = useState('');

    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };

    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    function buildBlogColumns() {
        setColumns([
            {
                field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
                renderCell: ({ row }) =>
                    <Button variant="outlined" onClick={() => onGridSelectBtnClick('Blog',row)}>{getTranslation('blogs_search_page', 'search_grid', 'Select')}</Button>,
            },
            {
                field: 'title',
                headerName: getTranslation('blogs_search_page', 'search_grid', 'Title'),
                width: 300
            },
            {
                field: 'active',
                headerName: getTranslation('blogs_search_page', 'search_grid', 'Active'),
                width: 90,
                renderCell: function (params) {
                    if (params.row.active == true) {
                        return <Chip label="Active" color="success" />
                    } else {
                        return <Chip label="Inactive" color="error" />
                    }
                }
            },
            {
                field: 'dateCreated',
                headerName: getTranslation('blogs_search_page', 'search_grid', 'Created'),
                width: 225,
            },
        ]);
    }

    function buildProductColumns() {
        setColumns([
            {
                field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
                renderCell: ({ row }) =>
                    <Button variant="outlined" onClick={() => onGridSelectBtnClick('Product', row)}>{getTranslation('blogs_search_page', 'search_grid', 'Select')}</Button>,
            },
            {
                field: 'productName',
                headerName: getTranslation('blogs_search_page', 'search_grid', 'Product Name'),
                width: 300
            },
            {
                field: 'active',
                headerName: getTranslation('blogs_search_page', 'search_grid', 'Active'),
                width: 90,
                renderCell: function (params) {
                    if (params.row.active == true) {
                        return <Chip label="Active" color="success" />
                    } else {
                        return <Chip label="Inactive" color="error" />
                    }
                }
            },
        ]);
    }

    function buildWebsitePageColumns() {
        setColumns([
            {
                field: 'open', headerName: '', width: 100, sortable: false, filterable: false, hideable: false,
                renderCell: ({ row }) =>
                    <Button variant="outlined" onClick={() => onGridSelectBtnClick('WebsitePage', row)}>{getTranslation('blogs_search_page', 'search_grid', 'Select')}</Button>,
            },
            {
                field: 'name',
                headerName: getTranslation('blogs_search_page', 'search_grid', 'Name'),
                width: 300
            },
            {
                field: 'slug',
                headerName: getTranslation('blogs_search_page', 'search_grid', 'Slug'),
                width: 300
            },
        ]);
    }

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    function onGridSelectBtnClick(category, row) {
        //var tmpFinalUrl = finalUrl;
        var tmpFinalUrl = finalUrlRef.current;

        switch (category) {
            case 'Blog':
                tmpFinalUrl = '/' + row.blogId;
                setFinalUrl(tmpFinalUrl);
                break;
            case 'Product':
                tmpFinalUrl = '/' + row.productId;
                setFinalUrl(tmpFinalUrl);
                break;
            case 'WebsitePage':
                tmpFinalUrl = row.name + tmpFinalUrl;
                setFinalUrl(tmpFinalUrl);
                break;
        }     

        if (category != 'WebsitePage') {
            if (handlerReturnValue) {
                handlerReturnValue(tmpFinalUrl);
            }
            goToStep2();
        } else {
            if (handlerReturnValue) {
                setAcceptAfterUpdate(true); //this will accept the changes and close dialog after the setReturnValue is called.
                handlerReturnValue(tmpFinalUrl);
            }
        }
    }

    function goToStep2() {        
        setCriteria(null);
        setPercentCompleted(100);
        setCurrentStep(2);
        setStepMsg("Select the web page that contains the " + resourceType.toUpperCase() + " on it.");
        beginSearch("WebsitePage");
        getSiteNames();        
    }


    function handleOnCriteriaChanged(searchValue) {
        if (searchValue != '') {
            setCriteria(searchValue);
        } else {
            setCriteria(null);
        }        
    }

    function beginSearch(category) {
        var filtering = {};
        switch (category) {
            case 'Blog':
                if (criteria != null) {
                    filtering = {
                        "LogicalOperator": "and", "ConditionGroups": [{
                            "LogicalOperator": "and", "Conditions": [
                                { "Name": "title", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" },
                            ]
                        }]
                    };
                }

                BAFetchData({
                    callName: "GetBlog", method: "GET", url: "marketing/blog/getlist?filtering=" + JSON.stringify(filtering),
                    token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
            case 'Product':
                if (criteria != null) {
                    filtering = {
                        "LogicalOperator": "and", "ConditionGroups": [{
                            "LogicalOperator": "and", "Conditions": [
                                { "Name": "title", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" },
                            ]
                        }]
                    };
                }

                BAFetchData({
                    callName: "GetProduct", method: "GET", url: "ecommerce/product/getlist?filtering=" + JSON.stringify(filtering),
                    token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
            case 'WebsitePage':
                filtering = {
                    "LogicalOperator": "and", "ConditionGroups": [{
                        "LogicalOperator": "and", "Conditions": [
                            { "Name": "siteId", "ConditionOperator": "EQUAL", "Value": siteId, "DataType": "String" },
                            //{ "Name": "name", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" }
                        ]
                    }]
                };
                if (criteria != null) {
                    filtering.ConditionGroups[0].Conditions.push({ "Name": "name", "ConditionOperator": "CONTAINS", "Value": criteria, "DataType": "String" });
                }

                BAFetchData({
                    callName: "GetWebpage", method: "GET", url: "cms/sitepages/getlist?filtering=" + JSON.stringify(filtering),
                    token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
        }

    }

    function getSiteNames() {
        BAFetchData({
            callName: "GetSiteNames", method: "GET", url: "cms/sites/getlist?filtering={}",
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onSiteChange(e, siteId) {
        setSiteId(siteId);
        beginSearch('WebsitePage', siteId);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetBlog":
                setPrimaryKeyName('blogId');
                buildBlogColumns();
                setResultData(data);
                break;
            case "GetProduct":
                setPrimaryKeyName('productId');
                buildProductColumns();
                setResultData(data);
                break;
            case "GetWebpage":
                setPrimaryKeyName('sitePageId');
                buildWebsitePageColumns();
                setResultData(data);
                break;
            case "GetSiteNames":
                setSiteList(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {       
        beginSearch('WebsitePage',siteId);
    }, [siteId])

    useEffect(() => {
        setStepMsg("Select the desired " + resourceType.toUpperCase() + " record you want to link to.");
        if (resourceType == 'WebsitePage') {
            getSiteNames();
        } else {
            beginSearch(resourceType);
        }        
    }, [])


    console.log(finalUrl);

    return (
        <>
            <div style={{ width: "700px" }}>
                <ProgressBarCtrl value={percentComplete} />
                <div style={{fontSize:"22px",fontWeight:"500",marginTop:"10px",color:"black"}} >Step #{currentStep}</div>
                <div style={{ color: "black" }}>{stepMsg}</div>
                <div style={{ display: "grid", gridTemplateColumns: ( resourceType == 'WebsitePage' || currentStep == 2 ? "200px 1fr 150px" : "1fr 150px" ), gridGap: "10px", marginTop: "20px" }}>
                    {resourceType == 'WebsitePage' || currentStep == 2 ?
                        <div>
                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                <InputLabel id="demo-simple-select-standard-label">Sites</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="pageSize"
                                    onChange={(e) => onSiteChange(e, e.target.value)}
                                    label="Site"
                                >
                                    {siteList.map((site, index) => (
                                        <MenuItem value={site.siteId}>{site.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        : null}
                    <div><TextField autoFocus fullWidth label="Search Criteria" variant="standard" style={{ marginBottom: "16px" }} value={criteria || ''} InputLabelProps={{ shrink: criteria }} onChange={(e) => { handleOnCriteriaChanged(e.target.value); }} /></div>
                    <div style={{ paddingTop: "10px" }}><Button variant="contained" size="small" fullWidth style={{ paddingTop: "10px" }} onClick={() => {beginSearch(resourceType); }}>Apply Filter</Button></div>
                </div>
                
                {resultData != null ?
                    <div>
                        <Box sx={{ height: 630, width: '100%' }}>
                            <DataGrid
                                sx={{
                                    border: 0,
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within": {
                                        outline: "none !important",
                                    },
                                }}
                                rows={resultData}
                                getRowId={(row) => row[primaryKeyName]}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </div>
                : null}
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>

    );
}

export default LinkSelector;