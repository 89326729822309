import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../dialogctrl/dialogctrl';
import LoaderSpinnerCtrl from '../loaderspinnerctrl/loaderspinnerctrl';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalState } from '../../globalstates/GState';

function NewsReel() {

    const navigate = useNavigate();

    const [wlInfo] = useGlobalState("wlInfo");

    const openCertificationOffer = () => {
        const url = `${wlInfo.wlBusinessDomain}/certificationoffer`;
        window.open(url, '_blank');
    }

    return (
        <>
            <div>
                <div style={{ marginBottom: "40px", textAlign: "center" }}>
                    <h3>Become a Certified<br/>Funnel Goal Specialist</h3>
                </div>
                <div>
                    <p style={{ fontSize: "20px", lineHeight: "123.5%" }}>We're making it easy for agencies and consultants to level up their tech skills to help their clients grow their business.</p>
                </div>
                <div>
                    <img src="./images/woman_on_computer_100.webp" style={{ width: "100%" }} alt="" />
                </div>
                <div>
                    <p style={{ fontSize: "14px" }}>Join the waiting list to obtain free voucher to our Funnel Goal specialist course.</p>
                </div>
                <div style={{ textAlign: "center" }}>
                    <Button variant="contained" size="small" onClick={openCertificationOffer} >Learn More</Button>
                </div>
            </div>
        </>
    );
}

export default NewsReel;

