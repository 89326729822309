import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../dialogctrl/dialogctrl';
import FileManager from '../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


function FilterPlusCtrl({ filterSettings, filtering, autoUpdate, handleOnDone, handleOnLookup }) {

    const [filterSettingsObj, setFilterSettingsObj] = useState([]);

    const [filteringObj, setFilteringObj] = useState(
        {
            "LogicalOperator": "and",
            "ConditionGroups": []
        }
    );

    //const [filteringObj, setFilteringObj] = useState(
    //    {
    //        "LogicalOperator": "or",
    //        "ConditionGroups": [
    //            {
    //                "LogicalOperator": "or",
    //                "Conditions": [
    //                    {
    //                        "Name": "productId",
    //                        "ConditionOperator": "EQUAL",
    //                        "Value": "12345",
    //                        "DataType": "String"
    //                    }
    //                ]
    //            },
    //            {
    //                "LogicalOperator": "and",
    //                "Conditions": [
    //                    {
    //                        "Name": "featureImage",
    //                        "ConditionOperator": "NOT_CONTAINS",
    //                        "Value": "dummy",
    //                        "DataType": "String"
    //                    }
    //                ]
    //            }
    //        ]
    //    }
    //);

    const [expressions, setExpressions] = useState(['EQUAL', 'IS_EMPTY', 'NOT_EQUAL', 'GREATER_THAN', 'GREATER_THAN_OR_EQUAL',
        'LESS_THAN', 'LESS_THAN_OR_EQUAL', 'CONTAINS', 'NOT_CONTAINS', 'STARTSWITH', 'ENDSWITH', 'IS_BETWEEN']);


    function updateRootLogicalOperator(operator) {
        var tmpFilteringObj = { ...filteringObj };
        tmpFilteringObj.LogicalOperator = operator;
        setFilteringObj(tmpFilteringObj);
    }

    function updateGroupLogicalOperator(index, operator) {
        var tmpFilteringObj = { ...filteringObj };
        tmpFilteringObj.ConditionGroups[index].LogicalOperator = operator;
        setFilteringObj(tmpFilteringObj);
    }

    function addConditionGroup() {
        var tmpFilteringObj = { ...filteringObj };
        tmpFilteringObj.ConditionGroups.push(newConditionGroupObj());
        setFilteringObj(tmpFilteringObj);
    }

    function newConditionGroupObj() {
        return {
            "LogicalOperator": "and",
            "Conditions": [
                {
                    "TableName":"",
                    "Name": "",
                    "ConditionOperator": "EQUAL",
                    "Value": "",
                    "Value2": "",
                    "DataType": "String",
                    "ControlType": "textbox"
                }
            ]
        }
    }


    function addCondition(conditionGroupIndex) {
        var tmpFilteringObj = { ...filteringObj };
        tmpFilteringObj.ConditionGroups[conditionGroupIndex].Conditions.push(newConditionObj());
        setFilteringObj(tmpFilteringObj);
    }

    function newConditionObj() {
        return {
            "TableName":"",
            "Name": "",
            "ConditionOperator": "EQUAL",
            "Value": "",
            "Value2": "",
            "DataType": "String",
            "ControlType": "textbox"
        }
    }


    function removeConditionGroup(groupIndex) {
        var tmpFilteringObj = { ...filteringObj };
        tmpFilteringObj.ConditionGroups.splice(groupIndex, 1);
        setFilteringObj(tmpFilteringObj);
    }

    function removeCondition(groupIndex,conditionIndex) {
        var tmpFilteringObj = { ...filteringObj };
        tmpFilteringObj.ConditionGroups[groupIndex].Conditions.splice(conditionIndex, 1);
        if (tmpFilteringObj.ConditionGroups[groupIndex].Conditions.length == 0) {
            tmpFilteringObj.ConditionGroups = [];
        }
        setFilteringObj(tmpFilteringObj);
    }



    //function onValueChange(groupIndex, conditionIndex,propertyName,value) {
        //var tmpFilteringObj = { ...filteringObj };
        //tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex][propertyName] = value;
        //if (propertyName == 'Name') {
        //    tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].DataType = getFieldDataType(value);
        //}
        //setFilteringObj(tmpFilteringObj);
    //}



    function onDone() {
        var tmpFilteringObj = { ...filteringObj };
        if (tmpFilteringObj.ConditionGroups.length > 0) {
            handleOnDone(tmpFilteringObj);
        } else {
            handleOnDone({});
        }        
    }

    function onTableChange(groupIndex, conditionIndex, propertyName, value) {
        var tmpFilteringObj = { ...filteringObj };
        var tableChange = false;
        if (tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].TableName != value) {
            tableChange = true;
        }
        tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].TableName = value;
        if (tableChange == true) {
            tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].Name = "";
            tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].ConditionOperator = "EQUAL";
            tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].DataType = "string";
            tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].ControlType = "textbox";
            tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].Value = "";
            tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].Value2 = "";
        }
        
        setFilteringObj(tmpFilteringObj);
    }

    //function onTableChange(tableName, ci) {
    //    var tmpFilteringObj = { ...filteringObj };
    //    var tableChange = false;
    //    if (tmpFilteringObj.ConditionGroups[cgi].TableName != tableName) {
    //        tableChange = true;
    //    }
    //    tmpFilteringObj.ConditionGroups[cgi].TableName = tableName;
    //    if (tableChange == true) {
    //        tmpFilteringObj.ConditionGroups[cgi].Conditions = [
    //            {
    //                "Name": "",
    //                "ConditionOperator": "EQUAL",
    //                "Value": "",
    //                "DataType": "String"
    //            }
    //        ]
    //    }
    //    setFilteringObj(tmpFilteringObj);
    //}

    function getAvailableFields(tableName) {
        var fields = [];
        var tables = filterSettings.settings.tables;
        for (var ti = 0; ti < tables.length; ti++) {
            if (tables[ti].dbTableName == tableName) {
                fields = tables[ti].availableFields;
                break;
            }
        }        
        return fields;
    }

    function onFieldChange(groupIndex, conditionIndex, propertyName, value) {
        var tmpFilteringObj = { ...filteringObj };
        tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex][propertyName] = value;
        tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].Value = "";
        tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].Value2 = "";
        var dbTableName = tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].TableName
        tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].DataType = getFieldDataType(dbTableName, value);
        tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].ControlType = getFieldControlType(dbTableName, value);       
        setFilteringObj(tmpFilteringObj);
    }

    function getFieldDataType(dbTableName, fieldName) {
        var dataType = null;
        var tables = filterSettings.settings.tables;
        for (var ti = 0; ti < tables.length; ti++) {
            if (tables[ti].dbTableName == dbTableName) {
                var fields = tables[ti].availableFields;
                for (var fi = 0; fi < fields.length; fi++) {
                    if (fields[fi].fieldName == fieldName) {
                        dataType = fields[fi].dataType;
                        break;
                    }
                }                
                break;
            }
        }      
        return dataType;
    }

    function getFieldControlType(dbTableName, fieldName) {
        var controlType = 'textbox';
        var tables = filterSettings.settings.tables;
        for (var ti = 0; ti < tables.length; ti++) {
            if (tables[ti].dbTableName == dbTableName) {
                var fields = tables[ti].availableFields;
                for (var fi = 0; fi < fields.length; fi++) {
                    if (fields[fi].fieldName == fieldName) {
                        controlType = fields[fi].controlType;
                        break;
                    }
                }
                break;
            }
        }
        return controlType;
    }

    
    function onExpressionChange(groupIndex, conditionIndex, propertyName, value) {
        var tmpFilteringObj = { ...filteringObj };
        tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex][propertyName] = value;
        if (propertyName == 'Name') {
            tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex].DataType = getFieldDataType(value);
        }
        setFilteringObj(tmpFilteringObj);
    }

    function onValueChange(groupIndex, conditionIndex, propertyName, value) {
        var tmpFilteringObj = { ...filteringObj };
        tmpFilteringObj.ConditionGroups[groupIndex].Conditions[conditionIndex][propertyName] = value;
        setFilteringObj(tmpFilteringObj);
    }



    useEffect(() => {
        setFilterSettingsObj(filterSettings);
    }, [filterSettings])

    useEffect(() => {
        if (filtering != undefined) {
            if (JSON.stringify(filtering) != '{}') {
                setFilteringObj(filtering);
            }
        }
    }, [filtering])

    return (
        <>
            <div>
                <div style={{color:"black"}}>Filters</div>
                <div style={{ fontSize: "13px",display:"none" }}>Use the options below to filter the view</div>
                {filteringObj.ConditionGroups.length > 0 ?
                    <div style={{ display: "grid", gridTemplateColumns: "150px 50px 1fr", gridGap: "10px", width: "100%" }}>
                        <div style={{ paddingTop: "5px" }}>Use this operator --&gt;</div>
                        <div>
                            <FormControl variant="standard" sx={{ m: 0, marginBottom: "0px" }}>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    label="Operator"
                                    value={filteringObj.LogicalOperator}
                                    onChange={(e) => { updateRootLogicalOperator(e.target.value); }}
                                >
                                    <MenuItem value="and">and</MenuItem>
                                    <MenuItem value="or">or</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ paddingTop: "5px" }}>for all condition groups</div>
                    </div>
                    : null}
                {filteringObj.ConditionGroups.map((conditionGroup, conditionGroupIndex) => (
                    <>
                    <div style={{ backgroundColor: "white", padding: "10px"}}>
                        <div style={{display:"grid",gridTemplateColumns:"20px 135px 135px 50px 1fr",gridGap:"10px",fontSize:"14px"}}>
                                <div style={{ paddingTop: "4px" }} onClick={(e) => { removeConditionGroup(conditionGroupIndex); }}><DeleteForeverIcon></DeleteForeverIcon></div>
                            <div style={{ fontWeight: "bold",marginTop:"5px" }}>Condition Group #{conditionGroupIndex + 1}</div>
                            <div style={{ marginTop: "5px" }}>Use this operator --&gt;</div>
                            <div>
                                <FormControl variant="standard" sx={{ m: 0, marginBottom: "0px" }}>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        label="Operator"
                                            value={conditionGroup.LogicalOperator}
                                            onChange={(e) => { updateGroupLogicalOperator(conditionGroupIndex,e.target.value); }}
                                    >
                                        <MenuItem value="and">and</MenuItem>
                                        <MenuItem value="or">or</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ marginTop: "5px" }}>for conditions in this group</div>
                        </div>
                        {conditionGroup.Conditions.map((condition, conditionIndex) => (
                            <div style={{ display: "grid", gridTemplateColumns: "50px 1fr", gridGap: "10px",marginTop:"5px" }}>
                                <div style={{ fontWeight: "bold", marginLeft: "20px", paddingTop: "20px" }}>
                                    {conditionIndex > 0 ?
                                        conditionGroup.LogicalOperator
                                        : null}
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "260px 180px 240px 1fr 20px", gridGap: "10px", fontSize: "14px", marginTop: "0px" }}>
                                    <div>
                                        <FormControl variant="standard" fullWidth sx={{ m: 0, marginBottom: "0px" }}>
                                            <InputLabel id="demo-simple-select-standard-label">Select From Table </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                label="Column"
                                                value={condition.TableName}
                                                onChange={(e) => { onTableChange(conditionGroupIndex, conditionIndex, 'TableName', e.target.value); }}
                                            >
                                                <MenuItem value=""><em>Select table</em></MenuItem>
                                                {filterSettingsObj.settings.tables.map((table, index) => (
                                                    <MenuItem value={table.dbTableName}>{table.tableCaption}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div>
                                        <FormControl variant="standard" fullWidth sx={{ m: 0, marginBottom: "0px" }}>
                                            <InputLabel id="demo-simple-select-standard-label">Field Name</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                label="Column"
                                                value={condition.Name}
                                                onChange={(e) => { onFieldChange(conditionGroupIndex, conditionIndex, 'Name', e.target.value);}}
                                            >
                                                {getAvailableFields(condition.TableName).map((field, fieldIndex) => (
                                                    <MenuItem value={field.fieldName}>{field.caption}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <FormControl variant="standard" fullWidth sx={{ m: 0, marginBottom: "0px" }}>
                                            <InputLabel id="demo-simple-select-standard-label">Expression</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                label="Column"
                                                value={condition.ConditionOperator}
                                                onChange={(e) => { onExpressionChange(conditionGroupIndex, conditionIndex, 'ConditionOperator', e.target.value); }}
                                            >
                                                {expressions.map((expression, index) => (
                                                    <MenuItem value={expression}>{expression}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {condition.ConditionOperator == 'IS_BETWEEN' ?
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 60px 1fr", gridGap: "5px" }}>
                                            <div>
                                                {condition.ControlType == 'date' ?
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                            <DemoContainer components={['DatePicker']} style={{ marginBottom: "10px" }}>
                                                                <DesktopDatePicker
                                                                    label="Value"
                                                                    variant="Standard"
                                                                    slotProps={{ textField: { fullWidth: true, size: 'small' } }}
                                                                    value={condition.Value != null ? dayjs(condition.Value) : null}
                                                                    onChange={(newValue) => { onValueChange(conditionGroupIndex, conditionIndex, 'Value', newValue); }}
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                : null}
                                                {condition.ControlType != 'date' ?
                                                    <TextField id="value" fullWidth label="Value" variant="standard" style={{ marginBottom: "0px" }} value={condition.Value || ''} InputLabelProps={{ shrink: condition.Value }} onChange={(e) => { onValueChange(conditionGroupIndex, conditionIndex, 'Value', e.target.value); }} />
                                                : null}
                                                
                                            </div>
                                            <div style={{ textAlign: "center", paddingTop: "20px" }}> and </div>
                                            <div>
                                                {condition.ControlType == 'date' ?
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                        <DemoContainer components={['DatePicker']} style={{ marginBottom: "10px" }}>
                                                            <DesktopDatePicker
                                                                label="Value"
                                                                variant="Standard"
                                                                slotProps={{ textField: { fullWidth: true, size: 'small' } }}
                                                                value={condition.Value2 != null ? dayjs(condition.Value2) : null}
                                                                onChange={(newValue) => { onValueChange(conditionGroupIndex, conditionIndex, 'Value2', newValue); }}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                : null}
                                                {condition.ControlType != 'date' ?
                                                    <TextField id="value2" fullWidth label="Value2" variant="standard" style={{ marginBottom: "0px" }} value={condition.Value2 || ''} InputLabelProps={{ shrink: condition.Value2 }} onChange={(e) => { onValueChange(conditionGroupIndex, conditionIndex, 'Value2', e.target.value); }} />
                                                : null}                                                
                                            </div>
                                        </div>
                                        : 
                                        <div>
                                            {condition.ControlType == 'date' ?
                                                <div>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                        <DemoContainer components={['DatePicker']} style={{ marginBottom: "10px" }}>
                                                            <DesktopDatePicker
                                                                label="Value"
                                                                variant="Standard"
                                                                slotProps={{ textField: { fullWidth: true,size:'small' } }}
                                                                value={condition.Value != null ? dayjs(condition.Value) : null}
                                                                onChange={(newValue) => { onValueChange(conditionGroupIndex, conditionIndex, 'Value', newValue); }}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                                : null}
                                            {condition.ControlType == 'textbox' ?
                                                <div>
                                                    <TextField id="value" fullWidth label="Value" variant="standard" style={{ marginBottom: "0px" }} value={condition.Value || ''} InputLabelProps={{ shrink: condition.Value }} onChange={(e) => { onValueChange(conditionGroupIndex, conditionIndex, 'Value', e.target.value); }} />
                                                </div>
                                                : null}
                                            {condition.ControlType == 'lookup2' ?
                                                <div style={{display:"grid",gridTemplateColumns:"1fr 90px 70px",gridGap:"5px"}}>
                                                    <div>
                                                        <TextField id="value2" fullWidth label="Value" variant="standard" style={{ marginBottom: "0px" }} value={condition.Value2 || ''} InputLabelProps={{ shrink: condition.Value2 }} inputProps={{ readOnly: true }} onChange={(e) => { onValueChange(conditionGroupIndex, conditionIndex, 'Value2', e.target.value); }} />
                                                    </div>
                                                    <div style={{ paddingTop: "14px" }}>
                                                        <Button variant="outlined" color="primary" size="small" style={{ fontSize: "13px", borderColor: "grey", backgroundColor: "transparent", color: "black" }} onClick={() => { handleOnLookup('lookup2', filteringObj, conditionGroupIndex, conditionIndex, condition.TableName, condition.Name); }}>Browse...</Button>
                                                    </div>
                                                    <div style={{paddingTop: "14px"} }>
                                                        <Button variant="outlined" color="primary" size="small" style={{ fontSize: "13px", borderColor: "grey", backgroundColor: "transparent", color: "black"}} >Clear</Button>
                                                    </div>
                                                </div>
                                                : null}

                                        </div>
                                    }
                                    <div style={{ paddingTop: "20px" }} onClick={(e) => { removeCondition(conditionGroupIndex, conditionIndex);}}><DeleteForeverIcon></DeleteForeverIcon></div>
                                </div>                                
                            </div>
                        ))}
                        <div style={{ marginTop: "5px" }}>
                                <Button variant="outlined" color="primary" size="small" style={{ fontSize: "13px", backgroundColor: "#6D6D6D", color: "white" }} onClick={(e) => { addCondition(conditionGroupIndex); }}>Add new condition to group</Button>
                        </div>
                        </div>
                        {(conditionGroupIndex + 1) < filteringObj.ConditionGroups.length ?
                            <div style={{ marginLeft: "0px", fontWeight: "bold" }}>{filteringObj.LogicalOperator}</div>
                            : null}
                    </>
                ))}
                <div style={{ marginTop: "10px" }}>
                    <Button variant="outlined" color="primary" size="small" style={{ fontSize: "13px" }} onClick={(e) => { addConditionGroup(); }}>Add new condition group</Button>
                </div>
                <div style={{marginTop:"10px"}}>
                    <Button variant="contained" size="small" onClick={(e) => { onDone(); }}>Update Results</Button>
                </div>
                
            </div>
        </>
    );
}

export default FilterPlusCtrl;