import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Editor from '@monaco-editor/react';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function JavascriptEditor({ dialogType,javascriptText, handlerReturnValue}) {

    const styleSheetRef = useRef();
    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    //const [formData, setFormData] = useState({});
    const [javascriptObj, setJavascriptObj] = useState(javascriptText);

    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.siteId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "site_settings_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('site_settings_page', 'header_section', 'Javascript');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == 0) {
            //set default data
            data.active = true;
        }        
    }

    function handleEditorChange(value, event) {
        //var tmpJavascriptObj = { ...javascriptObj };
        setJavascriptObj(value)
        //var tmpFormData = { ...formData };
        //tmpFormData.styleSheet = value;
        //styleSheetRef.current.value = value;

        //setFormData((...formData) => { return tmpFormData });       
        if (handlerReturnValue) {
            handlerReturnValue(value);
        }
    }

    function handleOnCssChange(e, value) {
        //var tmpJavascriptObj = { ...javascriptObj };
        setJavascriptObj(value)
        //var tmpFormData = { ...formData };
        //tmpFormData.styleSheet = value;
        //styleSheetRef.current.value = value;

        //setFormData((...formData) => { return tmpFormData });       
        if (handlerReturnValue) {
            handlerReturnValue(value);
        }
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        var test = '';
        test = '';
        setJavascriptObj(javascriptText);
    }, [javascriptText]);

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {javascriptObj == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {javascriptObj != null &&
                
                <div className="fg-app-page-box" style={{  }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">                                            
                                            <div className="row">
                                                <div className="col-sm-12" style={{ margin: "0px", padding: "0px" }}>
                                                    <Editor
                                                        height="400px"
                                                        theme="vs-dark"
                                                        defaultLanguage="javascript"
                                                        defaultValue={javascriptObj}
                                                        onChange={handleEditorChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    //function handleRecordDeleteConfirm() {
    //    var msg = 'You are about to delete site. <span style="font-weight:bold;">Are you sure</span>?';
    //    setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
    //    setOpenDialog(true);
    //}


    //function deleteRecord() {
    //    BAFetchData({
    //        callName: "DeleteRecord", method: "DELETE", url: "cms/sites/delete?siteid=" + primaryKeyValue,
    //        token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
    //    });
    //}

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    //function handleSaveForm() {
    //    //perform form data validation
    //    setErrorList([]);

    //    //Set just in time values before save
    //    addJustInTimeData(formData);

    //    if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
    //        if (isCustomFormValidationValid() == true) {
    //            saveForm();
    //        }
    //    } else {
    //        var msg = 'There are form validation errors.';
    //        setGenericDialogSchema({ title: 'Validation Errors', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
    //        setOpenDialog(true);
    //    }                
    //}

    //function saveForm() {
    //    if (primaryKeyValue == '0') {
    //        BAFetchData({
    //            callName: "SaveForm", method: "POST", url: "cms/sites/add",
    //            token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
    //        });
    //    } else {
    //        BAFetchData({
    //            callName: "SaveForm", method: "PUT", url: "cms/sites/update",
    //            token: "fg1234", body: JSON.stringify(formData), onSuccess: onAPISuccess, onError: onAPIError
    //        });
    //    }
    //}
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        //if (formData.isCompany == false) {
        //    if (formData.firstName == null && formData.lastName == null && formData.businessName == null) {
        //        isValid = false;
        //        tempErrorList['firstName'] = "First Name, Last Name or Business Name is required.";
        //    }
        //}
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default JavascriptEditor;