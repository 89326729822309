import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import CloseIcon from '@mui/icons-material/Close';
import FileManager from '../../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import Dropdown from 'react-bootstrap/Dropdown';
import helpers from '../../../helpers/Helpers';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';


function InteractMouseClickCtrl({ eventData, propertyChangeCallback, toggleContainer, title }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    //const [widgetObj, setWidgetObj] = useState(widgetData);
    const [eventItem, setEventItem] = useState(eventData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            
    //const [showAnimationItem, setShowAnimationItem] = useState(false);


    //function handleOnClassNameChange(value) {
    //    var tmpWidgetObj = { ...widgetObj };
    //    tmpWidgetObj.className = value;
    //    setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //    propertyChangeCallback(tmpWidgetObj);
    //}

    //function handleOnIconChange(value) {
    //    var tmpWidgetObj = { ...widgetObj };
    //    tmpWidgetObj.className = value;
    //    setWidgetObj((...widgetObj) => { return tmpWidgetObj });
    //    propertyChangeCallback(tmpWidgetObj);
    //}

    //function openFileManager() {
    //    var actionType = "FullPath";
    //    var msg = 'This is a test';
    //    setGenericDialogSchema({ title: 'Icon Manager', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={dialogType, setReturnValue} returnFullPath={false} iconOnly={true}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleUpdateImage(returnValues, actionType); } })
    //    setOpenDialog(true);
    //}

    //function handleUpdateImage(imgUrl, actionType) {
    //    handleOnClassNameChange(imgUrl);
    //}

    function getNewActionJson(id, actionTypeId) {
        return {
            "id": id, "actionTypeId": actionTypeId, config: {},"playInReverse":false, autoStopEventId:""
        };
    }

    function onNewAction(actionTypeId) {
        var tmpEventItem = { ...eventItem };
        var newId = helpers.getUUID();
        //tmpWidgetObj['events'] = {};
        tmpEventItem.action = getNewActionJson(newId, actionTypeId);
        setEventItem(tmpEventItem);
        propertyChangeCallback(tmpEventItem);
    }

    function getNewConfigActionJson(id) {
        return {
            "id": id, "title": "New Title", actionItemGroups: [], "useFirstGroupAsInitialState": false
        };
    }

    function onNewActionConfig() {
        var newId = helpers.getUUID();
        var tmpEventItem = { ...eventItem };
        tmpEventItem.action.config = getNewConfigActionJson(newId);
        propertyChangeCallback(tmpEventItem);
        toggleContainer('animationItem',tmpEventItem.id);
    }

    function removeAnimationItem() {
        var tmpEventItem = { ...eventItem };
        tmpEventItem.action.config = {};
        setEventItem(tmpEventItem);
        propertyChangeCallback(tmpEventItem);
    }

    useEffect(() => {
        setEventItem(eventData);
    }, [eventData])



    return (
        <>
            <div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <h2 style={{ fontSize: "14px", fontWeight: "500" }}>{title}</h2>
                    <div style={{ marginBottom: "20px" }}>
                        <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                            <div className="fg-pb-property-sub-label">Action</div>
                            <div>
                                <select className="form-control form-control-sm fg-pb-property-field" value={eventItem.action.actionTypeId || ''} onChange={(e) => { onNewAction(e.target.value); }}>
                                    <option value="">Select Action...</option>
                                    <option value="GENERAL_START_ACTION">Start an Animation</option>
                                    <option value="GENERAL_CALL_FUNCTION">Call Javascript Function</option>
                                </select>
                            </div>
                        </div>
                        {JSON.stringify(eventItem.action) != "{}" ?
                            <>
                                <div>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 30px", width: "100%" }}>
                                        <div>Timed Animations</div>
                                        <div><AddIcon onClick={(e) => { onNewActionConfig() }} /></div>
                                    </div>
                                    <div style={{ backgroundColor: "whitesmoke", padding: "5px" }}>
                                        {JSON.stringify(eventItem.action.config) == "{}" ?
                                            <div style={{ color: "grey", fontSize: "12px", textAlign: "center" }}>No Animations</div>
                                            : null}
                                        {JSON.stringify(eventItem.action.config) != "{}" ?
                                            <div style={{ backgroundColor: "whitesmoke", padding: "5px", marginBottom: "5px" }}>
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 30px 30px" }}>
                                                    <div>{eventItem.action.config.title}</div>
                                                    <div style={{ textAlign: "right", cursor: "pointer" }} onClick={() => { toggleContainer('animationItem', eventItem.id);}}><EditIcon></EditIcon></div>
                                                    <div style={{ textAlign: "right", cursor: "pointer" }} onClick={() => { removeAnimationItem(); }}><DeleteForeverIcon></DeleteForeverIcon></div>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </>
                            : null}
                    </div>
                </div>

            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default InteractMouseClickCtrl;

