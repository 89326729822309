import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import { generateVideoThumbnails } from '../../../helpers/VideoThumbnailGeneratorByUrl';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';

function SmPostPreviewCtrl({formData}) {

    const [postType, setPostType] = useState('');
    const [currentUser] = useGlobalState("userInfo");
    const wlInfo = JSON.parse(localStorage.getItem("wl-info"));
    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});
    const [metaData, setMetaData] = useState(null);
    //const options = { url: formData.linkUrl || '' };
    const [currentPreview, setCurrentPreview] = useState({ integrationName: null, name: null });

    async function extractMeta() {
        const url = formData.linkUrl || '';
        if (url != '') {
            BAFetchData({
                callName: "GetMetaData",
                method: "POST",
                url: "apiintegration/getmetadata?url=" + url,
                token: "fg1234",
                onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMetaData":
                console.log('metaData', data);
                setMetaData(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        alert(error);
    }

    function getPostWidth() {
        var width = "100%";
        switch (formData.postType) {
            case "Photo":
                break;
            case "Link":
                break;
            case "Video-V-60s":
            case "Video-V-90s":
            case "Video-V-10m":
                width = "250px";
                break;
        }
    }

    function getHostFromString(urlStr) {
        var host = '';
        if (urlStr != '') {
            var url = new URL(urlStr);
            host = url.hostname.replace('www.','');
        }
        return host;
    }

    function formatTextWithLines(text) {
        if (text != undefined) {
            return text.split('\n').join('<br />');
        } else {
            return "";
        }
    }

    function formatMessage(message) {
        var msg = message
        //msg = msg.replaceAll("\n", "");
        return msg;
    }

    function onPlatformClick(integrationName, name) {
        setCurrentPreview({ integrationName: integrationName, name: name });
    }

    useEffect(() => {
        setShowLoader(false);
        extractMeta();
        if (postType != formData.postType) {
            setMetaData(null);
            setPostType(formData.postType);
            if (formData.targets != null && formData.targets != undefined) {
                if (formData.targets.length > 0) {
                    setCurrentPreview({ integrationName: formData.targets[0].integrationName, name: formData.targets[0].name });
                } else {
                    setCurrentPreview({ integrationName: null, name: null });
                }
            } else {
                setCurrentPreview({ integrationName: null, name: null });
            }
        }
    }, [formData]);

    return (
        <>
            <div style={{display:"grid",gridTemplateColumns:"80px 1fr",gridGap:"5px",marginBottom:"10px"}}>
                <div>Preview In</div>
                <div>
                    {formData.targets != null ?
                        (
                            formData.targets.map((target, index) => (
                                <div style={{ cursor: "pointer", display: "inline-block", marginRight: "10px", backgroundColor: "#c0c0c0", padding: "2px 7px 2px 7px", borderRadius: "4px", color: "white" }} onClick={() => { onPlatformClick(target.integrationName, target.name); }}>{target.integrationName}</div>
                            ))
                        )
                        : null}
                </div>
            </div>
            {currentPreview.integrationName != null ?
                <div style={{ backgroundColor: "white", border: "1px solid #c0c0c0", borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}>
                    <div style={{ padding: "5px", display: "grid", gridTemplateColumns: "1fr 25px 25px", gridGap: "5px" }}>
                        <div style={{ display: "none" }}>
                            <img src="https://scontent-atl3-1.xx.fbcdn.net/v/t39.30808-1/294937882_600956218401354_1978272732399767268_n.jpg?stp=cp0_dst-jpg_p40x40&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=y1foOASpnecQ7kNvgH02AcB&_nc_ht=scontent-atl3-1.xx&oh=00_AYCVJN0ynQHH2sNPh-YpaS-7Jeg-cD3f-3mYXRQOz2HKhQ&oe=667BECA6" style={{ width: "50px", borderRadius: "50%" }} />
                        </div>
                        <div>
                            <div style={{ fontWeight: "500", fontSize: "16px" }}>{currentPreview.name}</div>
                            <div style={{ fontSize: "13px", color: "grey" }}>Sponsored</div>
                        </div>
                        <div>
                            <MoreHorizIcon style={{ color: "grey", fontSize: "28px", marginTop: "5px" }} />
                        </div>
                        <div>
                            <CloseIcon style={{ color: "grey", fontSize: "28px", marginTop: "5px" }} />
                        </div>
                    </div>
                    <div style={{ padding: "5px 7px 10px 7px", fontSize: "15px", lineHeight: "125%" }}>
                        <p dangerouslySetInnerHTML={{ __html: formatTextWithLines(formData.message || "") }}></p>
                    </div>
                    <div style={{ textAlign: "center", backgroundColor: "black" }}>
                        {formData.postType == "Photo" ?
                            <img src={formData.imageUrl || ''} style={{ width: "100%" }} />
                            : null}
                        {formData.postType == "Link" ?
                            (metaData != null ?
                                <img src={metaData.imageUrl || ''} style={{ width: "100%" }} />
                                : null)
                            : null}
                        {(formData.postType == "Video-V-60s" || formData.postType == "Video-V-90s" || formData.postType == "Video-V-10m") ?
                            <img src={formData.videoThumbnail || ''} style={{ width: "50%" }} />
                            : null}
                    </div>
                    {formData.postType == 'Link' ?
                        (metaData != null ?
                            <div style={{ backgroundColor: "#F0F2F5", padding: "5px" }}>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 100px", gridGap: "5px" }}>
                                    <div>
                                        <div style={{ fontSize: "13px", color: "grey", textTransform: "uppercase" }}>{getHostFromString(metaData.url || '')}</div>
                                        <div style={{ fontWeight: "500", fontSize: "18px" }}>{metaData.title || ''}</div>
                                    </div>
                                    <div style={{ display: "none" }}>
                                        <Button variant="outlined" style={{ backgroundColor: "#EB5454", color: "white", border: "1px solid #EB5454" }} >Learn More</Button>
                                    </div>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: formatTextWithLines(metaData.description || '') }}></div>
                            </div>
                            : null)
                        : null}
                </div>
                : null}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );
}

export default SmPostPreviewCtrl;
