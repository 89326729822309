import { BAFetchData } from "../../customhooks/useBAFetch";
import helpers from "../../helpers/Helpers";

const PageEngineUtil = {

    CreatePagesByFeature: async function (baId, siteId) {


        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "siteId", "ConditionOperator": "EQUAL", "Value": siteId, "DataType": "string" },
                ]
            }]
        };

        const selectedFeatures = await BAFetchData({
            callName: "GetSelectedFeatures",
            method: "GET",
            url: "cms/sitefeaturemap/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        var conditions = [];
        for (var i = 0; i < selectedFeatures.data.length; i++) {
            conditions.push({ "Name": "featureId", "ConditionOperator": "EQUAL", "Value": selectedFeatures.data[i].featureId, "DataType": "number" });
        }

        filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "or", "Conditions": conditions
            }]
        };

        const featureListData = await BAFetchData({
            callName: "GetAvailableFeatures",
            method: "GET",
            url: "cms/sitefeature/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: null, onError: null
        });

        var feature = null;
        var multiPage = false;
        var purposeName = null;
        var featureList = featureListData.data;
        var pageList = null;
        var pageInfo = null;

        var masterPage = 'root';
        this.CreatePage(siteId, masterPage, 'm', '', 'Master Page');
        this.CreatePage(siteId, 'home', 'p', masterPage, 'Free Offer');

        for (var fli = 0; fli < featureList.length; fli++) {
            feature = featureList[fli];
            pageList = JSON.parse(feature.pageList);
            for (var pi = 0; pi < pageList.pages.length; pi++) {
                pageInfo = pageList.pages[pi];
                this.CreatePage(siteId, pageInfo.pageName, pageInfo.pageType, pageInfo.masterPage, pageInfo.purpose);    
            }           
        }
    },

    CreatePage: async function (siteId, pageName, pageType, masterPage, purpose) {
        var newSitePageId = helpers.getUUID();
        var formData = { sitePageId: newSitePageId, baid: 0, siteId: siteId, name: pageName, slug: pageName, pageType: pageType, masterPage: masterPage, dateCreated: new Date().toISOString(), purpose:purpose };
        //BAFetchData({
        //    callName: "AddPage", method: "POST", url: "cms/sitepages/add",
        //    token: "fg1234", body: JSON.stringify(formData), onSuccess: null, onError: null
        //});

        const pageData = await BAFetchData({
            callName: "AddPage",
            method: "POST",
            url: "cms/sitepages/add",
            token: "fg1234", body: JSON.stringify(formData), onSuccess: null, onError: null
        });


        const pagePurposeData = await BAFetchData({
            callName: "GetPurposeRecord",
            method: "GET",
            url: "cms/purpose/getrecord?purposename=" + pageData.data.purpose,
            token: "fg1234", body: null, onSuccess: null, onError: null
        });

        var sectionSettings = JSON.parse(pagePurposeData.data.settings);
        var sections = sectionSettings.sections;
        var section = null;
        var conditions = [];

        var rootId = 'fpgwidget_' + helpers.getUUID();
        var elements = {};
        elements[rootId] = {
            id: rootId, customId: "", elementName: "body", tag: "div", widgetType: "container", draggable: false,
            className: "", inlineStyle: {}, breakpointStyles: {}, children: [], parents: [], placeholderPosition: "", events: {}
        };


        for (var si = 0; si < sections.length; si++) {
            section = sections[si];
            conditions = [];
            conditions.push({ "Name": "baid", "ConditionOperator": "EQUAL", "Value": "1", "DataType": "Number" });
            conditions.push({ "Name": "category", "ConditionOperator": "EQUAL", "Value": section.sectionCategory.replaceAll('_', ' '), "DataType": "String" });
            conditions.push({ "Name": "templateName", "ConditionOperator": "EQUAL", "Value": section.templateName, "DataType": "String" });
            if (section.keyword != '') {
                conditions.push({ "Name": "keywords", "ConditionOperator": "CONTAINS", "Value": section.keyword, "DataType": "String" });
            }

            var filtering = {
                "LogicalOperator": "and", "ConditionGroups": [
                    { "LogicalOperator": "and", "Conditions": conditions }
                ]
            };

            const sectionTemplateData = await BAFetchData({
                callName: "GetTemplateSectionForAi",
                method: "GET",
                url: "template/getlistcustom?filtering=" + JSON.stringify(filtering),
                token: "fg1234", body: null, onSuccess: null, onError: null
            });     

            var componentsStr = sectionTemplateData.data[0].components
            var originalSectionElements = JSON.parse(componentsStr);
            var sectionElements = null;
            var sectionRootId = null;
            var newKey = null;
            for (const key in originalSectionElements) {
                newKey = 'fpgwidget_' + helpers.getUUID();
                componentsStr = componentsStr.replaceAll(key, newKey);
            }
            sectionElements = JSON.parse(componentsStr);
            sectionRootId = helpers.getRootElementId(sectionElements);
            elements = Object.assign(elements, sectionElements);


            elements[rootId].children.push(sectionRootId);
            elements[sectionRootId].parents.push(rootId);          

            var test = "test";
            test = "hellos";
        }


        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "sitepageid", "ConditionOperator": "EQUAL", "Value": newSitePageId, "DataType": "string" },
                    { "Name": "active", "ConditionOperator": "EQUAL", "Value": "True", "DataType": "boolean" },
                    { "Name": "primaryvariant", "ConditionOperator": "EQUAL", "Value": "True", "DataType": "boolean" }
                ]
            }]
        };

        const pageVariantData = await BAFetchData({
            callName: "GetTemplateSectionForAi",
            method: "GET",
            url: "cms/pagevariants/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234", body: null, onSuccess: null, onError: null
        });     

        var formData = pageVariantData.data[0]
        formData.components = JSON.stringify(elements);


        const pageVariantDataUpdated = await BAFetchData({
            callName: "SaveForm",
            method: "PUT",
            url: "cms/pagevariants/update",
            token: "fg1234", body: JSON.stringify(formData), onSuccess: null, onError: null
        });     


        var test = "test";
        test = "hellos";

    },

    GetClonedSectionTemplate: async function (templateId) {
        //var finalElements = {};
        const sectionTemplateData = await BAFetchData({
            callName: "GetTemplateSectionForAi",
            method: "GET",
            url: "template/getrecord?templateid=" + templateId,
            token: "fg1234", body: null, onSuccess: null, onError: null
        });

        var componentsStr = sectionTemplateData.data.components
        var originalSectionElements = JSON.parse(componentsStr);
        var sectionElements = null;
        var sectionRootId = null;
        var newKey = null;
        for (const key in originalSectionElements) {
            newKey = 'fpgwidget_' + helpers.getUUID();
            componentsStr = componentsStr.replaceAll(key, newKey);
        }
        sectionElements = JSON.parse(componentsStr);
        //sectionRootId = helpers.getRootElementId(sectionElements);
        //finalElements = Object.assign(finalElements, sectionElements);
        //finalElements = sectionElements;
        return sectionElements;
    },

    UpdatePageDataSourcePropertiesAndRows: async function (dataSource) {
        var apiUrl = dataSource.apiEndpoint + '?'

        if (dataSource.demo == true) {
            apiUrl += "demo=true";
        }
        if (dataSource.designParameters != '') {
            if (helpers.getLastChar(apiUrl) == "?") {
                apiUrl += dataSource.designParameters;
            } else {
                apiUrl += "&" + dataSource.designParameters;
            }
        }

        if (dataSource.pageSize != "") {
            if (helpers.getLastChar(apiUrl) == "?") {
                apiUrl += "paginationParams=" + JSON.stringify({ "page": 1, "itemsPerPage": dataSource.pageSize });
            } else {
                apiUrl += "&paginationParams=" + JSON.stringify({ "page": 1, "itemsPerPage": dataSource.pageSize });
            }
        }

        const sectionTemplateData = await BAFetchData({
            callName: "Connect",
            method: "GET",
            url: process.env.REACT_APP_API_BASE_URL + apiUrl,
            token: "fg1234", body: null, onSuccess: null, onError: null
        });
        var data = sectionTemplateData.data;
        if (Array.isArray(data) == true) {
            var headerXPagination = JSON.parse(sectionTemplateData.headers['x-pagination']);
            if (data[0] != undefined) {
                dataSource.properties = data[0];
            }
            dataSource.rows = data;
            dataSource.paginationInfo = headerXPagination;
        } else {
            dataSource.properties = data;
            dataSource.rows.push(data);
            dataSource.paginationInfo = null;
        }

        //if (handlerReturnValue) {
        //    handlerReturnValue(tmpDataSource);
        //}
        //setDataSource((...dataSource) => { return tmpDataSource });


        ////BAFetchData({
        ////    callName: "Connect", method: "GET", url: process.env.REACT_APP_API_BASE_URL + apiUrl,
        ////    token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        ////}); 
        return dataSource;
    }

}

export default PageEngineUtil

